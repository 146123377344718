@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
.src-common-components-Wrapper-__styles-module___companyOpacity {
    opacity: .7 !important;
}

.src-common-features-BidStatus-BidAccept-__styles-module___qmerit-logo {
  width: 30px;
  margin-right: 12px !important;
  vertical-align: middle;
}

.src-common-features-BidStatus-BidAccept-__styles-module___bidderLogo {
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 29px;
  display: flex;
  flex-wrap: wrap;
}

.src-common-features-BidStatus-BidAccept-__styles-module___bidderLogo svg {
  width: 31px;
  height: 31px;
}

.src-common-features-BidStatus-BidAccept-__styles-module___bidderLogo p {
  align-self: center;
  margin-left: 13px;
  font-size: 16px;
  font-weight: bold;
}

.src-common-features-BidStatus-BidAccept-__styles-module___bidderName {
  font-size: 16px;
  font-weight: bold;
  color: #00bcd4;
  margin-top: 24px !important;
}

.src-common-features-BidStatus-BidAccept-__styles-module___bidRequest {
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.3px;
  color: #6e727c;
}

.src-common-features-BidStatus-BidAccept-__styles-module___description {
  font-family: Lato;
  font-size: 16px;
  color: #3e4148;
  padding: 16px 0 0;
  margin-bottom: 24px;
}

.src-common-features-BidStatus-BidAccept-__styles-module___documentArea {
  margin: 0 24px;
}

.src-common-features-BidStatus-BidAccept-__styles-module___documentArea a:first-of-type {
  background-color: #fafafa;
  width: 120px;
  height: 120px;
  float: left;
}

.src-common-features-BidStatus-BidAccept-__styles-module___documentArea img {
  width: 93px;
  height: 120px;
  margin-left: 14px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
    0 0 2px 0 rgba(0, 0, 0, 0.14);
}

.src-common-features-BidStatus-BidAccept-__styles-module___documentArea h4 {
  margin-left: 16px;
  padding-top: 24px;
  margin-bottom: 24px;
  float: left;
  font-size: 16px;
  font-weight: bold;
  color: #00bcd4;
}

.src-common-features-BidStatus-BidAccept-__styles-module___infoField {
  display: flex;
  clear: both;
  min-width: 264px;
  flex-wrap: wrap;
  margin: 0 -12px;
}

.src-common-features-BidStatus-BidAccept-__styles-module___customerLocationWrapper {
  flex-grow: 1;
  padding: 24px 12px 0;
}
.src-common-features-BidStatus-BidAccept-__styles-module___customerInfo {
  min-width: 264px;
  clear: both;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}

.src-common-features-BidStatus-BidAccept-__styles-module___customerInfo a {
  display: block;
}

/* .infoField >div, .customerInfo >div {
  width: 50%;
} */

.src-common-features-BidStatus-BidAccept-__styles-module___infoLabel {
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #6e727c;
  text-transform: capitalize;
  margin: 0 0 4px;
  width: 264px;
}

.src-common-features-BidStatus-BidAccept-__styles-module___customerOverflow {
  flex-grow: 1;
  padding: 0 12px;
}

.src-common-features-BidStatus-BidAccept-__styles-module___contactDetailsEmail {
  max-width: 250px;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #00bcd4;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.src-common-features-BidStatus-BidAccept-__styles-module___contactName {
  font-size: 16px;
  font-weight: bold;
  color: #3e4148;
}

.src-common-features-BidStatus-BidAccept-__styles-module___preferredContact {
  font-size: 12px;
  font-style: italic;
  line-height: 1.33;
  color: #6e727c;
}

.src-common-features-BidStatus-BidAccept-__styles-module___siteAddressDetails {
  font-size: 16px;
  color: #00bcd4;
}

.src-common-features-BidStatus-BidAccept-__styles-module___bidDetails {
  color: #3e4148;
}

.src-common-features-BidStatus-BidAccept-__styles-module___banner {
  margin-top: 48px;
}

.src-common-features-BidStatus-BidAccept-__styles-module___viewButtonWrapper {
  display: flex;
  justify-content: center;
}
.src-common-features-BidStatus-BidAccept-__styles-module___viewButton {
  width: 200px;
  margin-top: 48px;
  margin-bottom: 48px;
  border-radius: 100px;
  border: solid 1px #3359ba;
  background-image: linear-gradient(to bottom, #3862c5, #3359ba);
  font-size: 12px;
  line-height: 40px;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  display: block;
}

.src-common-features-BidStatus-BidAccept-__styles-module___bidNowSection {
  min-height: 263px;
  background-image: linear-gradient(to bottom, #00bcd4, #3862c5 67%, #2b4dac);
  margin: 0 -24px;
  padding: 24px 24px 0 24px;
}

.src-common-features-BidStatus-BidAccept-__styles-module___viewBidNowTitle {
  color: #fff;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 8px;
}

.src-common-features-BidStatus-BidAccept-__styles-module___viewBidNowText {
  color: #fff;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 16px;
}

.src-common-features-BidStatus-BidAccept-__styles-module___viewBidNowButtonSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.src-common-features-BidStatus-BidAccept-__styles-module___viewButtonVertCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 180px;
  max-width: 264px;
}
.src-common-features-BidStatus-BidAccept-__styles-module___viewBidButtonText {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  width: 264px;
  height: 40px;
  background-image: linear-gradient(to bottom, #ffffff, #f4f5f6);
  border-radius: 100px;
  color: #00bcd4;
  cursor: pointer;
  border: none;
}

.src-common-features-BidStatus-BidAccept-__styles-module___viewBidNowImageSection {
  width: 264px;
  height: 180px;
  margin-top: 1px;
}
.src-common-features-BidStatus-BidAccept-__styles-module___viewBidNowImage {
  max-width: 100%;
  max-height: 100%;
  margin-top: 1px;
}

.src-common-features-BidStatus-BidAccept-__styles-module___viewBidContact {
  padding-top: 24px;
  color: #3e4148;
}

.src-common-features-BidStatus-BidAccept-__styles-module___viewBidContactText {
  color: #00bcd4;
}
@media (max-width: 576.98px) {
  .src-common-features-BidStatus-BidAccept-__styles-module___bidNowSection {
    margin: 0 -16px;
  }
}
/* iPhone 5 ----------- */

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  .src-common-features-BidStatus-BidAccept-__styles-module___customerLocationWrapper {
    margin: 24px 0 0 0;
  }

  .src-common-features-BidStatus-BidAccept-__styles-module___viewBidNowButtonSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .src-common-features-BidStatus-BidAccept-__styles-module___viewButtonVertCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    max-width: 264px;
  }
}

/* iPhone 6, 7, 8 ----------- */

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  .src-common-features-BidStatus-BidAccept-__styles-module___customerLocationWrapper {
    margin: 24px 0 0 0;
  }
}

/* iPhone 6+, 7+, 8+ ----------- */

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .src-common-features-BidStatus-BidAccept-__styles-module___customerLocationWrapper {
    margin: 24px 0 0 0;
  }
}

/* iPhone X ----------- */

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .src-common-features-BidStatus-BidAccept-__styles-module___customerLocationWrapper {
    margin: 24px 0 0 0;
  }

  .src-common-features-BidStatus-BidAccept-__styles-module___viewBidNowButtonSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .src-common-features-BidStatus-BidAccept-__styles-module___viewButtonVertCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    max-width: 264px;
  }
}

/* iPhone XS MAX----------- */

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .src-common-features-BidStatus-BidAccept-__styles-module___customerLocationWrapper {
    margin: 24px 0 0 0;
  }

  .src-common-features-BidStatus-BidAccept-__styles-module___viewBidNowButtonSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .src-common-features-BidStatus-BidAccept-__styles-module___viewButtonVertCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    max-width: 264px;
  }
}

/* iPhone XR ----------- */

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .src-common-features-BidStatus-BidAccept-__styles-module___customerLocationWrapper {
    margin: 24px 0 0 0;
  }

  .src-common-features-BidStatus-BidAccept-__styles-module___viewBidNowButtonSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .src-common-features-BidStatus-BidAccept-__styles-module___viewButtonVertCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    max-width: 264px;
  }
}

/* iPhone XR Long----------- */

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 2) {
  .src-common-features-BidStatus-BidAccept-__styles-module___customerLocationWrapper {
    margin: 24px 0 0 0;
  }

  .src-common-features-BidStatus-BidAccept-__styles-module___viewBidNowButtonSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .src-common-features-BidStatus-BidAccept-__styles-module___viewButtonVertCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    max-width: 264px;
  }
}

/* ALL 414PX ----------- */

@media only screen and (device-width: 414px) and (-webkit-device-pixel-ratio: 2) {
  .src-common-features-BidStatus-BidAccept-__styles-module___customerLocationWrapper {
    margin: 24px 0 0 0;
  }

  .src-common-features-BidStatus-BidAccept-__styles-module___viewBidNowButtonSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .src-common-features-BidStatus-BidAccept-__styles-module___viewButtonVertCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    max-width: 264px;
  }
}

.src-common-features-BidStatus-BidDecline-__styles-module___qmerit-logo {
  width: 30px;
  margin-right: 6px;
  vertical-align: middle;
}

.src-common-features-BidStatus-BidDecline-__styles-module___declineFlexContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.src-common-features-BidStatus-BidDecline-__styles-module___bidName {
  font-size: 16px;
  color: #00bcd4;
  margin: 0;
}
.src-common-features-BidStatus-BidDecline-__styles-module___bidNumber {
  margin-top: 0;
  color: #6e727c;
  font-size: 12px;
}

.src-common-features-BidStatus-BidDecline-__styles-module___declineField {
  margin-bottom: 8px;
}

.src-common-features-BidStatus-BidDecline-__styles-module___inputLabel {
  display: inline;
  padding-left: 8px;
  color: #b9bbc1;
}

.src-common-features-BidStatus-BidDecline-__styles-module___declineInput {
  max-width: 20px;
  margin: 0 0 4px 8px;
}

input[type="radio"] {
  -webkit-appearance: radio;
}

input:checked + .src-common-features-BidStatus-BidDecline-__styles-module___inputLabel {
  color: #3e4148;
}

.src-common-features-BidStatus-BidDecline-__styles-module___bdDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  color: #3e4148;
  margin: 24px 0 22px;
}

.src-common-features-BidStatus-BidDecline-__styles-module___declineReasonLabel {
  font-size: 14px;
  color: #3e4148;
}

.src-common-features-BidStatus-BidDecline-__styles-module___textAreaContainer {
  margin: 20px 0 0;
  padding: 0 40px;
}
.src-common-features-BidStatus-BidDecline-__styles-module___declineTextArea {
  width: 100% !important;
  margin: 4px 0 0;
  padding: 8px;
  height: 64px;
  min-height: 64px;
  border: solid 1px #dedfe2;
  background-color: #fafafa;
}

.src-common-features-BidStatus-BidDecline-__styles-module___buttonContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 16px;
}

.src-common-features-BidStatus-BidDecline-__styles-module___declineButton {
  text-align: center;
  border-radius: 100px;
  border: solid 1px #e64a19;
  background-image: linear-gradient(to bottom, #ff5722, #e64a19);

  width: 100%;
  max-width: 200px;
  height: 40px;

  cursor: pointer;

  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 1px;
  color: #ffffff;
  text-transform: uppercase;
}

.src-common-features-BidStatus-BidDecline-__styles-module___declineButton:disabled {
  opacity: 0.5;
}

@media (max-width: 576.98px) {
  .src-common-features-BidStatus-BidDecline-__styles-module___declineButton {
    max-width: 279px;
  }
}

.src-common-components-Radio-__styles-module___radioWraper {
  padding-left: 14px;
  margin-bottom: 12px;
}
.src-common-components-Radio-__styles-module___radioWraper .src-common-components-Radio-__styles-module___radioFieldWraper {
}
.src-common-components-Radio-__styles-module___radioWraper .src-common-components-Radio-__styles-module___radioFieldWraper .src-common-components-Radio-__styles-module___radioField {
  display: none;
}
.src-common-components-Radio-__styles-module___radioWraper .src-common-components-Radio-__styles-module___radioFieldWraper label {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  color: rgba(62, 65, 72, 0.35);
  display: inline-block;
  cursor: pointer;
}
.src-common-components-Radio-__styles-module___radioWraper .src-common-components-Radio-__styles-module___radioFieldWraper label::before {
  content: "\F111";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  margin-right: 10px;
  color: #b9bbc1;
}
.src-common-components-Radio-__styles-module___radioWraper .src-common-components-Radio-__styles-module___radioFieldWraper label.src-common-components-Radio-__styles-module___square::before {
  font-size: 16px;
  content: '\F0C8'
}

.src-common-components-Radio-__styles-module___radioWraper .src-common-components-Radio-__styles-module___radioFieldWraper .src-common-components-Radio-__styles-module___radioField:checked + label {
  color: #3e4148;
}
.src-common-components-Radio-__styles-module___radioWraper .src-common-components-Radio-__styles-module___radioFieldWraper .src-common-components-Radio-__styles-module___radioField[id="not"] + label {
  margin-bottom: 22px;
}
.src-common-components-Radio-__styles-module___radioWraper .src-common-components-Radio-__styles-module___radioFieldWraper .src-common-components-Radio-__styles-module___radioField[id="not"]:checked + label {
  margin-bottom: 0;
}
.src-common-components-Radio-__styles-module___radioWraper .src-common-components-Radio-__styles-module___radioFieldWraper .src-common-components-Radio-__styles-module___radioField:checked + label::before {
  content: "\F192";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  color: #00bcd4;
}
.src-common-components-Radio-__styles-module___radioWraper .src-common-components-Radio-__styles-module___radioFieldWraper .src-common-components-Radio-__styles-module___radioField:checked + label.src-common-components-Radio-__styles-module___square::before {
  font-size: 16px;
  content: '\F14A';
}
.src-common-components-Radio-__styles-module___radioWraper .src-common-components-Radio-__styles-module___radioBody {
  display: none;
}
.src-common-components-Radio-__styles-module___radioWraper .src-common-components-Radio-__styles-module___radioBody:empty {
  display: none !important;
}
.src-common-components-Radio-__styles-module___radioWraper .src-common-components-Radio-__styles-module___radioFieldWraper .src-common-components-Radio-__styles-module___radioField:checked + label + .src-common-components-Radio-__styles-module___radioBody {
  display: block;
  padding: 14px 24px 0px;
}

/* .qmerit-logo {
  width: 30px;
  margin-right: 6px !important;
  vertical-align: middle;
} */

.src-common-features-BidStatus-ActionBidAccept-__styles-module___bidderLogo {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 29px;
  font-size: 16px;
  font-weight: bold;
}
.src-common-features-BidStatus-ActionBidAccept-__styles-module___bidderLogo svg {
  width: 31px;
  height: 31px;
}
.src-common-features-BidStatus-ActionBidAccept-__styles-module___bidderLogo p {
  align-self: center;
  margin-left: 13px;
  font-size: 16px;
  font-weight: bold;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___bidderName {
  font-size: 16px;
  font-weight: bold;
  color: #00bcd4;
  margin-top: 24px !important;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___bidRequest {
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.3px;
  color: #6e727c;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___description {
  font-family: Lato;
  font-size: 16px;
  color: #3e4148;
  padding: 16px 0;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___documentArea {
  margin: 8px 0;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___documentArea a:first-of-type {
  background-color: #fafafa;
  width: 120px;
  height: 120px;
  float: left;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___documentArea img {
  width: 93px;
  height: 120px;
  margin-left: 14px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
    0 0 2px 0 rgba(0, 0, 0, 0.14);
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___documentArea h4 {
  margin-left: 16px;
  padding-top: 24px;
  margin-bottom: 24px;
  float: left;
  font-size: 16px;
  font-weight: bold;
  color: #00bcd4;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___infoField {
  min-width: 264px;
  clear: both;

  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___customerLocationWrapper {
  flex-grow: 1;
  padding: 24px 12px 0;
}
.src-common-features-BidStatus-ActionBidAccept-__styles-module___customerInfo {
  min-width: 264px;
  clear: both;

  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___customerInfo a {
  display: block;
}

/* .infoField >div, .customerInfo >div {
  width: 50%;
} */

.src-common-features-BidStatus-ActionBidAccept-__styles-module___infoLabel {
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #6e727c;
  text-transform: capitalize;
  margin: 4px 0 4px 0;
  width: 264px;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___contactDetails {
  font-size: 16px;
  color: #3e4148;
  margin-top: 8px;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___customerOverflow {
  flex-grow: 1;
  padding: 0 12px;
  padding-top: 24px;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___contactDetailsEmail {
  max-width: 250px;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #00bcd4;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___contactName {
  font-size: 16px;
  font-weight: bold;
  color: #3e4148;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___preferredContact {
  font-size: 12px;
  font-style: italic;
  line-height: 1.33;
  color: #6e727c;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___siteAddressDetails {
  font-size: 16px;
  color: #00bcd4;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___bidDetails {
  color: #3e4148;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___actionsButtonWrapper {
  min-width: 256px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___viewButtonAccept {
  width: 264px;
  margin-top: 48px;
  margin-bottom: 48px;
  border-radius: 100px;
  border: solid 1px #7cb342;
  background-image: linear-gradient(to bottom, #8bc34a, #7cb342);
  font-size: 12px;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___viewButtonDecline {
  width: 264px;
  margin-top: 48px;
  margin-bottom: 48px;
  border-radius: 100px;
  border: solid 1px #f4511e;
  background-image: linear-gradient(to bottom, #ff5722, #f4511e);
  font-size: 12px;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-left: 16px;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___banner {
  margin-top: 48px;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___viewButtonWrapper {
  display: flex;
  justify-content: center;
}
.src-common-features-BidStatus-ActionBidAccept-__styles-module___viewButton {
  width: 200px;
  margin-top: 48px;
  margin-bottom: 48px;
  border-radius: 100px;
  border: solid 1px #3359ba;
  background-image: linear-gradient(to bottom, #3862c5, #3359ba);
  font-size: 12px;
  line-height: 40px;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  display: block;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___bidNowSection {
  min-height: 263px;
  background-image: linear-gradient(to bottom, #00bcd4, #3862c5 67%, #2b4dac);
  margin: 0 -24px;
  padding: 24px 24px 0 24px;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___viewBidNowTitle {
  color: #fff;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: bold;
  margin-bottom: 8px;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___viewBidNowText {
  color: #fff;
  font-family: "Lato";
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 16px;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___viewBidNowButtonSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___viewButtonVertCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 180px;
  max-width: 264px;
}
.src-common-features-BidStatus-ActionBidAccept-__styles-module___viewBidButtonText {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  width: 264px;
  height: 40px;
  background-image: linear-gradient(to bottom, #ffffff, #f4f5f6);
  border-radius: 100px;
  color: #00bcd4;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___viewBidNowImageSection {
  width: 264px;
  height: 180px;
  margin-top: 1px;
}
.src-common-features-BidStatus-ActionBidAccept-__styles-module___viewBidNowImage {
  max-width: 100%;
  max-height: 100%;
  margin-top: 1px;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___viewBidContact {
  padding-top: 24px;
  color: #3e4148;
}

.src-common-features-BidStatus-ActionBidAccept-__styles-module___viewBidContactText {
  color: #00bcd4;
}
@media (max-width: 576.98px) {
  .src-common-features-BidStatus-ActionBidAccept-__styles-module___bidNowSection {
    margin: 0 -16px;
  }
}
/* iPhone 5 ----------- */

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  .src-common-features-BidStatus-ActionBidAccept-__styles-module___customerLocationWrapper {
    margin: 24px 0 0 0;
  }

  .src-common-features-BidStatus-ActionBidAccept-__styles-module___viewBidNowButtonSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .src-common-features-BidStatus-ActionBidAccept-__styles-module___viewButtonVertCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    max-width: 264px;
  }
}

/* iPhone 6, 7, 8 ----------- */

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  .src-common-features-BidStatus-ActionBidAccept-__styles-module___customerLocationWrapper {
    margin: 24px 0 0 0;
  }

  .src-common-features-BidStatus-ActionBidAccept-__styles-module___viewBidNowButtonSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .src-common-features-BidStatus-ActionBidAccept-__styles-module___viewButtonVertCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    max-width: 264px;
  }
}

/* iPhone 6+, 7+, 8+ ----------- */

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .src-common-features-BidStatus-ActionBidAccept-__styles-module___customerLocationWrapper {
    margin: 24px 0 0 0;
  }

  .src-common-features-BidStatus-ActionBidAccept-__styles-module___viewBidNowButtonSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .src-common-features-BidStatus-ActionBidAccept-__styles-module___viewButtonVertCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    max-width: 264px;
  }
}

/* iPhone X ----------- */

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .src-common-features-BidStatus-ActionBidAccept-__styles-module___customerLocationWrapper {
    margin: 24px 0 0 0;
  }

  .src-common-features-BidStatus-ActionBidAccept-__styles-module___viewBidNowButtonSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .src-common-features-BidStatus-ActionBidAccept-__styles-module___viewButtonVertCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    max-width: 264px;
  }
}

/* iPhone XS MAX----------- */

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .src-common-features-BidStatus-ActionBidAccept-__styles-module___customerLocationWrapper {
    margin: 24px 0 0 0;
  }

  .src-common-features-BidStatus-ActionBidAccept-__styles-module___viewBidNowButtonSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .src-common-features-BidStatus-ActionBidAccept-__styles-module___viewButtonVertCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    max-width: 264px;
  }
}

/* iPhone XR ----------- */

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .src-common-features-BidStatus-ActionBidAccept-__styles-module___customerLocationWrapper {
    margin: 24px 0 0 0;
  }

  .src-common-features-BidStatus-ActionBidAccept-__styles-module___viewBidNowButtonSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .src-common-features-BidStatus-ActionBidAccept-__styles-module___viewButtonVertCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    max-width: 264px;
  }
}

* {
  -webkit-font-smoothing: antialiased;
}
.src-common-features-BidStatus-CustomerDeclined-__styles-module___qmerit-logo {
  width: 30px;
  margin-right: 6px;
  vertical-align: middle;
}

.src-common-features-BidStatus-CustomerDeclined-__styles-module___declineFlexContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.src-common-features-BidStatus-CustomerDeclined-__styles-module___bidName {
  font-size: 16px;
  color: #00bcd4;
  margin: 0;
  margin-bottom: 3px;
}
.src-common-features-BidStatus-CustomerDeclined-__styles-module___bidNumber {
  margin-top: 0;
  color: #6e727c;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  margin-bottom: 25px;
}

.src-common-features-BidStatus-CustomerDeclined-__styles-module___greenBelt {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14);
  background-color: rgba(220, 237, 200, 0.95);
  margin-bottom: 20px;
  margin-top: 22px;
}
.src-common-features-BidStatus-CustomerDeclined-__styles-module___alertMsg {
  position: relative;
}
.src-common-features-BidStatus-CustomerDeclined-__styles-module___alertMsg p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  color: #33691e;
  padding: 7.5px 15px;
  padding-left: 40px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14);
  background-color: rgba(220, 237, 200, 0.95);
  margin: 0 0 17px;
}
.src-common-features-BidStatus-CustomerDeclined-__styles-module___alertMsg img {
  position: absolute;
  top: 11px;
  left: 14px;
  width: 15px;
  margin-right: 11px;
}
.src-common-features-BidStatus-CustomerDeclined-__styles-module___headingcustomer {
  margin-bottom: 22px;
}

.src-common-features-BidStatus-CustomerDeclined-__styles-module___headingcustomer p {
  font-size: 16px;
  color: #3e4148;
}

.src-common-features-BidStatus-CustomerDeclined-__styles-module___greenText {
  width: 500px;
  /* height: 20px; */
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #33691e;
  flex-wrap: wrap;
  margin: 7.5px 15px;
}

.src-common-features-BidStatus-CustomerDeclined-__styles-module___greenText img {
  width: 16px;
  position: relative;
  top: 4px;
  margin-right: 12px;
}

.src-common-features-BidStatus-CustomerDeclined-__styles-module___declineField {
  margin-bottom: 16px;
}

.src-common-features-BidStatus-CustomerDeclined-__styles-module___inputLabel {
  display: inline;
  padding-left: 8px;
  color: #b9bbc1;
  font-weight: 300;
}

.src-common-features-BidStatus-CustomerDeclined-__styles-module___declineInput {
  max-width: 20px;
  margin: 0 0 0px 8px;
  position: relative;
  top: -2px;
}

input:checked + .src-common-features-BidStatus-CustomerDeclined-__styles-module___inputLabel {
  color: #3e4148;
}

.src-common-features-BidStatus-CustomerDeclined-__styles-module___add_a_note {
  color: #00bcd4;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 5px;
}
.src-common-features-BidStatus-CustomerDeclined-__styles-module___bdDescription {
  font-size: 16px;
  color: #6e727c;
  margin: 16px 0;
}

.src-common-features-BidStatus-CustomerDeclined-__styles-module___declineReasonLabel {
  font-size: 14px;
  color: #3e4148;
  margin-left: 0px;
  font-weight: 400;
  width: 100%;
}

.src-common-features-BidStatus-CustomerDeclined-__styles-module___textAreaContainer {
  /* max-width: 498px; */
  /* min-width: 498px; */
  width: calc(100% - 45px);
  margin: 6px 0 0 45px;
  display: flex;
  flex-wrap: wrap;
}
.src-common-features-BidStatus-CustomerDeclined-__styles-module___declineTextArea {
  /* max-width: 498px; */
  width: calc(100% - 40px) !important;
  max-width: calc(100% - 40px) !important;
  min-width: calc(100% - 40px) !important;
  /* min-width: auto; */
  margin: 6px 0 0 0px;
  padding: 9px 16px;
  height: 64px;
  -webkit-appearance: none;
  box-shadow: none !important;
  border: 1px solid #dedfe2;
  background-color: #fafafa;
  font-weight: normal;
  display: block;
  color: #3e4148;
  -webkit-font-smoothing: antialiased;
}

@media (max-width: 380px) {
  .src-common-features-BidStatus-CustomerDeclined-__styles-module___declineTextArea {
    height: 80px;
  }
}

.src-common-features-BidStatus-CustomerDeclined-__styles-module___declineTextArea::-moz-placeholder {
  font-weight: normal;
  font-size: 16px;
  color: #b9bbc1;
}

.src-common-features-BidStatus-CustomerDeclined-__styles-module___declineTextArea::placeholder {
  font-weight: normal;
  font-size: 16px;
  color: #b9bbc1;
}

.src-common-features-BidStatus-CustomerDeclined-__styles-module___buttonContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 25px;
}

.src-common-features-BidStatus-CustomerDeclined-__styles-module___declineButton {
  width: 120px;
  height: 32px;
  border-radius: 100px;
  text-transform: uppercase;
  border: solid 1px #00acc1;
  background: #00acc1;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #ffffff;
  margin-left: auto;
  font-weight: bold;
}

.src-common-features-BidStatus-CustomerDeclined-__styles-module___declineButton:disabled {
  opacity: 0.5;
}

.src-common-features-BidStatus-CustomerDeclined-__styles-module___radioFieldWraperSec {
  margin-bottom: 12px;
}
.src-common-features-BidStatus-CustomerDeclined-__styles-module___radioFieldWraperSec input {
  display: none;
}
.src-common-features-BidStatus-CustomerDeclined-__styles-module___radioFieldWraperSec label {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.25;
  color: #b9bbc1;
  padding-left: 14px;
}
.src-common-features-BidStatus-CustomerDeclined-__styles-module___radioFieldWraperSec label::before {
  content: "\F111";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  color: #b9bbc1;
  margin-right: 11px;
}
.src-common-features-BidStatus-CustomerDeclined-__styles-module___radioFieldWraperSec input:checked + label::before {
  content: "\F192";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  color: #00bcd4;
}

.src-common-features-BidStatus-CustomerDeclined-__styles-module___radioFieldWraperSec input:checked + label {
  font-weight: 400;
  color: #3e4148;
}

/* .declineTextArea {
  background-image: url("../../../../../public/images/icon-02.svg");
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 99.5% 98%;
} */

* {
  -webkit-font-smoothing: antialiased;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___quoteTitle {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  color: #00bcd4;
  display: block;
  margin-bottom: 3px;
}
.src-common-features-BidStatus-CustomerContacted-__styles-module___alertMsg {
  position: relative;
}
.src-common-features-BidStatus-CustomerContacted-__styles-module___alertMsg p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  color: #33691e;
  padding: 7.5px 15px;
  padding-left: 40px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14);
  background-color: rgba(220, 237, 200, 0.95);
  margin: 0 0 17px;
}
.src-common-features-BidStatus-CustomerContacted-__styles-module___alertMsg img {
  position: absolute;
  top: 11px;
  left: 14px;
  width: 15px;
  margin-right: 11px;
}
.src-common-features-BidStatus-CustomerContacted-__styles-module___greenBelt {
  display: flex;
  flex-wrap: wrap;
  min-height: 44px;
  height: auto;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14);
  background-color: rgba(220, 237, 200, 0.95);
  margin-bottom: 24px;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___greenText {
  width: 500px;
  height: 20px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #33691e;
  flex-wrap: wrap;
  margin: 8px 0 0 16px;
}
.src-common-features-BidStatus-CustomerContacted-__styles-module___comments {
  color: #b9bbc1;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  line-height: 1.43;
  letter-spacing: 0.3px;
}
.src-common-features-BidStatus-CustomerContacted-__styles-module___appointmentText {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.3px;
}
.src-common-features-BidStatus-CustomerContacted-__styles-module___selectDateWraper {
  position: relative;
  margin-bottom: 15px;
}
.src-common-features-BidStatus-CustomerContacted-__styles-module___btnWraper {
  padding-top: 13px;
  display: flex;
  justify-content: space-between;
}
.src-common-features-BidStatus-CustomerContacted-__styles-module___btnWraper .src-common-features-BidStatus-CustomerContacted-__styles-module___btn {
  cursor: pointer;
  border: 0;
  box-shadow: none;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  background: transparent;
  height: 32px;
  padding: 0;
}
.src-common-features-BidStatus-CustomerContacted-__styles-module___btnWraper .src-common-features-BidStatus-CustomerContacted-__styles-module___btn.src-common-features-BidStatus-CustomerContacted-__styles-module___btnNote {
  letter-spacing: 0.5px;
  color: #00bcd4;
  text-transform: uppercase;
}
.src-common-features-BidStatus-CustomerContacted-__styles-module___btnWraper .src-common-features-BidStatus-CustomerContacted-__styles-module___btn.src-common-features-BidStatus-CustomerContacted-__styles-module___btnDone {
  border-radius: 100px;
  letter-spacing: 1px;
  color: #ffffff;
  min-width: 120px;
  text-align: center;
  padding: 0 15px;
  background-image: linear-gradient(to bottom, #00bcd4, #00acc1);
  text-transform: uppercase;
  font-weight: bold;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___selectDateWraper input {
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  color: #3e4148;
  text-align: left;
  border: solid 1px #dedfe2;
  background: #fafafa;
  height: 44px;
  margin: 0;
  width: 100%;
  padding: 0 30px 0 12px;
  box-sizing: border-box;
}
.src-common-features-BidStatus-CustomerContacted-__styles-module___selectDateWraper i {
  color: #969ba5;
  font-size: 18.27px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.src-common-features-BidStatus-CustomerContacted-__styles-module___quoteBRnum {
  font-size: 12px;
  margin: 0 0 16px 0;
  color: #6e727c;
  line-height: 16px;
  letter-spacing: 0.3px;
}
.src-common-features-BidStatus-CustomerContacted-__styles-module___quoteBRnum span {
  letter-spacing: 0;
}
.src-common-features-BidStatus-CustomerContacted-__styles-module___incMsg {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  color: #3e4148;
  margin-bottom: 22px;
  margin-top: 0px;
}
.src-common-features-BidStatus-CustomerContacted-__styles-module___quoteSubTitle {
  font-size: 14px;
  color: #3e4148;
  font-weight: normal;
  margin: 0;
  padding-bottom: 4px;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___deciUpload {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-wrap: wrap;
}

input {
  width: 50%;
  border: 0;
  margin-left: 24px;
  font-size: 12px;
  background-color: #fafafa;
  text-align: right;
  padding-right: 16px;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___inputRed {
  width: 50%;
  border: 0;
  margin-left: 24px;
  font-size: 12px;
  background-color: #fbe9e7;
  text-align: right;
  padding-right: 16px;
  color: #ff5722;
}

input:focus {
  outline: none;
}

/* .deciBox {
    margin-bottom: 24px;
} */
.src-common-features-BidStatus-CustomerContacted-__styles-module___quoteErrorContainer {
  display: flex;
  flex-direction: row;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___quoteIcon {
  margin: 2.55px;
  color: #ff5722;
}
.src-common-features-BidStatus-CustomerContacted-__styles-module___first {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 264px;
  border: 1px solid #dedfe2;
  background-color: #fafafa;
  height: 44px;
  border-radius: 4px;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___firstRed {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 264px;
  border: 1px solid #ff5722;
  background-color: #fbe9e7;
  height: 44px;
  border-radius: 4px;
  z-index: 1;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___quoteDollar {
  padding: 12px 0 0 12px;
  color: #b9bbc1;
  font-size: 16px;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___quoteDollarRed {
  padding: 12px 0 0 12px;
  color: red;
  font-size: 16px;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___second {
  width: 70px;
  border: 1px solid #dedfe2;
  height: 32px;
  background-color: #dedfe2;
  text-align: center;
  padding-top: 11px;
  color: #6e727c;
  border-radius: 0 4px 4px 0;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___secondRed {
  width: 70px;
  border-color: #ff5722;
  border-radius: 0 4px 4px 0;
  border: 0 0 0 1px;
  height: 33px;
  background-color: #ff5722;
  text-align: center;
  padding-top: 11px;
  z-index: 99;
  color: #fff;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___deciError {
  color: #ff5722;
  font-size: 14px;
  font-style: italic;
  margin-top: 4px;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___quoteUploadSection {
  margin-left: 0 0 24px 24px;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___quoteOption {
  font-size: 14px;
  font-style: italic;
  /* color: #b9bbc1; */
  color: #6e727c;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___quoteDrop {
  width: 264px;
  height: 144px;
  border: 1px dashed #dedfe2;
  background-color: #f4f5f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___quoteCircle {
  display: flex;
  align-self: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-image: linear-gradient(to bottom, #00bcd4, #00acc1);
  color: #fff;
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___quotePlus {
  font-size: 24px;
  margin-top: 6px;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___uploadLabel {
  display: flex;
  align-self: center;
  font-weight: bold;
  font-size: 16px;
  color: #3e4148;
  margin-bottom: 4px;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___quoteInstructions {
  display: flex;
  align-self: center;
  font-size: 12px;
  color: #6e727c;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___quoteMessageSection {
  width: 552px;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___quoteSubTitle {
  font-size: 14px;
  color: #3e4148;
  font-weight: normal;
  margin: 0;
  padding-bottom: 4px;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___quoteOption2 {
  font-size: 14px;
  font-style: italic;
  color: #b9bbc1;
}

textarea {
  width: 100%;
  /* max-width: 518px;
  min-width: 518px; */
  max-height: 40vh;
  border: 1px solid #dedfe2;
  background-color: #fafafa;
  border-radius: 4px;
  padding: 16px;
  font-size: 16px;
}

textarea:focus {
  outline: #00bcd4;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #b9bbc1;
  font-family: "Lato";
  font-size: 16px;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #b9bbc1;
  font-family: Lato;
  font-size: 16px;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #b9bbc1;
  font-family: Lato;
  font-size: 16px;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #b9bbc1;
  font-family: Lato;
  font-size: 16px;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___quoteButtonSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___quoteButton {
  margin: 0;
  padding: 0;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___buttonText {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  width: 200px;
  height: 40px;
  background-image: linear-gradient(to bottom, #8bc34a, #7cb342);
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  border: none;
}

.src-common-features-BidStatus-CustomerContacted-__styles-module___buttonText:disabled {
  opacity: 0.5;
}

/* Samsung Galaxy S5 ----------- */

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .src-common-features-BidStatus-CustomerContacted-__styles-module___quoteMessageSection {
    width: 274px;
    max-width: 274px;
  }

  textarea {
    width: 244px !important;
    max-width: 244px !important;
    min-width: 244px !important;
    padding: 16px;
    min-height: 10vh;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___first {
    width: 274px !important;
    max-width: 274px !important;
    margin-bottom: 24px;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___firstRed {
    width: 274px !important;
    max-width: 274px !important;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___deciError {
    margin-bottom: 24px;
  }
}

/* Google Pixel 2 ------------------ */

@media only screen and (min-device-width: 411px) and (max-device-height: 731px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .src-common-features-BidStatus-CustomerContacted-__styles-module___quoteMessageSection {
    width: 294px;
    max-width: 294px;
  }

  textarea {
    /* width: 294px !important;
    max-width: 294px !important;
    min-width: 294px !important; */
    padding: 16px;
    min-height: 10vh;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___first {
    width: 274px !important;
    max-width: 274px !important;
    margin-bottom: 24px;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___firstRed {
    width: 274px !important;
    max-width: 274px !important;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___deciError {
    margin-bottom: 24px;
  }
}

/* iPhone 5 ----------- */

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  .src-common-features-BidStatus-CustomerContacted-__styles-module___quoteMessageSection {
    width: 248px;
    max-width: 248px;
  }

  textarea {
    /* width: 248px !important;
    max-width: 248px !important;
    min-width: 248px !important; */
    padding: 16px;
    min-height: 10vh;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___first {
    width: 280px !important;
    max-width: 280px !important;
    margin-bottom: 24px;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___firstRed {
    width: 280px !important;
    max-width: 280px !important;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___deciError {
    margin-bottom: 24px;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___quoteDrop {
    width: 280px !important;
    max-width: 280px !important;
  }
}

/* iPhone 6, 7, 8 ----------- */

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  .src-common-features-BidStatus-CustomerContacted-__styles-module___quoteMessageSection {
    width: 308px !important;
    max-width: 308px !important;
  }

  textarea {
    /* width: 308px !important;
    max-width: 308px !important;
    min-width: 308px !important; */
    padding: 16px;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___first {
    width: 340px;
    max-width: 340px;
    margin-bottom: 24px !important;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___firstRed {
    width: 340px;
    max-width: 340px;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___deciError {
    margin-bottom: 24px;
  }
}

/* iPhone X ----------- */

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .src-common-features-BidStatus-CustomerContacted-__styles-module___quoteMessageSection {
    width: 308px !important;
    max-width: 308px !important;
  }

  textarea {
    /* width: 308px !important;
    max-width: 308px !important;
    min-width: 308px !important; */
    padding: 16px;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___first {
    width: 340px;
    max-width: 340px;
    margin-bottom: 24px !important;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___firstRed {
    width: 340px;
    max-width: 340px;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___deciError {
    margin-bottom: 24px;
  }
}

/* iPhone 6+, 7+, 8+ ----------- */

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .src-common-features-BidStatus-CustomerContacted-__styles-module___quoteMessageSection {
    width: 348px !important;
    max-width: 348px !important;
  }

  textarea {
    /* width: 348px !important;
    max-width: 348px !important;
    min-width: 348px !important; */
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___first {
    width: 380px !important;
    max-width: 380px !important;
    margin-bottom: 24px !important;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___firstRed {
    width: 380px !important;
    max-width: 380px !important;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___deciError {
    margin-bottom: 24px;
  }
}

/* iPhone XR ----------- */

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .src-common-features-BidStatus-CustomerContacted-__styles-module___quoteMessageSection {
    width: 350px !important;
    max-width: 350px !important;
  }

  textarea {
    /* width: 350px !important;
    max-width: 350px !important;
    min-width: 350px !important; */
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___first {
    width: 382px !important;
    max-width: 382px !important;
    margin-bottom: 24px !important;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___firstRed {
    width: 382px !important;
    max-width: 382px !important;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___deciError {
    margin-bottom: 24px;
  }
}

/* iPhone XS ----------- */

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .src-common-features-BidStatus-CustomerContacted-__styles-module___quoteMessageSection {
    width: 308px !important;
    max-width: 308px !important;
  }

  textarea {
    /* width: 308px !important;
    max-width: 308px !important;
    min-width: 308px !important; */
    padding: 16px;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___first {
    width: 340px;
    max-width: 340px;
    margin-bottom: 24px !important;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___firstRed {
    width: 340px;
    max-width: 340px;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___deciError {
    margin-bottom: 24px;
  }

  input {
    width: 50%;
    border: 0;
    margin-left: 24px;
    font-size: 16px;
    background-color: #fafafa;
    text-align: right;
    padding-right: 16px;
  }
}

/* iPhone XS MAX----------- */

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .src-common-features-BidStatus-CustomerContacted-__styles-module___quoteMessageSection {
    width: 348px !important;
    max-width: 348px !important;
  }

  textarea {
    /* width: 348px !important;
    max-width: 348px !important;
    min-width: 348px !important; */
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___first {
    width: 380px !important;
    max-width: 380px !important;
    margin-bottom: 24px !important;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___firstRed {
    width: 380px !important;
    max-width: 380px !important;
  }

  .src-common-features-BidStatus-CustomerContacted-__styles-module___deciError {
    margin-bottom: 24px;
  }

  input {
    width: 50%;
    border: 0;
    margin-left: 24px;
    font-size: 16px;
    background-color: #fafafa;
    text-align: right;
    padding-right: 16px;
  }
}

* {
  -webkit-font-smoothing: antialiased;
}

.src-common-features-BidStatus-WeSelected-__styles-module___quoteTitle {
  font-size: 16px;
  font-weight: bold;
  color: #00bcd4;
  margin: 0;
  display: inline-block;
  margin-bottom: 5px;
}

.src-common-features-BidStatus-WeSelected-__styles-module___quoteBRnum {
  font-size: 12px;
  margin: 0 0 23px 0;
  color: #6e727c;
}

.src-common-features-BidStatus-WeSelected-__styles-module___weSelectedTitle {
  color: #3e4148;
  margin-bottom: 16px;
}

.src-common-features-BidStatus-WeSelected-__styles-module___bidStatus {
}

.src-common-features-BidStatus-WeSelected-__styles-module___dollarInpWraper {
  width: 100%;
}
input {
  width: 50%;
  border: 0;
  margin-left: 24px;
  font-size: 12px;
  background-color: #fafafa;
  text-align: right;
  padding-right: 16px;
}

input:focus {
  outline: none;
}

/* .second {
  width: 70px;
  border: 1px solid #dedfe2;
  height: 32px;
  background-color: #dedfe2;
  text-align: center;
  padding-top: 11px;
  color: #6e727c;
  border-radius: 0 4px 4px 0;
}

.secondRed {
  width: 70px;
  border-color: #ff5722;
  border-radius: 0 4px 4px 0;
  border: 0 0 0 1px;
  height: 33px;
  background-color: #ff5722;
  text-align: center;
  padding-top: 11px;
  z-index: 99;
  color: #fff;
} */
/*
.second img {
  width: 18px;
  position: relative;
  top: 4px;
  left: 2px;
} */

.src-common-features-BidStatus-WeSelected-__styles-module___btnWraper .src-common-features-BidStatus-WeSelected-__styles-module___btn {
  cursor: pointer;
  border: 0;
  box-shadow: none;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  background: transparent;
  height: 32px;
  padding: 0;
}
.src-common-features-BidStatus-WeSelected-__styles-module___btnWraper .src-common-features-BidStatus-WeSelected-__styles-module___btn.src-common-features-BidStatus-WeSelected-__styles-module___btnNote {
  letter-spacing: 0.5px;
  color: #00bcd4;
}
.src-common-features-BidStatus-WeSelected-__styles-module___btnWraper .src-common-features-BidStatus-WeSelected-__styles-module___btn.src-common-features-BidStatus-WeSelected-__styles-module___btnDone {
  border-radius: 100px;
  letter-spacing: 1px;
  color: #ffffff;
  min-width: 120px;
  text-align: center;
  padding: 0 15px;
  background-image: linear-gradient(to bottom, #00bcd4, #00acc1);
}

.src-common-features-BidStatus-WeSelected-__styles-module___surveryWraper .src-common-features-BidStatus-WeSelected-__styles-module___btn {
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  background: transparent;
  border: 0;
  padding: 8px 15px;
}
.src-common-features-BidStatus-WeSelected-__styles-module___surveryWraper .src-common-features-BidStatus-WeSelected-__styles-module___btn.src-common-features-BidStatus-WeSelected-__styles-module___btnSurvery {
  letter-spacing: 1px;
  color: #ffffff;
  min-height: 32px;
  border-radius: 100px;
  margin-bottom: 10px;
  background-image: linear-gradient(to bottom, #00bcd4, #00acc1);
}
.src-common-features-BidStatus-WeSelected-__styles-module___surveryWraper .src-common-features-BidStatus-WeSelected-__styles-module___btn.src-common-features-BidStatus-WeSelected-__styles-module___btnNotNow {
  letter-spacing: 0.5px;
  color: #b9bbc1;
}
.src-common-features-BidStatus-WeSelected-__styles-module___deciError {
  color: #ff5722;
  font-size: 14px;
  font-style: italic;
  margin-top: 4px;
}

.src-common-features-BidStatus-WeSelected-__styles-module___quoteUploadSection {
  margin-left: 0 0 24px 24px;
}

.src-common-features-BidStatus-WeSelected-__styles-module___quoteOption {
  font-size: 14px;
  font-style: italic;
  color: #b9bbc1;
}

.src-common-features-BidStatus-WeSelected-__styles-module___quoteDrop {
  width: 264px;
  height: 144px;
  border: 1px dashed #dedfe2;
  background-color: #f4f5f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
}

.src-common-features-BidStatus-WeSelected-__styles-module___quoteCircle {
  display: flex;
  align-self: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-image: linear-gradient(to bottom, #00bcd4, #00acc1);
  color: #fff;
  justify-content: center;
  margin-bottom: 8px;
}

.src-common-features-BidStatus-WeSelected-__styles-module___quotePlus {
  font-size: 24px;
  margin-top: 6px;
}

.src-common-features-BidStatus-WeSelected-__styles-module___uploadLabel {
  display: flex;
  align-self: center;
  font-weight: bold;
  font-size: 16px;
  color: #3e4148;
  margin-bottom: 4px;
}

.src-common-features-BidStatus-WeSelected-__styles-module___quoteInstructions {
  display: flex;
  align-self: center;
  font-size: 12px;
  color: #6e727c;
}

.src-common-features-BidStatus-WeSelected-__styles-module___quoteMessageSection {
  width: 552px;
}

.src-common-features-BidStatus-WeSelected-__styles-module___quoteOption2 {
  font-size: 14px;
  font-style: italic;
  color: #b9bbc1;
}

textarea {
  width: 100%;
  /* max-width: 518px;
  min-width: 518px; */
  max-height: 40vh;
  border: 1px solid #dedfe2;
  background-color: #fafafa;
  border-radius: 4px;
  padding: 16px;
  font-size: 16px;
}

textarea:focus {
  outline: #00bcd4;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(62, 65, 72, 0.35);
  font-family: "Lato";
  font-size: 16px;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(62, 65, 72, 0.35);
  font-family: Lato;
  font-size: 16px;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(62, 65, 72, 0.35);
  font-family: Lato;
  font-size: 16px;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(62, 65, 72, 0.35);
  font-family: Lato;
  font-size: 16px;
}

.src-common-features-BidStatus-WeSelected-__styles-module___quoteButtonSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.src-common-features-BidStatus-WeSelected-__styles-module___quoteButton {
  margin: 0;
  padding: 0;
}

.src-common-features-BidStatus-WeSelected-__styles-module___buttonText {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  width: 200px;
  height: 40px;
  background-image: linear-gradient(to bottom, #8bc34a, #7cb342);
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  border: none;
}

.src-common-features-BidStatus-WeSelected-__styles-module___buttonText:disabled {
  opacity: 0.5;
}
/* Tab Start ----------- */
.src-common-features-BidStatus-WeSelected-__styles-module___tabWraper {
}
.src-common-features-BidStatus-WeSelected-__styles-module___tabs {
  display: flex;
  flex-wrap: wrap;
}
.src-common-features-BidStatus-WeSelected-__styles-module___tabs button {
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.3px;
  flex-grow: 1;
  height: 34px;
  box-shadow: none;
  color: rgba(62, 65, 72, 0.35);
  background-color: #dedfe2;
  border: 1px solid #dedfe2;
}
.src-common-features-BidStatus-WeSelected-__styles-module___tabs button:nth-child(1) {
  border-radius: 4px 0 0 4px;
}
.src-common-features-BidStatus-WeSelected-__styles-module___tabs button:nth-last-child(1) {
  border-radius: 0 4px 4px 0;
}
.src-common-features-BidStatus-WeSelected-__styles-module___tabs button:hover {
  background-color: #d7d7d8;
}
.src-common-features-BidStatus-WeSelected-__styles-module___tabs button.src-common-features-BidStatus-WeSelected-__styles-module___active {
  font-weight: 600;
  color: #3e4148;
  background-color: #fff;
}
.src-common-features-BidStatus-WeSelected-__styles-module___tabs button.src-common-features-BidStatus-WeSelected-__styles-module___active:hover {
  background-color: #fafafa;
}
.src-common-features-BidStatus-WeSelected-__styles-module___tabBody {
  padding: 20px 0 0;
}
.src-common-features-BidStatus-WeSelected-__styles-module___weSelectText {
  margin-top: 0;
  margin-bottom: 11px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
}
.src-common-features-BidStatus-WeSelected-__styles-module___comments {
  color: #b9bbc1;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  line-height: 1.43;
  letter-spacing: 0.3px;
}
.src-common-features-BidStatus-WeSelected-__styles-module___appointmentText {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.3px;
}
.src-common-features-BidStatus-WeSelected-__styles-module___activityText {
  margin-bottom: 22px;
}
.src-common-features-BidStatus-WeSelected-__styles-module___surveyText {
  margin-bottom: 20px;
}
.src-common-features-BidStatus-WeSelected-__styles-module___selectDateWraper {
  position: relative;
  margin-bottom: 15px;
}
.src-common-features-BidStatus-WeSelected-__styles-module___selectDateWraper input {
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  color: #3e4148;
  text-align: left;
  border: solid 1px #dedfe2;
  background: #fafafa;
  height: 44px;
  margin: 0;
  width: 100%;
  padding: 0 30px 0 12px;
  box-sizing: border-box;
  display: inline-block;
}
.src-common-features-BidStatus-WeSelected-__styles-module___selectDateWraper i {
  color: #969ba5;
  font-size: 18.27px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.src-common-features-BidStatus-WeSelected-__styles-module___radioWraper {
  padding-left: 14px;
}

.src-common-features-BidStatus-WeSelected-__styles-module___radioWraper .src-common-features-BidStatus-WeSelected-__styles-module___radioFieldWraper {
}

.src-common-features-BidStatus-WeSelected-__styles-module___radioWraper .src-common-features-BidStatus-WeSelected-__styles-module___radioFieldWraper input {
  display: none;
}
.src-common-features-BidStatus-WeSelected-__styles-module___radioWraper .src-common-features-BidStatus-WeSelected-__styles-module___radioFieldWraper label {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  color: rgba(62, 65, 72, 0.35);
  margin-bottom: 7px;
  display: block;
  cursor: pointer;
}
.src-common-features-BidStatus-WeSelected-__styles-module___radioWraper .src-common-features-BidStatus-WeSelected-__styles-module___radioFieldWraper label::before {
  content: "\F111";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  margin-right: 10px;
  color: #b9bbc1;
}
.src-common-features-BidStatus-WeSelected-__styles-module___radioWraper .src-common-features-BidStatus-WeSelected-__styles-module___radioFieldWraper input:checked + label {
  color: #3e4148;
}

.src-common-features-BidStatus-WeSelected-__styles-module___radioWraper .src-common-features-BidStatus-WeSelected-__styles-module___radioFieldWraper input:checked + label::before {
  content: "\F192";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  color: #00bcd4;
}
.src-common-features-BidStatus-WeSelected-__styles-module___radioWraper .src-common-features-BidStatus-WeSelected-__styles-module___radioFieldWraper p {
  padding-left: 30px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  color: rgba(62, 65, 72, 0.35);
  margin: 0 0 15px;
}
.src-common-features-BidStatus-WeSelected-__styles-module___radioWraper .src-common-features-BidStatus-WeSelected-__styles-module___radioFieldWraper input:checked + label + p {
  color: rgba(62, 65, 72, 0.7);
}

.src-common-features-BidStatus-WeSelected-__styles-module___btnWraper {
  padding-top: 9px;
  display: flex;
  justify-content: space-between;
}

.src-common-features-BidStatus-WeSelected-__styles-module___surveryWraper {
  padding-top: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* Samsung Galaxy S5 ----------- */

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .src-common-features-BidStatus-WeSelected-__styles-module___quoteMessageSection {
    width: 274px;
    max-width: 274px;
  }

  textarea {
    /* width: 244px !important;
    max-width: 244px !important;
    min-width: 244px !important; */
    padding: 16px;
    min-height: 10vh;
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___first {
    /* width: 274px !important;
    max-width: 274px !important; */
    margin-bottom: 24px;
  }

  /* .firstRed {
    width: 274px !important;
    max-width: 274px !important; */
  /* } */

  .src-common-features-BidStatus-WeSelected-__styles-module___deciError {
    margin-bottom: 24px;
  }

  input {
    width: 50%;
    border: 0;
    margin-left: 24px;
    font-size: 16px;
    background-color: #fafafa;
    text-align: right;
    padding-right: 16px;
  }
}

/* Google Pixel 2 ------------------ */

@media only screen and (min-device-width: 411px) and (max-device-height: 731px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .src-common-features-BidStatus-WeSelected-__styles-module___quoteMessageSection {
    width: 294px;
    max-width: 294px;
  }

  textarea {
    /* width: 294px !important;
    max-width: 294px !important;
    min-width: 294px !important; */
    padding: 16px;
    min-height: 10vh;
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___first {
    /* width: 274px !important;
    max-width: 274px !important;
    margin-bottom: 24px; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___firstRed {
    /* width: 274px !important;
    max-width: 274px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___deciError {
    margin-bottom: 24px;
  }

  input {
    width: 50%;
    border: 0;
    margin-left: 24px;
    font-size: 16px;
    background-color: #fafafa;
    text-align: right;
    padding-right: 16px;
  }
}

/* iPhone 5 ----------- */

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  .src-common-features-BidStatus-WeSelected-__styles-module___quoteMessageSection {
    width: 248px;
    max-width: 248px;
  }

  textarea {
    /* width: 248px !important;
    max-width: 248px !important;
    min-width: 248px !important; */
    padding: 16px;
    min-height: 10vh;
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___first {
    /* width: 280px !important;
    max-width: 280px !important;
    margin-bottom: 24px; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___firstRed {
    /* width: 280px !important;
    max-width: 280px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___deciError {
    margin-bottom: 24px;
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___quoteDrop {
    width: 280px !important;
    max-width: 280px !important;
  }

  input {
    width: 50%;
    border: 0;
    margin-left: 24px;
    font-size: 16px;
    background-color: #fafafa;
    text-align: right;
    padding-right: 16px;
  }
}

/* iPhone 6, 7, 8 ----------- */

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  .src-common-features-BidStatus-WeSelected-__styles-module___quoteMessageSection {
    width: 308px !important;
    max-width: 308px !important;
  }

  textarea {
    /* width: 308px !important;
    max-width: 308px !important;
    min-width: 308px !important; */
    padding: 16px;
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___first {
    /* width: 340px;
    max-width: 340px;
    margin-bottom: 24px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___firstRed {
    /* width: 340px;
    max-width: 340px; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___deciError {
    margin-bottom: 24px;
  }

  input {
    width: 50%;
    border: 0;
    margin-left: 24px;
    font-size: 16px;
    background-color: #fafafa;
    text-align: right;
    padding-right: 16px;
  }
}

/* iPhone X ----------- */

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .src-common-features-BidStatus-WeSelected-__styles-module___quoteMessageSection {
    width: 308px !important;
    max-width: 308px !important;
  }

  textarea {
    /* width: 308px !important;
    max-width: 308px !important;
    min-width: 308px !important; */
    padding: 16px;
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___first {
    /* width: 340px;
    max-width: 340px;
    margin-bottom: 24px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___firstRed {
    /* width: 340px;
    max-width: 340px; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___deciError {
    margin-bottom: 24px;
  }

  input {
    width: 50%;
    border: 0;
    margin-left: 24px;
    font-size: 16px;
    background-color: #fafafa;
    text-align: right;
    padding-right: 16px;
  }
}

/* iPhone 6+, 7+, 8+ ----------- */

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .src-common-features-BidStatus-WeSelected-__styles-module___quoteMessageSection {
    width: 348px !important;
    max-width: 348px !important;
  }

  textarea {
    /* width: 348px !important;
    max-width: 348px !important;
    min-width: 348px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___first {
    /* width: 380px !important;
    max-width: 380px !important;
    margin-bottom: 24px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___firstRed {
    /* width: 380px !important;
    max-width: 380px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___deciError {
    margin-bottom: 24px;
  }

  input {
    width: 50%;
    border: 0;
    margin-left: 24px;
    font-size: 16px;
    background-color: #fafafa;
    text-align: right;
    padding-right: 16px;
  }
}

/* iPhone XS ----------- */

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .src-common-features-BidStatus-WeSelected-__styles-module___quoteMessageSection {
    width: 308px !important;
    max-width: 308px !important;
  }

  textarea {
    /* width: 308px !important;
    max-width: 308px !important;
    min-width: 308px !important; */
    padding: 16px;
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___first {
    /* width: 340px;
    max-width: 340px;
    margin-bottom: 24px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___firstRed {
    /* width: 340px;
    max-width: 340px; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___deciError {
    margin-bottom: 24px;
  }

  input {
    width: 50%;
    border: 0;
    margin-left: 24px;
    font-size: 16px;
    background-color: #fafafa;
    text-align: right;
    padding-right: 16px;
  }
}

/* iPhone XS MAX----------- */

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .src-common-features-BidStatus-WeSelected-__styles-module___quoteMessageSection {
    width: 348px !important;
    max-width: 348px !important;
  }

  textarea {
    /* width: 348px !important;
    max-width: 348px !important;
    min-width: 348px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___first {
    /* width: 380px !important;
    max-width: 380px !important;
    margin-bottom: 24px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___firstRed {
    /* width: 380px !important;
    max-width: 380px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___deciError {
    margin-bottom: 24px;
  }

  input {
    width: 50%;
    border: 0;
    margin-left: 24px;
    font-size: 16px;
    background-color: #fafafa;
    text-align: right;
    padding-right: 16px;
  }
}

/* iPhone XR ----------- */

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .src-common-features-BidStatus-WeSelected-__styles-module___quoteMessageSection {
    width: 348px !important;
    max-width: 348px !important;
  }

  textarea {
    /* width: 348px !important;
    max-width: 348px !important;
    min-width: 348px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___first {
    /* width: 380px !important;
    max-width: 380px !important;
    margin-bottom: 24px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___firstRed {
    /* width: 380px !important;
    max-width: 380px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___deciError {
    margin-bottom: 24px;
  }

  input {
    width: 50%;
    border: 0;
    margin-left: 24px;
    font-size: 16px;
    background-color: #fafafa;
    text-align: right;
    padding-right: 16px;
  }
}

/* iPhone XR Long----------- */

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 2) {
  .src-common-features-BidStatus-WeSelected-__styles-module___quoteMessageSection {
    width: 348px !important;
    max-width: 348px !important;
  }

  textarea {
    /* width: 348px !important;
    max-width: 348px !important;
    min-width: 348px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___first {
    /* width: 380px !important;
    max-width: 380px !important;
    margin-bottom: 24px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___firstRed {
    /* width: 380px !important;
    max-width: 380px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___deciError {
    margin-bottom: 24px;
  }

  input {
    width: 50%;
    border: 0;
    margin-left: 24px;
    font-size: 16px;
    background-color: #fafafa;
    text-align: right;
    padding-right: 16px;
  }
}

/* iPhone XR ----------- */

@media only screen and (device-width: 414px) and (-webkit-device-pixel-ratio: 2) {
  .src-common-features-BidStatus-WeSelected-__styles-module___quoteMessageSection {
    width: 348px !important;
    max-width: 348px !important;
  }

  textarea {
    /* width: 348px !important;
    max-width: 348px !important;
    min-width: 348px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___first {
    /* width: 380px !important;
    max-width: 380px !important; */
    /* margin-bottom: 24px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___firstRed {
    /* width: 380px !important;
    max-width: 380px !important; */
  }

  .src-common-features-BidStatus-WeSelected-__styles-module___deciError {
    margin-bottom: 24px;
  }

  input {
    width: 50%;
    border: 0;
    margin-left: 24px;
    font-size: 16px;
    background-color: #fafafa;
    text-align: right;
    padding-right: 16px;
  }
}

.src-common-features-BidStatus-WeSelected-Tabs-__styles-module___root {
}
.src-common-features-BidStatus-WeSelected-Tabs-__styles-module___survey {
  border-radius: 4px;
  border: solid 2px #edeeef;
  background-color: #f4f5f6;
  padding: 14px;
}
.src-common-features-BidStatus-WeSelected-Tabs-__styles-module___survey p {
  margin: 0 0 14px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  color: #3e4148;
}
.src-common-features-BidStatus-WeSelected-Tabs-__styles-module___survey button {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #00bcd4;
  border: 0px;
  margin: 0 auto;
  display: block;
  background: transparent;
}
.src-common-features-BidStatus-WeSelected-Tabs-__styles-module___survey button i {
  margin-right: 10px;
  font-size: 16px;
}
.src-common-features-BidStatus-WeSelected-Tabs-__styles-module___weSelectText {
  margin-top: 0;
  margin-bottom: 11px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
}
.src-common-features-BidStatus-WeSelected-Tabs-__styles-module___appointmentText {
  font-size: 14px;
  margin-bottom: 4px;
}
.src-common-features-BidStatus-WeSelected-Tabs-__styles-module___comments {
  color: #b9bbc1;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  line-height: 1.43;
  letter-spacing: 0.3px;
}

.src-common-components-CustomRadio-__styles-module___radioWraper {
  padding-left: 12px;
  margin-bottom: 8px;
}
.src-common-components-CustomRadio-__styles-module___radioWraper .src-common-components-CustomRadio-__styles-module___radioFieldWraper {
}
.src-common-components-CustomRadio-__styles-module___radioWraper .src-common-components-CustomRadio-__styles-module___radioFieldWraper .src-common-components-CustomRadio-__styles-module___radioField {
  display: none;
}
.src-common-components-CustomRadio-__styles-module___radioWraper .src-common-components-CustomRadio-__styles-module___radioFieldWraper label {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #3E4148;
  display: inline-block;
  cursor: pointer;
}
.src-common-components-CustomRadio-__styles-module___radioWraper .src-common-components-CustomRadio-__styles-module___radioFieldWraper label::before {
  content: "\F111";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  margin-right: 10px;
  color: #B9BBC1;
}
.src-common-components-CustomRadio-__styles-module___radioWraper .src-common-components-CustomRadio-__styles-module___radioFieldWraper label.src-common-components-CustomRadio-__styles-module___square::before {
  font-size: 16px;
  content: '\F0C8'
}

.src-common-components-CustomRadio-__styles-module___radioWraper .src-common-components-CustomRadio-__styles-module___radioFieldWraper .src-common-components-CustomRadio-__styles-module___radioField:checked + label {
  color: #3e4148;
}
.src-common-components-CustomRadio-__styles-module___radioWraper .src-common-components-CustomRadio-__styles-module___radioFieldWraper .src-common-components-CustomRadio-__styles-module___radioField[id="not"] + label {
  margin-bottom: 22px;
}
.src-common-components-CustomRadio-__styles-module___radioWraper .src-common-components-CustomRadio-__styles-module___radioFieldWraper .src-common-components-CustomRadio-__styles-module___radioField[id="not"]:checked + label {
  margin-bottom: 0;
}
.src-common-components-CustomRadio-__styles-module___radioWraper .src-common-components-CustomRadio-__styles-module___radioFieldWraper .src-common-components-CustomRadio-__styles-module___radioField:checked + label::before {
  content: "\F192";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  /* color: #00bcd4; */
  color: #3963FE;
}
.src-common-components-CustomRadio-__styles-module___radioWraper .src-common-components-CustomRadio-__styles-module___radioFieldWraper .src-common-components-CustomRadio-__styles-module___radioField:checked + label.src-common-components-CustomRadio-__styles-module___square::before {
  font-size: 16px;
  content: '\F14A';
}
.src-common-components-CustomRadio-__styles-module___radioWraper .src-common-components-CustomRadio-__styles-module___radioBody {
  display: none;
}
.src-common-components-CustomRadio-__styles-module___radioWraper .src-common-components-CustomRadio-__styles-module___radioBody:empty {
  display: none !important;
}
.src-common-components-CustomRadio-__styles-module___radioWraper .src-common-components-CustomRadio-__styles-module___radioFieldWraper .src-common-components-CustomRadio-__styles-module___radioField:checked + label + .src-common-components-CustomRadio-__styles-module___radioBody {
  display: block;
  padding: 14px 24px 0px;
}

#payment-form {
  width: 33%;
  min-width: 400px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  padding: 24px;
}

.form-title{
font-family: 'Montserrat' !important;
width: 352px;
height: 24px;
color: rgb(62, 65, 72);
font-size: 18px !important;
font-weight: bold;
line-height: 24px;
margin: 0px;
margin-bottom: 24px;
}

.email-title{
  width: 43px;
  height: 20px;
  color: rgb(62, 65, 72);
  font-size: 14px;
  font-family: 'Lato';
  font-weight: normal;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: 0px 0px 3.5px 0px
}

#email {
  border-radius: 6px;
  margin-bottom: 16px;
  margin-left: 0px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 14px;
  width: 100%;
  background: white;
  box-sizing: border-box;
  text-align: left;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
#submit {
  background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(47, 85, 254) 100%) !important;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 12px;
  border: 0;
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

#submit:hover {
  filter: contrast(115%);
}

#submit:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(47, 85, 254) 100%) !important;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  transform-origin: 10.4px 10.2px;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(47, 85, 254) 100%) !important;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  transform-origin: 0px 10.2px;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-height: 710px) {
  body {
    overflow:auto;
  }
}

@media only screen and (max-width: 425px) {
  #payment-form {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 600px) {
  #payment-form {
    width: 100vw;
    min-width: 0;
    min-width: initial;
    box-shadow: none;
  }
}

@media (max-width: 600px) {

  body {
    height: auto !important; 
    width: auto;
    overflow:auto;
  }

  #root {
    display: flex;
    align-items: center;
    background-color: white;
    width: auto;
    height: auto;
  }

  .form-title{
    width: auto;
  }

}


#payment-form {
  width: 33%;
  min-width: 400px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  padding: 24px;
}

.form-title{
font-family: 'Montserrat' !important;
width: 352px;
height: 24px;
color: rgb(62, 65, 72);
font-size: 18px !important;
font-weight: bold;
line-height: 24px;
margin: 0px;
margin-bottom: 24px;
}

.email-title{
  width: 43px;
  height: 20px;
  color: rgb(62, 65, 72);
  font-size: 14px;
  font-family: 'Lato';
  font-weight: normal;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: 0px 0px 3.5px 0px
}

#email {
  border-radius: 6px;
  margin-bottom: 16px;
  margin-left: 0px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 14px;
  width: 100%;
  background: white;
  box-sizing: border-box;
  text-align: left;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
#submit {
  background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(47, 85, 254) 100%) !important;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 12px;
  border: 0;
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

#submit:hover {
  filter: contrast(115%);
}

#submit:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(47, 85, 254) 100%) !important;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  transform-origin: 10.4px 10.2px;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(47, 85, 254) 100%) !important;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  transform-origin: 0px 10.2px;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-height: 710px) {
  body {
    overflow:auto;
  }
}

@media only screen and (max-width: 425px) {
  #payment-form {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 600px) {
  #payment-form {
    width: 100vw;
    min-width: 0;
    min-width: initial;
    box-shadow: none;
  }
}

@media (max-width: 600px) {

  body {
    height: auto !important; 
    width: auto;
    overflow:auto;
  }

  #root {
    display: flex;
    align-items: center;
    background-color: white;
    width: auto;
    height: auto;
  }

  .form-title{
    width: auto;
  }

}


/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"),
    url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh7USSwaPGQ3q5d0N7w.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"),
    url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh7USSwiPGQ3q5d0.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"),
    url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"),
    url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjx4wXiWtFCc.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"),
    url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh6UVSwaPGQ3q5d0N7w.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"),
    url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_ZpC3gTD_vx3rCubqg.woff2)
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_ZpC3g3D_vx3rCubqg.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_ZpC3gbD_vx3rCubqg.woff2)
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_ZpC3gfD_vx3rCubqg.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_ZpC3gnD_vx3rCs.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gTD_vx3rCubqg.woff2)
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3g3D_vx3rCubqg.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gbD_vx3rCubqg.woff2)
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gfD_vx3rCubqg.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "La Belle Aurore";
  font-style: normal;
  font-weight: 400;
  src: local("La Belle Aurore"), local("LaBelleAurore"),
    url(https://fonts.gstatic.com/s/labelleaurore/v9/RrQIbot8-mNYKnGNDkWlocovHeI4HO2EF1qELw.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html {
  margin: 0;
  padding: 0;
  min-height: 100%;
  font-family: sans-serif;
}

body {
  font-family: 'Lato', sans-serif;
  margin: 0;
  background: #f4f5f6;
}

input,
textarea {
  -webkit-appearance: none;
  font-family: 'Lato', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

h1 {
  font-size: 16px;
  font-weight: bold;
}

h2 {
  font-size: 16px;
  font-weight: bold;
}

p {
  margin: 4px 0;
}

a {
  color: #00e5ff;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

#root {
  padding: 0 16px;
}
.qmerit-logo {
  width: 33px;
  margin-right: 14px;
  vertical-align: middle;
  /* opacity: 50%; */
}

.quick-logo {
  width: 115px;
}

main {
}

header.wrapper {
}

header.wrapper .title {
}

header.wrapper h2 {
}

header.wrapper h3 {
}

.mainRow {
  flex: 1 1;
}

.no-card {
  max-width: 100%;
  width: 600px;
  margin: 24px auto;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  max-width: 600px;
  width: 100%;
  margin: 24px auto;
  border-radius: 8px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  color: #3e4148;
}

.card header {
  height: 52px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.card .action {
  background: linear-gradient(to bottom, #ffc107, #ffa000);
}
.card .info {
  background: linear-gradient(to bottom, #3862c5, #2b4dac);
}

.card header h2 {
  padding: 16px;
  color: #ffffff;
}

.card section {
  padding: 24px;
}

.card h2 img {
  margin-right: 8px;
}
.inp-checkbox {
  margin-bottom: 18px;
  padding-left: 14px;
  padding-right: 14px;
}

.inp-checkbox input[type='checkbox'] {
  display: none;
}
.inp-checkbox label {
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  padding-left: 26px;
  color: rgba(62, 65, 72, 0.35);
  margin-bottom: 14px;
  display: inline-block;
  min-height: 20px;
}
.inp-checkbox .child {
  padding-left: 26px;
  padding-right: 26px;
}
.inp-checkbox input[type='checkbox'] + label::before {
  content: '\F0C8';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  position: absolute;
  left: 0;
  font-size: 16px;
  color: #969ba4;
  top: 2px;
}
.inp-checkbox input[type='checkbox'] + label::after {
  font-family: 'Font Awesome 5 Pro';
  position: absolute;
  left: 0;
}
.inp-checkbox input[type='checkbox']:disabled + label {
  cursor: not-allowed;
  color: rgba(62, 65, 72, 0.2);
}
.inp-checkbox input[type='checkbox']:disabled + label::before {
  font-weight: 400;
  content: '\F0C8';
  color: #969ba5;
  color: rgba(62, 65, 72, 0.2);
}
.inp-checkbox input[type='checkbox']:disabled + label::after {
  font-weight: 400;
  content: '\F00D';
  color: #969ba5;
  font-size: 12.8px;
  color: rgba(62, 65, 72, 0.2);
  top: 4px;
  left: 2.5px;
}
.inp-checkbox input[type='checkbox']:checked + label {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  color: #3e4148;
}
.inp-checkbox input[type='checkbox']:checked + label::before {
  font-weight: 900;
  content: '\F0C8';
  color: #00bcd4;
  top: 0px;
}
.inp-checkbox input[type='checkbox']:checked + label::after {
  font-weight: 400;
  content: '\F00C';
  color: #fff;
  font-size: 10.01px;
  left: 2px;
  top: 4px;
}
footer {
  display: flex;
  justify-content: center;
}
footer p {
  font-size: 12px;
  margin: 0;
  line-height: 16px;
  letter-spacing: 0.5px;
  padding-bottom: 24px;
}
@media (max-width: 767.98px) {
  #root {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 576.98px) {
  .requirements-collaps {
    background: none;
  }
  .requirements-collaps main > header {
    height: 0;
    overflow: hidden;
    transition: 0.3s all;
  }
  .mobile-no-Overflow {
    overflow: hidden;
    position: relative;
  }

  .mobile-no-Overflow::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background: #fff;
    height: 200vh;
    width: 100%;
    z-index: 999;
  }

  header.wrapper {
    padding: 0 12px;
  }
  .card {
    margin-top: 12px;
    margin-bottom: 0px;
    border-radius: 0;
  }
  .card section {
    padding: 16px;
  }
  .inp-checkbox {
    padding-left: 8px;
    padding-right: 8px;
  }
  .inp-checkbox label {
    padding-left: 20px;
  }
  .inp-checkbox .child {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
}
.loader-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loader-content h2 {
  margin: 24px 0 0;
  opacity: 0.75;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
}
.loader-icon ellipse {
  animation: loader-anination 0.8s infinite linear;
}
.loader-icon ellipse:nth-child(2) {
  animation-delay: -0.7s;
}
.loader-icon ellipse:nth-child(3) {
  animation-delay: -0.6s;
}
.loader-icon ellipse:nth-child(4) {
  animation-delay: -0.5s;
}
.loader-icon ellipse:nth-child(5) {
  animation-delay: -0.4s;
}
.loader-icon ellipse:nth-child(6) {
  animation-delay: -0.3s;
}
.loader-icon ellipse:nth-child(7) {
  animation-delay: -0.2s;
}
.loader-icon ellipse:nth-child(8) {
  animation-delay: -0.1s;
}
@keyframes loader-anination {
  75% {
    fill: transparent;
  }
  100% {
    fill: transparent;
  }
}

/*!
 * Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
.fa {
  font-family: "Font Awesome 6 Pro";
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: 900;
  font-weight: var(--fa-style, 900); }

.fas,
.fass,
.far,
.fasr,
.fal,
.fasl,
.fat,
.fast,
.fad,
.fadr,
.fadl,
.fadt,
.fasds,
.fasdr,
.fasdl,
.fasdt,
.fab,
.fa-solid,
.fa-regular,
.fa-light,
.fa-thin,
.fa-brands,
.fa-classic,
.fa-duotone,
.fa-sharp,
.fa-sharp-duotone,
.fa {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto; }

.fas::before,
.fass::before,
.far::before,
.fasr::before,
.fal::before,
.fasl::before,
.fat::before,
.fast::before,
.fad::before,
.fadr::before,
.fadl::before,
.fadt::before,
.fasds::before,
.fasdr::before,
.fasdl::before,
.fasdt::before,
.fab::before,
.fa-solid::before,
.fa-regular::before,
.fa-light::before,
.fa-thin::before,
.fa-brands::before,
.fa-classic::before,
.fa-duotone::before,
.fa-sharp::before,
.fa-sharp-duotone::before,
.fa::before {
  content: var(--fa); }

.fad::after,
.fa-duotone.fa-solid::after,
.fa-duotone::after,
.fadr::after,
.fa-duotone.fa-regular::after,
.fadl::after,
.fa-duotone.fa-light::after,
.fadt::after,
.fa-duotone.fa-thin::after,
.fasds::after,
.fa-sharp-duotone.fa-solid::after,
.fa-sharp-duotone::after,
.fasdr::after,
.fa-sharp-duotone.fa-regular::after,
.fasdl::after,
.fa-sharp-duotone.fa-light::after,
.fasdt::after,
.fa-sharp-duotone.fa-thin::after {
  content: var(--fa--fa); }

.fa-classic.fa-duotone {
  font-family: 'Font Awesome 6 Duotone'; }

.fass,
.fa-sharp {
  font-weight: 900; }

.fad,
.fa-duotone {
  font-weight: 900; }

.fasds,
.fa-sharp-duotone {
  font-weight: 900; }

.fa-classic,
.fas,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin {
  font-family: 'Font Awesome 6 Pro'; }

.fa-duotone,
.fad,
.fadr,
.fadl,
.fadt {
  font-family: 'Font Awesome 6 Duotone'; }

.fa-brands,
.fab {
  font-family: 'Font Awesome 6 Brands'; }

.fa-sharp,
.fass,
.fasr,
.fasl,
.fast {
  font-family: 'Font Awesome 6 Sharp'; }

.fa-sharp-duotone,
.fasds,
.fasdr,
.fasdl,
.fasdt {
  font-family: 'Font Awesome 6 Sharp Duotone'; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em; }

.fa-xs {
  font-size: 0.75em;
  line-height: 0.08333em;
  vertical-align: 0.125em; }

.fa-sm {
  font-size: 0.875em;
  line-height: 0.07143em;
  vertical-align: 0.05357em; }

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em; }

.fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em; }

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: calc(-1 * 2em);
  left: calc(-1 * var(--fa-li-width, 2em));
  position: absolute;
  text-align: center;
  width: 2em;
  width: var(--fa-li-width, 2em);
  line-height: inherit; }

.fa-border {
  border-color: #eee;
  border-color: var(--fa-border-color, #eee);
  border-radius: 0.1em;
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: solid;
  border-style: var(--fa-border-style, solid);
  border-width: 0.08em;
  border-width: var(--fa-border-width, 0.08em);
  padding: 0.2em 0.25em 0.15em;
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em); }

.fa-pull-left {
  float: left;
  margin-right: 0.3em;
  margin-right: var(--fa-pull-margin, 0.3em); }

.fa-pull-right {
  float: right;
  margin-left: 0.3em;
  margin-left: var(--fa-pull-margin, 0.3em); }

.fa-beat {
  animation-name: fa-beat;
  animation-delay: 0s;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: normal;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: 1s;
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: ease-in-out;
  animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: 0s;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: normal;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: 1s;
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1)); }

.fa-fade {
  animation-name: fa-fade;
  animation-delay: 0s;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: normal;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: 1s;
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: 0s;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: normal;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: 1s;
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-flip {
  animation-name: fa-flip;
  animation-delay: 0s;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: normal;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: 1s;
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: ease-in-out;
  animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-shake {
  animation-name: fa-shake;
  animation-delay: 0s;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: normal;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: 1s;
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: linear;
  animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin {
  animation-name: fa-spin;
  animation-delay: 0s;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: normal;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: 2s;
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: linear;
  animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin-reverse {
  --fa-animation-direction: reverse; }

.fa-pulse,
.fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: normal;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: 1s;
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: steps(8);
  animation-timing-function: var(--fa-animation-timing, steps(8)); }

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
  .fa-bounce,
  .fa-fade,
  .fa-beat-fade,
  .fa-flip,
  .fa-pulse,
  .fa-shake,
  .fa-spin,
  .fa-spin-pulse {
    animation-delay: -1ms;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s; } }

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1); }
  45% {
    transform: scale(1.25);
    transform: scale(var(--fa-beat-scale, 1.25)); } }

@keyframes fa-bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-0.5em);
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-0.125em);
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

@keyframes fa-fade {
  50% {
    opacity: 0.4;
    opacity: var(--fa-fade-opacity, 0.4); } }

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: 0.4;
    opacity: var(--fa-beat-fade-opacity, 0.4);
    transform: scale(1); }
  50% {
    opacity: 1;
    transform: scale(1.125);
    transform: scale(var(--fa-beat-fade-scale, 1.125)); } }

@keyframes fa-flip {
  50% {
    transform: rotate3d(0, 1, 0, -180deg);
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg); }
  4% {
    transform: rotate(15deg); }
  8%, 24% {
    transform: rotate(-18deg); }
  12%, 28% {
    transform: rotate(18deg); }
  16% {
    transform: rotate(-22deg); }
  20% {
    transform: rotate(22deg); }
  32% {
    transform: rotate(-12deg); }
  36% {
    transform: rotate(12deg); }
  40%, 100% {
    transform: rotate(0deg); } }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fa-rotate-90 {
  transform: rotate(90deg); }

.fa-rotate-180 {
  transform: rotate(180deg); }

.fa-rotate-270 {
  transform: rotate(270deg); }

.fa-flip-horizontal {
  transform: scale(-1, 1); }

.fa-flip-vertical {
  transform: scale(1, -1); }

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1); }

.fa-rotate-by {
  transform: rotate(0);
  transform: rotate(var(--fa-rotate-angle, 0)); }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: auto;
  z-index: var(--fa-stack-z-index, auto); }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff;
  color: var(--fa-inverse, #fff); }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */

.fa-0 {
  --fa: "0";
  --fa--fa: "00"; }

.fa-1 {
  --fa: "1";
  --fa--fa: "11"; }

.fa-2 {
  --fa: "2";
  --fa--fa: "22"; }

.fa-3 {
  --fa: "3";
  --fa--fa: "33"; }

.fa-4 {
  --fa: "4";
  --fa--fa: "44"; }

.fa-5 {
  --fa: "5";
  --fa--fa: "55"; }

.fa-6 {
  --fa: "6";
  --fa--fa: "66"; }

.fa-7 {
  --fa: "7";
  --fa--fa: "77"; }

.fa-8 {
  --fa: "8";
  --fa--fa: "88"; }

.fa-9 {
  --fa: "9";
  --fa--fa: "99"; }

.fa-fill-drip {
  --fa: "\F576";
  --fa--fa: "\F576\F576"; }

.fa-arrows-to-circle {
  --fa: "\E4BD";
  --fa--fa: "\E4BD\E4BD"; }

.fa-circle-chevron-right {
  --fa: "\F138";
  --fa--fa: "\F138\F138"; }

.fa-chevron-circle-right {
  --fa: "\F138";
  --fa--fa: "\F138\F138"; }

.fa-wagon-covered {
  --fa: "\F8EE";
  --fa--fa: "\F8EE\F8EE"; }

.fa-line-height {
  --fa: "\F871";
  --fa--fa: "\F871\F871"; }

.fa-bagel {
  --fa: "\E3D7";
  --fa--fa: "\E3D7\E3D7"; }

.fa-transporter-7 {
  --fa: "\E2A8";
  --fa--fa: "\E2A8\E2A8"; }

.fa-at {
  --fa: "@";
  --fa--fa: "@@"; }

.fa-rectangles-mixed {
  --fa: "\E323";
  --fa--fa: "\E323\E323"; }

.fa-phone-arrow-up-right {
  --fa: "\E224";
  --fa--fa: "\E224\E224"; }

.fa-phone-arrow-up {
  --fa: "\E224";
  --fa--fa: "\E224\E224"; }

.fa-phone-outgoing {
  --fa: "\E224";
  --fa--fa: "\E224\E224"; }

.fa-trash-can {
  --fa: "\F2ED";
  --fa--fa: "\F2ED\F2ED"; }

.fa-trash-alt {
  --fa: "\F2ED";
  --fa--fa: "\F2ED\F2ED"; }

.fa-circle-l {
  --fa: "\E114";
  --fa--fa: "\E114\E114"; }

.fa-head-side-goggles {
  --fa: "\F6EA";
  --fa--fa: "\F6EA\F6EA"; }

.fa-head-vr {
  --fa: "\F6EA";
  --fa--fa: "\F6EA\F6EA"; }

.fa-text-height {
  --fa: "\F034";
  --fa--fa: "\F034\F034"; }

.fa-user-xmark {
  --fa: "\F235";
  --fa--fa: "\F235\F235"; }

.fa-user-times {
  --fa: "\F235";
  --fa--fa: "\F235\F235"; }

.fa-face-hand-yawn {
  --fa: "\E379";
  --fa--fa: "\E379\E379"; }

.fa-gauge-simple-min {
  --fa: "\F62D";
  --fa--fa: "\F62D\F62D"; }

.fa-tachometer-slowest {
  --fa: "\F62D";
  --fa--fa: "\F62D\F62D"; }

.fa-stethoscope {
  --fa: "\F0F1";
  --fa--fa: "\F0F1\F0F1"; }

.fa-coffin {
  --fa: "\F6C6";
  --fa--fa: "\F6C6\F6C6"; }

.fa-message {
  --fa: "\F27A";
  --fa--fa: "\F27A\F27A"; }

.fa-comment-alt {
  --fa: "\F27A";
  --fa--fa: "\F27A\F27A"; }

.fa-salad {
  --fa: "\F81E";
  --fa--fa: "\F81E\F81E"; }

.fa-bowl-salad {
  --fa: "\F81E";
  --fa--fa: "\F81E\F81E"; }

.fa-info {
  --fa: "\F129";
  --fa--fa: "\F129\F129"; }

.fa-robot-astromech {
  --fa: "\E2D2";
  --fa--fa: "\E2D2\E2D2"; }

.fa-ring-diamond {
  --fa: "\E5AB";
  --fa--fa: "\E5AB\E5AB"; }

.fa-fondue-pot {
  --fa: "\E40D";
  --fa--fa: "\E40D\E40D"; }

.fa-theta {
  --fa: "\F69E";
  --fa--fa: "\F69E\F69E"; }

.fa-face-hand-peeking {
  --fa: "\E481";
  --fa--fa: "\E481\E481"; }

.fa-square-user {
  --fa: "\E283";
  --fa--fa: "\E283\E283"; }

.fa-down-left-and-up-right-to-center {
  --fa: "\F422";
  --fa--fa: "\F422\F422"; }

.fa-compress-alt {
  --fa: "\F422";
  --fa--fa: "\F422\F422"; }

.fa-explosion {
  --fa: "\E4E9";
  --fa--fa: "\E4E9\E4E9"; }

.fa-file-lines {
  --fa: "\F15C";
  --fa--fa: "\F15C\F15C"; }

.fa-file-alt {
  --fa: "\F15C";
  --fa--fa: "\F15C\F15C"; }

.fa-file-text {
  --fa: "\F15C";
  --fa--fa: "\F15C\F15C"; }

.fa-wave-square {
  --fa: "\F83E";
  --fa--fa: "\F83E\F83E"; }

.fa-ring {
  --fa: "\F70B";
  --fa--fa: "\F70B\F70B"; }

.fa-building-un {
  --fa: "\E4D9";
  --fa--fa: "\E4D9\E4D9"; }

.fa-dice-three {
  --fa: "\F527";
  --fa--fa: "\F527\F527"; }

.fa-tire-pressure-warning {
  --fa: "\F633";
  --fa--fa: "\F633\F633"; }

.fa-wifi-fair {
  --fa: "\F6AB";
  --fa--fa: "\F6AB\F6AB"; }

.fa-wifi-2 {
  --fa: "\F6AB";
  --fa--fa: "\F6AB\F6AB"; }

.fa-calendar-days {
  --fa: "\F073";
  --fa--fa: "\F073\F073"; }

.fa-calendar-alt {
  --fa: "\F073";
  --fa--fa: "\F073\F073"; }

.fa-mp3-player {
  --fa: "\F8CE";
  --fa--fa: "\F8CE\F8CE"; }

.fa-anchor-circle-check {
  --fa: "\E4AA";
  --fa--fa: "\E4AA\E4AA"; }

.fa-tally-4 {
  --fa: "\E297";
  --fa--fa: "\E297\E297"; }

.fa-rectangle-history {
  --fa: "\E4A2";
  --fa--fa: "\E4A2\E4A2"; }

.fa-building-circle-arrow-right {
  --fa: "\E4D1";
  --fa--fa: "\E4D1\E4D1"; }

.fa-volleyball {
  --fa: "\F45F";
  --fa--fa: "\F45F\F45F"; }

.fa-volleyball-ball {
  --fa: "\F45F";
  --fa--fa: "\F45F\F45F"; }

.fa-sun-haze {
  --fa: "\F765";
  --fa--fa: "\F765\F765"; }

.fa-text-size {
  --fa: "\F894";
  --fa--fa: "\F894\F894"; }

.fa-ufo {
  --fa: "\E047";
  --fa--fa: "\E047\E047"; }

.fa-fork {
  --fa: "\F2E3";
  --fa--fa: "\F2E3\F2E3"; }

.fa-utensil-fork {
  --fa: "\F2E3";
  --fa--fa: "\F2E3\F2E3"; }

.fa-arrows-up-to-line {
  --fa: "\E4C2";
  --fa--fa: "\E4C2\E4C2"; }

.fa-mobile-signal {
  --fa: "\E1EF";
  --fa--fa: "\E1EF\E1EF"; }

.fa-barcode-scan {
  --fa: "\F465";
  --fa--fa: "\F465\F465"; }

.fa-sort-down {
  --fa: "\F0DD";
  --fa--fa: "\F0DD\F0DD"; }

.fa-sort-desc {
  --fa: "\F0DD";
  --fa--fa: "\F0DD\F0DD"; }

.fa-folder-arrow-down {
  --fa: "\E053";
  --fa--fa: "\E053\E053"; }

.fa-folder-download {
  --fa: "\E053";
  --fa--fa: "\E053\E053"; }

.fa-circle-minus {
  --fa: "\F056";
  --fa--fa: "\F056\F056"; }

.fa-minus-circle {
  --fa: "\F056";
  --fa--fa: "\F056\F056"; }

.fa-face-icicles {
  --fa: "\E37C";
  --fa--fa: "\E37C\E37C"; }

.fa-shovel {
  --fa: "\F713";
  --fa--fa: "\F713\F713"; }

.fa-door-open {
  --fa: "\F52B";
  --fa--fa: "\F52B\F52B"; }

.fa-films {
  --fa: "\E17A";
  --fa--fa: "\E17A\E17A"; }

.fa-right-from-bracket {
  --fa: "\F2F5";
  --fa--fa: "\F2F5\F2F5"; }

.fa-sign-out-alt {
  --fa: "\F2F5";
  --fa--fa: "\F2F5\F2F5"; }

.fa-face-glasses {
  --fa: "\E377";
  --fa--fa: "\E377\E377"; }

.fa-nfc {
  --fa: "\E1F7";
  --fa--fa: "\E1F7\E1F7"; }

.fa-atom {
  --fa: "\F5D2";
  --fa--fa: "\F5D2\F5D2"; }

.fa-soap {
  --fa: "\E06E";
  --fa--fa: "\E06E\E06E"; }

.fa-icons {
  --fa: "\F86D";
  --fa--fa: "\F86D\F86D"; }

.fa-heart-music-camera-bolt {
  --fa: "\F86D";
  --fa--fa: "\F86D\F86D"; }

.fa-microphone-lines-slash {
  --fa: "\F539";
  --fa--fa: "\F539\F539"; }

.fa-microphone-alt-slash {
  --fa: "\F539";
  --fa--fa: "\F539\F539"; }

.fa-closed-captioning-slash {
  --fa: "\E135";
  --fa--fa: "\E135\E135"; }

.fa-calculator-simple {
  --fa: "\F64C";
  --fa--fa: "\F64C\F64C"; }

.fa-calculator-alt {
  --fa: "\F64C";
  --fa--fa: "\F64C\F64C"; }

.fa-bridge-circle-check {
  --fa: "\E4C9";
  --fa--fa: "\E4C9\E4C9"; }

.fa-sliders-up {
  --fa: "\F3F1";
  --fa--fa: "\F3F1\F3F1"; }

.fa-sliders-v {
  --fa: "\F3F1";
  --fa--fa: "\F3F1\F3F1"; }

.fa-location-minus {
  --fa: "\F609";
  --fa--fa: "\F609\F609"; }

.fa-map-marker-minus {
  --fa: "\F609";
  --fa--fa: "\F609\F609"; }

.fa-pump-medical {
  --fa: "\E06A";
  --fa--fa: "\E06A\E06A"; }

.fa-fingerprint {
  --fa: "\F577";
  --fa--fa: "\F577\F577"; }

.fa-ski-boot {
  --fa: "\E3CC";
  --fa--fa: "\E3CC\E3CC"; }

.fa-standard-definition {
  --fa: "\E28A";
  --fa--fa: "\E28A\E28A"; }

.fa-rectangle-sd {
  --fa: "\E28A";
  --fa--fa: "\E28A\E28A"; }

.fa-h1 {
  --fa: "\F313";
  --fa--fa: "\F313\F313"; }

.fa-hand-point-right {
  --fa: "\F0A4";
  --fa--fa: "\F0A4\F0A4"; }

.fa-magnifying-glass-location {
  --fa: "\F689";
  --fa--fa: "\F689\F689"; }

.fa-search-location {
  --fa: "\F689";
  --fa--fa: "\F689\F689"; }

.fa-message-bot {
  --fa: "\E3B8";
  --fa--fa: "\E3B8\E3B8"; }

.fa-forward-step {
  --fa: "\F051";
  --fa--fa: "\F051\F051"; }

.fa-step-forward {
  --fa: "\F051";
  --fa--fa: "\F051\F051"; }

.fa-face-smile-beam {
  --fa: "\F5B8";
  --fa--fa: "\F5B8\F5B8"; }

.fa-smile-beam {
  --fa: "\F5B8";
  --fa--fa: "\F5B8\F5B8"; }

.fa-light-ceiling {
  --fa: "\E016";
  --fa--fa: "\E016\E016"; }

.fa-message-exclamation {
  --fa: "\F4A5";
  --fa--fa: "\F4A5\F4A5"; }

.fa-comment-alt-exclamation {
  --fa: "\F4A5";
  --fa--fa: "\F4A5\F4A5"; }

.fa-bowl-scoop {
  --fa: "\E3DE";
  --fa--fa: "\E3DE\E3DE"; }

.fa-bowl-shaved-ice {
  --fa: "\E3DE";
  --fa--fa: "\E3DE\E3DE"; }

.fa-square-x {
  --fa: "\E286";
  --fa--fa: "\E286\E286"; }

.fa-building-memo {
  --fa: "\E61E";
  --fa--fa: "\E61E\E61E"; }

.fa-utility-pole-double {
  --fa: "\E2C4";
  --fa--fa: "\E2C4\E2C4"; }

.fa-flag-checkered {
  --fa: "\F11E";
  --fa--fa: "\F11E\F11E"; }

.fa-chevrons-up {
  --fa: "\F325";
  --fa--fa: "\F325\F325"; }

.fa-chevron-double-up {
  --fa: "\F325";
  --fa--fa: "\F325\F325"; }

.fa-football {
  --fa: "\F44E";
  --fa--fa: "\F44E\F44E"; }

.fa-football-ball {
  --fa: "\F44E";
  --fa--fa: "\F44E\F44E"; }

.fa-user-vneck {
  --fa: "\E461";
  --fa--fa: "\E461\E461"; }

.fa-school-circle-exclamation {
  --fa: "\E56C";
  --fa--fa: "\E56C\E56C"; }

.fa-crop {
  --fa: "\F125";
  --fa--fa: "\F125\F125"; }

.fa-angles-down {
  --fa: "\F103";
  --fa--fa: "\F103\F103"; }

.fa-angle-double-down {
  --fa: "\F103";
  --fa--fa: "\F103\F103"; }

.fa-users-rectangle {
  --fa: "\E594";
  --fa--fa: "\E594\E594"; }

.fa-people-roof {
  --fa: "\E537";
  --fa--fa: "\E537\E537"; }

.fa-square-arrow-right {
  --fa: "\F33B";
  --fa--fa: "\F33B\F33B"; }

.fa-arrow-square-right {
  --fa: "\F33B";
  --fa--fa: "\F33B\F33B"; }

.fa-location-plus {
  --fa: "\F60A";
  --fa--fa: "\F60A\F60A"; }

.fa-map-marker-plus {
  --fa: "\F60A";
  --fa--fa: "\F60A\F60A"; }

.fa-lightbulb-exclamation-on {
  --fa: "\E1CA";
  --fa--fa: "\E1CA\E1CA"; }

.fa-people-line {
  --fa: "\E534";
  --fa--fa: "\E534\E534"; }

.fa-beer-mug-empty {
  --fa: "\F0FC";
  --fa--fa: "\F0FC\F0FC"; }

.fa-beer {
  --fa: "\F0FC";
  --fa--fa: "\F0FC\F0FC"; }

.fa-carpool {
  --fa: "\E69C";
  --fa--fa: "\E69C\E69C"; }

.fa-car-people {
  --fa: "\E69C";
  --fa--fa: "\E69C\E69C"; }

.fa-crate-empty {
  --fa: "\E151";
  --fa--fa: "\E151\E151"; }

.fa-diagram-predecessor {
  --fa: "\E477";
  --fa--fa: "\E477\E477"; }

.fa-transporter {
  --fa: "\E042";
  --fa--fa: "\E042\E042"; }

.fa-calendar-circle-user {
  --fa: "\E471";
  --fa--fa: "\E471\E471"; }

.fa-arrow-up-long {
  --fa: "\F176";
  --fa--fa: "\F176\F176"; }

.fa-long-arrow-up {
  --fa: "\F176";
  --fa--fa: "\F176\F176"; }

.fa-person-carry-box {
  --fa: "\F4CF";
  --fa--fa: "\F4CF\F4CF"; }

.fa-person-carry {
  --fa: "\F4CF";
  --fa--fa: "\F4CF\F4CF"; }

.fa-fire-flame-simple {
  --fa: "\F46A";
  --fa--fa: "\F46A\F46A"; }

.fa-burn {
  --fa: "\F46A";
  --fa--fa: "\F46A\F46A"; }

.fa-person {
  --fa: "\F183";
  --fa--fa: "\F183\F183"; }

.fa-male {
  --fa: "\F183";
  --fa--fa: "\F183\F183"; }

.fa-laptop {
  --fa: "\F109";
  --fa--fa: "\F109\F109"; }

.fa-file-csv {
  --fa: "\F6DD";
  --fa--fa: "\F6DD\F6DD"; }

.fa-menorah {
  --fa: "\F676";
  --fa--fa: "\F676\F676"; }

.fa-union {
  --fa: "\F6A2";
  --fa--fa: "\F6A2\F6A2"; }

.fa-chevrons-left {
  --fa: "\F323";
  --fa--fa: "\F323\F323"; }

.fa-chevron-double-left {
  --fa: "\F323";
  --fa--fa: "\F323\F323"; }

.fa-circle-heart {
  --fa: "\F4C7";
  --fa--fa: "\F4C7\F4C7"; }

.fa-heart-circle {
  --fa: "\F4C7";
  --fa--fa: "\F4C7\F4C7"; }

.fa-truck-plane {
  --fa: "\E58F";
  --fa--fa: "\E58F\E58F"; }

.fa-record-vinyl {
  --fa: "\F8D9";
  --fa--fa: "\F8D9\F8D9"; }

.fa-bring-forward {
  --fa: "\F856";
  --fa--fa: "\F856\F856"; }

.fa-square-p {
  --fa: "\E279";
  --fa--fa: "\E279\E279"; }

.fa-face-grin-stars {
  --fa: "\F587";
  --fa--fa: "\F587\F587"; }

.fa-grin-stars {
  --fa: "\F587";
  --fa--fa: "\F587\F587"; }

.fa-sigma {
  --fa: "\F68B";
  --fa--fa: "\F68B\F68B"; }

.fa-camera-movie {
  --fa: "\F8A9";
  --fa--fa: "\F8A9\F8A9"; }

.fa-bong {
  --fa: "\F55C";
  --fa--fa: "\F55C\F55C"; }

.fa-clarinet {
  --fa: "\F8AD";
  --fa--fa: "\F8AD\F8AD"; }

.fa-truck-flatbed {
  --fa: "\E2B6";
  --fa--fa: "\E2B6\E2B6"; }

.fa-spaghetti-monster-flying {
  --fa: "\F67B";
  --fa--fa: "\F67B\F67B"; }

.fa-pastafarianism {
  --fa: "\F67B";
  --fa--fa: "\F67B\F67B"; }

.fa-arrow-down-up-across-line {
  --fa: "\E4AF";
  --fa--fa: "\E4AF\E4AF"; }

.fa-arrows-rotate-reverse {
  --fa: "\E630";
  --fa--fa: "\E630\E630"; }

.fa-leaf-heart {
  --fa: "\F4CB";
  --fa--fa: "\F4CB\F4CB"; }

.fa-house-building {
  --fa: "\E1B1";
  --fa--fa: "\E1B1\E1B1"; }

.fa-cheese-swiss {
  --fa: "\F7F0";
  --fa--fa: "\F7F0\F7F0"; }

.fa-spoon {
  --fa: "\F2E5";
  --fa--fa: "\F2E5\F2E5"; }

.fa-utensil-spoon {
  --fa: "\F2E5";
  --fa--fa: "\F2E5\F2E5"; }

.fa-jar-wheat {
  --fa: "\E517";
  --fa--fa: "\E517\E517"; }

.fa-envelopes-bulk {
  --fa: "\F674";
  --fa--fa: "\F674\F674"; }

.fa-mail-bulk {
  --fa: "\F674";
  --fa--fa: "\F674\F674"; }

.fa-file-circle-exclamation {
  --fa: "\E4EB";
  --fa--fa: "\E4EB\E4EB"; }

.fa-bow-arrow {
  --fa: "\F6B9";
  --fa--fa: "\F6B9\F6B9"; }

.fa-cart-xmark {
  --fa: "\E0DD";
  --fa--fa: "\E0DD\E0DD"; }

.fa-hexagon-xmark {
  --fa: "\F2EE";
  --fa--fa: "\F2EE\F2EE"; }

.fa-times-hexagon {
  --fa: "\F2EE";
  --fa--fa: "\F2EE\F2EE"; }

.fa-xmark-hexagon {
  --fa: "\F2EE";
  --fa--fa: "\F2EE\F2EE"; }

.fa-circle-h {
  --fa: "\F47E";
  --fa--fa: "\F47E\F47E"; }

.fa-hospital-symbol {
  --fa: "\F47E";
  --fa--fa: "\F47E\F47E"; }

.fa-merge {
  --fa: "\E526";
  --fa--fa: "\E526\E526"; }

.fa-pager {
  --fa: "\F815";
  --fa--fa: "\F815\F815"; }

.fa-cart-minus {
  --fa: "\E0DB";
  --fa--fa: "\E0DB\E0DB"; }

.fa-address-book {
  --fa: "\F2B9";
  --fa--fa: "\F2B9\F2B9"; }

.fa-contact-book {
  --fa: "\F2B9";
  --fa--fa: "\F2B9\F2B9"; }

.fa-pan-frying {
  --fa: "\E42C";
  --fa--fa: "\E42C\E42C"; }

.fa-grid {
  --fa: "\E195";
  --fa--fa: "\E195\E195"; }

.fa-grid-3 {
  --fa: "\E195";
  --fa--fa: "\E195\E195"; }

.fa-football-helmet {
  --fa: "\F44F";
  --fa--fa: "\F44F\F44F"; }

.fa-hand-love {
  --fa: "\E1A5";
  --fa--fa: "\E1A5\E1A5"; }

.fa-trees {
  --fa: "\F724";
  --fa--fa: "\F724\F724"; }

.fa-strikethrough {
  --fa: "\F0CC";
  --fa--fa: "\F0CC\F0CC"; }

.fa-page {
  --fa: "\E428";
  --fa--fa: "\E428\E428"; }

.fa-k {
  --fa: "K";
  --fa--fa: "KK"; }

.fa-diagram-previous {
  --fa: "\E478";
  --fa--fa: "\E478\E478"; }

.fa-gauge-min {
  --fa: "\F628";
  --fa--fa: "\F628\F628"; }

.fa-tachometer-alt-slowest {
  --fa: "\F628";
  --fa--fa: "\F628\F628"; }

.fa-folder-grid {
  --fa: "\E188";
  --fa--fa: "\E188\E188"; }

.fa-eggplant {
  --fa: "\E16C";
  --fa--fa: "\E16C\E16C"; }

.fa-excavator {
  --fa: "\E656";
  --fa--fa: "\E656\E656"; }

.fa-ram {
  --fa: "\F70A";
  --fa--fa: "\F70A\F70A"; }

.fa-landmark-flag {
  --fa: "\E51C";
  --fa--fa: "\E51C\E51C"; }

.fa-lips {
  --fa: "\F600";
  --fa--fa: "\F600\F600"; }

.fa-pencil {
  --fa: "\F303";
  --fa--fa: "\F303\F303"; }

.fa-pencil-alt {
  --fa: "\F303";
  --fa--fa: "\F303\F303"; }

.fa-backward {
  --fa: "\F04A";
  --fa--fa: "\F04A\F04A"; }

.fa-caret-right {
  --fa: "\F0DA";
  --fa--fa: "\F0DA\F0DA"; }

.fa-comments {
  --fa: "\F086";
  --fa--fa: "\F086\F086"; }

.fa-paste {
  --fa: "\F0EA";
  --fa--fa: "\F0EA\F0EA"; }

.fa-file-clipboard {
  --fa: "\F0EA";
  --fa--fa: "\F0EA\F0EA"; }

.fa-desktop-arrow-down {
  --fa: "\E155";
  --fa--fa: "\E155\E155"; }

.fa-code-pull-request {
  --fa: "\E13C";
  --fa--fa: "\E13C\E13C"; }

.fa-pumpkin {
  --fa: "\F707";
  --fa--fa: "\F707\F707"; }

.fa-clipboard-list {
  --fa: "\F46D";
  --fa--fa: "\F46D\F46D"; }

.fa-pen-field {
  --fa: "\E211";
  --fa--fa: "\E211\E211"; }

.fa-chart-sine {
  --fa: "\E69D";
  --fa--fa: "\E69D\E69D"; }

.fa-blueberries {
  --fa: "\E2E8";
  --fa--fa: "\E2E8\E2E8"; }

.fa-truck-ramp-box {
  --fa: "\F4DE";
  --fa--fa: "\F4DE\F4DE"; }

.fa-truck-loading {
  --fa: "\F4DE";
  --fa--fa: "\F4DE\F4DE"; }

.fa-note {
  --fa: "\E1FF";
  --fa--fa: "\E1FF\E1FF"; }

.fa-arrow-down-to-square {
  --fa: "\E096";
  --fa--fa: "\E096\E096"; }

.fa-user-check {
  --fa: "\F4FC";
  --fa--fa: "\F4FC\F4FC"; }

.fa-cloud-xmark {
  --fa: "\E35F";
  --fa--fa: "\E35F\E35F"; }

.fa-vial-virus {
  --fa: "\E597";
  --fa--fa: "\E597\E597"; }

.fa-book-blank {
  --fa: "\F5D9";
  --fa--fa: "\F5D9\F5D9"; }

.fa-book-alt {
  --fa: "\F5D9";
  --fa--fa: "\F5D9\F5D9"; }

.fa-golf-flag-hole {
  --fa: "\E3AC";
  --fa--fa: "\E3AC\E3AC"; }

.fa-message-arrow-down {
  --fa: "\E1DB";
  --fa--fa: "\E1DB\E1DB"; }

.fa-comment-alt-arrow-down {
  --fa: "\E1DB";
  --fa--fa: "\E1DB\E1DB"; }

.fa-face-unamused {
  --fa: "\E39F";
  --fa--fa: "\E39F\E39F"; }

.fa-sheet-plastic {
  --fa: "\E571";
  --fa--fa: "\E571\E571"; }

.fa-circle-9 {
  --fa: "\E0F6";
  --fa--fa: "\E0F6\E0F6"; }

.fa-blog {
  --fa: "\F781";
  --fa--fa: "\F781\F781"; }

.fa-user-ninja {
  --fa: "\F504";
  --fa--fa: "\F504\F504"; }

.fa-pencil-slash {
  --fa: "\E215";
  --fa--fa: "\E215\E215"; }

.fa-bowling-pins {
  --fa: "\F437";
  --fa--fa: "\F437\F437"; }

.fa-person-arrow-up-from-line {
  --fa: "\E539";
  --fa--fa: "\E539\E539"; }

.fa-down-right {
  --fa: "\E16B";
  --fa--fa: "\E16B\E16B"; }

.fa-scroll-torah {
  --fa: "\F6A0";
  --fa--fa: "\F6A0\F6A0"; }

.fa-torah {
  --fa: "\F6A0";
  --fa--fa: "\F6A0\F6A0"; }

.fa-webhook {
  --fa: "\E5D5";
  --fa--fa: "\E5D5\E5D5"; }

.fa-blinds-open {
  --fa: "\F8FC";
  --fa--fa: "\F8FC\F8FC"; }

.fa-fence {
  --fa: "\E303";
  --fa--fa: "\E303\E303"; }

.fa-up {
  --fa: "\F357";
  --fa--fa: "\F357\F357"; }

.fa-arrow-alt-up {
  --fa: "\F357";
  --fa--fa: "\F357\F357"; }

.fa-broom-ball {
  --fa: "\F458";
  --fa--fa: "\F458\F458"; }

.fa-quidditch {
  --fa: "\F458";
  --fa--fa: "\F458\F458"; }

.fa-quidditch-broom-ball {
  --fa: "\F458";
  --fa--fa: "\F458\F458"; }

.fa-drumstick {
  --fa: "\F6D6";
  --fa--fa: "\F6D6\F6D6"; }

.fa-square-v {
  --fa: "\E284";
  --fa--fa: "\E284\E284"; }

.fa-face-awesome {
  --fa: "\E409";
  --fa--fa: "\E409\E409"; }

.fa-gave-dandy {
  --fa: "\E409";
  --fa--fa: "\E409\E409"; }

.fa-dial-off {
  --fa: "\E162";
  --fa--fa: "\E162\E162"; }

.fa-toggle-off {
  --fa: "\F204";
  --fa--fa: "\F204\F204"; }

.fa-face-smile-horns {
  --fa: "\E391";
  --fa--fa: "\E391\E391"; }

.fa-box-archive {
  --fa: "\F187";
  --fa--fa: "\F187\F187"; }

.fa-archive {
  --fa: "\F187";
  --fa--fa: "\F187\F187"; }

.fa-grapes {
  --fa: "\E306";
  --fa--fa: "\E306\E306"; }

.fa-person-drowning {
  --fa: "\E545";
  --fa--fa: "\E545\E545"; }

.fa-dial-max {
  --fa: "\E15E";
  --fa--fa: "\E15E\E15E"; }

.fa-circle-m {
  --fa: "\E115";
  --fa--fa: "\E115\E115"; }

.fa-calendar-image {
  --fa: "\E0D4";
  --fa--fa: "\E0D4\E0D4"; }

.fa-circle-caret-down {
  --fa: "\F32D";
  --fa--fa: "\F32D\F32D"; }

.fa-caret-circle-down {
  --fa: "\F32D";
  --fa--fa: "\F32D\F32D"; }

.fa-arrow-down-9-1 {
  --fa: "\F886";
  --fa--fa: "\F886\F886"; }

.fa-sort-numeric-desc {
  --fa: "\F886";
  --fa--fa: "\F886\F886"; }

.fa-sort-numeric-down-alt {
  --fa: "\F886";
  --fa--fa: "\F886\F886"; }

.fa-face-grin-tongue-squint {
  --fa: "\F58A";
  --fa--fa: "\F58A\F58A"; }

.fa-grin-tongue-squint {
  --fa: "\F58A";
  --fa--fa: "\F58A\F58A"; }

.fa-shish-kebab {
  --fa: "\F821";
  --fa--fa: "\F821\F821"; }

.fa-spray-can {
  --fa: "\F5BD";
  --fa--fa: "\F5BD\F5BD"; }

.fa-alarm-snooze {
  --fa: "\F845";
  --fa--fa: "\F845\F845"; }

.fa-scarecrow {
  --fa: "\F70D";
  --fa--fa: "\F70D\F70D"; }

.fa-truck-monster {
  --fa: "\F63B";
  --fa--fa: "\F63B\F63B"; }

.fa-gift-card {
  --fa: "\F663";
  --fa--fa: "\F663\F663"; }

.fa-w {
  --fa: "W";
  --fa--fa: "WW"; }

.fa-code-pull-request-draft {
  --fa: "\E3FA";
  --fa--fa: "\E3FA\E3FA"; }

.fa-square-b {
  --fa: "\E264";
  --fa--fa: "\E264\E264"; }

.fa-elephant {
  --fa: "\F6DA";
  --fa--fa: "\F6DA\F6DA"; }

.fa-earth-africa {
  --fa: "\F57C";
  --fa--fa: "\F57C\F57C"; }

.fa-globe-africa {
  --fa: "\F57C";
  --fa--fa: "\F57C\F57C"; }

.fa-rainbow {
  --fa: "\F75B";
  --fa--fa: "\F75B\F75B"; }

.fa-circle-notch {
  --fa: "\F1CE";
  --fa--fa: "\F1CE\F1CE"; }

.fa-tablet-screen-button {
  --fa: "\F3FA";
  --fa--fa: "\F3FA\F3FA"; }

.fa-tablet-alt {
  --fa: "\F3FA";
  --fa--fa: "\F3FA\F3FA"; }

.fa-paw {
  --fa: "\F1B0";
  --fa--fa: "\F1B0\F1B0"; }

.fa-message-question {
  --fa: "\E1E3";
  --fa--fa: "\E1E3\E1E3"; }

.fa-cloud {
  --fa: "\F0C2";
  --fa--fa: "\F0C2\F0C2"; }

.fa-trowel-bricks {
  --fa: "\E58A";
  --fa--fa: "\E58A\E58A"; }

.fa-square-3 {
  --fa: "\E258";
  --fa--fa: "\E258\E258"; }

.fa-face-flushed {
  --fa: "\F579";
  --fa--fa: "\F579\F579"; }

.fa-flushed {
  --fa: "\F579";
  --fa--fa: "\F579\F579"; }

.fa-hospital-user {
  --fa: "\F80D";
  --fa--fa: "\F80D\F80D"; }

.fa-microwave {
  --fa: "\E01B";
  --fa--fa: "\E01B\E01B"; }

.fa-chf-sign {
  --fa: "\E602";
  --fa--fa: "\E602\E602"; }

.fa-tent-arrow-left-right {
  --fa: "\E57F";
  --fa--fa: "\E57F\E57F"; }

.fa-cart-circle-arrow-up {
  --fa: "\E3F0";
  --fa--fa: "\E3F0\E3F0"; }

.fa-trash-clock {
  --fa: "\E2B0";
  --fa--fa: "\E2B0\E2B0"; }

.fa-reflect-both {
  --fa: "\E66F";
  --fa--fa: "\E66F\E66F"; }

.fa-gavel {
  --fa: "\F0E3";
  --fa--fa: "\F0E3\F0E3"; }

.fa-legal {
  --fa: "\F0E3";
  --fa--fa: "\F0E3\F0E3"; }

.fa-sprinkler-ceiling {
  --fa: "\E44C";
  --fa--fa: "\E44C\E44C"; }

.fa-browsers {
  --fa: "\E0CB";
  --fa--fa: "\E0CB\E0CB"; }

.fa-trillium {
  --fa: "\E588";
  --fa--fa: "\E588\E588"; }

.fa-table-cells-unlock {
  --fa: "\E692";
  --fa--fa: "\E692\E692"; }

.fa-music-slash {
  --fa: "\F8D1";
  --fa--fa: "\F8D1\F8D1"; }

.fa-truck-ramp {
  --fa: "\F4E0";
  --fa--fa: "\F4E0\F4E0"; }

.fa-binoculars {
  --fa: "\F1E5";
  --fa--fa: "\F1E5\F1E5"; }

.fa-microphone-slash {
  --fa: "\F131";
  --fa--fa: "\F131\F131"; }

.fa-box-tissue {
  --fa: "\E05B";
  --fa--fa: "\E05B\E05B"; }

.fa-circle-c {
  --fa: "\E101";
  --fa--fa: "\E101\E101"; }

.fa-star-christmas {
  --fa: "\F7D4";
  --fa--fa: "\F7D4\F7D4"; }

.fa-chart-bullet {
  --fa: "\E0E1";
  --fa--fa: "\E0E1\E0E1"; }

.fa-motorcycle {
  --fa: "\F21C";
  --fa--fa: "\F21C\F21C"; }

.fa-tree-christmas {
  --fa: "\F7DB";
  --fa--fa: "\F7DB\F7DB"; }

.fa-tire-flat {
  --fa: "\F632";
  --fa--fa: "\F632\F632"; }

.fa-sunglasses {
  --fa: "\F892";
  --fa--fa: "\F892\F892"; }

.fa-badge {
  --fa: "\F335";
  --fa--fa: "\F335\F335"; }

.fa-message-pen {
  --fa: "\F4A4";
  --fa--fa: "\F4A4\F4A4"; }

.fa-comment-alt-edit {
  --fa: "\F4A4";
  --fa--fa: "\F4A4\F4A4"; }

.fa-message-edit {
  --fa: "\F4A4";
  --fa--fa: "\F4A4\F4A4"; }

.fa-bell-concierge {
  --fa: "\F562";
  --fa--fa: "\F562\F562"; }

.fa-concierge-bell {
  --fa: "\F562";
  --fa--fa: "\F562\F562"; }

.fa-pen-ruler {
  --fa: "\F5AE";
  --fa--fa: "\F5AE\F5AE"; }

.fa-pencil-ruler {
  --fa: "\F5AE";
  --fa--fa: "\F5AE\F5AE"; }

.fa-file-mp3 {
  --fa: "\E648";
  --fa--fa: "\E648\E648"; }

.fa-arrow-progress {
  --fa: "\E5DF";
  --fa--fa: "\E5DF\E5DF"; }

.fa-chess-rook-piece {
  --fa: "\F448";
  --fa--fa: "\F448\F448"; }

.fa-chess-rook-alt {
  --fa: "\F448";
  --fa--fa: "\F448\F448"; }

.fa-square-root {
  --fa: "\F697";
  --fa--fa: "\F697\F697"; }

.fa-album-collection-circle-plus {
  --fa: "\E48E";
  --fa--fa: "\E48E\E48E"; }

.fa-people-arrows {
  --fa: "\E068";
  --fa--fa: "\E068\E068"; }

.fa-people-arrows-left-right {
  --fa: "\E068";
  --fa--fa: "\E068\E068"; }

.fa-sign-post {
  --fa: "\E624";
  --fa--fa: "\E624\E624"; }

.fa-face-angry-horns {
  --fa: "\E368";
  --fa--fa: "\E368\E368"; }

.fa-mars-and-venus-burst {
  --fa: "\E523";
  --fa--fa: "\E523\E523"; }

.fa-tombstone {
  --fa: "\F720";
  --fa--fa: "\F720\F720"; }

.fa-square-caret-right {
  --fa: "\F152";
  --fa--fa: "\F152\F152"; }

.fa-caret-square-right {
  --fa: "\F152";
  --fa--fa: "\F152\F152"; }

.fa-scissors {
  --fa: "\F0C4";
  --fa--fa: "\F0C4\F0C4"; }

.fa-cut {
  --fa: "\F0C4";
  --fa--fa: "\F0C4\F0C4"; }

.fa-list-music {
  --fa: "\F8C9";
  --fa--fa: "\F8C9\F8C9"; }

.fa-sun-plant-wilt {
  --fa: "\E57A";
  --fa--fa: "\E57A\E57A"; }

.fa-toilets-portable {
  --fa: "\E584";
  --fa--fa: "\E584\E584"; }

.fa-hockey-puck {
  --fa: "\F453";
  --fa--fa: "\F453\F453"; }

.fa-mustache {
  --fa: "\E5BC";
  --fa--fa: "\E5BC\E5BC"; }

.fa-hyphen {
  --fa: "-";
  --fa--fa: "--"; }

.fa-table {
  --fa: "\F0CE";
  --fa--fa: "\F0CE\F0CE"; }

.fa-user-chef {
  --fa: "\E3D2";
  --fa--fa: "\E3D2\E3D2"; }

.fa-message-image {
  --fa: "\E1E0";
  --fa--fa: "\E1E0\E1E0"; }

.fa-comment-alt-image {
  --fa: "\E1E0";
  --fa--fa: "\E1E0\E1E0"; }

.fa-users-medical {
  --fa: "\F830";
  --fa--fa: "\F830\F830"; }

.fa-sensor-triangle-exclamation {
  --fa: "\E029";
  --fa--fa: "\E029\E029"; }

.fa-sensor-alert {
  --fa: "\E029";
  --fa--fa: "\E029\E029"; }

.fa-magnifying-glass-arrow-right {
  --fa: "\E521";
  --fa--fa: "\E521\E521"; }

.fa-tachograph-digital {
  --fa: "\F566";
  --fa--fa: "\F566\F566"; }

.fa-digital-tachograph {
  --fa: "\F566";
  --fa--fa: "\F566\F566"; }

.fa-face-mask {
  --fa: "\E37F";
  --fa--fa: "\E37F\E37F"; }

.fa-pickleball {
  --fa: "\E435";
  --fa--fa: "\E435\E435"; }

.fa-star-sharp-half {
  --fa: "\E28C";
  --fa--fa: "\E28C\E28C"; }

.fa-users-slash {
  --fa: "\E073";
  --fa--fa: "\E073\E073"; }

.fa-clover {
  --fa: "\E139";
  --fa--fa: "\E139\E139"; }

.fa-meat {
  --fa: "\F814";
  --fa--fa: "\F814\F814"; }

.fa-reply {
  --fa: "\F3E5";
  --fa--fa: "\F3E5\F3E5"; }

.fa-mail-reply {
  --fa: "\F3E5";
  --fa--fa: "\F3E5\F3E5"; }

.fa-star-and-crescent {
  --fa: "\F699";
  --fa--fa: "\F699\F699"; }

.fa-empty-set {
  --fa: "\F656";
  --fa--fa: "\F656\F656"; }

.fa-house-fire {
  --fa: "\E50C";
  --fa--fa: "\E50C\E50C"; }

.fa-square-minus {
  --fa: "\F146";
  --fa--fa: "\F146\F146"; }

.fa-minus-square {
  --fa: "\F146";
  --fa--fa: "\F146\F146"; }

.fa-helicopter {
  --fa: "\F533";
  --fa--fa: "\F533\F533"; }

.fa-bird {
  --fa: "\E469";
  --fa--fa: "\E469\E469"; }

.fa-compass {
  --fa: "\F14E";
  --fa--fa: "\F14E\F14E"; }

.fa-square-caret-down {
  --fa: "\F150";
  --fa--fa: "\F150\F150"; }

.fa-caret-square-down {
  --fa: "\F150";
  --fa--fa: "\F150\F150"; }

.fa-heart-half-stroke {
  --fa: "\E1AC";
  --fa--fa: "\E1AC\E1AC"; }

.fa-heart-half-alt {
  --fa: "\E1AC";
  --fa--fa: "\E1AC\E1AC"; }

.fa-file-circle-question {
  --fa: "\E4EF";
  --fa--fa: "\E4EF\E4EF"; }

.fa-truck-utensils {
  --fa: "\E628";
  --fa--fa: "\E628\E628"; }

.fa-laptop-code {
  --fa: "\F5FC";
  --fa--fa: "\F5FC\F5FC"; }

.fa-joystick {
  --fa: "\F8C5";
  --fa--fa: "\F8C5\F8C5"; }

.fa-grill-fire {
  --fa: "\E5A4";
  --fa--fa: "\E5A4\E5A4"; }

.fa-rectangle-vertical-history {
  --fa: "\E237";
  --fa--fa: "\E237\E237"; }

.fa-swatchbook {
  --fa: "\F5C3";
  --fa--fa: "\F5C3\F5C3"; }

.fa-prescription-bottle {
  --fa: "\F485";
  --fa--fa: "\F485\F485"; }

.fa-bars {
  --fa: "\F0C9";
  --fa--fa: "\F0C9\F0C9"; }

.fa-navicon {
  --fa: "\F0C9";
  --fa--fa: "\F0C9\F0C9"; }

.fa-keyboard-left {
  --fa: "\E1C3";
  --fa--fa: "\E1C3\E1C3"; }

.fa-people-group {
  --fa: "\E533";
  --fa--fa: "\E533\E533"; }

.fa-hourglass-end {
  --fa: "\F253";
  --fa--fa: "\F253\F253"; }

.fa-hourglass-3 {
  --fa: "\F253";
  --fa--fa: "\F253\F253"; }

.fa-heart-crack {
  --fa: "\F7A9";
  --fa--fa: "\F7A9\F7A9"; }

.fa-heart-broken {
  --fa: "\F7A9";
  --fa--fa: "\F7A9\F7A9"; }

.fa-face-beam-hand-over-mouth {
  --fa: "\E47C";
  --fa--fa: "\E47C\E47C"; }

.fa-droplet-percent {
  --fa: "\F750";
  --fa--fa: "\F750\F750"; }

.fa-humidity {
  --fa: "\F750";
  --fa--fa: "\F750\F750"; }

.fa-square-up-right {
  --fa: "\F360";
  --fa--fa: "\F360\F360"; }

.fa-external-link-square-alt {
  --fa: "\F360";
  --fa--fa: "\F360\F360"; }

.fa-face-kiss-beam {
  --fa: "\F597";
  --fa--fa: "\F597\F597"; }

.fa-kiss-beam {
  --fa: "\F597";
  --fa--fa: "\F597\F597"; }

.fa-corn {
  --fa: "\F6C7";
  --fa--fa: "\F6C7\F6C7"; }

.fa-roller-coaster {
  --fa: "\E324";
  --fa--fa: "\E324\E324"; }

.fa-photo-film-music {
  --fa: "\E228";
  --fa--fa: "\E228\E228"; }

.fa-radar {
  --fa: "\E024";
  --fa--fa: "\E024\E024"; }

.fa-sickle {
  --fa: "\F822";
  --fa--fa: "\F822\F822"; }

.fa-film {
  --fa: "\F008";
  --fa--fa: "\F008\F008"; }

.fa-coconut {
  --fa: "\E2F6";
  --fa--fa: "\E2F6\E2F6"; }

.fa-ruler-horizontal {
  --fa: "\F547";
  --fa--fa: "\F547\F547"; }

.fa-shield-cross {
  --fa: "\F712";
  --fa--fa: "\F712\F712"; }

.fa-cassette-tape {
  --fa: "\F8AB";
  --fa--fa: "\F8AB\F8AB"; }

.fa-square-terminal {
  --fa: "\E32A";
  --fa--fa: "\E32A\E32A"; }

.fa-people-robbery {
  --fa: "\E536";
  --fa--fa: "\E536\E536"; }

.fa-lightbulb {
  --fa: "\F0EB";
  --fa--fa: "\F0EB\F0EB"; }

.fa-caret-left {
  --fa: "\F0D9";
  --fa--fa: "\F0D9\F0D9"; }

.fa-comment-middle {
  --fa: "\E149";
  --fa--fa: "\E149\E149"; }

.fa-trash-can-list {
  --fa: "\E2AB";
  --fa--fa: "\E2AB\E2AB"; }

.fa-block {
  --fa: "\E46A";
  --fa--fa: "\E46A\E46A"; }

.fa-circle-exclamation {
  --fa: "\F06A";
  --fa--fa: "\F06A\F06A"; }

.fa-exclamation-circle {
  --fa: "\F06A";
  --fa--fa: "\F06A\F06A"; }

.fa-school-circle-xmark {
  --fa: "\E56D";
  --fa--fa: "\E56D\E56D"; }

.fa-arrow-right-from-bracket {
  --fa: "\F08B";
  --fa--fa: "\F08B\F08B"; }

.fa-sign-out {
  --fa: "\F08B";
  --fa--fa: "\F08B\F08B"; }

.fa-face-frown-slight {
  --fa: "\E376";
  --fa--fa: "\E376\E376"; }

.fa-circle-chevron-down {
  --fa: "\F13A";
  --fa--fa: "\F13A\F13A"; }

.fa-chevron-circle-down {
  --fa: "\F13A";
  --fa--fa: "\F13A\F13A"; }

.fa-sidebar-flip {
  --fa: "\E24F";
  --fa--fa: "\E24F\E24F"; }

.fa-unlock-keyhole {
  --fa: "\F13E";
  --fa--fa: "\F13E\F13E"; }

.fa-unlock-alt {
  --fa: "\F13E";
  --fa--fa: "\F13E\F13E"; }

.fa-temperature-list {
  --fa: "\E299";
  --fa--fa: "\E299\E299"; }

.fa-cloud-showers-heavy {
  --fa: "\F740";
  --fa--fa: "\F740\F740"; }

.fa-headphones-simple {
  --fa: "\F58F";
  --fa--fa: "\F58F\F58F"; }

.fa-headphones-alt {
  --fa: "\F58F";
  --fa--fa: "\F58F\F58F"; }

.fa-sitemap {
  --fa: "\F0E8";
  --fa--fa: "\F0E8\F0E8"; }

.fa-pipe-section {
  --fa: "\E438";
  --fa--fa: "\E438\E438"; }

.fa-space-station-moon-construction {
  --fa: "\E034";
  --fa--fa: "\E034\E034"; }

.fa-space-station-moon-alt {
  --fa: "\E034";
  --fa--fa: "\E034\E034"; }

.fa-circle-dollar-to-slot {
  --fa: "\F4B9";
  --fa--fa: "\F4B9\F4B9"; }

.fa-donate {
  --fa: "\F4B9";
  --fa--fa: "\F4B9\F4B9"; }

.fa-memory {
  --fa: "\F538";
  --fa--fa: "\F538\F538"; }

.fa-face-sleeping {
  --fa: "\E38D";
  --fa--fa: "\E38D\E38D"; }

.fa-road-spikes {
  --fa: "\E568";
  --fa--fa: "\E568\E568"; }

.fa-fire-burner {
  --fa: "\E4F1";
  --fa--fa: "\E4F1\E4F1"; }

.fa-squirrel {
  --fa: "\F71A";
  --fa--fa: "\F71A\F71A"; }

.fa-arrow-up-to-line {
  --fa: "\F341";
  --fa--fa: "\F341\F341"; }

.fa-arrow-to-top {
  --fa: "\F341";
  --fa--fa: "\F341\F341"; }

.fa-flag {
  --fa: "\F024";
  --fa--fa: "\F024\F024"; }

.fa-face-cowboy-hat {
  --fa: "\E36E";
  --fa--fa: "\E36E\E36E"; }

.fa-hanukiah {
  --fa: "\F6E6";
  --fa--fa: "\F6E6\F6E6"; }

.fa-chart-scatter-3d {
  --fa: "\E0E8";
  --fa--fa: "\E0E8\E0E8"; }

.fa-display-chart-up {
  --fa: "\E5E3";
  --fa--fa: "\E5E3\E5E3"; }

.fa-square-code {
  --fa: "\E267";
  --fa--fa: "\E267\E267"; }

.fa-feather {
  --fa: "\F52D";
  --fa--fa: "\F52D\F52D"; }

.fa-volume-low {
  --fa: "\F027";
  --fa--fa: "\F027\F027"; }

.fa-volume-down {
  --fa: "\F027";
  --fa--fa: "\F027\F027"; }

.fa-xmark-to-slot {
  --fa: "\F771";
  --fa--fa: "\F771\F771"; }

.fa-times-to-slot {
  --fa: "\F771";
  --fa--fa: "\F771\F771"; }

.fa-vote-nay {
  --fa: "\F771";
  --fa--fa: "\F771\F771"; }

.fa-box-taped {
  --fa: "\F49A";
  --fa--fa: "\F49A\F49A"; }

.fa-box-alt {
  --fa: "\F49A";
  --fa--fa: "\F49A\F49A"; }

.fa-comment-slash {
  --fa: "\F4B3";
  --fa--fa: "\F4B3\F4B3"; }

.fa-swords {
  --fa: "\F71D";
  --fa--fa: "\F71D\F71D"; }

.fa-cloud-sun-rain {
  --fa: "\F743";
  --fa--fa: "\F743\F743"; }

.fa-album {
  --fa: "\F89F";
  --fa--fa: "\F89F\F89F"; }

.fa-circle-n {
  --fa: "\E118";
  --fa--fa: "\E118\E118"; }

.fa-compress {
  --fa: "\F066";
  --fa--fa: "\F066\F066"; }

.fa-wheat-awn {
  --fa: "\E2CD";
  --fa--fa: "\E2CD\E2CD"; }

.fa-wheat-alt {
  --fa: "\E2CD";
  --fa--fa: "\E2CD\E2CD"; }

.fa-ankh {
  --fa: "\F644";
  --fa--fa: "\F644\F644"; }

.fa-hands-holding-child {
  --fa: "\E4FA";
  --fa--fa: "\E4FA\E4FA"; }

.fa-asterisk {
  --fa: "*";
  --fa--fa: "**"; }

.fa-key-skeleton-left-right {
  --fa: "\E3B4";
  --fa--fa: "\E3B4\E3B4"; }

.fa-comment-lines {
  --fa: "\F4B0";
  --fa--fa: "\F4B0\F4B0"; }

.fa-luchador-mask {
  --fa: "\F455";
  --fa--fa: "\F455\F455"; }

.fa-luchador {
  --fa: "\F455";
  --fa--fa: "\F455\F455"; }

.fa-mask-luchador {
  --fa: "\F455";
  --fa--fa: "\F455\F455"; }

.fa-square-check {
  --fa: "\F14A";
  --fa--fa: "\F14A\F14A"; }

.fa-check-square {
  --fa: "\F14A";
  --fa--fa: "\F14A\F14A"; }

.fa-shredder {
  --fa: "\F68A";
  --fa--fa: "\F68A\F68A"; }

.fa-book-open-cover {
  --fa: "\E0C0";
  --fa--fa: "\E0C0\E0C0"; }

.fa-book-open-alt {
  --fa: "\E0C0";
  --fa--fa: "\E0C0\E0C0"; }

.fa-sandwich {
  --fa: "\F81F";
  --fa--fa: "\F81F\F81F"; }

.fa-peseta-sign {
  --fa: "\E221";
  --fa--fa: "\E221\E221"; }

.fa-square-parking-slash {
  --fa: "\F617";
  --fa--fa: "\F617\F617"; }

.fa-parking-slash {
  --fa: "\F617";
  --fa--fa: "\F617\F617"; }

.fa-train-tunnel {
  --fa: "\E454";
  --fa--fa: "\E454\E454"; }

.fa-heading {
  --fa: "\F1DC";
  --fa--fa: "\F1DC\F1DC"; }

.fa-header {
  --fa: "\F1DC";
  --fa--fa: "\F1DC\F1DC"; }

.fa-ghost {
  --fa: "\F6E2";
  --fa--fa: "\F6E2\F6E2"; }

.fa-face-anguished {
  --fa: "\E369";
  --fa--fa: "\E369\E369"; }

.fa-hockey-sticks {
  --fa: "\F454";
  --fa--fa: "\F454\F454"; }

.fa-abacus {
  --fa: "\F640";
  --fa--fa: "\F640\F640"; }

.fa-film-simple {
  --fa: "\F3A0";
  --fa--fa: "\F3A0\F3A0"; }

.fa-film-alt {
  --fa: "\F3A0";
  --fa--fa: "\F3A0\F3A0"; }

.fa-list {
  --fa: "\F03A";
  --fa--fa: "\F03A\F03A"; }

.fa-list-squares {
  --fa: "\F03A";
  --fa--fa: "\F03A\F03A"; }

.fa-tree-palm {
  --fa: "\F82B";
  --fa--fa: "\F82B\F82B"; }

.fa-square-phone-flip {
  --fa: "\F87B";
  --fa--fa: "\F87B\F87B"; }

.fa-phone-square-alt {
  --fa: "\F87B";
  --fa--fa: "\F87B\F87B"; }

.fa-user-beard-bolt {
  --fa: "\E689";
  --fa--fa: "\E689\E689"; }

.fa-cart-plus {
  --fa: "\F217";
  --fa--fa: "\F217\F217"; }

.fa-gamepad {
  --fa: "\F11B";
  --fa--fa: "\F11B\F11B"; }

.fa-border-center-v {
  --fa: "\F89D";
  --fa--fa: "\F89D\F89D"; }

.fa-circle-dot {
  --fa: "\F192";
  --fa--fa: "\F192\F192"; }

.fa-dot-circle {
  --fa: "\F192";
  --fa--fa: "\F192\F192"; }

.fa-clipboard-medical {
  --fa: "\E133";
  --fa--fa: "\E133\E133"; }

.fa-face-dizzy {
  --fa: "\F567";
  --fa--fa: "\F567\F567"; }

.fa-dizzy {
  --fa: "\F567";
  --fa--fa: "\F567\F567"; }

.fa-egg {
  --fa: "\F7FB";
  --fa--fa: "\F7FB\F7FB"; }

.fa-up-to-line {
  --fa: "\F34D";
  --fa--fa: "\F34D\F34D"; }

.fa-arrow-alt-to-top {
  --fa: "\F34D";
  --fa--fa: "\F34D\F34D"; }

.fa-house-medical-circle-xmark {
  --fa: "\E513";
  --fa--fa: "\E513\E513"; }

.fa-watch-fitness {
  --fa: "\F63E";
  --fa--fa: "\F63E\F63E"; }

.fa-clock-nine-thirty {
  --fa: "\E34D";
  --fa--fa: "\E34D\E34D"; }

.fa-campground {
  --fa: "\F6BB";
  --fa--fa: "\F6BB\F6BB"; }

.fa-folder-plus {
  --fa: "\F65E";
  --fa--fa: "\F65E\F65E"; }

.fa-jug {
  --fa: "\F8C6";
  --fa--fa: "\F8C6\F8C6"; }

.fa-futbol {
  --fa: "\F1E3";
  --fa--fa: "\F1E3\F1E3"; }

.fa-futbol-ball {
  --fa: "\F1E3";
  --fa--fa: "\F1E3\F1E3"; }

.fa-soccer-ball {
  --fa: "\F1E3";
  --fa--fa: "\F1E3\F1E3"; }

.fa-snow-blowing {
  --fa: "\F761";
  --fa--fa: "\F761\F761"; }

.fa-paintbrush {
  --fa: "\F1FC";
  --fa--fa: "\F1FC\F1FC"; }

.fa-paint-brush {
  --fa: "\F1FC";
  --fa--fa: "\F1FC\F1FC"; }

.fa-lock {
  --fa: "\F023";
  --fa--fa: "\F023\F023"; }

.fa-arrow-down-from-line {
  --fa: "\F345";
  --fa--fa: "\F345\F345"; }

.fa-arrow-from-top {
  --fa: "\F345";
  --fa--fa: "\F345\F345"; }

.fa-gas-pump {
  --fa: "\F52F";
  --fa--fa: "\F52F\F52F"; }

.fa-signal-bars-slash {
  --fa: "\F694";
  --fa--fa: "\F694\F694"; }

.fa-signal-alt-slash {
  --fa: "\F694";
  --fa--fa: "\F694\F694"; }

.fa-monkey {
  --fa: "\F6FB";
  --fa--fa: "\F6FB\F6FB"; }

.fa-rectangle-pro {
  --fa: "\E235";
  --fa--fa: "\E235\E235"; }

.fa-pro {
  --fa: "\E235";
  --fa--fa: "\E235\E235"; }

.fa-house-night {
  --fa: "\E010";
  --fa--fa: "\E010\E010"; }

.fa-hot-tub-person {
  --fa: "\F593";
  --fa--fa: "\F593\F593"; }

.fa-hot-tub {
  --fa: "\F593";
  --fa--fa: "\F593\F593"; }

.fa-globe-pointer {
  --fa: "\E60E";
  --fa--fa: "\E60E\E60E"; }

.fa-blanket {
  --fa: "\F498";
  --fa--fa: "\F498\F498"; }

.fa-map-location {
  --fa: "\F59F";
  --fa--fa: "\F59F\F59F"; }

.fa-map-marked {
  --fa: "\F59F";
  --fa--fa: "\F59F\F59F"; }

.fa-house-flood-water {
  --fa: "\E50E";
  --fa--fa: "\E50E\E50E"; }

.fa-comments-question-check {
  --fa: "\E14F";
  --fa--fa: "\E14F\E14F"; }

.fa-tree {
  --fa: "\F1BB";
  --fa--fa: "\F1BB\F1BB"; }

.fa-arrows-cross {
  --fa: "\E0A2";
  --fa--fa: "\E0A2\E0A2"; }

.fa-backpack {
  --fa: "\F5D4";
  --fa--fa: "\F5D4\F5D4"; }

.fa-square-small {
  --fa: "\E27E";
  --fa--fa: "\E27E\E27E"; }

.fa-folder-arrow-up {
  --fa: "\E054";
  --fa--fa: "\E054\E054"; }

.fa-folder-upload {
  --fa: "\E054";
  --fa--fa: "\E054\E054"; }

.fa-bridge-lock {
  --fa: "\E4CC";
  --fa--fa: "\E4CC\E4CC"; }

.fa-crosshairs-simple {
  --fa: "\E59F";
  --fa--fa: "\E59F\E59F"; }

.fa-sack-dollar {
  --fa: "\F81D";
  --fa--fa: "\F81D\F81D"; }

.fa-pen-to-square {
  --fa: "\F044";
  --fa--fa: "\F044\F044"; }

.fa-edit {
  --fa: "\F044";
  --fa--fa: "\F044\F044"; }

.fa-square-sliders {
  --fa: "\F3F0";
  --fa--fa: "\F3F0\F3F0"; }

.fa-sliders-h-square {
  --fa: "\F3F0";
  --fa--fa: "\F3F0\F3F0"; }

.fa-car-side {
  --fa: "\F5E4";
  --fa--fa: "\F5E4\F5E4"; }

.fa-message-middle-top {
  --fa: "\E1E2";
  --fa--fa: "\E1E2\E1E2"; }

.fa-comment-middle-top-alt {
  --fa: "\E1E2";
  --fa--fa: "\E1E2\E1E2"; }

.fa-lightbulb-on {
  --fa: "\F672";
  --fa--fa: "\F672\F672"; }

.fa-knife {
  --fa: "\F2E4";
  --fa--fa: "\F2E4\F2E4"; }

.fa-utensil-knife {
  --fa: "\F2E4";
  --fa--fa: "\F2E4\F2E4"; }

.fa-share-nodes {
  --fa: "\F1E0";
  --fa--fa: "\F1E0\F1E0"; }

.fa-share-alt {
  --fa: "\F1E0";
  --fa--fa: "\F1E0\F1E0"; }

.fa-display-chart-up-circle-dollar {
  --fa: "\E5E6";
  --fa--fa: "\E5E6\E5E6"; }

.fa-wave-sine {
  --fa: "\F899";
  --fa--fa: "\F899\F899"; }

.fa-heart-circle-minus {
  --fa: "\E4FF";
  --fa--fa: "\E4FF\E4FF"; }

.fa-circle-w {
  --fa: "\E12C";
  --fa--fa: "\E12C\E12C"; }

.fa-circle-calendar {
  --fa: "\E102";
  --fa--fa: "\E102\E102"; }

.fa-calendar-circle {
  --fa: "\E102";
  --fa--fa: "\E102\E102"; }

.fa-hourglass-half {
  --fa: "\F252";
  --fa--fa: "\F252\F252"; }

.fa-hourglass-2 {
  --fa: "\F252";
  --fa--fa: "\F252\F252"; }

.fa-microscope {
  --fa: "\F610";
  --fa--fa: "\F610\F610"; }

.fa-sunset {
  --fa: "\F767";
  --fa--fa: "\F767\F767"; }

.fa-sink {
  --fa: "\E06D";
  --fa--fa: "\E06D\E06D"; }

.fa-calendar-exclamation {
  --fa: "\F334";
  --fa--fa: "\F334\F334"; }

.fa-truck-container-empty {
  --fa: "\E2B5";
  --fa--fa: "\E2B5\E2B5"; }

.fa-hand-heart {
  --fa: "\F4BC";
  --fa--fa: "\F4BC\F4BC"; }

.fa-bag-shopping {
  --fa: "\F290";
  --fa--fa: "\F290\F290"; }

.fa-shopping-bag {
  --fa: "\F290";
  --fa--fa: "\F290\F290"; }

.fa-arrow-down-z-a {
  --fa: "\F881";
  --fa--fa: "\F881\F881"; }

.fa-sort-alpha-desc {
  --fa: "\F881";
  --fa--fa: "\F881\F881"; }

.fa-sort-alpha-down-alt {
  --fa: "\F881";
  --fa--fa: "\F881\F881"; }

.fa-mitten {
  --fa: "\F7B5";
  --fa--fa: "\F7B5\F7B5"; }

.fa-reply-clock {
  --fa: "\E239";
  --fa--fa: "\E239\E239"; }

.fa-reply-time {
  --fa: "\E239";
  --fa--fa: "\E239\E239"; }

.fa-person-rays {
  --fa: "\E54D";
  --fa--fa: "\E54D\E54D"; }

.fa-right {
  --fa: "\F356";
  --fa--fa: "\F356\F356"; }

.fa-arrow-alt-right {
  --fa: "\F356";
  --fa--fa: "\F356\F356"; }

.fa-circle-f {
  --fa: "\E10E";
  --fa--fa: "\E10E\E10E"; }

.fa-users {
  --fa: "\F0C0";
  --fa--fa: "\F0C0\F0C0"; }

.fa-face-pleading {
  --fa: "\E386";
  --fa--fa: "\E386\E386"; }

.fa-eye-slash {
  --fa: "\F070";
  --fa--fa: "\F070\F070"; }

.fa-flask-vial {
  --fa: "\E4F3";
  --fa--fa: "\E4F3\E4F3"; }

.fa-police-box {
  --fa: "\E021";
  --fa--fa: "\E021\E021"; }

.fa-cucumber {
  --fa: "\E401";
  --fa--fa: "\E401\E401"; }

.fa-head-side-brain {
  --fa: "\F808";
  --fa--fa: "\F808\F808"; }

.fa-hand {
  --fa: "\F256";
  --fa--fa: "\F256\F256"; }

.fa-hand-paper {
  --fa: "\F256";
  --fa--fa: "\F256\F256"; }

.fa-person-biking-mountain {
  --fa: "\F84B";
  --fa--fa: "\F84B\F84B"; }

.fa-biking-mountain {
  --fa: "\F84B";
  --fa--fa: "\F84B\F84B"; }

.fa-utensils-slash {
  --fa: "\E464";
  --fa--fa: "\E464\E464"; }

.fa-print-magnifying-glass {
  --fa: "\F81A";
  --fa--fa: "\F81A\F81A"; }

.fa-print-search {
  --fa: "\F81A";
  --fa--fa: "\F81A\F81A"; }

.fa-turn-right {
  --fa: "\E639";
  --fa--fa: "\E639\E639"; }

.fa-folder-bookmark {
  --fa: "\E186";
  --fa--fa: "\E186\E186"; }

.fa-arrow-turn-left-down {
  --fa: "\E633";
  --fa--fa: "\E633\E633"; }

.fa-om {
  --fa: "\F679";
  --fa--fa: "\F679\F679"; }

.fa-pi {
  --fa: "\F67E";
  --fa--fa: "\F67E\F67E"; }

.fa-flask-round-potion {
  --fa: "\F6E1";
  --fa--fa: "\F6E1\F6E1"; }

.fa-flask-potion {
  --fa: "\F6E1";
  --fa--fa: "\F6E1\F6E1"; }

.fa-face-shush {
  --fa: "\E38C";
  --fa--fa: "\E38C\E38C"; }

.fa-worm {
  --fa: "\E599";
  --fa--fa: "\E599\E599"; }

.fa-house-circle-xmark {
  --fa: "\E50B";
  --fa--fa: "\E50B\E50B"; }

.fa-plug {
  --fa: "\F1E6";
  --fa--fa: "\F1E6\F1E6"; }

.fa-calendar-circle-exclamation {
  --fa: "\E46E";
  --fa--fa: "\E46E\E46E"; }

.fa-square-i {
  --fa: "\E272";
  --fa--fa: "\E272\E272"; }

.fa-chevron-up {
  --fa: "\F077";
  --fa--fa: "\F077\F077"; }

.fa-face-saluting {
  --fa: "\E484";
  --fa--fa: "\E484\E484"; }

.fa-gauge-simple-low {
  --fa: "\F62C";
  --fa--fa: "\F62C\F62C"; }

.fa-tachometer-slow {
  --fa: "\F62C";
  --fa--fa: "\F62C\F62C"; }

.fa-face-persevering {
  --fa: "\E385";
  --fa--fa: "\E385\E385"; }

.fa-circle-camera {
  --fa: "\E103";
  --fa--fa: "\E103\E103"; }

.fa-camera-circle {
  --fa: "\E103";
  --fa--fa: "\E103\E103"; }

.fa-hand-spock {
  --fa: "\F259";
  --fa--fa: "\F259\F259"; }

.fa-spider-web {
  --fa: "\F719";
  --fa--fa: "\F719\F719"; }

.fa-circle-microphone {
  --fa: "\E116";
  --fa--fa: "\E116\E116"; }

.fa-microphone-circle {
  --fa: "\E116";
  --fa--fa: "\E116\E116"; }

.fa-book-arrow-up {
  --fa: "\E0BA";
  --fa--fa: "\E0BA\E0BA"; }

.fa-popsicle {
  --fa: "\E43E";
  --fa--fa: "\E43E\E43E"; }

.fa-command {
  --fa: "\E142";
  --fa--fa: "\E142\E142"; }

.fa-blinds {
  --fa: "\F8FB";
  --fa--fa: "\F8FB\F8FB"; }

.fa-stopwatch {
  --fa: "\F2F2";
  --fa--fa: "\F2F2\F2F2"; }

.fa-saxophone {
  --fa: "\F8DC";
  --fa--fa: "\F8DC\F8DC"; }

.fa-square-2 {
  --fa: "\E257";
  --fa--fa: "\E257\E257"; }

.fa-field-hockey-stick-ball {
  --fa: "\F44C";
  --fa--fa: "\F44C\F44C"; }

.fa-field-hockey {
  --fa: "\F44C";
  --fa--fa: "\F44C\F44C"; }

.fa-arrow-up-square-triangle {
  --fa: "\F88B";
  --fa--fa: "\F88B\F88B"; }

.fa-sort-shapes-up-alt {
  --fa: "\F88B";
  --fa--fa: "\F88B\F88B"; }

.fa-face-scream {
  --fa: "\E38B";
  --fa--fa: "\E38B\E38B"; }

.fa-square-m {
  --fa: "\E276";
  --fa--fa: "\E276\E276"; }

.fa-camera-web {
  --fa: "\F832";
  --fa--fa: "\F832\F832"; }

.fa-webcam {
  --fa: "\F832";
  --fa--fa: "\F832\F832"; }

.fa-comment-arrow-down {
  --fa: "\E143";
  --fa--fa: "\E143\E143"; }

.fa-lightbulb-cfl {
  --fa: "\E5A6";
  --fa--fa: "\E5A6\E5A6"; }

.fa-window-frame-open {
  --fa: "\E050";
  --fa--fa: "\E050\E050"; }

.fa-face-kiss {
  --fa: "\F596";
  --fa--fa: "\F596\F596"; }

.fa-kiss {
  --fa: "\F596";
  --fa--fa: "\F596\F596"; }

.fa-bridge-circle-xmark {
  --fa: "\E4CB";
  --fa--fa: "\E4CB\E4CB"; }

.fa-period {
  --fa: ".";
  --fa--fa: ".."; }

.fa-face-grin-tongue {
  --fa: "\F589";
  --fa--fa: "\F589\F589"; }

.fa-grin-tongue {
  --fa: "\F589";
  --fa--fa: "\F589\F589"; }

.fa-up-to-dotted-line {
  --fa: "\E457";
  --fa--fa: "\E457\E457"; }

.fa-thought-bubble {
  --fa: "\E32E";
  --fa--fa: "\E32E\E32E"; }

.fa-skeleton-ribs {
  --fa: "\E5CB";
  --fa--fa: "\E5CB\E5CB"; }

.fa-raygun {
  --fa: "\E025";
  --fa--fa: "\E025\E025"; }

.fa-flute {
  --fa: "\F8B9";
  --fa--fa: "\F8B9\F8B9"; }

.fa-acorn {
  --fa: "\F6AE";
  --fa--fa: "\F6AE\F6AE"; }

.fa-video-arrow-up-right {
  --fa: "\E2C9";
  --fa--fa: "\E2C9\E2C9"; }

.fa-grate-droplet {
  --fa: "\E194";
  --fa--fa: "\E194\E194"; }

.fa-seal-exclamation {
  --fa: "\E242";
  --fa--fa: "\E242\E242"; }

.fa-chess-bishop {
  --fa: "\F43A";
  --fa--fa: "\F43A\F43A"; }

.fa-message-sms {
  --fa: "\E1E5";
  --fa--fa: "\E1E5\E1E5"; }

.fa-coffee-beans {
  --fa: "\E13F";
  --fa--fa: "\E13F\E13F"; }

.fa-hat-witch {
  --fa: "\F6E7";
  --fa--fa: "\F6E7\F6E7"; }

.fa-face-grin-wink {
  --fa: "\F58C";
  --fa--fa: "\F58C\F58C"; }

.fa-grin-wink {
  --fa: "\F58C";
  --fa--fa: "\F58C\F58C"; }

.fa-clock-three-thirty {
  --fa: "\E357";
  --fa--fa: "\E357\E357"; }

.fa-ear-deaf {
  --fa: "\F2A4";
  --fa--fa: "\F2A4\F2A4"; }

.fa-deaf {
  --fa: "\F2A4";
  --fa--fa: "\F2A4\F2A4"; }

.fa-deafness {
  --fa: "\F2A4";
  --fa--fa: "\F2A4\F2A4"; }

.fa-hard-of-hearing {
  --fa: "\F2A4";
  --fa--fa: "\F2A4\F2A4"; }

.fa-alarm-clock {
  --fa: "\F34E";
  --fa--fa: "\F34E\F34E"; }

.fa-eclipse {
  --fa: "\F749";
  --fa--fa: "\F749\F749"; }

.fa-face-relieved {
  --fa: "\E389";
  --fa--fa: "\E389\E389"; }

.fa-road-circle-check {
  --fa: "\E564";
  --fa--fa: "\E564\E564"; }

.fa-dice-five {
  --fa: "\F523";
  --fa--fa: "\F523\F523"; }

.fa-octagon-minus {
  --fa: "\F308";
  --fa--fa: "\F308\F308"; }

.fa-minus-octagon {
  --fa: "\F308";
  --fa--fa: "\F308\F308"; }

.fa-square-rss {
  --fa: "\F143";
  --fa--fa: "\F143\F143"; }

.fa-rss-square {
  --fa: "\F143";
  --fa--fa: "\F143\F143"; }

.fa-face-zany {
  --fa: "\E3A4";
  --fa--fa: "\E3A4\E3A4"; }

.fa-tricycle {
  --fa: "\E5C3";
  --fa--fa: "\E5C3\E5C3"; }

.fa-land-mine-on {
  --fa: "\E51B";
  --fa--fa: "\E51B\E51B"; }

.fa-square-arrow-up-left {
  --fa: "\E263";
  --fa--fa: "\E263\E263"; }

.fa-i-cursor {
  --fa: "\F246";
  --fa--fa: "\F246\F246"; }

.fa-chart-mixed-up-circle-dollar {
  --fa: "\E5D9";
  --fa--fa: "\E5D9\E5D9"; }

.fa-salt-shaker {
  --fa: "\E446";
  --fa--fa: "\E446\E446"; }

.fa-stamp {
  --fa: "\F5BF";
  --fa--fa: "\F5BF\F5BF"; }

.fa-file-plus {
  --fa: "\F319";
  --fa--fa: "\F319\F319"; }

.fa-draw-square {
  --fa: "\F5EF";
  --fa--fa: "\F5EF\F5EF"; }

.fa-toilet-paper-under-slash {
  --fa: "\E2A1";
  --fa--fa: "\E2A1\E2A1"; }

.fa-toilet-paper-reverse-slash {
  --fa: "\E2A1";
  --fa--fa: "\E2A1\E2A1"; }

.fa-stairs {
  --fa: "\E289";
  --fa--fa: "\E289\E289"; }

.fa-drone-front {
  --fa: "\F860";
  --fa--fa: "\F860\F860"; }

.fa-drone-alt {
  --fa: "\F860";
  --fa--fa: "\F860\F860"; }

.fa-glass-empty {
  --fa: "\E191";
  --fa--fa: "\E191\E191"; }

.fa-dial-high {
  --fa: "\E15C";
  --fa--fa: "\E15C\E15C"; }

.fa-user-helmet-safety {
  --fa: "\F82C";
  --fa--fa: "\F82C\F82C"; }

.fa-user-construction {
  --fa: "\F82C";
  --fa--fa: "\F82C\F82C"; }

.fa-user-hard-hat {
  --fa: "\F82C";
  --fa--fa: "\F82C\F82C"; }

.fa-i {
  --fa: "I";
  --fa--fa: "II"; }

.fa-hryvnia-sign {
  --fa: "\F6F2";
  --fa--fa: "\F6F2\F6F2"; }

.fa-hryvnia {
  --fa: "\F6F2";
  --fa--fa: "\F6F2\F6F2"; }

.fa-arrow-down-left-and-arrow-up-right-to-center {
  --fa: "\E092";
  --fa--fa: "\E092\E092"; }

.fa-pills {
  --fa: "\F484";
  --fa--fa: "\F484\F484"; }

.fa-face-grin-wide {
  --fa: "\F581";
  --fa--fa: "\F581\F581"; }

.fa-grin-alt {
  --fa: "\F581";
  --fa--fa: "\F581\F581"; }

.fa-tooth {
  --fa: "\F5C9";
  --fa--fa: "\F5C9\F5C9"; }

.fa-basketball-hoop {
  --fa: "\F435";
  --fa--fa: "\F435\F435"; }

.fa-objects-align-bottom {
  --fa: "\E3BB";
  --fa--fa: "\E3BB\E3BB"; }

.fa-v {
  --fa: "V";
  --fa--fa: "VV"; }

.fa-sparkles {
  --fa: "\F890";
  --fa--fa: "\F890\F890"; }

.fa-squid {
  --fa: "\E450";
  --fa--fa: "\E450\E450"; }

.fa-leafy-green {
  --fa: "\E41D";
  --fa--fa: "\E41D\E41D"; }

.fa-circle-arrow-up-right {
  --fa: "\E0FC";
  --fa--fa: "\E0FC\E0FC"; }

.fa-calendars {
  --fa: "\E0D7";
  --fa--fa: "\E0D7\E0D7"; }

.fa-bangladeshi-taka-sign {
  --fa: "\E2E6";
  --fa--fa: "\E2E6\E2E6"; }

.fa-bicycle {
  --fa: "\F206";
  --fa--fa: "\F206\F206"; }

.fa-hammer-war {
  --fa: "\F6E4";
  --fa--fa: "\F6E4\F6E4"; }

.fa-circle-d {
  --fa: "\E104";
  --fa--fa: "\E104\E104"; }

.fa-spider-black-widow {
  --fa: "\F718";
  --fa--fa: "\F718\F718"; }

.fa-staff-snake {
  --fa: "\E579";
  --fa--fa: "\E579\E579"; }

.fa-rod-asclepius {
  --fa: "\E579";
  --fa--fa: "\E579\E579"; }

.fa-rod-snake {
  --fa: "\E579";
  --fa--fa: "\E579\E579"; }

.fa-staff-aesculapius {
  --fa: "\E579";
  --fa--fa: "\E579\E579"; }

.fa-pear {
  --fa: "\E20C";
  --fa--fa: "\E20C\E20C"; }

.fa-head-side-cough-slash {
  --fa: "\E062";
  --fa--fa: "\E062\E062"; }

.fa-file-mov {
  --fa: "\E647";
  --fa--fa: "\E647\E647"; }

.fa-triangle {
  --fa: "\F2EC";
  --fa--fa: "\F2EC\F2EC"; }

.fa-apartment {
  --fa: "\E468";
  --fa--fa: "\E468\E468"; }

.fa-truck-medical {
  --fa: "\F0F9";
  --fa--fa: "\F0F9\F0F9"; }

.fa-ambulance {
  --fa: "\F0F9";
  --fa--fa: "\F0F9\F0F9"; }

.fa-pepper {
  --fa: "\E432";
  --fa--fa: "\E432\E432"; }

.fa-piano {
  --fa: "\F8D4";
  --fa--fa: "\F8D4\F8D4"; }

.fa-gun-squirt {
  --fa: "\E19D";
  --fa--fa: "\E19D\E19D"; }

.fa-wheat-awn-circle-exclamation {
  --fa: "\E598";
  --fa--fa: "\E598\E598"; }

.fa-snowman {
  --fa: "\F7D0";
  --fa--fa: "\F7D0\F7D0"; }

.fa-user-alien {
  --fa: "\E04A";
  --fa--fa: "\E04A\E04A"; }

.fa-shield-check {
  --fa: "\F2F7";
  --fa--fa: "\F2F7\F2F7"; }

.fa-mortar-pestle {
  --fa: "\F5A7";
  --fa--fa: "\F5A7\F5A7"; }

.fa-road-barrier {
  --fa: "\E562";
  --fa--fa: "\E562\E562"; }

.fa-chart-candlestick {
  --fa: "\E0E2";
  --fa--fa: "\E0E2\E0E2"; }

.fa-briefcase-blank {
  --fa: "\E0C8";
  --fa--fa: "\E0C8\E0C8"; }

.fa-school {
  --fa: "\F549";
  --fa--fa: "\F549\F549"; }

.fa-igloo {
  --fa: "\F7AE";
  --fa--fa: "\F7AE\F7AE"; }

.fa-bracket-round {
  --fa: "(";
  --fa--fa: "(("; }

.fa-parenthesis {
  --fa: "(";
  --fa--fa: "(("; }

.fa-joint {
  --fa: "\F595";
  --fa--fa: "\F595\F595"; }

.fa-horse-saddle {
  --fa: "\F8C3";
  --fa--fa: "\F8C3\F8C3"; }

.fa-mug-marshmallows {
  --fa: "\F7B7";
  --fa--fa: "\F7B7\F7B7"; }

.fa-filters {
  --fa: "\E17E";
  --fa--fa: "\E17E\E17E"; }

.fa-bell-on {
  --fa: "\F8FA";
  --fa--fa: "\F8FA\F8FA"; }

.fa-angle-right {
  --fa: "\F105";
  --fa--fa: "\F105\F105"; }

.fa-dial-med {
  --fa: "\E15F";
  --fa--fa: "\E15F\E15F"; }

.fa-horse {
  --fa: "\F6F0";
  --fa--fa: "\F6F0\F6F0"; }

.fa-q {
  --fa: "Q";
  --fa--fa: "QQ"; }

.fa-monitor-waveform {
  --fa: "\F611";
  --fa--fa: "\F611\F611"; }

.fa-monitor-heart-rate {
  --fa: "\F611";
  --fa--fa: "\F611\F611"; }

.fa-link-simple {
  --fa: "\E1CD";
  --fa--fa: "\E1CD\E1CD"; }

.fa-whistle {
  --fa: "\F460";
  --fa--fa: "\F460\F460"; }

.fa-g {
  --fa: "G";
  --fa--fa: "GG"; }

.fa-wine-glass-crack {
  --fa: "\F4BB";
  --fa--fa: "\F4BB\F4BB"; }

.fa-fragile {
  --fa: "\F4BB";
  --fa--fa: "\F4BB\F4BB"; }

.fa-slot-machine {
  --fa: "\E3CE";
  --fa--fa: "\E3CE\E3CE"; }

.fa-notes-medical {
  --fa: "\F481";
  --fa--fa: "\F481\F481"; }

.fa-car-wash {
  --fa: "\F5E6";
  --fa--fa: "\F5E6\F5E6"; }

.fa-escalator {
  --fa: "\E171";
  --fa--fa: "\E171\E171"; }

.fa-comment-image {
  --fa: "\E148";
  --fa--fa: "\E148\E148"; }

.fa-temperature-half {
  --fa: "\F2C9";
  --fa--fa: "\F2C9\F2C9"; }

.fa-temperature-2 {
  --fa: "\F2C9";
  --fa--fa: "\F2C9\F2C9"; }

.fa-thermometer-2 {
  --fa: "\F2C9";
  --fa--fa: "\F2C9\F2C9"; }

.fa-thermometer-half {
  --fa: "\F2C9";
  --fa--fa: "\F2C9\F2C9"; }

.fa-dong-sign {
  --fa: "\E169";
  --fa--fa: "\E169\E169"; }

.fa-donut {
  --fa: "\E406";
  --fa--fa: "\E406\E406"; }

.fa-doughnut {
  --fa: "\E406";
  --fa--fa: "\E406\E406"; }

.fa-capsules {
  --fa: "\F46B";
  --fa--fa: "\F46B\F46B"; }

.fa-poo-storm {
  --fa: "\F75A";
  --fa--fa: "\F75A\F75A"; }

.fa-poo-bolt {
  --fa: "\F75A";
  --fa--fa: "\F75A\F75A"; }

.fa-tally-1 {
  --fa: "\E294";
  --fa--fa: "\E294\E294"; }

.fa-file-vector {
  --fa: "\E64C";
  --fa--fa: "\E64C\E64C"; }

.fa-face-frown-open {
  --fa: "\F57A";
  --fa--fa: "\F57A\F57A"; }

.fa-frown-open {
  --fa: "\F57A";
  --fa--fa: "\F57A\F57A"; }

.fa-square-dashed {
  --fa: "\E269";
  --fa--fa: "\E269\E269"; }

.fa-bag-shopping-plus {
  --fa: "\E651";
  --fa--fa: "\E651\E651"; }

.fa-square-j {
  --fa: "\E273";
  --fa--fa: "\E273\E273"; }

.fa-hand-point-up {
  --fa: "\F0A6";
  --fa--fa: "\F0A6\F0A6"; }

.fa-money-bill {
  --fa: "\F0D6";
  --fa--fa: "\F0D6\F0D6"; }

.fa-arrow-up-big-small {
  --fa: "\F88E";
  --fa--fa: "\F88E\F88E"; }

.fa-sort-size-up {
  --fa: "\F88E";
  --fa--fa: "\F88E\F88E"; }

.fa-barcode-read {
  --fa: "\F464";
  --fa--fa: "\F464\F464"; }

.fa-baguette {
  --fa: "\E3D8";
  --fa--fa: "\E3D8\E3D8"; }

.fa-bowl-soft-serve {
  --fa: "\E46B";
  --fa--fa: "\E46B\E46B"; }

.fa-face-holding-back-tears {
  --fa: "\E482";
  --fa--fa: "\E482\E482"; }

.fa-square-up {
  --fa: "\F353";
  --fa--fa: "\F353\F353"; }

.fa-arrow-alt-square-up {
  --fa: "\F353";
  --fa--fa: "\F353\F353"; }

.fa-train-subway-tunnel {
  --fa: "\E2A3";
  --fa--fa: "\E2A3\E2A3"; }

.fa-subway-tunnel {
  --fa: "\E2A3";
  --fa--fa: "\E2A3\E2A3"; }

.fa-square-exclamation {
  --fa: "\F321";
  --fa--fa: "\F321\F321"; }

.fa-exclamation-square {
  --fa: "\F321";
  --fa--fa: "\F321\F321"; }

.fa-semicolon {
  --fa: ";";
  --fa--fa: ";;"; }

.fa-bookmark {
  --fa: "\F02E";
  --fa--fa: "\F02E\F02E"; }

.fa-fan-table {
  --fa: "\E004";
  --fa--fa: "\E004\E004"; }

.fa-align-justify {
  --fa: "\F039";
  --fa--fa: "\F039\F039"; }

.fa-battery-low {
  --fa: "\E0B1";
  --fa--fa: "\E0B1\E0B1"; }

.fa-battery-1 {
  --fa: "\E0B1";
  --fa--fa: "\E0B1\E0B1"; }

.fa-credit-card-front {
  --fa: "\F38A";
  --fa--fa: "\F38A\F38A"; }

.fa-brain-arrow-curved-right {
  --fa: "\F677";
  --fa--fa: "\F677\F677"; }

.fa-mind-share {
  --fa: "\F677";
  --fa--fa: "\F677\F677"; }

.fa-umbrella-beach {
  --fa: "\F5CA";
  --fa--fa: "\F5CA\F5CA"; }

.fa-helmet-un {
  --fa: "\E503";
  --fa--fa: "\E503\E503"; }

.fa-location-smile {
  --fa: "\F60D";
  --fa--fa: "\F60D\F60D"; }

.fa-map-marker-smile {
  --fa: "\F60D";
  --fa--fa: "\F60D\F60D"; }

.fa-arrow-left-to-line {
  --fa: "\F33E";
  --fa--fa: "\F33E\F33E"; }

.fa-arrow-to-left {
  --fa: "\F33E";
  --fa--fa: "\F33E\F33E"; }

.fa-bullseye {
  --fa: "\F140";
  --fa--fa: "\F140\F140"; }

.fa-sushi {
  --fa: "\E48A";
  --fa--fa: "\E48A\E48A"; }

.fa-nigiri {
  --fa: "\E48A";
  --fa--fa: "\E48A\E48A"; }

.fa-message-captions {
  --fa: "\E1DE";
  --fa--fa: "\E1DE\E1DE"; }

.fa-comment-alt-captions {
  --fa: "\E1DE";
  --fa--fa: "\E1DE\E1DE"; }

.fa-trash-list {
  --fa: "\E2B1";
  --fa--fa: "\E2B1\E2B1"; }

.fa-bacon {
  --fa: "\F7E5";
  --fa--fa: "\F7E5\F7E5"; }

.fa-option {
  --fa: "\E318";
  --fa--fa: "\E318\E318"; }

.fa-raccoon {
  --fa: "\E613";
  --fa--fa: "\E613\E613"; }

.fa-hand-point-down {
  --fa: "\F0A7";
  --fa--fa: "\F0A7\F0A7"; }

.fa-arrow-up-from-bracket {
  --fa: "\E09A";
  --fa--fa: "\E09A\E09A"; }

.fa-head-side-gear {
  --fa: "\E611";
  --fa--fa: "\E611\E611"; }

.fa-trash-plus {
  --fa: "\E2B2";
  --fa--fa: "\E2B2\E2B2"; }

.fa-file-cad {
  --fa: "\E672";
  --fa--fa: "\E672\E672"; }

.fa-objects-align-top {
  --fa: "\E3C0";
  --fa--fa: "\E3C0\E3C0"; }

.fa-folder {
  --fa: "\F07B";
  --fa--fa: "\F07B\F07B"; }

.fa-folder-blank {
  --fa: "\F07B";
  --fa--fa: "\F07B\F07B"; }

.fa-face-anxious-sweat {
  --fa: "\E36A";
  --fa--fa: "\E36A\E36A"; }

.fa-credit-card-blank {
  --fa: "\F389";
  --fa--fa: "\F389\F389"; }

.fa-file-waveform {
  --fa: "\F478";
  --fa--fa: "\F478\F478"; }

.fa-file-medical-alt {
  --fa: "\F478";
  --fa--fa: "\F478\F478"; }

.fa-microchip-ai {
  --fa: "\E1EC";
  --fa--fa: "\E1EC\E1EC"; }

.fa-mug {
  --fa: "\F874";
  --fa--fa: "\F874\F874"; }

.fa-plane-up-slash {
  --fa: "\E22E";
  --fa--fa: "\E22E\E22E"; }

.fa-radiation {
  --fa: "\F7B9";
  --fa--fa: "\F7B9\F7B9"; }

.fa-pen-circle {
  --fa: "\E20E";
  --fa--fa: "\E20E\E20E"; }

.fa-bag-seedling {
  --fa: "\E5F2";
  --fa--fa: "\E5F2\E5F2"; }

.fa-chart-simple {
  --fa: "\E473";
  --fa--fa: "\E473\E473"; }

.fa-crutches {
  --fa: "\F7F8";
  --fa--fa: "\F7F8\F7F8"; }

.fa-circle-parking {
  --fa: "\F615";
  --fa--fa: "\F615\F615"; }

.fa-parking-circle {
  --fa: "\F615";
  --fa--fa: "\F615\F615"; }

.fa-mars-stroke {
  --fa: "\F229";
  --fa--fa: "\F229\F229"; }

.fa-leaf-oak {
  --fa: "\F6F7";
  --fa--fa: "\F6F7\F6F7"; }

.fa-square-bolt {
  --fa: "\E265";
  --fa--fa: "\E265\E265"; }

.fa-vial {
  --fa: "\F492";
  --fa--fa: "\F492\F492"; }

.fa-gauge {
  --fa: "\F624";
  --fa--fa: "\F624\F624"; }

.fa-dashboard {
  --fa: "\F624";
  --fa--fa: "\F624\F624"; }

.fa-gauge-med {
  --fa: "\F624";
  --fa--fa: "\F624\F624"; }

.fa-tachometer-alt-average {
  --fa: "\F624";
  --fa--fa: "\F624\F624"; }

.fa-wand-magic-sparkles {
  --fa: "\E2CA";
  --fa--fa: "\E2CA\E2CA"; }

.fa-magic-wand-sparkles {
  --fa: "\E2CA";
  --fa--fa: "\E2CA\E2CA"; }

.fa-lambda {
  --fa: "\F66E";
  --fa--fa: "\F66E\F66E"; }

.fa-e {
  --fa: "E";
  --fa--fa: "EE"; }

.fa-pizza {
  --fa: "\F817";
  --fa--fa: "\F817\F817"; }

.fa-bowl-chopsticks-noodles {
  --fa: "\E2EA";
  --fa--fa: "\E2EA\E2EA"; }

.fa-h3 {
  --fa: "\F315";
  --fa--fa: "\F315\F315"; }

.fa-pen-clip {
  --fa: "\F305";
  --fa--fa: "\F305\F305"; }

.fa-pen-alt {
  --fa: "\F305";
  --fa--fa: "\F305\F305"; }

.fa-bridge-circle-exclamation {
  --fa: "\E4CA";
  --fa--fa: "\E4CA\E4CA"; }

.fa-badge-percent {
  --fa: "\F646";
  --fa--fa: "\F646\F646"; }

.fa-rotate-reverse {
  --fa: "\E631";
  --fa--fa: "\E631\E631"; }

.fa-user {
  --fa: "\F007";
  --fa--fa: "\F007\F007"; }

.fa-sensor {
  --fa: "\E028";
  --fa--fa: "\E028\E028"; }

.fa-comma {
  --fa: ",";
  --fa--fa: ",,"; }

.fa-school-circle-check {
  --fa: "\E56B";
  --fa--fa: "\E56B\E56B"; }

.fa-toilet-paper-under {
  --fa: "\E2A0";
  --fa--fa: "\E2A0\E2A0"; }

.fa-toilet-paper-reverse {
  --fa: "\E2A0";
  --fa--fa: "\E2A0\E2A0"; }

.fa-light-emergency {
  --fa: "\E41F";
  --fa--fa: "\E41F\E41F"; }

.fa-arrow-down-to-arc {
  --fa: "\E4AE";
  --fa--fa: "\E4AE\E4AE"; }

.fa-dumpster {
  --fa: "\F793";
  --fa--fa: "\F793\F793"; }

.fa-van-shuttle {
  --fa: "\F5B6";
  --fa--fa: "\F5B6\F5B6"; }

.fa-shuttle-van {
  --fa: "\F5B6";
  --fa--fa: "\F5B6\F5B6"; }

.fa-building-user {
  --fa: "\E4DA";
  --fa--fa: "\E4DA\E4DA"; }

.fa-light-switch {
  --fa: "\E017";
  --fa--fa: "\E017\E017"; }

.fa-square-caret-left {
  --fa: "\F191";
  --fa--fa: "\F191\F191"; }

.fa-caret-square-left {
  --fa: "\F191";
  --fa--fa: "\F191\F191"; }

.fa-highlighter {
  --fa: "\F591";
  --fa--fa: "\F591\F591"; }

.fa-wave-pulse {
  --fa: "\F5F8";
  --fa--fa: "\F5F8\F5F8"; }

.fa-heart-rate {
  --fa: "\F5F8";
  --fa--fa: "\F5F8\F5F8"; }

.fa-key {
  --fa: "\F084";
  --fa--fa: "\F084\F084"; }

.fa-arrow-left-to-bracket {
  --fa: "\E669";
  --fa--fa: "\E669\E669"; }

.fa-hat-santa {
  --fa: "\F7A7";
  --fa--fa: "\F7A7\F7A7"; }

.fa-tamale {
  --fa: "\E451";
  --fa--fa: "\E451\E451"; }

.fa-box-check {
  --fa: "\F467";
  --fa--fa: "\F467\F467"; }

.fa-bullhorn {
  --fa: "\F0A1";
  --fa--fa: "\F0A1\F0A1"; }

.fa-steak {
  --fa: "\F824";
  --fa--fa: "\F824\F824"; }

.fa-location-crosshairs-slash {
  --fa: "\F603";
  --fa--fa: "\F603\F603"; }

.fa-location-slash {
  --fa: "\F603";
  --fa--fa: "\F603\F603"; }

.fa-person-dolly {
  --fa: "\F4D0";
  --fa--fa: "\F4D0\F4D0"; }

.fa-globe {
  --fa: "\F0AC";
  --fa--fa: "\F0AC\F0AC"; }

.fa-synagogue {
  --fa: "\F69B";
  --fa--fa: "\F69B\F69B"; }

.fa-file-chart-column {
  --fa: "\F659";
  --fa--fa: "\F659\F659"; }

.fa-file-chart-line {
  --fa: "\F659";
  --fa--fa: "\F659\F659"; }

.fa-person-half-dress {
  --fa: "\E548";
  --fa--fa: "\E548\E548"; }

.fa-folder-image {
  --fa: "\E18A";
  --fa--fa: "\E18A\E18A"; }

.fa-calendar-pen {
  --fa: "\F333";
  --fa--fa: "\F333\F333"; }

.fa-calendar-edit {
  --fa: "\F333";
  --fa--fa: "\F333\F333"; }

.fa-road-bridge {
  --fa: "\E563";
  --fa--fa: "\E563\E563"; }

.fa-face-smile-tear {
  --fa: "\E393";
  --fa--fa: "\E393\E393"; }

.fa-message-plus {
  --fa: "\F4A8";
  --fa--fa: "\F4A8\F4A8"; }

.fa-comment-alt-plus {
  --fa: "\F4A8";
  --fa--fa: "\F4A8\F4A8"; }

.fa-location-arrow {
  --fa: "\F124";
  --fa--fa: "\F124\F124"; }

.fa-c {
  --fa: "C";
  --fa--fa: "CC"; }

.fa-tablet-button {
  --fa: "\F10A";
  --fa--fa: "\F10A\F10A"; }

.fa-person-dress-fairy {
  --fa: "\E607";
  --fa--fa: "\E607\E607"; }

.fa-rectangle-history-circle-user {
  --fa: "\E4A4";
  --fa--fa: "\E4A4\E4A4"; }

.fa-building-lock {
  --fa: "\E4D6";
  --fa--fa: "\E4D6\E4D6"; }

.fa-chart-line-up {
  --fa: "\E0E5";
  --fa--fa: "\E0E5\E0E5"; }

.fa-mailbox {
  --fa: "\F813";
  --fa--fa: "\F813\F813"; }

.fa-sign-posts {
  --fa: "\E625";
  --fa--fa: "\E625\E625"; }

.fa-truck-bolt {
  --fa: "\E3D0";
  --fa--fa: "\E3D0\E3D0"; }

.fa-pizza-slice {
  --fa: "\F818";
  --fa--fa: "\F818\F818"; }

.fa-money-bill-wave {
  --fa: "\F53A";
  --fa--fa: "\F53A\F53A"; }

.fa-chart-area {
  --fa: "\F1FE";
  --fa--fa: "\F1FE\F1FE"; }

.fa-area-chart {
  --fa: "\F1FE";
  --fa--fa: "\F1FE\F1FE"; }

.fa-house-flag {
  --fa: "\E50D";
  --fa--fa: "\E50D\E50D"; }

.fa-circle-three-quarters-stroke {
  --fa: "\E5D4";
  --fa--fa: "\E5D4\E5D4"; }

.fa-person-circle-minus {
  --fa: "\E540";
  --fa--fa: "\E540\E540"; }

.fa-scalpel {
  --fa: "\F61D";
  --fa--fa: "\F61D\F61D"; }

.fa-ban {
  --fa: "\F05E";
  --fa--fa: "\F05E\F05E"; }

.fa-cancel {
  --fa: "\F05E";
  --fa--fa: "\F05E\F05E"; }

.fa-bell-exclamation {
  --fa: "\F848";
  --fa--fa: "\F848\F848"; }

.fa-circle-bookmark {
  --fa: "\E100";
  --fa--fa: "\E100\E100"; }

.fa-bookmark-circle {
  --fa: "\E100";
  --fa--fa: "\E100\E100"; }

.fa-egg-fried {
  --fa: "\F7FC";
  --fa--fa: "\F7FC\F7FC"; }

.fa-face-weary {
  --fa: "\E3A1";
  --fa--fa: "\E3A1\E3A1"; }

.fa-uniform-martial-arts {
  --fa: "\E3D1";
  --fa--fa: "\E3D1\E3D1"; }

.fa-camera-rotate {
  --fa: "\E0D8";
  --fa--fa: "\E0D8\E0D8"; }

.fa-sun-dust {
  --fa: "\F764";
  --fa--fa: "\F764\F764"; }

.fa-comment-text {
  --fa: "\E14D";
  --fa--fa: "\E14D\E14D"; }

.fa-spray-can-sparkles {
  --fa: "\F5D0";
  --fa--fa: "\F5D0\F5D0"; }

.fa-air-freshener {
  --fa: "\F5D0";
  --fa--fa: "\F5D0\F5D0"; }

.fa-signal-bars {
  --fa: "\F690";
  --fa--fa: "\F690\F690"; }

.fa-signal-alt {
  --fa: "\F690";
  --fa--fa: "\F690\F690"; }

.fa-signal-alt-4 {
  --fa: "\F690";
  --fa--fa: "\F690\F690"; }

.fa-signal-bars-strong {
  --fa: "\F690";
  --fa--fa: "\F690\F690"; }

.fa-diamond-exclamation {
  --fa: "\E405";
  --fa--fa: "\E405\E405"; }

.fa-star {
  --fa: "\F005";
  --fa--fa: "\F005\F005"; }

.fa-dial-min {
  --fa: "\E161";
  --fa--fa: "\E161\E161"; }

.fa-repeat {
  --fa: "\F363";
  --fa--fa: "\F363\F363"; }

.fa-cross {
  --fa: "\F654";
  --fa--fa: "\F654\F654"; }

.fa-page-caret-down {
  --fa: "\E429";
  --fa--fa: "\E429\E429"; }

.fa-file-caret-down {
  --fa: "\E429";
  --fa--fa: "\E429\E429"; }

.fa-box {
  --fa: "\F466";
  --fa--fa: "\F466\F466"; }

.fa-venus-mars {
  --fa: "\F228";
  --fa--fa: "\F228\F228"; }

.fa-clock-seven-thirty {
  --fa: "\E351";
  --fa--fa: "\E351\E351"; }

.fa-arrow-pointer {
  --fa: "\F245";
  --fa--fa: "\F245\F245"; }

.fa-mouse-pointer {
  --fa: "\F245";
  --fa--fa: "\F245\F245"; }

.fa-clock-four-thirty {
  --fa: "\E34B";
  --fa--fa: "\E34B\E34B"; }

.fa-signal-bars-good {
  --fa: "\F693";
  --fa--fa: "\F693\F693"; }

.fa-signal-alt-3 {
  --fa: "\F693";
  --fa--fa: "\F693\F693"; }

.fa-cactus {
  --fa: "\F8A7";
  --fa--fa: "\F8A7\F8A7"; }

.fa-lightbulb-gear {
  --fa: "\E5FD";
  --fa--fa: "\E5FD\E5FD"; }

.fa-maximize {
  --fa: "\F31E";
  --fa--fa: "\F31E\F31E"; }

.fa-expand-arrows-alt {
  --fa: "\F31E";
  --fa--fa: "\F31E\F31E"; }

.fa-charging-station {
  --fa: "\F5E7";
  --fa--fa: "\F5E7\F5E7"; }

.fa-shapes {
  --fa: "\F61F";
  --fa--fa: "\F61F\F61F"; }

.fa-triangle-circle-square {
  --fa: "\F61F";
  --fa--fa: "\F61F\F61F"; }

.fa-plane-tail {
  --fa: "\E22C";
  --fa--fa: "\E22C\E22C"; }

.fa-gauge-simple-max {
  --fa: "\F62B";
  --fa--fa: "\F62B\F62B"; }

.fa-tachometer-fastest {
  --fa: "\F62B";
  --fa--fa: "\F62B\F62B"; }

.fa-circle-u {
  --fa: "\E127";
  --fa--fa: "\E127\E127"; }

.fa-shield-slash {
  --fa: "\E24B";
  --fa--fa: "\E24B\E24B"; }

.fa-square-phone-hangup {
  --fa: "\E27A";
  --fa--fa: "\E27A\E27A"; }

.fa-phone-square-down {
  --fa: "\E27A";
  --fa--fa: "\E27A\E27A"; }

.fa-arrow-up-left {
  --fa: "\E09D";
  --fa--fa: "\E09D\E09D"; }

.fa-transporter-1 {
  --fa: "\E043";
  --fa--fa: "\E043\E043"; }

.fa-peanuts {
  --fa: "\E431";
  --fa--fa: "\E431\E431"; }

.fa-shuffle {
  --fa: "\F074";
  --fa--fa: "\F074\F074"; }

.fa-random {
  --fa: "\F074";
  --fa--fa: "\F074\F074"; }

.fa-person-running {
  --fa: "\F70C";
  --fa--fa: "\F70C\F70C"; }

.fa-running {
  --fa: "\F70C";
  --fa--fa: "\F70C\F70C"; }

.fa-mobile-retro {
  --fa: "\E527";
  --fa--fa: "\E527\E527"; }

.fa-grip-lines-vertical {
  --fa: "\F7A5";
  --fa--fa: "\F7A5\F7A5"; }

.fa-bin-bottles-recycle {
  --fa: "\E5F6";
  --fa--fa: "\E5F6\E5F6"; }

.fa-arrow-up-from-square {
  --fa: "\E09C";
  --fa--fa: "\E09C\E09C"; }

.fa-file-dashed-line {
  --fa: "\F877";
  --fa--fa: "\F877\F877"; }

.fa-page-break {
  --fa: "\F877";
  --fa--fa: "\F877\F877"; }

.fa-bracket-curly-right {
  --fa: "}";
  --fa--fa: "}}"; }

.fa-spider {
  --fa: "\F717";
  --fa--fa: "\F717\F717"; }

.fa-clock-three {
  --fa: "\E356";
  --fa--fa: "\E356\E356"; }

.fa-hands-bound {
  --fa: "\E4F9";
  --fa--fa: "\E4F9\E4F9"; }

.fa-scalpel-line-dashed {
  --fa: "\F61E";
  --fa--fa: "\F61E\F61E"; }

.fa-scalpel-path {
  --fa: "\F61E";
  --fa--fa: "\F61E\F61E"; }

.fa-file-invoice-dollar {
  --fa: "\F571";
  --fa--fa: "\F571\F571"; }

.fa-pipe-smoking {
  --fa: "\E3C4";
  --fa--fa: "\E3C4\E3C4"; }

.fa-face-astonished {
  --fa: "\E36B";
  --fa--fa: "\E36B\E36B"; }

.fa-window {
  --fa: "\F40E";
  --fa--fa: "\F40E\F40E"; }

.fa-plane-circle-exclamation {
  --fa: "\E556";
  --fa--fa: "\E556\E556"; }

.fa-ear {
  --fa: "\F5F0";
  --fa--fa: "\F5F0\F5F0"; }

.fa-file-lock {
  --fa: "\E3A6";
  --fa--fa: "\E3A6\E3A6"; }

.fa-diagram-venn {
  --fa: "\E15A";
  --fa--fa: "\E15A\E15A"; }

.fa-arrow-down-from-bracket {
  --fa: "\E667";
  --fa--fa: "\E667\E667"; }

.fa-x-ray {
  --fa: "\F497";
  --fa--fa: "\F497\F497"; }

.fa-goal-net {
  --fa: "\E3AB";
  --fa--fa: "\E3AB\E3AB"; }

.fa-coffin-cross {
  --fa: "\E051";
  --fa--fa: "\E051\E051"; }

.fa-octopus {
  --fa: "\E688";
  --fa--fa: "\E688\E688"; }

.fa-spell-check {
  --fa: "\F891";
  --fa--fa: "\F891\F891"; }

.fa-location-xmark {
  --fa: "\F60E";
  --fa--fa: "\F60E\F60E"; }

.fa-map-marker-times {
  --fa: "\F60E";
  --fa--fa: "\F60E\F60E"; }

.fa-map-marker-xmark {
  --fa: "\F60E";
  --fa--fa: "\F60E\F60E"; }

.fa-circle-quarter-stroke {
  --fa: "\E5D3";
  --fa--fa: "\E5D3\E5D3"; }

.fa-lasso {
  --fa: "\F8C8";
  --fa--fa: "\F8C8\F8C8"; }

.fa-slash {
  --fa: "\F715";
  --fa--fa: "\F715\F715"; }

.fa-person-to-portal {
  --fa: "\E022";
  --fa--fa: "\E022\E022"; }

.fa-portal-enter {
  --fa: "\E022";
  --fa--fa: "\E022\E022"; }

.fa-calendar-star {
  --fa: "\F736";
  --fa--fa: "\F736\F736"; }

.fa-computer-mouse {
  --fa: "\F8CC";
  --fa--fa: "\F8CC\F8CC"; }

.fa-mouse {
  --fa: "\F8CC";
  --fa--fa: "\F8CC\F8CC"; }

.fa-arrow-right-to-bracket {
  --fa: "\F090";
  --fa--fa: "\F090\F090"; }

.fa-sign-in {
  --fa: "\F090";
  --fa--fa: "\F090\F090"; }

.fa-pegasus {
  --fa: "\F703";
  --fa--fa: "\F703\F703"; }

.fa-files-medical {
  --fa: "\F7FD";
  --fa--fa: "\F7FD\F7FD"; }

.fa-cannon {
  --fa: "\E642";
  --fa--fa: "\E642\E642"; }

.fa-nfc-lock {
  --fa: "\E1F8";
  --fa--fa: "\E1F8\E1F8"; }

.fa-person-ski-lift {
  --fa: "\F7C8";
  --fa--fa: "\F7C8\F7C8"; }

.fa-ski-lift {
  --fa: "\F7C8";
  --fa--fa: "\F7C8\F7C8"; }

.fa-square-6 {
  --fa: "\E25B";
  --fa--fa: "\E25B\E25B"; }

.fa-shop-slash {
  --fa: "\E070";
  --fa--fa: "\E070\E070"; }

.fa-store-alt-slash {
  --fa: "\E070";
  --fa--fa: "\E070\E070"; }

.fa-wind-turbine {
  --fa: "\F89B";
  --fa--fa: "\F89B\F89B"; }

.fa-sliders-simple {
  --fa: "\E253";
  --fa--fa: "\E253\E253"; }

.fa-grid-round {
  --fa: "\E5DA";
  --fa--fa: "\E5DA\E5DA"; }

.fa-badge-sheriff {
  --fa: "\F8A2";
  --fa--fa: "\F8A2\F8A2"; }

.fa-server {
  --fa: "\F233";
  --fa--fa: "\F233\F233"; }

.fa-virus-covid-slash {
  --fa: "\E4A9";
  --fa--fa: "\E4A9\E4A9"; }

.fa-intersection {
  --fa: "\F668";
  --fa--fa: "\F668\F668"; }

.fa-shop-lock {
  --fa: "\E4A5";
  --fa--fa: "\E4A5\E4A5"; }

.fa-family {
  --fa: "\E300";
  --fa--fa: "\E300\E300"; }

.fa-hourglass-start {
  --fa: "\F251";
  --fa--fa: "\F251\F251"; }

.fa-hourglass-1 {
  --fa: "\F251";
  --fa--fa: "\F251\F251"; }

.fa-user-hair-buns {
  --fa: "\E3D3";
  --fa--fa: "\E3D3\E3D3"; }

.fa-blender-phone {
  --fa: "\F6B6";
  --fa--fa: "\F6B6\F6B6"; }

.fa-hourglass-clock {
  --fa: "\E41B";
  --fa--fa: "\E41B\E41B"; }

.fa-person-seat-reclined {
  --fa: "\E21F";
  --fa--fa: "\E21F\E21F"; }

.fa-paper-plane-top {
  --fa: "\E20A";
  --fa--fa: "\E20A\E20A"; }

.fa-paper-plane-alt {
  --fa: "\E20A";
  --fa--fa: "\E20A\E20A"; }

.fa-send {
  --fa: "\E20A";
  --fa--fa: "\E20A\E20A"; }

.fa-message-arrow-up {
  --fa: "\E1DC";
  --fa--fa: "\E1DC\E1DC"; }

.fa-comment-alt-arrow-up {
  --fa: "\E1DC";
  --fa--fa: "\E1DC\E1DC"; }

.fa-lightbulb-exclamation {
  --fa: "\F671";
  --fa--fa: "\F671\F671"; }

.fa-layer-minus {
  --fa: "\F5FE";
  --fa--fa: "\F5FE\F5FE"; }

.fa-layer-group-minus {
  --fa: "\F5FE";
  --fa--fa: "\F5FE\F5FE"; }

.fa-chart-pie-simple-circle-currency {
  --fa: "\E604";
  --fa--fa: "\E604\E604"; }

.fa-circle-e {
  --fa: "\E109";
  --fa--fa: "\E109\E109"; }

.fa-building-wheat {
  --fa: "\E4DB";
  --fa--fa: "\E4DB\E4DB"; }

.fa-gauge-max {
  --fa: "\F626";
  --fa--fa: "\F626\F626"; }

.fa-tachometer-alt-fastest {
  --fa: "\F626";
  --fa--fa: "\F626\F626"; }

.fa-person-breastfeeding {
  --fa: "\E53A";
  --fa--fa: "\E53A\E53A"; }

.fa-apostrophe {
  --fa: "'";
  --fa--fa: "''"; }

.fa-file-png {
  --fa: "\E666";
  --fa--fa: "\E666\E666"; }

.fa-fire-hydrant {
  --fa: "\E17F";
  --fa--fa: "\E17F\E17F"; }

.fa-right-to-bracket {
  --fa: "\F2F6";
  --fa--fa: "\F2F6\F2F6"; }

.fa-sign-in-alt {
  --fa: "\F2F6";
  --fa--fa: "\F2F6\F2F6"; }

.fa-video-plus {
  --fa: "\F4E1";
  --fa--fa: "\F4E1\F4E1"; }

.fa-square-right {
  --fa: "\F352";
  --fa--fa: "\F352\F352"; }

.fa-arrow-alt-square-right {
  --fa: "\F352";
  --fa--fa: "\F352\F352"; }

.fa-comment-smile {
  --fa: "\F4B4";
  --fa--fa: "\F4B4\F4B4"; }

.fa-venus {
  --fa: "\F221";
  --fa--fa: "\F221\F221"; }

.fa-passport {
  --fa: "\F5AB";
  --fa--fa: "\F5AB\F5AB"; }

.fa-thumbtack-slash {
  --fa: "\E68F";
  --fa--fa: "\E68F\E68F"; }

.fa-thumb-tack-slash {
  --fa: "\E68F";
  --fa--fa: "\E68F\E68F"; }

.fa-inbox-in {
  --fa: "\F310";
  --fa--fa: "\F310\F310"; }

.fa-inbox-arrow-down {
  --fa: "\F310";
  --fa--fa: "\F310\F310"; }

.fa-heart-pulse {
  --fa: "\F21E";
  --fa--fa: "\F21E\F21E"; }

.fa-heartbeat {
  --fa: "\F21E";
  --fa--fa: "\F21E\F21E"; }

.fa-circle-8 {
  --fa: "\E0F5";
  --fa--fa: "\E0F5\E0F5"; }

.fa-clouds-moon {
  --fa: "\F745";
  --fa--fa: "\F745\F745"; }

.fa-clock-ten-thirty {
  --fa: "\E355";
  --fa--fa: "\E355\E355"; }

.fa-people-carry-box {
  --fa: "\F4CE";
  --fa--fa: "\F4CE\F4CE"; }

.fa-people-carry {
  --fa: "\F4CE";
  --fa--fa: "\F4CE\F4CE"; }

.fa-folder-user {
  --fa: "\E18E";
  --fa--fa: "\E18E\E18E"; }

.fa-trash-can-xmark {
  --fa: "\E2AE";
  --fa--fa: "\E2AE\E2AE"; }

.fa-temperature-high {
  --fa: "\F769";
  --fa--fa: "\F769\F769"; }

.fa-microchip {
  --fa: "\F2DB";
  --fa--fa: "\F2DB\F2DB"; }

.fa-left-long-to-line {
  --fa: "\E41E";
  --fa--fa: "\E41E\E41E"; }

.fa-crown {
  --fa: "\F521";
  --fa--fa: "\F521\F521"; }

.fa-weight-hanging {
  --fa: "\F5CD";
  --fa--fa: "\F5CD\F5CD"; }

.fa-xmarks-lines {
  --fa: "\E59A";
  --fa--fa: "\E59A\E59A"; }

.fa-file-prescription {
  --fa: "\F572";
  --fa--fa: "\F572\F572"; }

.fa-table-cells-lock {
  --fa: "\E679";
  --fa--fa: "\E679\E679"; }

.fa-calendar-range {
  --fa: "\E0D6";
  --fa--fa: "\E0D6\E0D6"; }

.fa-flower-daffodil {
  --fa: "\F800";
  --fa--fa: "\F800\F800"; }

.fa-hand-back-point-up {
  --fa: "\E1A2";
  --fa--fa: "\E1A2\E1A2"; }

.fa-weight-scale {
  --fa: "\F496";
  --fa--fa: "\F496\F496"; }

.fa-weight {
  --fa: "\F496";
  --fa--fa: "\F496\F496"; }

.fa-arrow-up-to-arc {
  --fa: "\E617";
  --fa--fa: "\E617\E617"; }

.fa-star-exclamation {
  --fa: "\F2F3";
  --fa--fa: "\F2F3\F2F3"; }

.fa-books {
  --fa: "\F5DB";
  --fa--fa: "\F5DB\F5DB"; }

.fa-user-group {
  --fa: "\F500";
  --fa--fa: "\F500\F500"; }

.fa-user-friends {
  --fa: "\F500";
  --fa--fa: "\F500\F500"; }

.fa-arrow-up-a-z {
  --fa: "\F15E";
  --fa--fa: "\F15E\F15E"; }

.fa-sort-alpha-up {
  --fa: "\F15E";
  --fa--fa: "\F15E\F15E"; }

.fa-layer-plus {
  --fa: "\F5FF";
  --fa--fa: "\F5FF\F5FF"; }

.fa-layer-group-plus {
  --fa: "\F5FF";
  --fa--fa: "\F5FF\F5FF"; }

.fa-play-pause {
  --fa: "\E22F";
  --fa--fa: "\E22F\E22F"; }

.fa-block-question {
  --fa: "\E3DD";
  --fa--fa: "\E3DD\E3DD"; }

.fa-snooze {
  --fa: "\F880";
  --fa--fa: "\F880\F880"; }

.fa-zzz {
  --fa: "\F880";
  --fa--fa: "\F880\F880"; }

.fa-scanner-image {
  --fa: "\F8F3";
  --fa--fa: "\F8F3\F8F3"; }

.fa-tv-retro {
  --fa: "\F401";
  --fa--fa: "\F401\F401"; }

.fa-square-t {
  --fa: "\E280";
  --fa--fa: "\E280\E280"; }

.fa-farm {
  --fa: "\F864";
  --fa--fa: "\F864\F864"; }

.fa-barn-silo {
  --fa: "\F864";
  --fa--fa: "\F864\F864"; }

.fa-chess-knight {
  --fa: "\F441";
  --fa--fa: "\F441\F441"; }

.fa-bars-sort {
  --fa: "\E0AE";
  --fa--fa: "\E0AE\E0AE"; }

.fa-pallet-boxes {
  --fa: "\F483";
  --fa--fa: "\F483\F483"; }

.fa-palette-boxes {
  --fa: "\F483";
  --fa--fa: "\F483\F483"; }

.fa-pallet-alt {
  --fa: "\F483";
  --fa--fa: "\F483\F483"; }

.fa-face-laugh-squint {
  --fa: "\F59B";
  --fa--fa: "\F59B\F59B"; }

.fa-laugh-squint {
  --fa: "\F59B";
  --fa--fa: "\F59B\F59B"; }

.fa-code-simple {
  --fa: "\E13D";
  --fa--fa: "\E13D\E13D"; }

.fa-bolt-slash {
  --fa: "\E0B8";
  --fa--fa: "\E0B8\E0B8"; }

.fa-panel-fire {
  --fa: "\E42F";
  --fa--fa: "\E42F\E42F"; }

.fa-binary-circle-check {
  --fa: "\E33C";
  --fa--fa: "\E33C\E33C"; }

.fa-comment-minus {
  --fa: "\F4B1";
  --fa--fa: "\F4B1\F4B1"; }

.fa-burrito {
  --fa: "\F7ED";
  --fa--fa: "\F7ED\F7ED"; }

.fa-violin {
  --fa: "\F8ED";
  --fa--fa: "\F8ED\F8ED"; }

.fa-objects-column {
  --fa: "\E3C1";
  --fa--fa: "\E3C1\E3C1"; }

.fa-square-chevron-down {
  --fa: "\F329";
  --fa--fa: "\F329\F329"; }

.fa-chevron-square-down {
  --fa: "\F329";
  --fa--fa: "\F329\F329"; }

.fa-comment-plus {
  --fa: "\F4B2";
  --fa--fa: "\F4B2\F4B2"; }

.fa-triangle-instrument {
  --fa: "\F8E2";
  --fa--fa: "\F8E2\F8E2"; }

.fa-triangle-music {
  --fa: "\F8E2";
  --fa--fa: "\F8E2\F8E2"; }

.fa-wheelchair {
  --fa: "\F193";
  --fa--fa: "\F193\F193"; }

.fa-user-pilot-tie {
  --fa: "\E2C1";
  --fa--fa: "\E2C1\E2C1"; }

.fa-piano-keyboard {
  --fa: "\F8D5";
  --fa--fa: "\F8D5\F8D5"; }

.fa-bed-empty {
  --fa: "\F8F9";
  --fa--fa: "\F8F9\F8F9"; }

.fa-circle-arrow-up {
  --fa: "\F0AA";
  --fa--fa: "\F0AA\F0AA"; }

.fa-arrow-circle-up {
  --fa: "\F0AA";
  --fa--fa: "\F0AA\F0AA"; }

.fa-toggle-on {
  --fa: "\F205";
  --fa--fa: "\F205\F205"; }

.fa-rectangle-vertical {
  --fa: "\F2FB";
  --fa--fa: "\F2FB\F2FB"; }

.fa-rectangle-portrait {
  --fa: "\F2FB";
  --fa--fa: "\F2FB\F2FB"; }

.fa-person-walking {
  --fa: "\F554";
  --fa--fa: "\F554\F554"; }

.fa-walking {
  --fa: "\F554";
  --fa--fa: "\F554\F554"; }

.fa-l {
  --fa: "L";
  --fa--fa: "LL"; }

.fa-signal-stream {
  --fa: "\F8DD";
  --fa--fa: "\F8DD\F8DD"; }

.fa-down-to-bracket {
  --fa: "\E4E7";
  --fa--fa: "\E4E7\E4E7"; }

.fa-circle-z {
  --fa: "\E130";
  --fa--fa: "\E130\E130"; }

.fa-stars {
  --fa: "\F762";
  --fa--fa: "\F762\F762"; }

.fa-fire {
  --fa: "\F06D";
  --fa--fa: "\F06D\F06D"; }

.fa-bed-pulse {
  --fa: "\F487";
  --fa--fa: "\F487\F487"; }

.fa-procedures {
  --fa: "\F487";
  --fa--fa: "\F487\F487"; }

.fa-house-day {
  --fa: "\E00E";
  --fa--fa: "\E00E\E00E"; }

.fa-shuttle-space {
  --fa: "\F197";
  --fa--fa: "\F197\F197"; }

.fa-space-shuttle {
  --fa: "\F197";
  --fa--fa: "\F197\F197"; }

.fa-shirt-long-sleeve {
  --fa: "\E3C7";
  --fa--fa: "\E3C7\E3C7"; }

.fa-chart-pie-simple {
  --fa: "\F64E";
  --fa--fa: "\F64E\F64E"; }

.fa-chart-pie-alt {
  --fa: "\F64E";
  --fa--fa: "\F64E\F64E"; }

.fa-face-laugh {
  --fa: "\F599";
  --fa--fa: "\F599\F599"; }

.fa-laugh {
  --fa: "\F599";
  --fa--fa: "\F599\F599"; }

.fa-folder-open {
  --fa: "\F07C";
  --fa--fa: "\F07C\F07C"; }

.fa-album-collection-circle-user {
  --fa: "\E48F";
  --fa--fa: "\E48F\E48F"; }

.fa-candy {
  --fa: "\E3E7";
  --fa--fa: "\E3E7\E3E7"; }

.fa-bowl-hot {
  --fa: "\F823";
  --fa--fa: "\F823\F823"; }

.fa-soup {
  --fa: "\F823";
  --fa--fa: "\F823\F823"; }

.fa-flatbread {
  --fa: "\E40B";
  --fa--fa: "\E40B\E40B"; }

.fa-heart-circle-plus {
  --fa: "\E500";
  --fa--fa: "\E500\E500"; }

.fa-code-fork {
  --fa: "\E13B";
  --fa--fa: "\E13B\E13B"; }

.fa-city {
  --fa: "\F64F";
  --fa--fa: "\F64F\F64F"; }

.fa-signal-bars-weak {
  --fa: "\F691";
  --fa--fa: "\F691\F691"; }

.fa-signal-alt-1 {
  --fa: "\F691";
  --fa--fa: "\F691\F691"; }

.fa-microphone-lines {
  --fa: "\F3C9";
  --fa--fa: "\F3C9\F3C9"; }

.fa-microphone-alt {
  --fa: "\F3C9";
  --fa--fa: "\F3C9\F3C9"; }

.fa-clock-twelve {
  --fa: "\E358";
  --fa--fa: "\E358\E358"; }

.fa-pepper-hot {
  --fa: "\F816";
  --fa--fa: "\F816\F816"; }

.fa-citrus-slice {
  --fa: "\E2F5";
  --fa--fa: "\E2F5\E2F5"; }

.fa-sheep {
  --fa: "\F711";
  --fa--fa: "\F711\F711"; }

.fa-unlock {
  --fa: "\F09C";
  --fa--fa: "\F09C\F09C"; }

.fa-colon-sign {
  --fa: "\E140";
  --fa--fa: "\E140\E140"; }

.fa-headset {
  --fa: "\F590";
  --fa--fa: "\F590\F590"; }

.fa-badger-honey {
  --fa: "\F6B4";
  --fa--fa: "\F6B4\F6B4"; }

.fa-h4 {
  --fa: "\F86A";
  --fa--fa: "\F86A\F86A"; }

.fa-store-slash {
  --fa: "\E071";
  --fa--fa: "\E071\E071"; }

.fa-road-circle-xmark {
  --fa: "\E566";
  --fa--fa: "\E566\E566"; }

.fa-signal-slash {
  --fa: "\F695";
  --fa--fa: "\F695\F695"; }

.fa-user-minus {
  --fa: "\F503";
  --fa--fa: "\F503\F503"; }

.fa-mars-stroke-up {
  --fa: "\F22A";
  --fa--fa: "\F22A\F22A"; }

.fa-mars-stroke-v {
  --fa: "\F22A";
  --fa--fa: "\F22A\F22A"; }

.fa-champagne-glasses {
  --fa: "\F79F";
  --fa--fa: "\F79F\F79F"; }

.fa-glass-cheers {
  --fa: "\F79F";
  --fa--fa: "\F79F\F79F"; }

.fa-taco {
  --fa: "\F826";
  --fa--fa: "\F826\F826"; }

.fa-hexagon-plus {
  --fa: "\F300";
  --fa--fa: "\F300\F300"; }

.fa-plus-hexagon {
  --fa: "\F300";
  --fa--fa: "\F300\F300"; }

.fa-clipboard {
  --fa: "\F328";
  --fa--fa: "\F328\F328"; }

.fa-house-circle-exclamation {
  --fa: "\E50A";
  --fa--fa: "\E50A\E50A"; }

.fa-file-arrow-up {
  --fa: "\F574";
  --fa--fa: "\F574\F574"; }

.fa-file-upload {
  --fa: "\F574";
  --fa--fa: "\F574\F574"; }

.fa-wifi {
  --fa: "\F1EB";
  --fa--fa: "\F1EB\F1EB"; }

.fa-wifi-3 {
  --fa: "\F1EB";
  --fa--fa: "\F1EB\F1EB"; }

.fa-wifi-strong {
  --fa: "\F1EB";
  --fa--fa: "\F1EB\F1EB"; }

.fa-messages {
  --fa: "\F4B6";
  --fa--fa: "\F4B6\F4B6"; }

.fa-comments-alt {
  --fa: "\F4B6";
  --fa--fa: "\F4B6\F4B6"; }

.fa-bath {
  --fa: "\F2CD";
  --fa--fa: "\F2CD\F2CD"; }

.fa-bathtub {
  --fa: "\F2CD";
  --fa--fa: "\F2CD\F2CD"; }

.fa-umbrella-simple {
  --fa: "\E2BC";
  --fa--fa: "\E2BC\E2BC"; }

.fa-umbrella-alt {
  --fa: "\E2BC";
  --fa--fa: "\E2BC\E2BC"; }

.fa-rectangle-history-circle-plus {
  --fa: "\E4A3";
  --fa--fa: "\E4A3\E4A3"; }

.fa-underline {
  --fa: "\F0CD";
  --fa--fa: "\F0CD\F0CD"; }

.fa-prescription-bottle-pill {
  --fa: "\E5C0";
  --fa--fa: "\E5C0\E5C0"; }

.fa-user-pen {
  --fa: "\F4FF";
  --fa--fa: "\F4FF\F4FF"; }

.fa-user-edit {
  --fa: "\F4FF";
  --fa--fa: "\F4FF\F4FF"; }

.fa-binary-slash {
  --fa: "\E33E";
  --fa--fa: "\E33E\E33E"; }

.fa-square-o {
  --fa: "\E278";
  --fa--fa: "\E278\E278"; }

.fa-caduceus {
  --fa: "\E681";
  --fa--fa: "\E681\E681"; }

.fa-signature {
  --fa: "\F5B7";
  --fa--fa: "\F5B7\F5B7"; }

.fa-stroopwafel {
  --fa: "\F551";
  --fa--fa: "\F551\F551"; }

.fa-bold {
  --fa: "\F032";
  --fa--fa: "\F032\F032"; }

.fa-anchor-lock {
  --fa: "\E4AD";
  --fa--fa: "\E4AD\E4AD"; }

.fa-building-ngo {
  --fa: "\E4D7";
  --fa--fa: "\E4D7\E4D7"; }

.fa-transporter-3 {
  --fa: "\E045";
  --fa--fa: "\E045\E045"; }

.fa-engine-warning {
  --fa: "\F5F2";
  --fa--fa: "\F5F2\F5F2"; }

.fa-engine-exclamation {
  --fa: "\F5F2";
  --fa--fa: "\F5F2\F5F2"; }

.fa-circle-down-right {
  --fa: "\E108";
  --fa--fa: "\E108\E108"; }

.fa-square-k {
  --fa: "\E274";
  --fa--fa: "\E274\E274"; }

.fa-manat-sign {
  --fa: "\E1D5";
  --fa--fa: "\E1D5\E1D5"; }

.fa-money-check-pen {
  --fa: "\F872";
  --fa--fa: "\F872\F872"; }

.fa-money-check-edit {
  --fa: "\F872";
  --fa--fa: "\F872\F872"; }

.fa-not-equal {
  --fa: "\F53E";
  --fa--fa: "\F53E\F53E"; }

.fa-border-top-left {
  --fa: "\F853";
  --fa--fa: "\F853\F853"; }

.fa-border-style {
  --fa: "\F853";
  --fa--fa: "\F853\F853"; }

.fa-map-location-dot {
  --fa: "\F5A0";
  --fa--fa: "\F5A0\F5A0"; }

.fa-map-marked-alt {
  --fa: "\F5A0";
  --fa--fa: "\F5A0\F5A0"; }

.fa-tilde {
  --fa: "~";
  --fa--fa: "~~"; }

.fa-jedi {
  --fa: "\F669";
  --fa--fa: "\F669\F669"; }

.fa-square-poll-vertical {
  --fa: "\F681";
  --fa--fa: "\F681\F681"; }

.fa-poll {
  --fa: "\F681";
  --fa--fa: "\F681\F681"; }

.fa-arrow-down-square-triangle {
  --fa: "\F889";
  --fa--fa: "\F889\F889"; }

.fa-sort-shapes-down-alt {
  --fa: "\F889";
  --fa--fa: "\F889\F889"; }

.fa-mug-hot {
  --fa: "\F7B6";
  --fa--fa: "\F7B6\F7B6"; }

.fa-dog-leashed {
  --fa: "\F6D4";
  --fa--fa: "\F6D4\F6D4"; }

.fa-car-battery {
  --fa: "\F5DF";
  --fa--fa: "\F5DF\F5DF"; }

.fa-battery-car {
  --fa: "\F5DF";
  --fa--fa: "\F5DF\F5DF"; }

.fa-face-downcast-sweat {
  --fa: "\E371";
  --fa--fa: "\E371\E371"; }

.fa-mailbox-flag-up {
  --fa: "\E5BB";
  --fa--fa: "\E5BB\E5BB"; }

.fa-memo-circle-info {
  --fa: "\E49A";
  --fa--fa: "\E49A\E49A"; }

.fa-gift {
  --fa: "\F06B";
  --fa--fa: "\F06B\F06B"; }

.fa-dice-two {
  --fa: "\F528";
  --fa--fa: "\F528\F528"; }

.fa-volume {
  --fa: "\F6A8";
  --fa--fa: "\F6A8\F6A8"; }

.fa-volume-medium {
  --fa: "\F6A8";
  --fa--fa: "\F6A8\F6A8"; }

.fa-transporter-5 {
  --fa: "\E2A6";
  --fa--fa: "\E2A6\E2A6"; }

.fa-gauge-circle-bolt {
  --fa: "\E496";
  --fa--fa: "\E496\E496"; }

.fa-coin-front {
  --fa: "\E3FC";
  --fa--fa: "\E3FC\E3FC"; }

.fa-file-slash {
  --fa: "\E3A7";
  --fa--fa: "\E3A7\E3A7"; }

.fa-message-arrow-up-right {
  --fa: "\E1DD";
  --fa--fa: "\E1DD\E1DD"; }

.fa-treasure-chest {
  --fa: "\F723";
  --fa--fa: "\F723\F723"; }

.fa-chess-queen {
  --fa: "\F445";
  --fa--fa: "\F445\F445"; }

.fa-paintbrush-fine {
  --fa: "\F5A9";
  --fa--fa: "\F5A9\F5A9"; }

.fa-paint-brush-alt {
  --fa: "\F5A9";
  --fa--fa: "\F5A9\F5A9"; }

.fa-paint-brush-fine {
  --fa: "\F5A9";
  --fa--fa: "\F5A9\F5A9"; }

.fa-paintbrush-alt {
  --fa: "\F5A9";
  --fa--fa: "\F5A9\F5A9"; }

.fa-glasses {
  --fa: "\F530";
  --fa--fa: "\F530\F530"; }

.fa-hood-cloak {
  --fa: "\F6EF";
  --fa--fa: "\F6EF\F6EF"; }

.fa-square-quote {
  --fa: "\E329";
  --fa--fa: "\E329\E329"; }

.fa-up-left {
  --fa: "\E2BD";
  --fa--fa: "\E2BD\E2BD"; }

.fa-bring-front {
  --fa: "\F857";
  --fa--fa: "\F857\F857"; }

.fa-chess-board {
  --fa: "\F43C";
  --fa--fa: "\F43C\F43C"; }

.fa-burger-cheese {
  --fa: "\F7F1";
  --fa--fa: "\F7F1\F7F1"; }

.fa-cheeseburger {
  --fa: "\F7F1";
  --fa--fa: "\F7F1\F7F1"; }

.fa-building-circle-check {
  --fa: "\E4D2";
  --fa--fa: "\E4D2\E4D2"; }

.fa-repeat-1 {
  --fa: "\F365";
  --fa--fa: "\F365\F365"; }

.fa-arrow-down-to-line {
  --fa: "\F33D";
  --fa--fa: "\F33D\F33D"; }

.fa-arrow-to-bottom {
  --fa: "\F33D";
  --fa--fa: "\F33D\F33D"; }

.fa-grid-5 {
  --fa: "\E199";
  --fa--fa: "\E199\E199"; }

.fa-swap-arrows {
  --fa: "\E60A";
  --fa--fa: "\E60A\E60A"; }

.fa-right-long-to-line {
  --fa: "\E444";
  --fa--fa: "\E444\E444"; }

.fa-person-chalkboard {
  --fa: "\E53D";
  --fa--fa: "\E53D\E53D"; }

.fa-mars-stroke-right {
  --fa: "\F22B";
  --fa--fa: "\F22B\F22B"; }

.fa-mars-stroke-h {
  --fa: "\F22B";
  --fa--fa: "\F22B\F22B"; }

.fa-hand-back-fist {
  --fa: "\F255";
  --fa--fa: "\F255\F255"; }

.fa-hand-rock {
  --fa: "\F255";
  --fa--fa: "\F255\F255"; }

.fa-grid-round-5 {
  --fa: "\E5DE";
  --fa--fa: "\E5DE\E5DE"; }

.fa-tally {
  --fa: "\F69C";
  --fa--fa: "\F69C\F69C"; }

.fa-tally-5 {
  --fa: "\F69C";
  --fa--fa: "\F69C\F69C"; }

.fa-square-caret-up {
  --fa: "\F151";
  --fa--fa: "\F151\F151"; }

.fa-caret-square-up {
  --fa: "\F151";
  --fa--fa: "\F151\F151"; }

.fa-cloud-showers-water {
  --fa: "\E4E4";
  --fa--fa: "\E4E4\E4E4"; }

.fa-chart-bar {
  --fa: "\F080";
  --fa--fa: "\F080\F080"; }

.fa-bar-chart {
  --fa: "\F080";
  --fa--fa: "\F080\F080"; }

.fa-hands-bubbles {
  --fa: "\E05E";
  --fa--fa: "\E05E\E05E"; }

.fa-hands-wash {
  --fa: "\E05E";
  --fa--fa: "\E05E\E05E"; }

.fa-less-than-equal {
  --fa: "\F537";
  --fa--fa: "\F537\F537"; }

.fa-train {
  --fa: "\F238";
  --fa--fa: "\F238\F238"; }

.fa-up-from-dotted-line {
  --fa: "\E456";
  --fa--fa: "\E456\E456"; }

.fa-eye-low-vision {
  --fa: "\F2A8";
  --fa--fa: "\F2A8\F2A8"; }

.fa-low-vision {
  --fa: "\F2A8";
  --fa--fa: "\F2A8\F2A8"; }

.fa-traffic-light-go {
  --fa: "\F638";
  --fa--fa: "\F638\F638"; }

.fa-face-exhaling {
  --fa: "\E480";
  --fa--fa: "\E480\E480"; }

.fa-sensor-fire {
  --fa: "\E02A";
  --fa--fa: "\E02A\E02A"; }

.fa-user-unlock {
  --fa: "\E058";
  --fa--fa: "\E058\E058"; }

.fa-hexagon-divide {
  --fa: "\E1AD";
  --fa--fa: "\E1AD\E1AD"; }

.fa-00 {
  --fa: "\E467";
  --fa--fa: "\E467\E467"; }

.fa-crow {
  --fa: "\F520";
  --fa--fa: "\F520\F520"; }

.fa-cassette-betamax {
  --fa: "\F8A4";
  --fa--fa: "\F8A4\F8A4"; }

.fa-betamax {
  --fa: "\F8A4";
  --fa--fa: "\F8A4\F8A4"; }

.fa-sailboat {
  --fa: "\E445";
  --fa--fa: "\E445\E445"; }

.fa-window-restore {
  --fa: "\F2D2";
  --fa--fa: "\F2D2\F2D2"; }

.fa-nfc-magnifying-glass {
  --fa: "\E1F9";
  --fa--fa: "\E1F9\E1F9"; }

.fa-file-binary {
  --fa: "\E175";
  --fa--fa: "\E175\E175"; }

.fa-circle-v {
  --fa: "\E12A";
  --fa--fa: "\E12A\E12A"; }

.fa-square-plus {
  --fa: "\F0FE";
  --fa--fa: "\F0FE\F0FE"; }

.fa-plus-square {
  --fa: "\F0FE";
  --fa--fa: "\F0FE\F0FE"; }

.fa-bowl-scoops {
  --fa: "\E3DF";
  --fa--fa: "\E3DF\E3DF"; }

.fa-mistletoe {
  --fa: "\F7B4";
  --fa--fa: "\F7B4\F7B4"; }

.fa-custard {
  --fa: "\E403";
  --fa--fa: "\E403\E403"; }

.fa-lacrosse-stick {
  --fa: "\E3B5";
  --fa--fa: "\E3B5\E3B5"; }

.fa-hockey-mask {
  --fa: "\F6EE";
  --fa--fa: "\F6EE\F6EE"; }

.fa-sunrise {
  --fa: "\F766";
  --fa--fa: "\F766\F766"; }

.fa-subtitles {
  --fa: "\E60F";
  --fa--fa: "\E60F\E60F"; }

.fa-panel-ews {
  --fa: "\E42E";
  --fa--fa: "\E42E\E42E"; }

.fa-torii-gate {
  --fa: "\F6A1";
  --fa--fa: "\F6A1\F6A1"; }

.fa-cloud-exclamation {
  --fa: "\E491";
  --fa--fa: "\E491\E491"; }

.fa-message-lines {
  --fa: "\F4A6";
  --fa--fa: "\F4A6\F4A6"; }

.fa-comment-alt-lines {
  --fa: "\F4A6";
  --fa--fa: "\F4A6\F4A6"; }

.fa-frog {
  --fa: "\F52E";
  --fa--fa: "\F52E\F52E"; }

.fa-bucket {
  --fa: "\E4CF";
  --fa--fa: "\E4CF\E4CF"; }

.fa-floppy-disk-pen {
  --fa: "\E182";
  --fa--fa: "\E182\E182"; }

.fa-image {
  --fa: "\F03E";
  --fa--fa: "\F03E\F03E"; }

.fa-window-frame {
  --fa: "\E04F";
  --fa--fa: "\E04F\E04F"; }

.fa-microphone {
  --fa: "\F130";
  --fa--fa: "\F130\F130"; }

.fa-cow {
  --fa: "\F6C8";
  --fa--fa: "\F6C8\F6C8"; }

.fa-file-zip {
  --fa: "\E5EE";
  --fa--fa: "\E5EE\E5EE"; }

.fa-square-ring {
  --fa: "\E44F";
  --fa--fa: "\E44F\E44F"; }

.fa-down-from-line {
  --fa: "\F349";
  --fa--fa: "\F349\F349"; }

.fa-arrow-alt-from-top {
  --fa: "\F349";
  --fa--fa: "\F349\F349"; }

.fa-caret-up {
  --fa: "\F0D8";
  --fa--fa: "\F0D8\F0D8"; }

.fa-shield-xmark {
  --fa: "\E24C";
  --fa--fa: "\E24C\E24C"; }

.fa-shield-times {
  --fa: "\E24C";
  --fa--fa: "\E24C\E24C"; }

.fa-screwdriver {
  --fa: "\F54A";
  --fa--fa: "\F54A\F54A"; }

.fa-circle-sort-down {
  --fa: "\E031";
  --fa--fa: "\E031\E031"; }

.fa-sort-circle-down {
  --fa: "\E031";
  --fa--fa: "\E031\E031"; }

.fa-folder-closed {
  --fa: "\E185";
  --fa--fa: "\E185\E185"; }

.fa-house-tsunami {
  --fa: "\E515";
  --fa--fa: "\E515\E515"; }

.fa-square-nfi {
  --fa: "\E576";
  --fa--fa: "\E576\E576"; }

.fa-forklift {
  --fa: "\F47A";
  --fa--fa: "\F47A\F47A"; }

.fa-arrow-up-from-ground-water {
  --fa: "\E4B5";
  --fa--fa: "\E4B5\E4B5"; }

.fa-bracket-square-right {
  --fa: "]";
  --fa--fa: "]]"; }

.fa-martini-glass {
  --fa: "\F57B";
  --fa--fa: "\F57B\F57B"; }

.fa-glass-martini-alt {
  --fa: "\F57B";
  --fa--fa: "\F57B\F57B"; }

.fa-square-binary {
  --fa: "\E69B";
  --fa--fa: "\E69B\E69B"; }

.fa-rotate-left {
  --fa: "\F2EA";
  --fa--fa: "\F2EA\F2EA"; }

.fa-rotate-back {
  --fa: "\F2EA";
  --fa--fa: "\F2EA\F2EA"; }

.fa-rotate-backward {
  --fa: "\F2EA";
  --fa--fa: "\F2EA\F2EA"; }

.fa-undo-alt {
  --fa: "\F2EA";
  --fa--fa: "\F2EA\F2EA"; }

.fa-table-columns {
  --fa: "\F0DB";
  --fa--fa: "\F0DB\F0DB"; }

.fa-columns {
  --fa: "\F0DB";
  --fa--fa: "\F0DB\F0DB"; }

.fa-square-a {
  --fa: "\E25F";
  --fa--fa: "\E25F\E25F"; }

.fa-tick {
  --fa: "\E32F";
  --fa--fa: "\E32F\E32F"; }

.fa-lemon {
  --fa: "\F094";
  --fa--fa: "\F094\F094"; }

.fa-head-side-mask {
  --fa: "\E063";
  --fa--fa: "\E063\E063"; }

.fa-handshake {
  --fa: "\F2B5";
  --fa--fa: "\F2B5\F2B5"; }

.fa-gem {
  --fa: "\F3A5";
  --fa--fa: "\F3A5\F3A5"; }

.fa-dolly {
  --fa: "\F472";
  --fa--fa: "\F472\F472"; }

.fa-dolly-box {
  --fa: "\F472";
  --fa--fa: "\F472\F472"; }

.fa-smoking {
  --fa: "\F48D";
  --fa--fa: "\F48D\F48D"; }

.fa-minimize {
  --fa: "\F78C";
  --fa--fa: "\F78C\F78C"; }

.fa-compress-arrows-alt {
  --fa: "\F78C";
  --fa--fa: "\F78C\F78C"; }

.fa-refrigerator {
  --fa: "\E026";
  --fa--fa: "\E026\E026"; }

.fa-monument {
  --fa: "\F5A6";
  --fa--fa: "\F5A6\F5A6"; }

.fa-octagon-xmark {
  --fa: "\F2F0";
  --fa--fa: "\F2F0\F2F0"; }

.fa-times-octagon {
  --fa: "\F2F0";
  --fa--fa: "\F2F0\F2F0"; }

.fa-xmark-octagon {
  --fa: "\F2F0";
  --fa--fa: "\F2F0\F2F0"; }

.fa-align-slash {
  --fa: "\F846";
  --fa--fa: "\F846\F846"; }

.fa-snowplow {
  --fa: "\F7D2";
  --fa--fa: "\F7D2\F7D2"; }

.fa-angles-right {
  --fa: "\F101";
  --fa--fa: "\F101\F101"; }

.fa-angle-double-right {
  --fa: "\F101";
  --fa--fa: "\F101\F101"; }

.fa-truck-ramp-couch {
  --fa: "\F4DD";
  --fa--fa: "\F4DD\F4DD"; }

.fa-truck-couch {
  --fa: "\F4DD";
  --fa--fa: "\F4DD\F4DD"; }

.fa-cannabis {
  --fa: "\F55F";
  --fa--fa: "\F55F\F55F"; }

.fa-circle-play {
  --fa: "\F144";
  --fa--fa: "\F144\F144"; }

.fa-play-circle {
  --fa: "\F144";
  --fa--fa: "\F144\F144"; }

.fa-arrow-up-right-and-arrow-down-left-from-center {
  --fa: "\E0A0";
  --fa--fa: "\E0A0\E0A0"; }

.fa-location-arrow-up {
  --fa: "\E63A";
  --fa--fa: "\E63A\E63A"; }

.fa-tablets {
  --fa: "\F490";
  --fa--fa: "\F490\F490"; }

.fa-360-degrees {
  --fa: "\E2DC";
  --fa--fa: "\E2DC\E2DC"; }

.fa-ethernet {
  --fa: "\F796";
  --fa--fa: "\F796\F796"; }

.fa-euro-sign {
  --fa: "\F153";
  --fa--fa: "\F153\F153"; }

.fa-eur {
  --fa: "\F153";
  --fa--fa: "\F153\F153"; }

.fa-euro {
  --fa: "\F153";
  --fa--fa: "\F153\F153"; }

.fa-chair {
  --fa: "\F6C0";
  --fa--fa: "\F6C0\F6C0"; }

.fa-circle-check {
  --fa: "\F058";
  --fa--fa: "\F058\F058"; }

.fa-check-circle {
  --fa: "\F058";
  --fa--fa: "\F058\F058"; }

.fa-square-dashed-circle-plus {
  --fa: "\E5C2";
  --fa--fa: "\E5C2\E5C2"; }

.fa-hand-holding-circle-dollar {
  --fa: "\E621";
  --fa--fa: "\E621\E621"; }

.fa-money-simple-from-bracket {
  --fa: "\E313";
  --fa--fa: "\E313\E313"; }

.fa-bat {
  --fa: "\F6B5";
  --fa--fa: "\F6B5\F6B5"; }

.fa-circle-stop {
  --fa: "\F28D";
  --fa--fa: "\F28D\F28D"; }

.fa-stop-circle {
  --fa: "\F28D";
  --fa--fa: "\F28D\F28D"; }

.fa-head-side-headphones {
  --fa: "\F8C2";
  --fa--fa: "\F8C2\F8C2"; }

.fa-phone-rotary {
  --fa: "\F8D3";
  --fa--fa: "\F8D3\F8D3"; }

.fa-arrow-up-to-bracket {
  --fa: "\E66A";
  --fa--fa: "\E66A\E66A"; }

.fa-compass-drafting {
  --fa: "\F568";
  --fa--fa: "\F568\F568"; }

.fa-drafting-compass {
  --fa: "\F568";
  --fa--fa: "\F568\F568"; }

.fa-plate-wheat {
  --fa: "\E55A";
  --fa--fa: "\E55A\E55A"; }

.fa-calendar-circle-minus {
  --fa: "\E46F";
  --fa--fa: "\E46F\E46F"; }

.fa-chopsticks {
  --fa: "\E3F7";
  --fa--fa: "\E3F7\E3F7"; }

.fa-car-wrench {
  --fa: "\F5E3";
  --fa--fa: "\F5E3\F5E3"; }

.fa-car-mechanic {
  --fa: "\F5E3";
  --fa--fa: "\F5E3\F5E3"; }

.fa-icicles {
  --fa: "\F7AD";
  --fa--fa: "\F7AD\F7AD"; }

.fa-person-shelter {
  --fa: "\E54F";
  --fa--fa: "\E54F\E54F"; }

.fa-neuter {
  --fa: "\F22C";
  --fa--fa: "\F22C\F22C"; }

.fa-id-badge {
  --fa: "\F2C1";
  --fa--fa: "\F2C1\F2C1"; }

.fa-kazoo {
  --fa: "\F8C7";
  --fa--fa: "\F8C7\F8C7"; }

.fa-marker {
  --fa: "\F5A1";
  --fa--fa: "\F5A1\F5A1"; }

.fa-bin-bottles {
  --fa: "\E5F5";
  --fa--fa: "\E5F5\E5F5"; }

.fa-face-laugh-beam {
  --fa: "\F59A";
  --fa--fa: "\F59A\F59A"; }

.fa-laugh-beam {
  --fa: "\F59A";
  --fa--fa: "\F59A\F59A"; }

.fa-square-arrow-down-left {
  --fa: "\E261";
  --fa--fa: "\E261\E261"; }

.fa-battery-bolt {
  --fa: "\F376";
  --fa--fa: "\F376\F376"; }

.fa-tree-large {
  --fa: "\F7DD";
  --fa--fa: "\F7DD\F7DD"; }

.fa-helicopter-symbol {
  --fa: "\E502";
  --fa--fa: "\E502\E502"; }

.fa-aperture {
  --fa: "\E2DF";
  --fa--fa: "\E2DF\E2DF"; }

.fa-universal-access {
  --fa: "\F29A";
  --fa--fa: "\F29A\F29A"; }

.fa-gear-complex {
  --fa: "\E5E9";
  --fa--fa: "\E5E9\E5E9"; }

.fa-file-magnifying-glass {
  --fa: "\F865";
  --fa--fa: "\F865\F865"; }

.fa-file-search {
  --fa: "\F865";
  --fa--fa: "\F865\F865"; }

.fa-up-right {
  --fa: "\E2BE";
  --fa--fa: "\E2BE\E2BE"; }

.fa-circle-chevron-up {
  --fa: "\F139";
  --fa--fa: "\F139\F139"; }

.fa-chevron-circle-up {
  --fa: "\F139";
  --fa--fa: "\F139\F139"; }

.fa-user-police {
  --fa: "\E333";
  --fa--fa: "\E333\E333"; }

.fa-lari-sign {
  --fa: "\E1C8";
  --fa--fa: "\E1C8\E1C8"; }

.fa-volcano {
  --fa: "\F770";
  --fa--fa: "\F770\F770"; }

.fa-teddy-bear {
  --fa: "\E3CF";
  --fa--fa: "\E3CF\E3CF"; }

.fa-stocking {
  --fa: "\F7D5";
  --fa--fa: "\F7D5\F7D5"; }

.fa-person-walking-dashed-line-arrow-right {
  --fa: "\E553";
  --fa--fa: "\E553\E553"; }

.fa-image-slash {
  --fa: "\E1B7";
  --fa--fa: "\E1B7\E1B7"; }

.fa-mask-snorkel {
  --fa: "\E3B7";
  --fa--fa: "\E3B7\E3B7"; }

.fa-smoke {
  --fa: "\F760";
  --fa--fa: "\F760\F760"; }

.fa-sterling-sign {
  --fa: "\F154";
  --fa--fa: "\F154\F154"; }

.fa-gbp {
  --fa: "\F154";
  --fa--fa: "\F154\F154"; }

.fa-pound-sign {
  --fa: "\F154";
  --fa--fa: "\F154\F154"; }

.fa-battery-exclamation {
  --fa: "\E0B0";
  --fa--fa: "\E0B0\E0B0"; }

.fa-viruses {
  --fa: "\E076";
  --fa--fa: "\E076\E076"; }

.fa-square-person-confined {
  --fa: "\E577";
  --fa--fa: "\E577\E577"; }

.fa-user-tie {
  --fa: "\F508";
  --fa--fa: "\F508\F508"; }

.fa-up-to-bracket {
  --fa: "\E66E";
  --fa--fa: "\E66E\E66E"; }

.fa-arrow-down-long {
  --fa: "\F175";
  --fa--fa: "\F175\F175"; }

.fa-long-arrow-down {
  --fa: "\F175";
  --fa--fa: "\F175\F175"; }

.fa-tent-arrow-down-to-line {
  --fa: "\E57E";
  --fa--fa: "\E57E\E57E"; }

.fa-certificate {
  --fa: "\F0A3";
  --fa--fa: "\F0A3\F0A3"; }

.fa-crystal-ball {
  --fa: "\E362";
  --fa--fa: "\E362\E362"; }

.fa-reply-all {
  --fa: "\F122";
  --fa--fa: "\F122\F122"; }

.fa-mail-reply-all {
  --fa: "\F122";
  --fa--fa: "\F122\F122"; }

.fa-suitcase {
  --fa: "\F0F2";
  --fa--fa: "\F0F2\F0F2"; }

.fa-person-skating {
  --fa: "\F7C5";
  --fa--fa: "\F7C5\F7C5"; }

.fa-skating {
  --fa: "\F7C5";
  --fa--fa: "\F7C5\F7C5"; }

.fa-star-shooting {
  --fa: "\E036";
  --fa--fa: "\E036\E036"; }

.fa-binary-lock {
  --fa: "\E33D";
  --fa--fa: "\E33D\E33D"; }

.fa-filter-circle-dollar {
  --fa: "\F662";
  --fa--fa: "\F662\F662"; }

.fa-funnel-dollar {
  --fa: "\F662";
  --fa--fa: "\F662\F662"; }

.fa-camera-retro {
  --fa: "\F083";
  --fa--fa: "\F083\F083"; }

.fa-circle-arrow-down {
  --fa: "\F0AB";
  --fa--fa: "\F0AB\F0AB"; }

.fa-arrow-circle-down {
  --fa: "\F0AB";
  --fa--fa: "\F0AB\F0AB"; }

.fa-comment-pen {
  --fa: "\F4AE";
  --fa--fa: "\F4AE\F4AE"; }

.fa-comment-edit {
  --fa: "\F4AE";
  --fa--fa: "\F4AE\F4AE"; }

.fa-file-import {
  --fa: "\F56F";
  --fa--fa: "\F56F\F56F"; }

.fa-arrow-right-to-file {
  --fa: "\F56F";
  --fa--fa: "\F56F\F56F"; }

.fa-banjo {
  --fa: "\F8A3";
  --fa--fa: "\F8A3\F8A3"; }

.fa-square-arrow-up-right {
  --fa: "\F14C";
  --fa--fa: "\F14C\F14C"; }

.fa-external-link-square {
  --fa: "\F14C";
  --fa--fa: "\F14C\F14C"; }

.fa-light-emergency-on {
  --fa: "\E420";
  --fa--fa: "\E420\E420"; }

.fa-kerning {
  --fa: "\F86F";
  --fa--fa: "\F86F\F86F"; }

.fa-box-open {
  --fa: "\F49E";
  --fa--fa: "\F49E\F49E"; }

.fa-square-f {
  --fa: "\E270";
  --fa--fa: "\E270\E270"; }

.fa-scroll {
  --fa: "\F70E";
  --fa--fa: "\F70E\F70E"; }

.fa-spa {
  --fa: "\F5BB";
  --fa--fa: "\F5BB\F5BB"; }

.fa-arrow-left-from-line {
  --fa: "\F344";
  --fa--fa: "\F344\F344"; }

.fa-arrow-from-right {
  --fa: "\F344";
  --fa--fa: "\F344\F344"; }

.fa-strawberry {
  --fa: "\E32B";
  --fa--fa: "\E32B\E32B"; }

.fa-location-pin-lock {
  --fa: "\E51F";
  --fa--fa: "\E51F\E51F"; }

.fa-pause {
  --fa: "\F04C";
  --fa--fa: "\F04C\F04C"; }

.fa-clock-eight-thirty {
  --fa: "\E346";
  --fa--fa: "\E346\E346"; }

.fa-plane-engines {
  --fa: "\F3DE";
  --fa--fa: "\F3DE\F3DE"; }

.fa-plane-alt {
  --fa: "\F3DE";
  --fa--fa: "\F3DE\F3DE"; }

.fa-hill-avalanche {
  --fa: "\E507";
  --fa--fa: "\E507\E507"; }

.fa-temperature-empty {
  --fa: "\F2CB";
  --fa--fa: "\F2CB\F2CB"; }

.fa-temperature-0 {
  --fa: "\F2CB";
  --fa--fa: "\F2CB\F2CB"; }

.fa-thermometer-0 {
  --fa: "\F2CB";
  --fa--fa: "\F2CB\F2CB"; }

.fa-thermometer-empty {
  --fa: "\F2CB";
  --fa--fa: "\F2CB\F2CB"; }

.fa-bomb {
  --fa: "\F1E2";
  --fa--fa: "\F1E2\F1E2"; }

.fa-gauge-low {
  --fa: "\F627";
  --fa--fa: "\F627\F627"; }

.fa-tachometer-alt-slow {
  --fa: "\F627";
  --fa--fa: "\F627\F627"; }

.fa-registered {
  --fa: "\F25D";
  --fa--fa: "\F25D\F25D"; }

.fa-trash-can-plus {
  --fa: "\E2AC";
  --fa--fa: "\E2AC\E2AC"; }

.fa-address-card {
  --fa: "\F2BB";
  --fa--fa: "\F2BB\F2BB"; }

.fa-contact-card {
  --fa: "\F2BB";
  --fa--fa: "\F2BB\F2BB"; }

.fa-vcard {
  --fa: "\F2BB";
  --fa--fa: "\F2BB\F2BB"; }

.fa-chart-fft {
  --fa: "\E69E";
  --fa--fa: "\E69E\E69E"; }

.fa-scale-unbalanced-flip {
  --fa: "\F516";
  --fa--fa: "\F516\F516"; }

.fa-balance-scale-right {
  --fa: "\F516";
  --fa--fa: "\F516\F516"; }

.fa-globe-snow {
  --fa: "\F7A3";
  --fa--fa: "\F7A3\F7A3"; }

.fa-subscript {
  --fa: "\F12C";
  --fa--fa: "\F12C\F12C"; }

.fa-diamond-turn-right {
  --fa: "\F5EB";
  --fa--fa: "\F5EB\F5EB"; }

.fa-directions {
  --fa: "\F5EB";
  --fa--fa: "\F5EB\F5EB"; }

.fa-integral {
  --fa: "\F667";
  --fa--fa: "\F667\F667"; }

.fa-burst {
  --fa: "\E4DC";
  --fa--fa: "\E4DC\E4DC"; }

.fa-house-laptop {
  --fa: "\E066";
  --fa--fa: "\E066\E066"; }

.fa-laptop-house {
  --fa: "\E066";
  --fa--fa: "\E066\E066"; }

.fa-face-tired {
  --fa: "\F5C8";
  --fa--fa: "\F5C8\F5C8"; }

.fa-tired {
  --fa: "\F5C8";
  --fa--fa: "\F5C8\F5C8"; }

.fa-money-bills {
  --fa: "\E1F3";
  --fa--fa: "\E1F3\E1F3"; }

.fa-blinds-raised {
  --fa: "\F8FD";
  --fa--fa: "\F8FD\F8FD"; }

.fa-smog {
  --fa: "\F75F";
  --fa--fa: "\F75F\F75F"; }

.fa-ufo-beam {
  --fa: "\E048";
  --fa--fa: "\E048\E048"; }

.fa-hydra {
  --fa: "\E686";
  --fa--fa: "\E686\E686"; }

.fa-circle-caret-up {
  --fa: "\F331";
  --fa--fa: "\F331\F331"; }

.fa-caret-circle-up {
  --fa: "\F331";
  --fa--fa: "\F331\F331"; }

.fa-user-vneck-hair-long {
  --fa: "\E463";
  --fa--fa: "\E463\E463"; }

.fa-square-a-lock {
  --fa: "\E44D";
  --fa--fa: "\E44D\E44D"; }

.fa-crutch {
  --fa: "\F7F7";
  --fa--fa: "\F7F7\F7F7"; }

.fa-gas-pump-slash {
  --fa: "\F5F4";
  --fa--fa: "\F5F4\F5F4"; }

.fa-cloud-arrow-up {
  --fa: "\F0EE";
  --fa--fa: "\F0EE\F0EE"; }

.fa-cloud-upload {
  --fa: "\F0EE";
  --fa--fa: "\F0EE\F0EE"; }

.fa-cloud-upload-alt {
  --fa: "\F0EE";
  --fa--fa: "\F0EE\F0EE"; }

.fa-palette {
  --fa: "\F53F";
  --fa--fa: "\F53F\F53F"; }

.fa-transporter-4 {
  --fa: "\E2A5";
  --fa--fa: "\E2A5\E2A5"; }

.fa-chart-mixed-up-circle-currency {
  --fa: "\E5D8";
  --fa--fa: "\E5D8\E5D8"; }

.fa-objects-align-right {
  --fa: "\E3BF";
  --fa--fa: "\E3BF\E3BF"; }

.fa-arrows-turn-right {
  --fa: "\E4C0";
  --fa--fa: "\E4C0\E4C0"; }

.fa-vest {
  --fa: "\E085";
  --fa--fa: "\E085\E085"; }

.fa-pig {
  --fa: "\F706";
  --fa--fa: "\F706\F706"; }

.fa-inbox-full {
  --fa: "\E1BA";
  --fa--fa: "\E1BA\E1BA"; }

.fa-circle-envelope {
  --fa: "\E10C";
  --fa--fa: "\E10C\E10C"; }

.fa-envelope-circle {
  --fa: "\E10C";
  --fa--fa: "\E10C\E10C"; }

.fa-triangle-person-digging {
  --fa: "\F85D";
  --fa--fa: "\F85D\F85D"; }

.fa-construction {
  --fa: "\F85D";
  --fa--fa: "\F85D\F85D"; }

.fa-ferry {
  --fa: "\E4EA";
  --fa--fa: "\E4EA\E4EA"; }

.fa-bullseye-arrow {
  --fa: "\F648";
  --fa--fa: "\F648\F648"; }

.fa-arrows-down-to-people {
  --fa: "\E4B9";
  --fa--fa: "\E4B9\E4B9"; }

.fa-seedling {
  --fa: "\F4D8";
  --fa--fa: "\F4D8\F4D8"; }

.fa-sprout {
  --fa: "\F4D8";
  --fa--fa: "\F4D8\F4D8"; }

.fa-clock-seven {
  --fa: "\E350";
  --fa--fa: "\E350\E350"; }

.fa-left-right {
  --fa: "\F337";
  --fa--fa: "\F337\F337"; }

.fa-arrows-alt-h {
  --fa: "\F337";
  --fa--fa: "\F337\F337"; }

.fa-boxes-packing {
  --fa: "\E4C7";
  --fa--fa: "\E4C7\E4C7"; }

.fa-circle-arrow-left {
  --fa: "\F0A8";
  --fa--fa: "\F0A8\F0A8"; }

.fa-arrow-circle-left {
  --fa: "\F0A8";
  --fa--fa: "\F0A8\F0A8"; }

.fa-flashlight {
  --fa: "\F8B8";
  --fa--fa: "\F8B8\F8B8"; }

.fa-file-jpg {
  --fa: "\E646";
  --fa--fa: "\E646\E646"; }

.fa-group-arrows-rotate {
  --fa: "\E4F6";
  --fa--fa: "\E4F6\E4F6"; }

.fa-bowl-food {
  --fa: "\E4C6";
  --fa--fa: "\E4C6\E4C6"; }

.fa-square-9 {
  --fa: "\E25E";
  --fa--fa: "\E25E\E25E"; }

.fa-candy-cane {
  --fa: "\F786";
  --fa--fa: "\F786\F786"; }

.fa-arrow-down-wide-short {
  --fa: "\F160";
  --fa--fa: "\F160\F160"; }

.fa-sort-amount-asc {
  --fa: "\F160";
  --fa--fa: "\F160\F160"; }

.fa-sort-amount-down {
  --fa: "\F160";
  --fa--fa: "\F160\F160"; }

.fa-square-dollar {
  --fa: "\F2E9";
  --fa--fa: "\F2E9\F2E9"; }

.fa-dollar-square {
  --fa: "\F2E9";
  --fa--fa: "\F2E9\F2E9"; }

.fa-usd-square {
  --fa: "\F2E9";
  --fa--fa: "\F2E9\F2E9"; }

.fa-phone-arrow-right {
  --fa: "\E5BE";
  --fa--fa: "\E5BE\E5BE"; }

.fa-hand-holding-seedling {
  --fa: "\F4BF";
  --fa--fa: "\F4BF\F4BF"; }

.fa-message-check {
  --fa: "\F4A2";
  --fa--fa: "\F4A2\F4A2"; }

.fa-comment-alt-check {
  --fa: "\F4A2";
  --fa--fa: "\F4A2\F4A2"; }

.fa-cloud-bolt {
  --fa: "\F76C";
  --fa--fa: "\F76C\F76C"; }

.fa-thunderstorm {
  --fa: "\F76C";
  --fa--fa: "\F76C\F76C"; }

.fa-chart-line-up-down {
  --fa: "\E5D7";
  --fa--fa: "\E5D7\E5D7"; }

.fa-text-slash {
  --fa: "\F87D";
  --fa--fa: "\F87D\F87D"; }

.fa-remove-format {
  --fa: "\F87D";
  --fa--fa: "\F87D\F87D"; }

.fa-watch {
  --fa: "\F2E1";
  --fa--fa: "\F2E1\F2E1"; }

.fa-circle-down-left {
  --fa: "\E107";
  --fa--fa: "\E107\E107"; }

.fa-text {
  --fa: "\F893";
  --fa--fa: "\F893\F893"; }

.fa-projector {
  --fa: "\F8D6";
  --fa--fa: "\F8D6\F8D6"; }

.fa-face-smile-wink {
  --fa: "\F4DA";
  --fa--fa: "\F4DA\F4DA"; }

.fa-smile-wink {
  --fa: "\F4DA";
  --fa--fa: "\F4DA\F4DA"; }

.fa-tombstone-blank {
  --fa: "\F721";
  --fa--fa: "\F721\F721"; }

.fa-tombstone-alt {
  --fa: "\F721";
  --fa--fa: "\F721\F721"; }

.fa-chess-king-piece {
  --fa: "\F440";
  --fa--fa: "\F440\F440"; }

.fa-chess-king-alt {
  --fa: "\F440";
  --fa--fa: "\F440\F440"; }

.fa-circle-6 {
  --fa: "\E0F3";
  --fa--fa: "\E0F3\E0F3"; }

.fa-waves-sine {
  --fa: "\E65D";
  --fa--fa: "\E65D\E65D"; }

.fa-left {
  --fa: "\F355";
  --fa--fa: "\F355\F355"; }

.fa-arrow-alt-left {
  --fa: "\F355";
  --fa--fa: "\F355\F355"; }

.fa-file-word {
  --fa: "\F1C2";
  --fa--fa: "\F1C2\F1C2"; }

.fa-file-powerpoint {
  --fa: "\F1C4";
  --fa--fa: "\F1C4\F1C4"; }

.fa-square-down {
  --fa: "\F350";
  --fa--fa: "\F350\F350"; }

.fa-arrow-alt-square-down {
  --fa: "\F350";
  --fa--fa: "\F350\F350"; }

.fa-objects-align-center-vertical {
  --fa: "\E3BD";
  --fa--fa: "\E3BD\E3BD"; }

.fa-arrows-left-right {
  --fa: "\F07E";
  --fa--fa: "\F07E\F07E"; }

.fa-arrows-h {
  --fa: "\F07E";
  --fa--fa: "\F07E\F07E"; }

.fa-house-lock {
  --fa: "\E510";
  --fa--fa: "\E510\E510"; }

.fa-cloud-arrow-down {
  --fa: "\F0ED";
  --fa--fa: "\F0ED\F0ED"; }

.fa-cloud-download {
  --fa: "\F0ED";
  --fa--fa: "\F0ED\F0ED"; }

.fa-cloud-download-alt {
  --fa: "\F0ED";
  --fa--fa: "\F0ED\F0ED"; }

.fa-wreath {
  --fa: "\F7E2";
  --fa--fa: "\F7E2\F7E2"; }

.fa-children {
  --fa: "\E4E1";
  --fa--fa: "\E4E1\E4E1"; }

.fa-meter-droplet {
  --fa: "\E1EA";
  --fa--fa: "\E1EA\E1EA"; }

.fa-chalkboard {
  --fa: "\F51B";
  --fa--fa: "\F51B\F51B"; }

.fa-blackboard {
  --fa: "\F51B";
  --fa--fa: "\F51B\F51B"; }

.fa-user-large-slash {
  --fa: "\F4FA";
  --fa--fa: "\F4FA\F4FA"; }

.fa-user-alt-slash {
  --fa: "\F4FA";
  --fa--fa: "\F4FA\F4FA"; }

.fa-signal-strong {
  --fa: "\F68F";
  --fa--fa: "\F68F\F68F"; }

.fa-signal-4 {
  --fa: "\F68F";
  --fa--fa: "\F68F\F68F"; }

.fa-lollipop {
  --fa: "\E424";
  --fa--fa: "\E424\E424"; }

.fa-lollypop {
  --fa: "\E424";
  --fa--fa: "\E424\E424"; }

.fa-list-tree {
  --fa: "\E1D2";
  --fa--fa: "\E1D2\E1D2"; }

.fa-envelope-open {
  --fa: "\F2B6";
  --fa--fa: "\F2B6\F2B6"; }

.fa-draw-circle {
  --fa: "\F5ED";
  --fa--fa: "\F5ED\F5ED"; }

.fa-cat-space {
  --fa: "\E001";
  --fa--fa: "\E001\E001"; }

.fa-handshake-simple-slash {
  --fa: "\E05F";
  --fa--fa: "\E05F\E05F"; }

.fa-handshake-alt-slash {
  --fa: "\E05F";
  --fa--fa: "\E05F\E05F"; }

.fa-rabbit-running {
  --fa: "\F709";
  --fa--fa: "\F709\F709"; }

.fa-rabbit-fast {
  --fa: "\F709";
  --fa--fa: "\F709\F709"; }

.fa-memo-pad {
  --fa: "\E1DA";
  --fa--fa: "\E1DA\E1DA"; }

.fa-mattress-pillow {
  --fa: "\E525";
  --fa--fa: "\E525\E525"; }

.fa-alarm-plus {
  --fa: "\F844";
  --fa--fa: "\F844\F844"; }

.fa-alicorn {
  --fa: "\F6B0";
  --fa--fa: "\F6B0\F6B0"; }

.fa-comment-question {
  --fa: "\E14B";
  --fa--fa: "\E14B\E14B"; }

.fa-gingerbread-man {
  --fa: "\F79D";
  --fa--fa: "\F79D\F79D"; }

.fa-guarani-sign {
  --fa: "\E19A";
  --fa--fa: "\E19A\E19A"; }

.fa-burger-fries {
  --fa: "\E0CD";
  --fa--fa: "\E0CD\E0CD"; }

.fa-mug-tea {
  --fa: "\F875";
  --fa--fa: "\F875\F875"; }

.fa-border-top {
  --fa: "\F855";
  --fa--fa: "\F855\F855"; }

.fa-arrows-rotate {
  --fa: "\F021";
  --fa--fa: "\F021\F021"; }

.fa-refresh {
  --fa: "\F021";
  --fa--fa: "\F021\F021"; }

.fa-sync {
  --fa: "\F021";
  --fa--fa: "\F021\F021"; }

.fa-circle-book-open {
  --fa: "\E0FF";
  --fa--fa: "\E0FF\E0FF"; }

.fa-book-circle {
  --fa: "\E0FF";
  --fa--fa: "\E0FF\E0FF"; }

.fa-arrows-to-dotted-line {
  --fa: "\E0A6";
  --fa--fa: "\E0A6\E0A6"; }

.fa-fire-extinguisher {
  --fa: "\F134";
  --fa--fa: "\F134\F134"; }

.fa-magnifying-glass-arrows-rotate {
  --fa: "\E65E";
  --fa--fa: "\E65E\E65E"; }

.fa-garage-open {
  --fa: "\E00B";
  --fa--fa: "\E00B\E00B"; }

.fa-shelves-empty {
  --fa: "\E246";
  --fa--fa: "\E246\E246"; }

.fa-cruzeiro-sign {
  --fa: "\E152";
  --fa--fa: "\E152\E152"; }

.fa-watch-apple {
  --fa: "\E2CB";
  --fa--fa: "\E2CB\E2CB"; }

.fa-watch-calculator {
  --fa: "\F8F0";
  --fa--fa: "\F8F0\F8F0"; }

.fa-list-dropdown {
  --fa: "\E1CF";
  --fa--fa: "\E1CF\E1CF"; }

.fa-cabinet-filing {
  --fa: "\F64B";
  --fa--fa: "\F64B\F64B"; }

.fa-burger-soda {
  --fa: "\F858";
  --fa--fa: "\F858\F858"; }

.fa-square-arrow-up {
  --fa: "\F33C";
  --fa--fa: "\F33C\F33C"; }

.fa-arrow-square-up {
  --fa: "\F33C";
  --fa--fa: "\F33C\F33C"; }

.fa-greater-than-equal {
  --fa: "\F532";
  --fa--fa: "\F532\F532"; }

.fa-pallet-box {
  --fa: "\E208";
  --fa--fa: "\E208\E208"; }

.fa-face-confounded {
  --fa: "\E36C";
  --fa--fa: "\E36C\E36C"; }

.fa-shield-halved {
  --fa: "\F3ED";
  --fa--fa: "\F3ED\F3ED"; }

.fa-shield-alt {
  --fa: "\F3ED";
  --fa--fa: "\F3ED\F3ED"; }

.fa-truck-plow {
  --fa: "\F7DE";
  --fa--fa: "\F7DE\F7DE"; }

.fa-book-atlas {
  --fa: "\F558";
  --fa--fa: "\F558\F558"; }

.fa-atlas {
  --fa: "\F558";
  --fa--fa: "\F558\F558"; }

.fa-virus {
  --fa: "\E074";
  --fa--fa: "\E074\E074"; }

.fa-grid-round-2 {
  --fa: "\E5DB";
  --fa--fa: "\E5DB\E5DB"; }

.fa-comment-middle-top {
  --fa: "\E14A";
  --fa--fa: "\E14A\E14A"; }

.fa-wave {
  --fa: "\E65B";
  --fa--fa: "\E65B\E65B"; }

.fa-envelope-circle-check {
  --fa: "\E4E8";
  --fa--fa: "\E4E8\E4E8"; }

.fa-layer-group {
  --fa: "\F5FD";
  --fa--fa: "\F5FD\F5FD"; }

.fa-restroom-simple {
  --fa: "\E23A";
  --fa--fa: "\E23A\E23A"; }

.fa-arrows-to-dot {
  --fa: "\E4BE";
  --fa--fa: "\E4BE\E4BE"; }

.fa-border-outer {
  --fa: "\F851";
  --fa--fa: "\F851\F851"; }

.fa-hashtag-lock {
  --fa: "\E415";
  --fa--fa: "\E415\E415"; }

.fa-clock-two-thirty {
  --fa: "\E35B";
  --fa--fa: "\E35B\E35B"; }

.fa-archway {
  --fa: "\F557";
  --fa--fa: "\F557\F557"; }

.fa-heart-circle-check {
  --fa: "\E4FD";
  --fa--fa: "\E4FD\E4FD"; }

.fa-house-chimney-crack {
  --fa: "\F6F1";
  --fa--fa: "\F6F1\F6F1"; }

.fa-house-damage {
  --fa: "\F6F1";
  --fa--fa: "\F6F1\F6F1"; }

.fa-file-zipper {
  --fa: "\F1C6";
  --fa--fa: "\F1C6\F1C6"; }

.fa-file-archive {
  --fa: "\F1C6";
  --fa--fa: "\F1C6\F1C6"; }

.fa-ticket-perforated {
  --fa: "\E63E";
  --fa--fa: "\E63E\E63E"; }

.fa-heart-half {
  --fa: "\E1AB";
  --fa--fa: "\E1AB\E1AB"; }

.fa-comment-check {
  --fa: "\F4AC";
  --fa--fa: "\F4AC\F4AC"; }

.fa-square {
  --fa: "\F0C8";
  --fa--fa: "\F0C8\F0C8"; }

.fa-memo {
  --fa: "\E1D8";
  --fa--fa: "\E1D8\E1D8"; }

.fa-martini-glass-empty {
  --fa: "\F000";
  --fa--fa: "\F000\F000"; }

.fa-glass-martini {
  --fa: "\F000";
  --fa--fa: "\F000\F000"; }

.fa-couch {
  --fa: "\F4B8";
  --fa--fa: "\F4B8\F4B8"; }

.fa-cedi-sign {
  --fa: "\E0DF";
  --fa--fa: "\E0DF\E0DF"; }

.fa-italic {
  --fa: "\F033";
  --fa--fa: "\F033\F033"; }

.fa-glass-citrus {
  --fa: "\F869";
  --fa--fa: "\F869\F869"; }

.fa-calendar-lines-pen {
  --fa: "\E472";
  --fa--fa: "\E472\E472"; }

.fa-table-cells-column-lock {
  --fa: "\E678";
  --fa--fa: "\E678\E678"; }

.fa-church {
  --fa: "\F51D";
  --fa--fa: "\F51D\F51D"; }

.fa-person-snowmobiling {
  --fa: "\F7D1";
  --fa--fa: "\F7D1\F7D1"; }

.fa-snowmobile {
  --fa: "\F7D1";
  --fa--fa: "\F7D1\F7D1"; }

.fa-face-hushed {
  --fa: "\E37B";
  --fa--fa: "\E37B\E37B"; }

.fa-comments-dollar {
  --fa: "\F653";
  --fa--fa: "\F653\F653"; }

.fa-tickets-simple {
  --fa: "\E659";
  --fa--fa: "\E659\E659"; }

.fa-pickaxe {
  --fa: "\E5BF";
  --fa--fa: "\E5BF\E5BF"; }

.fa-link-simple-slash {
  --fa: "\E1CE";
  --fa--fa: "\E1CE\E1CE"; }

.fa-democrat {
  --fa: "\F747";
  --fa--fa: "\F747\F747"; }

.fa-face-confused {
  --fa: "\E36D";
  --fa--fa: "\E36D\E36D"; }

.fa-pinball {
  --fa: "\E229";
  --fa--fa: "\E229\E229"; }

.fa-z {
  --fa: "Z";
  --fa--fa: "ZZ"; }

.fa-person-skiing {
  --fa: "\F7C9";
  --fa--fa: "\F7C9\F7C9"; }

.fa-skiing {
  --fa: "\F7C9";
  --fa--fa: "\F7C9\F7C9"; }

.fa-deer {
  --fa: "\F78E";
  --fa--fa: "\F78E\F78E"; }

.fa-input-pipe {
  --fa: "\E1BE";
  --fa--fa: "\E1BE\E1BE"; }

.fa-road-lock {
  --fa: "\E567";
  --fa--fa: "\E567\E567"; }

.fa-a {
  --fa: "A";
  --fa--fa: "AA"; }

.fa-bookmark-slash {
  --fa: "\E0C2";
  --fa--fa: "\E0C2\E0C2"; }

.fa-temperature-arrow-down {
  --fa: "\E03F";
  --fa--fa: "\E03F\E03F"; }

.fa-temperature-down {
  --fa: "\E03F";
  --fa--fa: "\E03F\E03F"; }

.fa-mace {
  --fa: "\F6F8";
  --fa--fa: "\F6F8\F6F8"; }

.fa-feather-pointed {
  --fa: "\F56B";
  --fa--fa: "\F56B\F56B"; }

.fa-feather-alt {
  --fa: "\F56B";
  --fa--fa: "\F56B\F56B"; }

.fa-sausage {
  --fa: "\F820";
  --fa--fa: "\F820\F820"; }

.fa-trash-can-clock {
  --fa: "\E2AA";
  --fa--fa: "\E2AA\E2AA"; }

.fa-p {
  --fa: "P";
  --fa--fa: "PP"; }

.fa-broom-wide {
  --fa: "\E5D1";
  --fa--fa: "\E5D1\E5D1"; }

.fa-snowflake {
  --fa: "\F2DC";
  --fa--fa: "\F2DC\F2DC"; }

.fa-stomach {
  --fa: "\F623";
  --fa--fa: "\F623\F623"; }

.fa-newspaper {
  --fa: "\F1EA";
  --fa--fa: "\F1EA\F1EA"; }

.fa-rectangle-ad {
  --fa: "\F641";
  --fa--fa: "\F641\F641"; }

.fa-ad {
  --fa: "\F641";
  --fa--fa: "\F641\F641"; }

.fa-guitar-electric {
  --fa: "\F8BE";
  --fa--fa: "\F8BE\F8BE"; }

.fa-arrow-turn-down-right {
  --fa: "\E3D6";
  --fa--fa: "\E3D6\E3D6"; }

.fa-moon-cloud {
  --fa: "\F754";
  --fa--fa: "\F754\F754"; }

.fa-bread-slice-butter {
  --fa: "\E3E1";
  --fa--fa: "\E3E1\E3E1"; }

.fa-circle-arrow-right {
  --fa: "\F0A9";
  --fa--fa: "\F0A9\F0A9"; }

.fa-arrow-circle-right {
  --fa: "\F0A9";
  --fa--fa: "\F0A9\F0A9"; }

.fa-user-group-crown {
  --fa: "\F6A5";
  --fa--fa: "\F6A5\F6A5"; }

.fa-users-crown {
  --fa: "\F6A5";
  --fa--fa: "\F6A5\F6A5"; }

.fa-circle-i {
  --fa: "\E111";
  --fa--fa: "\E111\E111"; }

.fa-toilet-paper-check {
  --fa: "\E5B2";
  --fa--fa: "\E5B2\E5B2"; }

.fa-filter-circle-xmark {
  --fa: "\E17B";
  --fa--fa: "\E17B\E17B"; }

.fa-locust {
  --fa: "\E520";
  --fa--fa: "\E520\E520"; }

.fa-sort {
  --fa: "\F0DC";
  --fa--fa: "\F0DC\F0DC"; }

.fa-unsorted {
  --fa: "\F0DC";
  --fa--fa: "\F0DC\F0DC"; }

.fa-list-ol {
  --fa: "\F0CB";
  --fa--fa: "\F0CB\F0CB"; }

.fa-list-1-2 {
  --fa: "\F0CB";
  --fa--fa: "\F0CB\F0CB"; }

.fa-list-numeric {
  --fa: "\F0CB";
  --fa--fa: "\F0CB\F0CB"; }

.fa-chart-waterfall {
  --fa: "\E0EB";
  --fa--fa: "\E0EB\E0EB"; }

.fa-sparkle {
  --fa: "\E5D6";
  --fa--fa: "\E5D6\E5D6"; }

.fa-face-party {
  --fa: "\E383";
  --fa--fa: "\E383\E383"; }

.fa-kidneys {
  --fa: "\F5FB";
  --fa--fa: "\F5FB\F5FB"; }

.fa-wifi-exclamation {
  --fa: "\E2CF";
  --fa--fa: "\E2CF\E2CF"; }

.fa-chart-network {
  --fa: "\F78A";
  --fa--fa: "\F78A\F78A"; }

.fa-person-dress-burst {
  --fa: "\E544";
  --fa--fa: "\E544\E544"; }

.fa-dice-d4 {
  --fa: "\F6D0";
  --fa--fa: "\F6D0\F6D0"; }

.fa-money-check-dollar {
  --fa: "\F53D";
  --fa--fa: "\F53D\F53D"; }

.fa-money-check-alt {
  --fa: "\F53D";
  --fa--fa: "\F53D\F53D"; }

.fa-vector-square {
  --fa: "\F5CB";
  --fa--fa: "\F5CB\F5CB"; }

.fa-bread-slice {
  --fa: "\F7EC";
  --fa--fa: "\F7EC\F7EC"; }

.fa-language {
  --fa: "\F1AB";
  --fa--fa: "\F1AB\F1AB"; }

.fa-wheat-awn-slash {
  --fa: "\E338";
  --fa--fa: "\E338\E338"; }

.fa-face-kiss-wink-heart {
  --fa: "\F598";
  --fa--fa: "\F598\F598"; }

.fa-kiss-wink-heart {
  --fa: "\F598";
  --fa--fa: "\F598\F598"; }

.fa-dagger {
  --fa: "\F6CB";
  --fa--fa: "\F6CB\F6CB"; }

.fa-podium {
  --fa: "\F680";
  --fa--fa: "\F680\F680"; }

.fa-diamonds-4 {
  --fa: "\E68B";
  --fa--fa: "\E68B\E68B"; }

.fa-memo-circle-check {
  --fa: "\E1D9";
  --fa--fa: "\E1D9\E1D9"; }

.fa-route-highway {
  --fa: "\F61A";
  --fa--fa: "\F61A\F61A"; }

.fa-down-to-line {
  --fa: "\F34A";
  --fa--fa: "\F34A\F34A"; }

.fa-arrow-alt-to-bottom {
  --fa: "\F34A";
  --fa--fa: "\F34A\F34A"; }

.fa-filter {
  --fa: "\F0B0";
  --fa--fa: "\F0B0\F0B0"; }

.fa-square-g {
  --fa: "\E271";
  --fa--fa: "\E271\E271"; }

.fa-circle-phone {
  --fa: "\E11B";
  --fa--fa: "\E11B\E11B"; }

.fa-phone-circle {
  --fa: "\E11B";
  --fa--fa: "\E11B\E11B"; }

.fa-clipboard-prescription {
  --fa: "\F5E8";
  --fa--fa: "\F5E8\F5E8"; }

.fa-user-nurse-hair {
  --fa: "\E45D";
  --fa--fa: "\E45D\E45D"; }

.fa-question {
  --fa: "?";
  --fa--fa: "??"; }

.fa-file-signature {
  --fa: "\F573";
  --fa--fa: "\F573\F573"; }

.fa-toggle-large-on {
  --fa: "\E5B1";
  --fa--fa: "\E5B1\E5B1"; }

.fa-up-down-left-right {
  --fa: "\F0B2";
  --fa--fa: "\F0B2\F0B2"; }

.fa-arrows-alt {
  --fa: "\F0B2";
  --fa--fa: "\F0B2\F0B2"; }

.fa-dryer-heat {
  --fa: "\F862";
  --fa--fa: "\F862\F862"; }

.fa-dryer-alt {
  --fa: "\F862";
  --fa--fa: "\F862\F862"; }

.fa-house-chimney-user {
  --fa: "\E065";
  --fa--fa: "\E065\E065"; }

.fa-hand-holding-heart {
  --fa: "\F4BE";
  --fa--fa: "\F4BE\F4BE"; }

.fa-arrow-up-small-big {
  --fa: "\F88F";
  --fa--fa: "\F88F\F88F"; }

.fa-sort-size-up-alt {
  --fa: "\F88F";
  --fa--fa: "\F88F\F88F"; }

.fa-train-track {
  --fa: "\E453";
  --fa--fa: "\E453\E453"; }

.fa-puzzle-piece {
  --fa: "\F12E";
  --fa--fa: "\F12E\F12E"; }

.fa-money-check {
  --fa: "\F53C";
  --fa--fa: "\F53C\F53C"; }

.fa-star-half-stroke {
  --fa: "\F5C0";
  --fa--fa: "\F5C0\F5C0"; }

.fa-star-half-alt {
  --fa: "\F5C0";
  --fa--fa: "\F5C0\F5C0"; }

.fa-file-exclamation {
  --fa: "\F31A";
  --fa--fa: "\F31A\F31A"; }

.fa-code {
  --fa: "\F121";
  --fa--fa: "\F121\F121"; }

.fa-whiskey-glass {
  --fa: "\F7A0";
  --fa--fa: "\F7A0\F7A0"; }

.fa-glass-whiskey {
  --fa: "\F7A0";
  --fa--fa: "\F7A0\F7A0"; }

.fa-moon-stars {
  --fa: "\F755";
  --fa--fa: "\F755\F755"; }

.fa-building-circle-exclamation {
  --fa: "\E4D3";
  --fa--fa: "\E4D3\E4D3"; }

.fa-clothes-hanger {
  --fa: "\E136";
  --fa--fa: "\E136\E136"; }

.fa-mobile-notch {
  --fa: "\E1EE";
  --fa--fa: "\E1EE\E1EE"; }

.fa-mobile-iphone {
  --fa: "\E1EE";
  --fa--fa: "\E1EE\E1EE"; }

.fa-magnifying-glass-chart {
  --fa: "\E522";
  --fa--fa: "\E522\E522"; }

.fa-arrow-up-right-from-square {
  --fa: "\F08E";
  --fa--fa: "\F08E\F08E"; }

.fa-external-link {
  --fa: "\F08E";
  --fa--fa: "\F08E\F08E"; }

.fa-cubes-stacked {
  --fa: "\E4E6";
  --fa--fa: "\E4E6\E4E6"; }

.fa-images-user {
  --fa: "\E1B9";
  --fa--fa: "\E1B9\E1B9"; }

.fa-won-sign {
  --fa: "\F159";
  --fa--fa: "\F159\F159"; }

.fa-krw {
  --fa: "\F159";
  --fa--fa: "\F159\F159"; }

.fa-won {
  --fa: "\F159";
  --fa--fa: "\F159\F159"; }

.fa-image-polaroid-user {
  --fa: "\E1B6";
  --fa--fa: "\E1B6\E1B6"; }

.fa-virus-covid {
  --fa: "\E4A8";
  --fa--fa: "\E4A8\E4A8"; }

.fa-square-ellipsis {
  --fa: "\E26E";
  --fa--fa: "\E26E\E26E"; }

.fa-pie {
  --fa: "\F705";
  --fa--fa: "\F705\F705"; }

.fa-chess-knight-piece {
  --fa: "\F442";
  --fa--fa: "\F442\F442"; }

.fa-chess-knight-alt {
  --fa: "\F442";
  --fa--fa: "\F442\F442"; }

.fa-austral-sign {
  --fa: "\E0A9";
  --fa--fa: "\E0A9\E0A9"; }

.fa-cloud-plus {
  --fa: "\E35E";
  --fa--fa: "\E35E\E35E"; }

.fa-f {
  --fa: "F";
  --fa--fa: "FF"; }

.fa-leaf {
  --fa: "\F06C";
  --fa--fa: "\F06C\F06C"; }

.fa-bed-bunk {
  --fa: "\F8F8";
  --fa--fa: "\F8F8\F8F8"; }

.fa-road {
  --fa: "\F018";
  --fa--fa: "\F018\F018"; }

.fa-taxi {
  --fa: "\F1BA";
  --fa--fa: "\F1BA\F1BA"; }

.fa-cab {
  --fa: "\F1BA";
  --fa--fa: "\F1BA\F1BA"; }

.fa-person-circle-plus {
  --fa: "\E541";
  --fa--fa: "\E541\E541"; }

.fa-chart-pie {
  --fa: "\F200";
  --fa--fa: "\F200\F200"; }

.fa-pie-chart {
  --fa: "\F200";
  --fa--fa: "\F200\F200"; }

.fa-bolt-lightning {
  --fa: "\E0B7";
  --fa--fa: "\E0B7\E0B7"; }

.fa-clock-eight {
  --fa: "\E345";
  --fa--fa: "\E345\E345"; }

.fa-sack-xmark {
  --fa: "\E56A";
  --fa--fa: "\E56A\E56A"; }

.fa-file-xls {
  --fa: "\E64D";
  --fa--fa: "\E64D\E64D"; }

.fa-file-excel {
  --fa: "\F1C3";
  --fa--fa: "\F1C3\F1C3"; }

.fa-file-contract {
  --fa: "\F56C";
  --fa--fa: "\F56C\F56C"; }

.fa-fish-fins {
  --fa: "\E4F2";
  --fa--fa: "\E4F2\E4F2"; }

.fa-circle-q {
  --fa: "\E11E";
  --fa--fa: "\E11E\E11E"; }

.fa-building-flag {
  --fa: "\E4D5";
  --fa--fa: "\E4D5\E4D5"; }

.fa-face-grin-beam {
  --fa: "\F582";
  --fa--fa: "\F582\F582"; }

.fa-grin-beam {
  --fa: "\F582";
  --fa--fa: "\F582\F582"; }

.fa-object-ungroup {
  --fa: "\F248";
  --fa--fa: "\F248\F248"; }

.fa-face-disguise {
  --fa: "\E370";
  --fa--fa: "\E370\E370"; }

.fa-circle-arrow-down-right {
  --fa: "\E0FA";
  --fa--fa: "\E0FA\E0FA"; }

.fa-alien-8bit {
  --fa: "\F8F6";
  --fa--fa: "\F8F6\F8F6"; }

.fa-alien-monster {
  --fa: "\F8F6";
  --fa--fa: "\F8F6\F8F6"; }

.fa-hand-point-ribbon {
  --fa: "\E1A6";
  --fa--fa: "\E1A6\E1A6"; }

.fa-poop {
  --fa: "\F619";
  --fa--fa: "\F619\F619"; }

.fa-object-exclude {
  --fa: "\E49C";
  --fa--fa: "\E49C\E49C"; }

.fa-telescope {
  --fa: "\E03E";
  --fa--fa: "\E03E\E03E"; }

.fa-location-pin {
  --fa: "\F041";
  --fa--fa: "\F041\F041"; }

.fa-map-marker {
  --fa: "\F041";
  --fa--fa: "\F041\F041"; }

.fa-square-list {
  --fa: "\E489";
  --fa--fa: "\E489\E489"; }

.fa-kaaba {
  --fa: "\F66B";
  --fa--fa: "\F66B\F66B"; }

.fa-toilet-paper {
  --fa: "\F71E";
  --fa--fa: "\F71E\F71E"; }

.fa-helmet-safety {
  --fa: "\F807";
  --fa--fa: "\F807\F807"; }

.fa-hard-hat {
  --fa: "\F807";
  --fa--fa: "\F807\F807"; }

.fa-hat-hard {
  --fa: "\F807";
  --fa--fa: "\F807\F807"; }

.fa-comment-code {
  --fa: "\E147";
  --fa--fa: "\E147\E147"; }

.fa-sim-cards {
  --fa: "\E251";
  --fa--fa: "\E251\E251"; }

.fa-starship {
  --fa: "\E039";
  --fa--fa: "\E039\E039"; }

.fa-eject {
  --fa: "\F052";
  --fa--fa: "\F052\F052"; }

.fa-circle-right {
  --fa: "\F35A";
  --fa--fa: "\F35A\F35A"; }

.fa-arrow-alt-circle-right {
  --fa: "\F35A";
  --fa--fa: "\F35A\F35A"; }

.fa-plane-circle-check {
  --fa: "\E555";
  --fa--fa: "\E555\E555"; }

.fa-seal {
  --fa: "\E241";
  --fa--fa: "\E241\E241"; }

.fa-user-cowboy {
  --fa: "\F8EA";
  --fa--fa: "\F8EA\F8EA"; }

.fa-hexagon-vertical-nft {
  --fa: "\E505";
  --fa--fa: "\E505\E505"; }

.fa-face-rolling-eyes {
  --fa: "\F5A5";
  --fa--fa: "\F5A5\F5A5"; }

.fa-meh-rolling-eyes {
  --fa: "\F5A5";
  --fa--fa: "\F5A5\F5A5"; }

.fa-bread-loaf {
  --fa: "\F7EB";
  --fa--fa: "\F7EB\F7EB"; }

.fa-rings-wedding {
  --fa: "\F81B";
  --fa--fa: "\F81B\F81B"; }

.fa-object-group {
  --fa: "\F247";
  --fa--fa: "\F247\F247"; }

.fa-french-fries {
  --fa: "\F803";
  --fa--fa: "\F803\F803"; }

.fa-chart-line {
  --fa: "\F201";
  --fa--fa: "\F201\F201"; }

.fa-line-chart {
  --fa: "\F201";
  --fa--fa: "\F201\F201"; }

.fa-calendar-arrow-down {
  --fa: "\E0D0";
  --fa--fa: "\E0D0\E0D0"; }

.fa-calendar-download {
  --fa: "\E0D0";
  --fa--fa: "\E0D0\E0D0"; }

.fa-send-back {
  --fa: "\F87E";
  --fa--fa: "\F87E\F87E"; }

.fa-mask-ventilator {
  --fa: "\E524";
  --fa--fa: "\E524\E524"; }

.fa-tickets {
  --fa: "\E658";
  --fa--fa: "\E658\E658"; }

.fa-signature-lock {
  --fa: "\E3CA";
  --fa--fa: "\E3CA\E3CA"; }

.fa-arrow-right {
  --fa: "\F061";
  --fa--fa: "\F061\F061"; }

.fa-signs-post {
  --fa: "\F277";
  --fa--fa: "\F277\F277"; }

.fa-map-signs {
  --fa: "\F277";
  --fa--fa: "\F277\F277"; }

.fa-octagon-plus {
  --fa: "\F301";
  --fa--fa: "\F301\F301"; }

.fa-plus-octagon {
  --fa: "\F301";
  --fa--fa: "\F301\F301"; }

.fa-cash-register {
  --fa: "\F788";
  --fa--fa: "\F788\F788"; }

.fa-person-circle-question {
  --fa: "\E542";
  --fa--fa: "\E542\E542"; }

.fa-melon-slice {
  --fa: "\E311";
  --fa--fa: "\E311\E311"; }

.fa-space-station-moon {
  --fa: "\E033";
  --fa--fa: "\E033\E033"; }

.fa-message-smile {
  --fa: "\F4AA";
  --fa--fa: "\F4AA\F4AA"; }

.fa-comment-alt-smile {
  --fa: "\F4AA";
  --fa--fa: "\F4AA\F4AA"; }

.fa-cup-straw {
  --fa: "\E363";
  --fa--fa: "\E363\E363"; }

.fa-left-from-line {
  --fa: "\F348";
  --fa--fa: "\F348\F348"; }

.fa-arrow-alt-from-right {
  --fa: "\F348";
  --fa--fa: "\F348\F348"; }

.fa-h {
  --fa: "H";
  --fa--fa: "HH"; }

.fa-basket-shopping-simple {
  --fa: "\E0AF";
  --fa--fa: "\E0AF\E0AF"; }

.fa-shopping-basket-alt {
  --fa: "\E0AF";
  --fa--fa: "\E0AF\E0AF"; }

.fa-hands-holding-heart {
  --fa: "\F4C3";
  --fa--fa: "\F4C3\F4C3"; }

.fa-hands-heart {
  --fa: "\F4C3";
  --fa--fa: "\F4C3\F4C3"; }

.fa-clock-nine {
  --fa: "\E34C";
  --fa--fa: "\E34C\E34C"; }

.fa-hammer-brush {
  --fa: "\E620";
  --fa--fa: "\E620\E620"; }

.fa-tarp {
  --fa: "\E57B";
  --fa--fa: "\E57B\E57B"; }

.fa-face-sleepy {
  --fa: "\E38E";
  --fa--fa: "\E38E\E38E"; }

.fa-hand-horns {
  --fa: "\E1A9";
  --fa--fa: "\E1A9\E1A9"; }

.fa-screwdriver-wrench {
  --fa: "\F7D9";
  --fa--fa: "\F7D9\F7D9"; }

.fa-tools {
  --fa: "\F7D9";
  --fa--fa: "\F7D9\F7D9"; }

.fa-arrows-to-eye {
  --fa: "\E4BF";
  --fa--fa: "\E4BF\E4BF"; }

.fa-circle-three-quarters {
  --fa: "\E125";
  --fa--fa: "\E125\E125"; }

.fa-trophy-star {
  --fa: "\F2EB";
  --fa--fa: "\F2EB\F2EB"; }

.fa-trophy-alt {
  --fa: "\F2EB";
  --fa--fa: "\F2EB\F2EB"; }

.fa-plug-circle-bolt {
  --fa: "\E55B";
  --fa--fa: "\E55B\E55B"; }

.fa-face-thermometer {
  --fa: "\E39A";
  --fa--fa: "\E39A\E39A"; }

.fa-grid-round-4 {
  --fa: "\E5DD";
  --fa--fa: "\E5DD\E5DD"; }

.fa-sign-posts-wrench {
  --fa: "\E626";
  --fa--fa: "\E626\E626"; }

.fa-shirt-running {
  --fa: "\E3C8";
  --fa--fa: "\E3C8\E3C8"; }

.fa-book-circle-arrow-up {
  --fa: "\E0BD";
  --fa--fa: "\E0BD\E0BD"; }

.fa-face-nauseated {
  --fa: "\E381";
  --fa--fa: "\E381\E381"; }

.fa-heart {
  --fa: "\F004";
  --fa--fa: "\F004\F004"; }

.fa-file-chart-pie {
  --fa: "\F65A";
  --fa--fa: "\F65A\F65A"; }

.fa-mars-and-venus {
  --fa: "\F224";
  --fa--fa: "\F224\F224"; }

.fa-house-user {
  --fa: "\E1B0";
  --fa--fa: "\E1B0\E1B0"; }

.fa-home-user {
  --fa: "\E1B0";
  --fa--fa: "\E1B0\E1B0"; }

.fa-circle-arrow-down-left {
  --fa: "\E0F9";
  --fa--fa: "\E0F9\E0F9"; }

.fa-dumpster-fire {
  --fa: "\F794";
  --fa--fa: "\F794\F794"; }

.fa-hexagon-minus {
  --fa: "\F307";
  --fa--fa: "\F307\F307"; }

.fa-minus-hexagon {
  --fa: "\F307";
  --fa--fa: "\F307\F307"; }

.fa-left-to-line {
  --fa: "\F34B";
  --fa--fa: "\F34B\F34B"; }

.fa-arrow-alt-to-left {
  --fa: "\F34B";
  --fa--fa: "\F34B\F34B"; }

.fa-house-crack {
  --fa: "\E3B1";
  --fa--fa: "\E3B1\E3B1"; }

.fa-paw-simple {
  --fa: "\F701";
  --fa--fa: "\F701\F701"; }

.fa-paw-alt {
  --fa: "\F701";
  --fa--fa: "\F701\F701"; }

.fa-arrow-left-long-to-line {
  --fa: "\E3D4";
  --fa--fa: "\E3D4\E3D4"; }

.fa-brackets-round {
  --fa: "\E0C5";
  --fa--fa: "\E0C5\E0C5"; }

.fa-parentheses {
  --fa: "\E0C5";
  --fa--fa: "\E0C5\E0C5"; }

.fa-martini-glass-citrus {
  --fa: "\F561";
  --fa--fa: "\F561\F561"; }

.fa-cocktail {
  --fa: "\F561";
  --fa--fa: "\F561\F561"; }

.fa-user-shakespeare {
  --fa: "\E2C2";
  --fa--fa: "\E2C2\E2C2"; }

.fa-arrow-right-to-arc {
  --fa: "\E4B2";
  --fa--fa: "\E4B2\E4B2"; }

.fa-face-surprise {
  --fa: "\F5C2";
  --fa--fa: "\F5C2\F5C2"; }

.fa-surprise {
  --fa: "\F5C2";
  --fa--fa: "\F5C2\F5C2"; }

.fa-bottle-water {
  --fa: "\E4C5";
  --fa--fa: "\E4C5\E4C5"; }

.fa-circle-pause {
  --fa: "\F28B";
  --fa--fa: "\F28B\F28B"; }

.fa-pause-circle {
  --fa: "\F28B";
  --fa--fa: "\F28B\F28B"; }

.fa-gauge-circle-plus {
  --fa: "\E498";
  --fa--fa: "\E498\E498"; }

.fa-folders {
  --fa: "\F660";
  --fa--fa: "\F660\F660"; }

.fa-angel {
  --fa: "\F779";
  --fa--fa: "\F779\F779"; }

.fa-value-absolute {
  --fa: "\F6A6";
  --fa--fa: "\F6A6\F6A6"; }

.fa-rabbit {
  --fa: "\F708";
  --fa--fa: "\F708\F708"; }

.fa-toilet-paper-slash {
  --fa: "\E072";
  --fa--fa: "\E072\E072"; }

.fa-circle-euro {
  --fa: "\E5CE";
  --fa--fa: "\E5CE\E5CE"; }

.fa-apple-whole {
  --fa: "\F5D1";
  --fa--fa: "\F5D1\F5D1"; }

.fa-apple-alt {
  --fa: "\F5D1";
  --fa--fa: "\F5D1\F5D1"; }

.fa-kitchen-set {
  --fa: "\E51A";
  --fa--fa: "\E51A\E51A"; }

.fa-diamond-half {
  --fa: "\E5B7";
  --fa--fa: "\E5B7\E5B7"; }

.fa-lock-keyhole {
  --fa: "\F30D";
  --fa--fa: "\F30D\F30D"; }

.fa-lock-alt {
  --fa: "\F30D";
  --fa--fa: "\F30D\F30D"; }

.fa-r {
  --fa: "R";
  --fa--fa: "RR"; }

.fa-temperature-quarter {
  --fa: "\F2CA";
  --fa--fa: "\F2CA\F2CA"; }

.fa-temperature-1 {
  --fa: "\F2CA";
  --fa--fa: "\F2CA\F2CA"; }

.fa-thermometer-1 {
  --fa: "\F2CA";
  --fa--fa: "\F2CA\F2CA"; }

.fa-thermometer-quarter {
  --fa: "\F2CA";
  --fa--fa: "\F2CA\F2CA"; }

.fa-square-info {
  --fa: "\F30F";
  --fa--fa: "\F30F\F30F"; }

.fa-info-square {
  --fa: "\F30F";
  --fa--fa: "\F30F\F30F"; }

.fa-wifi-slash {
  --fa: "\F6AC";
  --fa--fa: "\F6AC\F6AC"; }

.fa-toilet-paper-xmark {
  --fa: "\E5B3";
  --fa--fa: "\E5B3\E5B3"; }

.fa-hands-holding-dollar {
  --fa: "\F4C5";
  --fa--fa: "\F4C5\F4C5"; }

.fa-hands-usd {
  --fa: "\F4C5";
  --fa--fa: "\F4C5\F4C5"; }

.fa-cube {
  --fa: "\F1B2";
  --fa--fa: "\F1B2\F1B2"; }

.fa-arrow-down-triangle-square {
  --fa: "\F888";
  --fa--fa: "\F888\F888"; }

.fa-sort-shapes-down {
  --fa: "\F888";
  --fa--fa: "\F888\F888"; }

.fa-bitcoin-sign {
  --fa: "\E0B4";
  --fa--fa: "\E0B4\E0B4"; }

.fa-shutters {
  --fa: "\E449";
  --fa--fa: "\E449\E449"; }

.fa-shield-dog {
  --fa: "\E573";
  --fa--fa: "\E573\E573"; }

.fa-solar-panel {
  --fa: "\F5BA";
  --fa--fa: "\F5BA\F5BA"; }

.fa-lock-open {
  --fa: "\F3C1";
  --fa--fa: "\F3C1\F3C1"; }

.fa-table-tree {
  --fa: "\E293";
  --fa--fa: "\E293\E293"; }

.fa-house-chimney-heart {
  --fa: "\E1B2";
  --fa--fa: "\E1B2\E1B2"; }

.fa-tally-3 {
  --fa: "\E296";
  --fa--fa: "\E296\E296"; }

.fa-elevator {
  --fa: "\E16D";
  --fa--fa: "\E16D\E16D"; }

.fa-money-bill-transfer {
  --fa: "\E528";
  --fa--fa: "\E528\E528"; }

.fa-money-bill-trend-up {
  --fa: "\E529";
  --fa--fa: "\E529\E529"; }

.fa-house-flood-water-circle-arrow-right {
  --fa: "\E50F";
  --fa--fa: "\E50F\E50F"; }

.fa-square-poll-horizontal {
  --fa: "\F682";
  --fa--fa: "\F682\F682"; }

.fa-poll-h {
  --fa: "\F682";
  --fa--fa: "\F682\F682"; }

.fa-circle {
  --fa: "\F111";
  --fa--fa: "\F111\F111"; }

.fa-left-to-bracket {
  --fa: "\E66D";
  --fa--fa: "\E66D\E66D"; }

.fa-cart-circle-exclamation {
  --fa: "\E3F2";
  --fa--fa: "\E3F2\E3F2"; }

.fa-sword {
  --fa: "\F71C";
  --fa--fa: "\F71C\F71C"; }

.fa-backward-fast {
  --fa: "\F049";
  --fa--fa: "\F049\F049"; }

.fa-fast-backward {
  --fa: "\F049";
  --fa--fa: "\F049\F049"; }

.fa-recycle {
  --fa: "\F1B8";
  --fa--fa: "\F1B8\F1B8"; }

.fa-user-astronaut {
  --fa: "\F4FB";
  --fa--fa: "\F4FB\F4FB"; }

.fa-interrobang {
  --fa: "\E5BA";
  --fa--fa: "\E5BA\E5BA"; }

.fa-plane-slash {
  --fa: "\E069";
  --fa--fa: "\E069\E069"; }

.fa-circle-dashed {
  --fa: "\E105";
  --fa--fa: "\E105\E105"; }

.fa-trademark {
  --fa: "\F25C";
  --fa--fa: "\F25C\F25C"; }

.fa-basketball {
  --fa: "\F434";
  --fa--fa: "\F434\F434"; }

.fa-basketball-ball {
  --fa: "\F434";
  --fa--fa: "\F434\F434"; }

.fa-fork-knife {
  --fa: "\F2E6";
  --fa--fa: "\F2E6\F2E6"; }

.fa-utensils-alt {
  --fa: "\F2E6";
  --fa--fa: "\F2E6\F2E6"; }

.fa-satellite-dish {
  --fa: "\F7C0";
  --fa--fa: "\F7C0\F7C0"; }

.fa-badge-check {
  --fa: "\F336";
  --fa--fa: "\F336\F336"; }

.fa-circle-up {
  --fa: "\F35B";
  --fa--fa: "\F35B\F35B"; }

.fa-arrow-alt-circle-up {
  --fa: "\F35B";
  --fa--fa: "\F35B\F35B"; }

.fa-slider {
  --fa: "\E252";
  --fa--fa: "\E252\E252"; }

.fa-mobile-screen-button {
  --fa: "\F3CD";
  --fa--fa: "\F3CD\F3CD"; }

.fa-mobile-alt {
  --fa: "\F3CD";
  --fa--fa: "\F3CD\F3CD"; }

.fa-clock-one-thirty {
  --fa: "\E34F";
  --fa--fa: "\E34F\E34F"; }

.fa-inbox-out {
  --fa: "\F311";
  --fa--fa: "\F311\F311"; }

.fa-inbox-arrow-up {
  --fa: "\F311";
  --fa--fa: "\F311\F311"; }

.fa-cloud-slash {
  --fa: "\E137";
  --fa--fa: "\E137\E137"; }

.fa-volume-high {
  --fa: "\F028";
  --fa--fa: "\F028\F028"; }

.fa-volume-up {
  --fa: "\F028";
  --fa--fa: "\F028\F028"; }

.fa-users-rays {
  --fa: "\E593";
  --fa--fa: "\E593\E593"; }

.fa-wallet {
  --fa: "\F555";
  --fa--fa: "\F555\F555"; }

.fa-octagon-check {
  --fa: "\E426";
  --fa--fa: "\E426\E426"; }

.fa-flatbread-stuffed {
  --fa: "\E40C";
  --fa--fa: "\E40C\E40C"; }

.fa-clipboard-check {
  --fa: "\F46C";
  --fa--fa: "\F46C\F46C"; }

.fa-cart-circle-plus {
  --fa: "\E3F3";
  --fa--fa: "\E3F3\E3F3"; }

.fa-truck-clock {
  --fa: "\F48C";
  --fa--fa: "\F48C\F48C"; }

.fa-shipping-timed {
  --fa: "\F48C";
  --fa--fa: "\F48C\F48C"; }

.fa-pool-8-ball {
  --fa: "\E3C5";
  --fa--fa: "\E3C5\E3C5"; }

.fa-file-audio {
  --fa: "\F1C7";
  --fa--fa: "\F1C7\F1C7"; }

.fa-turn-down-left {
  --fa: "\E331";
  --fa--fa: "\E331\E331"; }

.fa-lock-hashtag {
  --fa: "\E423";
  --fa--fa: "\E423\E423"; }

.fa-chart-radar {
  --fa: "\E0E7";
  --fa--fa: "\E0E7\E0E7"; }

.fa-staff {
  --fa: "\F71B";
  --fa--fa: "\F71B\F71B"; }

.fa-burger {
  --fa: "\F805";
  --fa--fa: "\F805\F805"; }

.fa-hamburger {
  --fa: "\F805";
  --fa--fa: "\F805\F805"; }

.fa-utility-pole {
  --fa: "\E2C3";
  --fa--fa: "\E2C3\E2C3"; }

.fa-transporter-6 {
  --fa: "\E2A7";
  --fa--fa: "\E2A7\E2A7"; }

.fa-arrow-turn-left {
  --fa: "\E632";
  --fa--fa: "\E632\E632"; }

.fa-wrench {
  --fa: "\F0AD";
  --fa--fa: "\F0AD\F0AD"; }

.fa-bugs {
  --fa: "\E4D0";
  --fa--fa: "\E4D0\E4D0"; }

.fa-vector-polygon {
  --fa: "\E2C7";
  --fa--fa: "\E2C7\E2C7"; }

.fa-diagram-nested {
  --fa: "\E157";
  --fa--fa: "\E157\E157"; }

.fa-rupee-sign {
  --fa: "\F156";
  --fa--fa: "\F156\F156"; }

.fa-rupee {
  --fa: "\F156";
  --fa--fa: "\F156\F156"; }

.fa-file-image {
  --fa: "\F1C5";
  --fa--fa: "\F1C5\F1C5"; }

.fa-circle-question {
  --fa: "\F059";
  --fa--fa: "\F059\F059"; }

.fa-question-circle {
  --fa: "\F059";
  --fa--fa: "\F059\F059"; }

.fa-tickets-perforated {
  --fa: "\E63F";
  --fa--fa: "\E63F\E63F"; }

.fa-image-user {
  --fa: "\E1B8";
  --fa--fa: "\E1B8\E1B8"; }

.fa-buoy {
  --fa: "\E5B5";
  --fa--fa: "\E5B5\E5B5"; }

.fa-plane-departure {
  --fa: "\F5B0";
  --fa--fa: "\F5B0\F5B0"; }

.fa-handshake-slash {
  --fa: "\E060";
  --fa--fa: "\E060\E060"; }

.fa-book-bookmark {
  --fa: "\E0BB";
  --fa--fa: "\E0BB\E0BB"; }

.fa-border-center-h {
  --fa: "\F89C";
  --fa--fa: "\F89C\F89C"; }

.fa-can-food {
  --fa: "\E3E6";
  --fa--fa: "\E3E6\E3E6"; }

.fa-typewriter {
  --fa: "\F8E7";
  --fa--fa: "\F8E7\F8E7"; }

.fa-arrow-right-from-arc {
  --fa: "\E4B1";
  --fa--fa: "\E4B1\E4B1"; }

.fa-circle-k {
  --fa: "\E113";
  --fa--fa: "\E113\E113"; }

.fa-face-hand-over-mouth {
  --fa: "\E378";
  --fa--fa: "\E378\E378"; }

.fa-popcorn {
  --fa: "\F819";
  --fa--fa: "\F819\F819"; }

.fa-house-water {
  --fa: "\F74F";
  --fa--fa: "\F74F\F74F"; }

.fa-house-flood {
  --fa: "\F74F";
  --fa--fa: "\F74F\F74F"; }

.fa-object-subtract {
  --fa: "\E49E";
  --fa--fa: "\E49E\E49E"; }

.fa-code-branch {
  --fa: "\F126";
  --fa--fa: "\F126\F126"; }

.fa-warehouse-full {
  --fa: "\F495";
  --fa--fa: "\F495\F495"; }

.fa-warehouse-alt {
  --fa: "\F495";
  --fa--fa: "\F495\F495"; }

.fa-hat-cowboy {
  --fa: "\F8C0";
  --fa--fa: "\F8C0\F8C0"; }

.fa-bridge {
  --fa: "\E4C8";
  --fa--fa: "\E4C8\E4C8"; }

.fa-phone-flip {
  --fa: "\F879";
  --fa--fa: "\F879\F879"; }

.fa-phone-alt {
  --fa: "\F879";
  --fa--fa: "\F879\F879"; }

.fa-arrow-down-from-dotted-line {
  --fa: "\E090";
  --fa--fa: "\E090\E090"; }

.fa-file-doc {
  --fa: "\E5ED";
  --fa--fa: "\E5ED\E5ED"; }

.fa-square-quarters {
  --fa: "\E44E";
  --fa--fa: "\E44E\E44E"; }

.fa-truck-front {
  --fa: "\E2B7";
  --fa--fa: "\E2B7\E2B7"; }

.fa-cat {
  --fa: "\F6BE";
  --fa--fa: "\F6BE\F6BE"; }

.fa-trash-xmark {
  --fa: "\E2B4";
  --fa--fa: "\E2B4\E2B4"; }

.fa-circle-caret-left {
  --fa: "\F32E";
  --fa--fa: "\F32E\F32E"; }

.fa-caret-circle-left {
  --fa: "\F32E";
  --fa--fa: "\F32E\F32E"; }

.fa-files {
  --fa: "\E178";
  --fa--fa: "\E178\E178"; }

.fa-anchor-circle-exclamation {
  --fa: "\E4AB";
  --fa--fa: "\E4AB\E4AB"; }

.fa-face-clouds {
  --fa: "\E47D";
  --fa--fa: "\E47D\E47D"; }

.fa-user-crown {
  --fa: "\F6A4";
  --fa--fa: "\F6A4\F6A4"; }

.fa-basket-shopping-plus {
  --fa: "\E653";
  --fa--fa: "\E653\E653"; }

.fa-truck-field {
  --fa: "\E58D";
  --fa--fa: "\E58D\E58D"; }

.fa-route {
  --fa: "\F4D7";
  --fa--fa: "\F4D7\F4D7"; }

.fa-cart-circle-check {
  --fa: "\E3F1";
  --fa--fa: "\E3F1\E3F1"; }

.fa-clipboard-question {
  --fa: "\E4E3";
  --fa--fa: "\E4E3\E4E3"; }

.fa-panorama {
  --fa: "\E209";
  --fa--fa: "\E209\E209"; }

.fa-comment-medical {
  --fa: "\F7F5";
  --fa--fa: "\F7F5\F7F5"; }

.fa-teeth-open {
  --fa: "\F62F";
  --fa--fa: "\F62F\F62F"; }

.fa-user-tie-hair-long {
  --fa: "\E460";
  --fa--fa: "\E460\E460"; }

.fa-file-circle-minus {
  --fa: "\E4ED";
  --fa--fa: "\E4ED\E4ED"; }

.fa-head-side-medical {
  --fa: "\F809";
  --fa--fa: "\F809\F809"; }

.fa-arrow-turn-right {
  --fa: "\E635";
  --fa--fa: "\E635\E635"; }

.fa-tags {
  --fa: "\F02C";
  --fa--fa: "\F02C\F02C"; }

.fa-wine-glass {
  --fa: "\F4E3";
  --fa--fa: "\F4E3\F4E3"; }

.fa-forward-fast {
  --fa: "\F050";
  --fa--fa: "\F050\F050"; }

.fa-fast-forward {
  --fa: "\F050";
  --fa--fa: "\F050\F050"; }

.fa-face-meh-blank {
  --fa: "\F5A4";
  --fa--fa: "\F5A4\F5A4"; }

.fa-meh-blank {
  --fa: "\F5A4";
  --fa--fa: "\F5A4\F5A4"; }

.fa-user-robot {
  --fa: "\E04B";
  --fa--fa: "\E04B\E04B"; }

.fa-square-parking {
  --fa: "\F540";
  --fa--fa: "\F540\F540"; }

.fa-parking {
  --fa: "\F540";
  --fa--fa: "\F540\F540"; }

.fa-card-diamond {
  --fa: "\E3EA";
  --fa--fa: "\E3EA\E3EA"; }

.fa-face-zipper {
  --fa: "\E3A5";
  --fa--fa: "\E3A5\E3A5"; }

.fa-face-raised-eyebrow {
  --fa: "\E388";
  --fa--fa: "\E388\E388"; }

.fa-house-signal {
  --fa: "\E012";
  --fa--fa: "\E012\E012"; }

.fa-square-chevron-up {
  --fa: "\F32C";
  --fa--fa: "\F32C\F32C"; }

.fa-chevron-square-up {
  --fa: "\F32C";
  --fa--fa: "\F32C\F32C"; }

.fa-bars-progress {
  --fa: "\F828";
  --fa--fa: "\F828\F828"; }

.fa-tasks-alt {
  --fa: "\F828";
  --fa--fa: "\F828\F828"; }

.fa-faucet-drip {
  --fa: "\E006";
  --fa--fa: "\E006\E006"; }

.fa-arrows-to-line {
  --fa: "\E0A7";
  --fa--fa: "\E0A7\E0A7"; }

.fa-dolphin {
  --fa: "\E168";
  --fa--fa: "\E168\E168"; }

.fa-arrow-up-right {
  --fa: "\E09F";
  --fa--fa: "\E09F\E09F"; }

.fa-circle-r {
  --fa: "\E120";
  --fa--fa: "\E120\E120"; }

.fa-cart-flatbed {
  --fa: "\F474";
  --fa--fa: "\F474\F474"; }

.fa-dolly-flatbed {
  --fa: "\F474";
  --fa--fa: "\F474\F474"; }

.fa-ban-smoking {
  --fa: "\F54D";
  --fa--fa: "\F54D\F54D"; }

.fa-smoking-ban {
  --fa: "\F54D";
  --fa--fa: "\F54D\F54D"; }

.fa-circle-sort-up {
  --fa: "\E032";
  --fa--fa: "\E032\E032"; }

.fa-sort-circle-up {
  --fa: "\E032";
  --fa--fa: "\E032\E032"; }

.fa-terminal {
  --fa: "\F120";
  --fa--fa: "\F120\F120"; }

.fa-mobile-button {
  --fa: "\F10B";
  --fa--fa: "\F10B\F10B"; }

.fa-house-medical-flag {
  --fa: "\E514";
  --fa--fa: "\E514\E514"; }

.fa-basket-shopping {
  --fa: "\F291";
  --fa--fa: "\F291\F291"; }

.fa-shopping-basket {
  --fa: "\F291";
  --fa--fa: "\F291\F291"; }

.fa-tape {
  --fa: "\F4DB";
  --fa--fa: "\F4DB\F4DB"; }

.fa-chestnut {
  --fa: "\E3F6";
  --fa--fa: "\E3F6\E3F6"; }

.fa-bus-simple {
  --fa: "\F55E";
  --fa--fa: "\F55E\F55E"; }

.fa-bus-alt {
  --fa: "\F55E";
  --fa--fa: "\F55E\F55E"; }

.fa-eye {
  --fa: "\F06E";
  --fa--fa: "\F06E\F06E"; }

.fa-face-sad-cry {
  --fa: "\F5B3";
  --fa--fa: "\F5B3\F5B3"; }

.fa-sad-cry {
  --fa: "\F5B3";
  --fa--fa: "\F5B3\F5B3"; }

.fa-heat {
  --fa: "\E00C";
  --fa--fa: "\E00C\E00C"; }

.fa-ticket-airline {
  --fa: "\E29A";
  --fa--fa: "\E29A\E29A"; }

.fa-ticket-perforated-plane {
  --fa: "\E29A";
  --fa--fa: "\E29A\E29A"; }

.fa-ticket-plane {
  --fa: "\E29A";
  --fa--fa: "\E29A\E29A"; }

.fa-boot-heeled {
  --fa: "\E33F";
  --fa--fa: "\E33F\E33F"; }

.fa-arrows-minimize {
  --fa: "\E0A5";
  --fa--fa: "\E0A5\E0A5"; }

.fa-compress-arrows {
  --fa: "\E0A5";
  --fa--fa: "\E0A5\E0A5"; }

.fa-audio-description {
  --fa: "\F29E";
  --fa--fa: "\F29E\F29E"; }

.fa-person-military-to-person {
  --fa: "\E54C";
  --fa--fa: "\E54C\E54C"; }

.fa-file-shield {
  --fa: "\E4F0";
  --fa--fa: "\E4F0\E4F0"; }

.fa-hexagon {
  --fa: "\F312";
  --fa--fa: "\F312\F312"; }

.fa-manhole {
  --fa: "\E1D6";
  --fa--fa: "\E1D6\E1D6"; }

.fa-user-slash {
  --fa: "\F506";
  --fa--fa: "\F506\F506"; }

.fa-pen {
  --fa: "\F304";
  --fa--fa: "\F304\F304"; }

.fa-tower-observation {
  --fa: "\E586";
  --fa--fa: "\E586\E586"; }

.fa-floppy-disks {
  --fa: "\E183";
  --fa--fa: "\E183\E183"; }

.fa-toilet-paper-blank-under {
  --fa: "\E29F";
  --fa--fa: "\E29F\E29F"; }

.fa-toilet-paper-reverse-alt {
  --fa: "\E29F";
  --fa--fa: "\E29F\E29F"; }

.fa-file-code {
  --fa: "\F1C9";
  --fa--fa: "\F1C9\F1C9"; }

.fa-signal {
  --fa: "\F012";
  --fa--fa: "\F012\F012"; }

.fa-signal-5 {
  --fa: "\F012";
  --fa--fa: "\F012\F012"; }

.fa-signal-perfect {
  --fa: "\F012";
  --fa--fa: "\F012\F012"; }

.fa-pump {
  --fa: "\E442";
  --fa--fa: "\E442\E442"; }

.fa-bus {
  --fa: "\F207";
  --fa--fa: "\F207\F207"; }

.fa-heart-circle-xmark {
  --fa: "\E501";
  --fa--fa: "\E501\E501"; }

.fa-arrow-up-left-from-circle {
  --fa: "\E09E";
  --fa--fa: "\E09E\E09E"; }

.fa-house-chimney {
  --fa: "\E3AF";
  --fa--fa: "\E3AF\E3AF"; }

.fa-home-lg {
  --fa: "\E3AF";
  --fa--fa: "\E3AF\E3AF"; }

.fa-window-maximize {
  --fa: "\F2D0";
  --fa--fa: "\F2D0\F2D0"; }

.fa-dryer {
  --fa: "\F861";
  --fa--fa: "\F861\F861"; }

.fa-face-frown {
  --fa: "\F119";
  --fa--fa: "\F119\F119"; }

.fa-frown {
  --fa: "\F119";
  --fa--fa: "\F119\F119"; }

.fa-chess-bishop-piece {
  --fa: "\F43B";
  --fa--fa: "\F43B\F43B"; }

.fa-chess-bishop-alt {
  --fa: "\F43B";
  --fa--fa: "\F43B\F43B"; }

.fa-shirt-tank-top {
  --fa: "\E3C9";
  --fa--fa: "\E3C9\E3C9"; }

.fa-diploma {
  --fa: "\F5EA";
  --fa--fa: "\F5EA\F5EA"; }

.fa-scroll-ribbon {
  --fa: "\F5EA";
  --fa--fa: "\F5EA\F5EA"; }

.fa-screencast {
  --fa: "\E23E";
  --fa--fa: "\E23E\E23E"; }

.fa-walker {
  --fa: "\F831";
  --fa--fa: "\F831\F831"; }

.fa-prescription {
  --fa: "\F5B1";
  --fa--fa: "\F5B1\F5B1"; }

.fa-shop {
  --fa: "\F54F";
  --fa--fa: "\F54F\F54F"; }

.fa-store-alt {
  --fa: "\F54F";
  --fa--fa: "\F54F\F54F"; }

.fa-floppy-disk {
  --fa: "\F0C7";
  --fa--fa: "\F0C7\F0C7"; }

.fa-save {
  --fa: "\F0C7";
  --fa--fa: "\F0C7\F0C7"; }

.fa-vihara {
  --fa: "\F6A7";
  --fa--fa: "\F6A7\F6A7"; }

.fa-face-kiss-closed-eyes {
  --fa: "\E37D";
  --fa--fa: "\E37D\E37D"; }

.fa-scale-unbalanced {
  --fa: "\F515";
  --fa--fa: "\F515\F515"; }

.fa-balance-scale-left {
  --fa: "\F515";
  --fa--fa: "\F515\F515"; }

.fa-file-user {
  --fa: "\F65C";
  --fa--fa: "\F65C\F65C"; }

.fa-user-police-tie {
  --fa: "\E334";
  --fa--fa: "\E334\E334"; }

.fa-face-tongue-money {
  --fa: "\E39D";
  --fa--fa: "\E39D\E39D"; }

.fa-tennis-ball {
  --fa: "\F45E";
  --fa--fa: "\F45E\F45E"; }

.fa-square-l {
  --fa: "\E275";
  --fa--fa: "\E275\E275"; }

.fa-sort-up {
  --fa: "\F0DE";
  --fa--fa: "\F0DE\F0DE"; }

.fa-sort-asc {
  --fa: "\F0DE";
  --fa--fa: "\F0DE\F0DE"; }

.fa-calendar-arrow-up {
  --fa: "\E0D1";
  --fa--fa: "\E0D1\E0D1"; }

.fa-calendar-upload {
  --fa: "\E0D1";
  --fa--fa: "\E0D1\E0D1"; }

.fa-comment-dots {
  --fa: "\F4AD";
  --fa--fa: "\F4AD\F4AD"; }

.fa-commenting {
  --fa: "\F4AD";
  --fa--fa: "\F4AD\F4AD"; }

.fa-plant-wilt {
  --fa: "\E5AA";
  --fa--fa: "\E5AA\E5AA"; }

.fa-scarf {
  --fa: "\F7C1";
  --fa--fa: "\F7C1\F7C1"; }

.fa-album-circle-plus {
  --fa: "\E48C";
  --fa--fa: "\E48C\E48C"; }

.fa-user-nurse-hair-long {
  --fa: "\E45E";
  --fa--fa: "\E45E\E45E"; }

.fa-diamond {
  --fa: "\F219";
  --fa--fa: "\F219\F219"; }

.fa-square-left {
  --fa: "\F351";
  --fa--fa: "\F351\F351"; }

.fa-arrow-alt-square-left {
  --fa: "\F351";
  --fa--fa: "\F351\F351"; }

.fa-face-grin-squint {
  --fa: "\F585";
  --fa--fa: "\F585\F585"; }

.fa-grin-squint {
  --fa: "\F585";
  --fa--fa: "\F585\F585"; }

.fa-circle-ellipsis-vertical {
  --fa: "\E10B";
  --fa--fa: "\E10B\E10B"; }

.fa-hand-holding-dollar {
  --fa: "\F4C0";
  --fa--fa: "\F4C0\F4C0"; }

.fa-hand-holding-usd {
  --fa: "\F4C0";
  --fa--fa: "\F4C0\F4C0"; }

.fa-grid-dividers {
  --fa: "\E3AD";
  --fa--fa: "\E3AD\E3AD"; }

.fa-chart-diagram {
  --fa: "\E695";
  --fa--fa: "\E695\E695"; }

.fa-bacterium {
  --fa: "\E05A";
  --fa--fa: "\E05A\E05A"; }

.fa-hand-pointer {
  --fa: "\F25A";
  --fa--fa: "\F25A\F25A"; }

.fa-drum-steelpan {
  --fa: "\F56A";
  --fa--fa: "\F56A\F56A"; }

.fa-hand-scissors {
  --fa: "\F257";
  --fa--fa: "\F257\F257"; }

.fa-hands-praying {
  --fa: "\F684";
  --fa--fa: "\F684\F684"; }

.fa-praying-hands {
  --fa: "\F684";
  --fa--fa: "\F684\F684"; }

.fa-face-pensive {
  --fa: "\E384";
  --fa--fa: "\E384\E384"; }

.fa-user-music {
  --fa: "\F8EB";
  --fa--fa: "\F8EB\F8EB"; }

.fa-arrow-rotate-right {
  --fa: "\F01E";
  --fa--fa: "\F01E\F01E"; }

.fa-arrow-right-rotate {
  --fa: "\F01E";
  --fa--fa: "\F01E\F01E"; }

.fa-arrow-rotate-forward {
  --fa: "\F01E";
  --fa--fa: "\F01E\F01E"; }

.fa-redo {
  --fa: "\F01E";
  --fa--fa: "\F01E\F01E"; }

.fa-messages-dollar {
  --fa: "\F652";
  --fa--fa: "\F652\F652"; }

.fa-comments-alt-dollar {
  --fa: "\F652";
  --fa--fa: "\F652\F652"; }

.fa-sensor-on {
  --fa: "\E02B";
  --fa--fa: "\E02B\E02B"; }

.fa-balloon {
  --fa: "\E2E3";
  --fa--fa: "\E2E3\E2E3"; }

.fa-biohazard {
  --fa: "\F780";
  --fa--fa: "\F780\F780"; }

.fa-chess-queen-piece {
  --fa: "\F446";
  --fa--fa: "\F446\F446"; }

.fa-chess-queen-alt {
  --fa: "\F446";
  --fa--fa: "\F446\F446"; }

.fa-location-crosshairs {
  --fa: "\F601";
  --fa--fa: "\F601\F601"; }

.fa-location {
  --fa: "\F601";
  --fa--fa: "\F601\F601"; }

.fa-mars-double {
  --fa: "\F227";
  --fa--fa: "\F227\F227"; }

.fa-left-from-bracket {
  --fa: "\E66C";
  --fa--fa: "\E66C\E66C"; }

.fa-house-person-leave {
  --fa: "\E00F";
  --fa--fa: "\E00F\E00F"; }

.fa-house-leave {
  --fa: "\E00F";
  --fa--fa: "\E00F\E00F"; }

.fa-house-person-depart {
  --fa: "\E00F";
  --fa--fa: "\E00F\E00F"; }

.fa-ruler-triangle {
  --fa: "\F61C";
  --fa--fa: "\F61C\F61C"; }

.fa-card-club {
  --fa: "\E3E9";
  --fa--fa: "\E3E9\E3E9"; }

.fa-child-dress {
  --fa: "\E59C";
  --fa--fa: "\E59C\E59C"; }

.fa-users-between-lines {
  --fa: "\E591";
  --fa--fa: "\E591\E591"; }

.fa-lungs-virus {
  --fa: "\E067";
  --fa--fa: "\E067\E067"; }

.fa-spinner-third {
  --fa: "\F3F4";
  --fa--fa: "\F3F4\F3F4"; }

.fa-face-grin-tears {
  --fa: "\F588";
  --fa--fa: "\F588\F588"; }

.fa-grin-tears {
  --fa: "\F588";
  --fa--fa: "\F588\F588"; }

.fa-phone {
  --fa: "\F095";
  --fa--fa: "\F095\F095"; }

.fa-computer-mouse-scrollwheel {
  --fa: "\F8CD";
  --fa--fa: "\F8CD\F8CD"; }

.fa-mouse-alt {
  --fa: "\F8CD";
  --fa--fa: "\F8CD\F8CD"; }

.fa-calendar-xmark {
  --fa: "\F273";
  --fa--fa: "\F273\F273"; }

.fa-calendar-times {
  --fa: "\F273";
  --fa--fa: "\F273\F273"; }

.fa-child-reaching {
  --fa: "\E59D";
  --fa--fa: "\E59D\E59D"; }

.fa-table-layout {
  --fa: "\E290";
  --fa--fa: "\E290\E290"; }

.fa-narwhal {
  --fa: "\F6FE";
  --fa--fa: "\F6FE\F6FE"; }

.fa-ramp-loading {
  --fa: "\F4D4";
  --fa--fa: "\F4D4\F4D4"; }

.fa-calendar-circle-plus {
  --fa: "\E470";
  --fa--fa: "\E470\E470"; }

.fa-toothbrush {
  --fa: "\F635";
  --fa--fa: "\F635\F635"; }

.fa-border-inner {
  --fa: "\F84E";
  --fa--fa: "\F84E\F84E"; }

.fa-paw-claws {
  --fa: "\F702";
  --fa--fa: "\F702\F702"; }

.fa-kiwi-fruit {
  --fa: "\E30C";
  --fa--fa: "\E30C\E30C"; }

.fa-traffic-light-slow {
  --fa: "\F639";
  --fa--fa: "\F639\F639"; }

.fa-rectangle-code {
  --fa: "\E322";
  --fa--fa: "\E322\E322"; }

.fa-head-side-virus {
  --fa: "\E064";
  --fa--fa: "\E064\E064"; }

.fa-keyboard-brightness {
  --fa: "\E1C0";
  --fa--fa: "\E1C0\E1C0"; }

.fa-books-medical {
  --fa: "\F7E8";
  --fa--fa: "\F7E8\F7E8"; }

.fa-lightbulb-slash {
  --fa: "\F673";
  --fa--fa: "\F673\F673"; }

.fa-house-blank {
  --fa: "\E487";
  --fa--fa: "\E487\E487"; }

.fa-home-blank {
  --fa: "\E487";
  --fa--fa: "\E487\E487"; }

.fa-square-5 {
  --fa: "\E25A";
  --fa--fa: "\E25A\E25A"; }

.fa-square-heart {
  --fa: "\F4C8";
  --fa--fa: "\F4C8\F4C8"; }

.fa-heart-square {
  --fa: "\F4C8";
  --fa--fa: "\F4C8\F4C8"; }

.fa-puzzle {
  --fa: "\E443";
  --fa--fa: "\E443\E443"; }

.fa-user-gear {
  --fa: "\F4FE";
  --fa--fa: "\F4FE\F4FE"; }

.fa-user-cog {
  --fa: "\F4FE";
  --fa--fa: "\F4FE\F4FE"; }

.fa-pipe-circle-check {
  --fa: "\E436";
  --fa--fa: "\E436\E436"; }

.fa-arrow-up-1-9 {
  --fa: "\F163";
  --fa--fa: "\F163\F163"; }

.fa-sort-numeric-up {
  --fa: "\F163";
  --fa--fa: "\F163\F163"; }

.fa-octagon-exclamation {
  --fa: "\E204";
  --fa--fa: "\E204\E204"; }

.fa-dial-low {
  --fa: "\E15D";
  --fa--fa: "\E15D\E15D"; }

.fa-door-closed {
  --fa: "\F52A";
  --fa--fa: "\F52A\F52A"; }

.fa-laptop-mobile {
  --fa: "\F87A";
  --fa--fa: "\F87A\F87A"; }

.fa-phone-laptop {
  --fa: "\F87A";
  --fa--fa: "\F87A\F87A"; }

.fa-conveyor-belt-boxes {
  --fa: "\F46F";
  --fa--fa: "\F46F\F46F"; }

.fa-conveyor-belt-alt {
  --fa: "\F46F";
  --fa--fa: "\F46F\F46F"; }

.fa-shield-virus {
  --fa: "\E06C";
  --fa--fa: "\E06C\E06C"; }

.fa-starfighter-twin-ion-engine-advanced {
  --fa: "\E28E";
  --fa--fa: "\E28E\E28E"; }

.fa-starfighter-alt-advanced {
  --fa: "\E28E";
  --fa--fa: "\E28E\E28E"; }

.fa-dice-six {
  --fa: "\F526";
  --fa--fa: "\F526\F526"; }

.fa-starfighter-twin-ion-engine {
  --fa: "\E038";
  --fa--fa: "\E038\E038"; }

.fa-starfighter-alt {
  --fa: "\E038";
  --fa--fa: "\E038\E038"; }

.fa-rocket-launch {
  --fa: "\E027";
  --fa--fa: "\E027\E027"; }

.fa-mosquito-net {
  --fa: "\E52C";
  --fa--fa: "\E52C\E52C"; }

.fa-file-fragment {
  --fa: "\E697";
  --fa--fa: "\E697\E697"; }

.fa-vent-damper {
  --fa: "\E465";
  --fa--fa: "\E465\E465"; }

.fa-bridge-water {
  --fa: "\E4CE";
  --fa--fa: "\E4CE\E4CE"; }

.fa-ban-bug {
  --fa: "\F7F9";
  --fa--fa: "\F7F9\F7F9"; }

.fa-debug {
  --fa: "\F7F9";
  --fa--fa: "\F7F9\F7F9"; }

.fa-person-booth {
  --fa: "\F756";
  --fa--fa: "\F756\F756"; }

.fa-text-width {
  --fa: "\F035";
  --fa--fa: "\F035\F035"; }

.fa-garage-car {
  --fa: "\E00A";
  --fa--fa: "\E00A\E00A"; }

.fa-square-kanban {
  --fa: "\E488";
  --fa--fa: "\E488\E488"; }

.fa-hat-wizard {
  --fa: "\F6E8";
  --fa--fa: "\F6E8\F6E8"; }

.fa-chart-kanban {
  --fa: "\E64F";
  --fa--fa: "\E64F\E64F"; }

.fa-pen-fancy {
  --fa: "\F5AC";
  --fa--fa: "\F5AC\F5AC"; }

.fa-coffee-pot {
  --fa: "\E002";
  --fa--fa: "\E002\E002"; }

.fa-mouse-field {
  --fa: "\E5A8";
  --fa--fa: "\E5A8\E5A8"; }

.fa-person-digging {
  --fa: "\F85E";
  --fa--fa: "\F85E\F85E"; }

.fa-digging {
  --fa: "\F85E";
  --fa--fa: "\F85E\F85E"; }

.fa-shower-down {
  --fa: "\E24D";
  --fa--fa: "\E24D\E24D"; }

.fa-shower-alt {
  --fa: "\E24D";
  --fa--fa: "\E24D\E24D"; }

.fa-box-circle-check {
  --fa: "\E0C4";
  --fa--fa: "\E0C4\E0C4"; }

.fa-brightness {
  --fa: "\E0C9";
  --fa--fa: "\E0C9\E0C9"; }

.fa-car-side-bolt {
  --fa: "\E344";
  --fa--fa: "\E344\E344"; }

.fa-file-xml {
  --fa: "\E654";
  --fa--fa: "\E654\E654"; }

.fa-ornament {
  --fa: "\F7B8";
  --fa--fa: "\F7B8\F7B8"; }

.fa-phone-arrow-down-left {
  --fa: "\E223";
  --fa--fa: "\E223\E223"; }

.fa-phone-arrow-down {
  --fa: "\E223";
  --fa--fa: "\E223\E223"; }

.fa-phone-incoming {
  --fa: "\E223";
  --fa--fa: "\E223\E223"; }

.fa-cloud-word {
  --fa: "\E138";
  --fa--fa: "\E138\E138"; }

.fa-hand-fingers-crossed {
  --fa: "\E1A3";
  --fa--fa: "\E1A3\E1A3"; }

.fa-trash {
  --fa: "\F1F8";
  --fa--fa: "\F1F8\F1F8"; }

.fa-gauge-simple {
  --fa: "\F629";
  --fa--fa: "\F629\F629"; }

.fa-gauge-simple-med {
  --fa: "\F629";
  --fa--fa: "\F629\F629"; }

.fa-tachometer-average {
  --fa: "\F629";
  --fa--fa: "\F629\F629"; }

.fa-arrow-down-small-big {
  --fa: "\F88D";
  --fa--fa: "\F88D\F88D"; }

.fa-sort-size-down-alt {
  --fa: "\F88D";
  --fa--fa: "\F88D\F88D"; }

.fa-book-medical {
  --fa: "\F7E6";
  --fa--fa: "\F7E6\F7E6"; }

.fa-face-melting {
  --fa: "\E483";
  --fa--fa: "\E483\E483"; }

.fa-poo {
  --fa: "\F2FE";
  --fa--fa: "\F2FE\F2FE"; }

.fa-pen-clip-slash {
  --fa: "\E20F";
  --fa--fa: "\E20F\E20F"; }

.fa-pen-alt-slash {
  --fa: "\E20F";
  --fa--fa: "\E20F\E20F"; }

.fa-quote-right {
  --fa: "\F10E";
  --fa--fa: "\F10E\F10E"; }

.fa-quote-right-alt {
  --fa: "\F10E";
  --fa--fa: "\F10E\F10E"; }

.fa-scroll-old {
  --fa: "\F70F";
  --fa--fa: "\F70F\F70F"; }

.fa-guitars {
  --fa: "\F8BF";
  --fa--fa: "\F8BF\F8BF"; }

.fa-phone-xmark {
  --fa: "\E227";
  --fa--fa: "\E227\E227"; }

.fa-hose {
  --fa: "\E419";
  --fa--fa: "\E419\E419"; }

.fa-clock-six {
  --fa: "\E352";
  --fa--fa: "\E352\E352"; }

.fa-shirt {
  --fa: "\F553";
  --fa--fa: "\F553\F553"; }

.fa-t-shirt {
  --fa: "\F553";
  --fa--fa: "\F553\F553"; }

.fa-tshirt {
  --fa: "\F553";
  --fa--fa: "\F553\F553"; }

.fa-billboard {
  --fa: "\E5CD";
  --fa--fa: "\E5CD\E5CD"; }

.fa-square-r {
  --fa: "\E27C";
  --fa--fa: "\E27C\E27C"; }

.fa-cubes {
  --fa: "\F1B3";
  --fa--fa: "\F1B3\F1B3"; }

.fa-envelope-open-dollar {
  --fa: "\F657";
  --fa--fa: "\F657\F657"; }

.fa-divide {
  --fa: "\F529";
  --fa--fa: "\F529\F529"; }

.fa-sun-cloud {
  --fa: "\F763";
  --fa--fa: "\F763\F763"; }

.fa-lamp-floor {
  --fa: "\E015";
  --fa--fa: "\E015\E015"; }

.fa-square-7 {
  --fa: "\E25C";
  --fa--fa: "\E25C\E25C"; }

.fa-tenge-sign {
  --fa: "\F7D7";
  --fa--fa: "\F7D7\F7D7"; }

.fa-tenge {
  --fa: "\F7D7";
  --fa--fa: "\F7D7\F7D7"; }

.fa-headphones {
  --fa: "\F025";
  --fa--fa: "\F025\F025"; }

.fa-hands-holding {
  --fa: "\F4C2";
  --fa--fa: "\F4C2\F4C2"; }

.fa-campfire {
  --fa: "\F6BA";
  --fa--fa: "\F6BA\F6BA"; }

.fa-circle-ampersand {
  --fa: "\E0F8";
  --fa--fa: "\E0F8\E0F8"; }

.fa-snowflakes {
  --fa: "\F7CF";
  --fa--fa: "\F7CF\F7CF"; }

.fa-hands-clapping {
  --fa: "\E1A8";
  --fa--fa: "\E1A8\E1A8"; }

.fa-republican {
  --fa: "\F75E";
  --fa--fa: "\F75E\F75E"; }

.fa-leaf-maple {
  --fa: "\F6F6";
  --fa--fa: "\F6F6\F6F6"; }

.fa-arrow-left {
  --fa: "\F060";
  --fa--fa: "\F060\F060"; }

.fa-person-circle-xmark {
  --fa: "\E543";
  --fa--fa: "\E543\E543"; }

.fa-ruler {
  --fa: "\F545";
  --fa--fa: "\F545\F545"; }

.fa-arrow-left-from-bracket {
  --fa: "\E668";
  --fa--fa: "\E668\E668"; }

.fa-cup-straw-swoosh {
  --fa: "\E364";
  --fa--fa: "\E364\E364"; }

.fa-temperature-sun {
  --fa: "\F76A";
  --fa--fa: "\F76A\F76A"; }

.fa-temperature-hot {
  --fa: "\F76A";
  --fa--fa: "\F76A\F76A"; }

.fa-align-left {
  --fa: "\F036";
  --fa--fa: "\F036\F036"; }

.fa-dice-d6 {
  --fa: "\F6D1";
  --fa--fa: "\F6D1\F6D1"; }

.fa-restroom {
  --fa: "\F7BD";
  --fa--fa: "\F7BD\F7BD"; }

.fa-high-definition {
  --fa: "\E1AE";
  --fa--fa: "\E1AE\E1AE"; }

.fa-rectangle-hd {
  --fa: "\E1AE";
  --fa--fa: "\E1AE\E1AE"; }

.fa-j {
  --fa: "J";
  --fa--fa: "JJ"; }

.fa-galaxy {
  --fa: "\E008";
  --fa--fa: "\E008\E008"; }

.fa-users-viewfinder {
  --fa: "\E595";
  --fa--fa: "\E595\E595"; }

.fa-file-video {
  --fa: "\F1C8";
  --fa--fa: "\F1C8\F1C8"; }

.fa-cherries {
  --fa: "\E0EC";
  --fa--fa: "\E0EC\E0EC"; }

.fa-up-right-from-square {
  --fa: "\F35D";
  --fa--fa: "\F35D\F35D"; }

.fa-external-link-alt {
  --fa: "\F35D";
  --fa--fa: "\F35D\F35D"; }

.fa-circle-sort {
  --fa: "\E030";
  --fa--fa: "\E030\E030"; }

.fa-sort-circle {
  --fa: "\E030";
  --fa--fa: "\E030\E030"; }

.fa-table-cells {
  --fa: "\F00A";
  --fa--fa: "\F00A\F00A"; }

.fa-th {
  --fa: "\F00A";
  --fa--fa: "\F00A\F00A"; }

.fa-bag-shopping-minus {
  --fa: "\E650";
  --fa--fa: "\E650\E650"; }

.fa-file-pdf {
  --fa: "\F1C1";
  --fa--fa: "\F1C1\F1C1"; }

.fa-siren {
  --fa: "\E02D";
  --fa--fa: "\E02D\E02D"; }

.fa-arrow-up-to-dotted-line {
  --fa: "\E0A1";
  --fa--fa: "\E0A1\E0A1"; }

.fa-image-landscape {
  --fa: "\E1B5";
  --fa--fa: "\E1B5\E1B5"; }

.fa-landscape {
  --fa: "\E1B5";
  --fa--fa: "\E1B5\E1B5"; }

.fa-tank-water {
  --fa: "\E452";
  --fa--fa: "\E452\E452"; }

.fa-curling-stone {
  --fa: "\F44A";
  --fa--fa: "\F44A\F44A"; }

.fa-curling {
  --fa: "\F44A";
  --fa--fa: "\F44A\F44A"; }

.fa-gamepad-modern {
  --fa: "\E5A2";
  --fa--fa: "\E5A2\E5A2"; }

.fa-gamepad-alt {
  --fa: "\E5A2";
  --fa--fa: "\E5A2\E5A2"; }

.fa-messages-question {
  --fa: "\E1E7";
  --fa--fa: "\E1E7\E1E7"; }

.fa-book-bible {
  --fa: "\F647";
  --fa--fa: "\F647\F647"; }

.fa-bible {
  --fa: "\F647";
  --fa--fa: "\F647\F647"; }

.fa-o {
  --fa: "O";
  --fa--fa: "OO"; }

.fa-suitcase-medical {
  --fa: "\F0FA";
  --fa--fa: "\F0FA\F0FA"; }

.fa-medkit {
  --fa: "\F0FA";
  --fa--fa: "\F0FA\F0FA"; }

.fa-briefcase-arrow-right {
  --fa: "\E2F2";
  --fa--fa: "\E2F2\E2F2"; }

.fa-expand-wide {
  --fa: "\F320";
  --fa--fa: "\F320\F320"; }

.fa-clock-eleven-thirty {
  --fa: "\E348";
  --fa--fa: "\E348\E348"; }

.fa-rv {
  --fa: "\F7BE";
  --fa--fa: "\F7BE\F7BE"; }

.fa-user-secret {
  --fa: "\F21B";
  --fa--fa: "\F21B\F21B"; }

.fa-otter {
  --fa: "\F700";
  --fa--fa: "\F700\F700"; }

.fa-dreidel {
  --fa: "\F792";
  --fa--fa: "\F792\F792"; }

.fa-person-dress {
  --fa: "\F182";
  --fa--fa: "\F182\F182"; }

.fa-female {
  --fa: "\F182";
  --fa--fa: "\F182\F182"; }

.fa-comment-dollar {
  --fa: "\F651";
  --fa--fa: "\F651\F651"; }

.fa-business-time {
  --fa: "\F64A";
  --fa--fa: "\F64A\F64A"; }

.fa-briefcase-clock {
  --fa: "\F64A";
  --fa--fa: "\F64A\F64A"; }

.fa-flower-tulip {
  --fa: "\F801";
  --fa--fa: "\F801\F801"; }

.fa-people-pants-simple {
  --fa: "\E21A";
  --fa--fa: "\E21A\E21A"; }

.fa-cloud-drizzle {
  --fa: "\F738";
  --fa--fa: "\F738\F738"; }

.fa-table-cells-large {
  --fa: "\F009";
  --fa--fa: "\F009\F009"; }

.fa-th-large {
  --fa: "\F009";
  --fa--fa: "\F009\F009"; }

.fa-book-tanakh {
  --fa: "\F827";
  --fa--fa: "\F827\F827"; }

.fa-tanakh {
  --fa: "\F827";
  --fa--fa: "\F827\F827"; }

.fa-solar-system {
  --fa: "\E02F";
  --fa--fa: "\E02F\E02F"; }

.fa-seal-question {
  --fa: "\E243";
  --fa--fa: "\E243\E243"; }

.fa-phone-volume {
  --fa: "\F2A0";
  --fa--fa: "\F2A0\F2A0"; }

.fa-volume-control-phone {
  --fa: "\F2A0";
  --fa--fa: "\F2A0\F2A0"; }

.fa-disc-drive {
  --fa: "\F8B5";
  --fa--fa: "\F8B5\F8B5"; }

.fa-hat-cowboy-side {
  --fa: "\F8C1";
  --fa--fa: "\F8C1\F8C1"; }

.fa-table-rows {
  --fa: "\E292";
  --fa--fa: "\E292\E292"; }

.fa-rows {
  --fa: "\E292";
  --fa--fa: "\E292\E292"; }

.fa-location-exclamation {
  --fa: "\F608";
  --fa--fa: "\F608\F608"; }

.fa-map-marker-exclamation {
  --fa: "\F608";
  --fa--fa: "\F608\F608"; }

.fa-face-fearful {
  --fa: "\E375";
  --fa--fa: "\E375\E375"; }

.fa-clipboard-user {
  --fa: "\F7F3";
  --fa--fa: "\F7F3\F7F3"; }

.fa-bus-school {
  --fa: "\F5DD";
  --fa--fa: "\F5DD\F5DD"; }

.fa-film-slash {
  --fa: "\E179";
  --fa--fa: "\E179\E179"; }

.fa-square-arrow-down-right {
  --fa: "\E262";
  --fa--fa: "\E262\E262"; }

.fa-book-sparkles {
  --fa: "\F6B8";
  --fa--fa: "\F6B8\F6B8"; }

.fa-book-spells {
  --fa: "\F6B8";
  --fa--fa: "\F6B8\F6B8"; }

.fa-washing-machine {
  --fa: "\F898";
  --fa--fa: "\F898\F898"; }

.fa-washer {
  --fa: "\F898";
  --fa--fa: "\F898\F898"; }

.fa-child {
  --fa: "\F1AE";
  --fa--fa: "\F1AE\F1AE"; }

.fa-lira-sign {
  --fa: "\F195";
  --fa--fa: "\F195\F195"; }

.fa-user-visor {
  --fa: "\E04C";
  --fa--fa: "\E04C\E04C"; }

.fa-file-plus-minus {
  --fa: "\E177";
  --fa--fa: "\E177\E177"; }

.fa-chess-clock-flip {
  --fa: "\F43E";
  --fa--fa: "\F43E\F43E"; }

.fa-chess-clock-alt {
  --fa: "\F43E";
  --fa--fa: "\F43E\F43E"; }

.fa-satellite {
  --fa: "\F7BF";
  --fa--fa: "\F7BF\F7BF"; }

.fa-truck-fire {
  --fa: "\E65A";
  --fa--fa: "\E65A\E65A"; }

.fa-plane-lock {
  --fa: "\E558";
  --fa--fa: "\E558\E558"; }

.fa-steering-wheel {
  --fa: "\F622";
  --fa--fa: "\F622\F622"; }

.fa-tag {
  --fa: "\F02B";
  --fa--fa: "\F02B\F02B"; }

.fa-stretcher {
  --fa: "\F825";
  --fa--fa: "\F825\F825"; }

.fa-book-section {
  --fa: "\E0C1";
  --fa--fa: "\E0C1\E0C1"; }

.fa-book-law {
  --fa: "\E0C1";
  --fa--fa: "\E0C1\E0C1"; }

.fa-inboxes {
  --fa: "\E1BB";
  --fa--fa: "\E1BB\E1BB"; }

.fa-coffee-bean {
  --fa: "\E13E";
  --fa--fa: "\E13E\E13E"; }

.fa-circle-yen {
  --fa: "\E5D0";
  --fa--fa: "\E5D0\E5D0"; }

.fa-brackets-curly {
  --fa: "\F7EA";
  --fa--fa: "\F7EA\F7EA"; }

.fa-ellipsis-stroke-vertical {
  --fa: "\F39C";
  --fa--fa: "\F39C\F39C"; }

.fa-ellipsis-v-alt {
  --fa: "\F39C";
  --fa--fa: "\F39C\F39C"; }

.fa-comment {
  --fa: "\F075";
  --fa--fa: "\F075\F075"; }

.fa-square-1 {
  --fa: "\E256";
  --fa--fa: "\E256\E256"; }

.fa-cake-candles {
  --fa: "\F1FD";
  --fa--fa: "\F1FD\F1FD"; }

.fa-birthday-cake {
  --fa: "\F1FD";
  --fa--fa: "\F1FD\F1FD"; }

.fa-cake {
  --fa: "\F1FD";
  --fa--fa: "\F1FD\F1FD"; }

.fa-head-side {
  --fa: "\F6E9";
  --fa--fa: "\F6E9\F6E9"; }

.fa-truck-ladder {
  --fa: "\E657";
  --fa--fa: "\E657\E657"; }

.fa-envelope {
  --fa: "\F0E0";
  --fa--fa: "\F0E0\F0E0"; }

.fa-dolly-empty {
  --fa: "\F473";
  --fa--fa: "\F473\F473"; }

.fa-face-tissue {
  --fa: "\E39C";
  --fa--fa: "\E39C\E39C"; }

.fa-angles-up {
  --fa: "\F102";
  --fa--fa: "\F102\F102"; }

.fa-angle-double-up {
  --fa: "\F102";
  --fa--fa: "\F102\F102"; }

.fa-bin-recycle {
  --fa: "\E5F7";
  --fa--fa: "\E5F7\E5F7"; }

.fa-paperclip {
  --fa: "\F0C6";
  --fa--fa: "\F0C6\F0C6"; }

.fa-chart-line-down {
  --fa: "\F64D";
  --fa--fa: "\F64D\F64D"; }

.fa-arrow-right-to-city {
  --fa: "\E4B3";
  --fa--fa: "\E4B3\E4B3"; }

.fa-lock-a {
  --fa: "\E422";
  --fa--fa: "\E422\E422"; }

.fa-ribbon {
  --fa: "\F4D6";
  --fa--fa: "\F4D6\F4D6"; }

.fa-lungs {
  --fa: "\F604";
  --fa--fa: "\F604\F604"; }

.fa-person-pinball {
  --fa: "\E21D";
  --fa--fa: "\E21D\E21D"; }

.fa-arrow-up-9-1 {
  --fa: "\F887";
  --fa--fa: "\F887\F887"; }

.fa-sort-numeric-up-alt {
  --fa: "\F887";
  --fa--fa: "\F887\F887"; }

.fa-apple-core {
  --fa: "\E08F";
  --fa--fa: "\E08F\E08F"; }

.fa-circle-y {
  --fa: "\E12F";
  --fa--fa: "\E12F\E12F"; }

.fa-h6 {
  --fa: "\E413";
  --fa--fa: "\E413\E413"; }

.fa-litecoin-sign {
  --fa: "\E1D3";
  --fa--fa: "\E1D3\E1D3"; }

.fa-bottle-baby {
  --fa: "\E673";
  --fa--fa: "\E673\E673"; }

.fa-circle-small {
  --fa: "\E122";
  --fa--fa: "\E122\E122"; }

.fa-border-none {
  --fa: "\F850";
  --fa--fa: "\F850\F850"; }

.fa-arrow-turn-down-left {
  --fa: "\E2E1";
  --fa--fa: "\E2E1\E2E1"; }

.fa-circle-wifi-circle-wifi {
  --fa: "\E67E";
  --fa--fa: "\E67E\E67E"; }

.fa-circle-wifi-group {
  --fa: "\E67E";
  --fa--fa: "\E67E\E67E"; }

.fa-circle-nodes {
  --fa: "\E4E2";
  --fa--fa: "\E4E2\E4E2"; }

.fa-parachute-box {
  --fa: "\F4CD";
  --fa--fa: "\F4CD\F4CD"; }

.fa-reflect-horizontal {
  --fa: "\E664";
  --fa--fa: "\E664\E664"; }

.fa-message-medical {
  --fa: "\F7F4";
  --fa--fa: "\F7F4\F7F4"; }

.fa-comment-alt-medical {
  --fa: "\F7F4";
  --fa--fa: "\F7F4\F7F4"; }

.fa-rugby-ball {
  --fa: "\E3C6";
  --fa--fa: "\E3C6\E3C6"; }

.fa-comment-music {
  --fa: "\F8B0";
  --fa--fa: "\F8B0\F8B0"; }

.fa-indent {
  --fa: "\F03C";
  --fa--fa: "\F03C\F03C"; }

.fa-tree-deciduous {
  --fa: "\F400";
  --fa--fa: "\F400\F400"; }

.fa-tree-alt {
  --fa: "\F400";
  --fa--fa: "\F400\F400"; }

.fa-puzzle-piece-simple {
  --fa: "\E231";
  --fa--fa: "\E231\E231"; }

.fa-puzzle-piece-alt {
  --fa: "\E231";
  --fa--fa: "\E231\E231"; }

.fa-truck-field-un {
  --fa: "\E58E";
  --fa--fa: "\E58E\E58E"; }

.fa-nfc-trash {
  --fa: "\E1FD";
  --fa--fa: "\E1FD\E1FD"; }

.fa-hourglass {
  --fa: "\F254";
  --fa--fa: "\F254\F254"; }

.fa-hourglass-empty {
  --fa: "\F254";
  --fa--fa: "\F254\F254"; }

.fa-mountain {
  --fa: "\F6FC";
  --fa--fa: "\F6FC\F6FC"; }

.fa-file-xmark {
  --fa: "\F317";
  --fa--fa: "\F317\F317"; }

.fa-file-times {
  --fa: "\F317";
  --fa--fa: "\F317\F317"; }

.fa-house-heart {
  --fa: "\F4C9";
  --fa--fa: "\F4C9\F4C9"; }

.fa-home-heart {
  --fa: "\F4C9";
  --fa--fa: "\F4C9\F4C9"; }

.fa-house-chimney-blank {
  --fa: "\E3B0";
  --fa--fa: "\E3B0\E3B0"; }

.fa-meter-bolt {
  --fa: "\E1E9";
  --fa--fa: "\E1E9\E1E9"; }

.fa-user-doctor {
  --fa: "\F0F0";
  --fa--fa: "\F0F0\F0F0"; }

.fa-user-md {
  --fa: "\F0F0";
  --fa--fa: "\F0F0\F0F0"; }

.fa-slash-back {
  --fa: "\\";
  --fa--fa: "\\\\"; }

.fa-circle-info {
  --fa: "\F05A";
  --fa--fa: "\F05A\F05A"; }

.fa-info-circle {
  --fa: "\F05A";
  --fa--fa: "\F05A\F05A"; }

.fa-fishing-rod {
  --fa: "\E3A8";
  --fa--fa: "\E3A8\E3A8"; }

.fa-hammer-crash {
  --fa: "\E414";
  --fa--fa: "\E414\E414"; }

.fa-message-heart {
  --fa: "\E5C9";
  --fa--fa: "\E5C9\E5C9"; }

.fa-cloud-meatball {
  --fa: "\F73B";
  --fa--fa: "\F73B\F73B"; }

.fa-camera-polaroid {
  --fa: "\F8AA";
  --fa--fa: "\F8AA\F8AA"; }

.fa-camera {
  --fa: "\F030";
  --fa--fa: "\F030\F030"; }

.fa-camera-alt {
  --fa: "\F030";
  --fa--fa: "\F030\F030"; }

.fa-square-virus {
  --fa: "\E578";
  --fa--fa: "\E578\E578"; }

.fa-cart-arrow-up {
  --fa: "\E3EE";
  --fa--fa: "\E3EE\E3EE"; }

.fa-meteor {
  --fa: "\F753";
  --fa--fa: "\F753\F753"; }

.fa-car-on {
  --fa: "\E4DD";
  --fa--fa: "\E4DD\E4DD"; }

.fa-sleigh {
  --fa: "\F7CC";
  --fa--fa: "\F7CC\F7CC"; }

.fa-arrow-down-1-9 {
  --fa: "\F162";
  --fa--fa: "\F162\F162"; }

.fa-sort-numeric-asc {
  --fa: "\F162";
  --fa--fa: "\F162\F162"; }

.fa-sort-numeric-down {
  --fa: "\F162";
  --fa--fa: "\F162\F162"; }

.fa-buoy-mooring {
  --fa: "\E5B6";
  --fa--fa: "\E5B6\E5B6"; }

.fa-square-4 {
  --fa: "\E259";
  --fa--fa: "\E259\E259"; }

.fa-hand-holding-droplet {
  --fa: "\F4C1";
  --fa--fa: "\F4C1\F4C1"; }

.fa-hand-holding-water {
  --fa: "\F4C1";
  --fa--fa: "\F4C1\F4C1"; }

.fa-file-eps {
  --fa: "\E644";
  --fa--fa: "\E644\E644"; }

.fa-tricycle-adult {
  --fa: "\E5C4";
  --fa--fa: "\E5C4\E5C4"; }

.fa-waveform {
  --fa: "\F8F1";
  --fa--fa: "\F8F1\F8F1"; }

.fa-water {
  --fa: "\F773";
  --fa--fa: "\F773\F773"; }

.fa-star-sharp-half-stroke {
  --fa: "\E28D";
  --fa--fa: "\E28D\E28D"; }

.fa-star-sharp-half-alt {
  --fa: "\E28D";
  --fa--fa: "\E28D\E28D"; }

.fa-nfc-signal {
  --fa: "\E1FB";
  --fa--fa: "\E1FB\E1FB"; }

.fa-plane-prop {
  --fa: "\E22B";
  --fa--fa: "\E22B\E22B"; }

.fa-calendar-check {
  --fa: "\F274";
  --fa--fa: "\F274\F274"; }

.fa-clock-desk {
  --fa: "\E134";
  --fa--fa: "\E134\E134"; }

.fa-calendar-clock {
  --fa: "\E0D2";
  --fa--fa: "\E0D2\E0D2"; }

.fa-calendar-time {
  --fa: "\E0D2";
  --fa--fa: "\E0D2\E0D2"; }

.fa-braille {
  --fa: "\F2A1";
  --fa--fa: "\F2A1\F2A1"; }

.fa-prescription-bottle-medical {
  --fa: "\F486";
  --fa--fa: "\F486\F486"; }

.fa-prescription-bottle-alt {
  --fa: "\F486";
  --fa--fa: "\F486\F486"; }

.fa-plate-utensils {
  --fa: "\E43B";
  --fa--fa: "\E43B\E43B"; }

.fa-family-pants {
  --fa: "\E302";
  --fa--fa: "\E302\E302"; }

.fa-hose-reel {
  --fa: "\E41A";
  --fa--fa: "\E41A\E41A"; }

.fa-house-window {
  --fa: "\E3B3";
  --fa--fa: "\E3B3\E3B3"; }

.fa-landmark {
  --fa: "\F66F";
  --fa--fa: "\F66F\F66F"; }

.fa-truck {
  --fa: "\F0D1";
  --fa--fa: "\F0D1\F0D1"; }

.fa-music-magnifying-glass {
  --fa: "\E662";
  --fa--fa: "\E662\E662"; }

.fa-crosshairs {
  --fa: "\F05B";
  --fa--fa: "\F05B\F05B"; }

.fa-cloud-rainbow {
  --fa: "\F73E";
  --fa--fa: "\F73E\F73E"; }

.fa-person-cane {
  --fa: "\E53C";
  --fa--fa: "\E53C\E53C"; }

.fa-alien {
  --fa: "\F8F5";
  --fa--fa: "\F8F5\F8F5"; }

.fa-tent {
  --fa: "\E57D";
  --fa--fa: "\E57D\E57D"; }

.fa-laptop-binary {
  --fa: "\E5E7";
  --fa--fa: "\E5E7\E5E7"; }

.fa-vest-patches {
  --fa: "\E086";
  --fa--fa: "\E086\E086"; }

.fa-people-dress-simple {
  --fa: "\E218";
  --fa--fa: "\E218\E218"; }

.fa-check-double {
  --fa: "\F560";
  --fa--fa: "\F560\F560"; }

.fa-arrow-down-a-z {
  --fa: "\F15D";
  --fa--fa: "\F15D\F15D"; }

.fa-sort-alpha-asc {
  --fa: "\F15D";
  --fa--fa: "\F15D\F15D"; }

.fa-sort-alpha-down {
  --fa: "\F15D";
  --fa--fa: "\F15D\F15D"; }

.fa-bowling-ball-pin {
  --fa: "\E0C3";
  --fa--fa: "\E0C3\E0C3"; }

.fa-bell-school-slash {
  --fa: "\F5D6";
  --fa--fa: "\F5D6\F5D6"; }

.fa-plus-large {
  --fa: "\E59E";
  --fa--fa: "\E59E\E59E"; }

.fa-money-bill-wheat {
  --fa: "\E52A";
  --fa--fa: "\E52A\E52A"; }

.fa-camera-viewfinder {
  --fa: "\E0DA";
  --fa--fa: "\E0DA\E0DA"; }

.fa-screenshot {
  --fa: "\E0DA";
  --fa--fa: "\E0DA\E0DA"; }

.fa-message-music {
  --fa: "\F8AF";
  --fa--fa: "\F8AF\F8AF"; }

.fa-comment-alt-music {
  --fa: "\F8AF";
  --fa--fa: "\F8AF\F8AF"; }

.fa-car-building {
  --fa: "\F859";
  --fa--fa: "\F859\F859"; }

.fa-border-bottom-right {
  --fa: "\F854";
  --fa--fa: "\F854\F854"; }

.fa-border-style-alt {
  --fa: "\F854";
  --fa--fa: "\F854\F854"; }

.fa-octagon {
  --fa: "\F306";
  --fa--fa: "\F306\F306"; }

.fa-comment-arrow-up-right {
  --fa: "\E145";
  --fa--fa: "\E145\E145"; }

.fa-octagon-divide {
  --fa: "\E203";
  --fa--fa: "\E203\E203"; }

.fa-cookie {
  --fa: "\F563";
  --fa--fa: "\F563\F563"; }

.fa-arrow-rotate-left {
  --fa: "\F0E2";
  --fa--fa: "\F0E2\F0E2"; }

.fa-arrow-left-rotate {
  --fa: "\F0E2";
  --fa--fa: "\F0E2\F0E2"; }

.fa-arrow-rotate-back {
  --fa: "\F0E2";
  --fa--fa: "\F0E2\F0E2"; }

.fa-arrow-rotate-backward {
  --fa: "\F0E2";
  --fa--fa: "\F0E2\F0E2"; }

.fa-undo {
  --fa: "\F0E2";
  --fa--fa: "\F0E2\F0E2"; }

.fa-tv-music {
  --fa: "\F8E6";
  --fa--fa: "\F8E6\F8E6"; }

.fa-hard-drive {
  --fa: "\F0A0";
  --fa--fa: "\F0A0\F0A0"; }

.fa-hdd {
  --fa: "\F0A0";
  --fa--fa: "\F0A0\F0A0"; }

.fa-reel {
  --fa: "\E238";
  --fa--fa: "\E238\E238"; }

.fa-face-grin-squint-tears {
  --fa: "\F586";
  --fa--fa: "\F586\F586"; }

.fa-grin-squint-tears {
  --fa: "\F586";
  --fa--fa: "\F586\F586"; }

.fa-dumbbell {
  --fa: "\F44B";
  --fa--fa: "\F44B\F44B"; }

.fa-rectangle-list {
  --fa: "\F022";
  --fa--fa: "\F022\F022"; }

.fa-list-alt {
  --fa: "\F022";
  --fa--fa: "\F022\F022"; }

.fa-tarp-droplet {
  --fa: "\E57C";
  --fa--fa: "\E57C\E57C"; }

.fa-alarm-exclamation {
  --fa: "\F843";
  --fa--fa: "\F843\F843"; }

.fa-house-medical-circle-check {
  --fa: "\E511";
  --fa--fa: "\E511\E511"; }

.fa-traffic-cone {
  --fa: "\F636";
  --fa--fa: "\F636\F636"; }

.fa-grate {
  --fa: "\E193";
  --fa--fa: "\E193\E193"; }

.fa-arrow-down-right {
  --fa: "\E093";
  --fa--fa: "\E093\E093"; }

.fa-person-skiing-nordic {
  --fa: "\F7CA";
  --fa--fa: "\F7CA\F7CA"; }

.fa-skiing-nordic {
  --fa: "\F7CA";
  --fa--fa: "\F7CA\F7CA"; }

.fa-calendar-plus {
  --fa: "\F271";
  --fa--fa: "\F271\F271"; }

.fa-person-from-portal {
  --fa: "\E023";
  --fa--fa: "\E023\E023"; }

.fa-portal-exit {
  --fa: "\E023";
  --fa--fa: "\E023\E023"; }

.fa-plane-arrival {
  --fa: "\F5AF";
  --fa--fa: "\F5AF\F5AF"; }

.fa-cowbell-circle-plus {
  --fa: "\F8B4";
  --fa--fa: "\F8B4\F8B4"; }

.fa-cowbell-more {
  --fa: "\F8B4";
  --fa--fa: "\F8B4\F8B4"; }

.fa-circle-left {
  --fa: "\F359";
  --fa--fa: "\F359\F359"; }

.fa-arrow-alt-circle-left {
  --fa: "\F359";
  --fa--fa: "\F359\F359"; }

.fa-distribute-spacing-vertical {
  --fa: "\E366";
  --fa--fa: "\E366\E366"; }

.fa-signal-bars-fair {
  --fa: "\F692";
  --fa--fa: "\F692\F692"; }

.fa-signal-alt-2 {
  --fa: "\F692";
  --fa--fa: "\F692\F692"; }

.fa-sportsball {
  --fa: "\E44B";
  --fa--fa: "\E44B\E44B"; }

.fa-game-console-handheld-crank {
  --fa: "\E5B9";
  --fa--fa: "\E5B9\E5B9"; }

.fa-train-subway {
  --fa: "\F239";
  --fa--fa: "\F239\F239"; }

.fa-subway {
  --fa: "\F239";
  --fa--fa: "\F239\F239"; }

.fa-chart-gantt {
  --fa: "\E0E4";
  --fa--fa: "\E0E4\E0E4"; }

.fa-face-smile-upside-down {
  --fa: "\E395";
  --fa--fa: "\E395\E395"; }

.fa-ball-pile {
  --fa: "\F77E";
  --fa--fa: "\F77E\F77E"; }

.fa-badge-dollar {
  --fa: "\F645";
  --fa--fa: "\F645\F645"; }

.fa-money-bills-simple {
  --fa: "\E1F4";
  --fa--fa: "\E1F4\E1F4"; }

.fa-money-bills-alt {
  --fa: "\E1F4";
  --fa--fa: "\E1F4\E1F4"; }

.fa-list-timeline {
  --fa: "\E1D1";
  --fa--fa: "\E1D1\E1D1"; }

.fa-indian-rupee-sign {
  --fa: "\E1BC";
  --fa--fa: "\E1BC\E1BC"; }

.fa-indian-rupee {
  --fa: "\E1BC";
  --fa--fa: "\E1BC\E1BC"; }

.fa-inr {
  --fa: "\E1BC";
  --fa--fa: "\E1BC\E1BC"; }

.fa-crop-simple {
  --fa: "\F565";
  --fa--fa: "\F565\F565"; }

.fa-crop-alt {
  --fa: "\F565";
  --fa--fa: "\F565\F565"; }

.fa-money-bill-1 {
  --fa: "\F3D1";
  --fa--fa: "\F3D1\F3D1"; }

.fa-money-bill-alt {
  --fa: "\F3D1";
  --fa--fa: "\F3D1\F3D1"; }

.fa-left-long {
  --fa: "\F30A";
  --fa--fa: "\F30A\F30A"; }

.fa-long-arrow-alt-left {
  --fa: "\F30A";
  --fa--fa: "\F30A\F30A"; }

.fa-keyboard-down {
  --fa: "\E1C2";
  --fa--fa: "\E1C2\E1C2"; }

.fa-circle-up-right {
  --fa: "\E129";
  --fa--fa: "\E129\E129"; }

.fa-cloud-bolt-moon {
  --fa: "\F76D";
  --fa--fa: "\F76D\F76D"; }

.fa-thunderstorm-moon {
  --fa: "\F76D";
  --fa--fa: "\F76D\F76D"; }

.fa-turn-left-up {
  --fa: "\E638";
  --fa--fa: "\E638\E638"; }

.fa-dna {
  --fa: "\F471";
  --fa--fa: "\F471\F471"; }

.fa-virus-slash {
  --fa: "\E075";
  --fa--fa: "\E075\E075"; }

.fa-bracket-round-right {
  --fa: ")";
  --fa--fa: "))"; }

.fa-circle-sterling {
  --fa: "\E5CF";
  --fa--fa: "\E5CF\E5CF"; }

.fa-circle-5 {
  --fa: "\E0F2";
  --fa--fa: "\E0F2\E0F2"; }

.fa-minus {
  --fa: "\F068";
  --fa--fa: "\F068\F068"; }

.fa-subtract {
  --fa: "\F068";
  --fa--fa: "\F068\F068"; }

.fa-fire-flame {
  --fa: "\F6DF";
  --fa--fa: "\F6DF\F6DF"; }

.fa-flame {
  --fa: "\F6DF";
  --fa--fa: "\F6DF\F6DF"; }

.fa-right-to-line {
  --fa: "\F34C";
  --fa--fa: "\F34C\F34C"; }

.fa-arrow-alt-to-right {
  --fa: "\F34C";
  --fa--fa: "\F34C\F34C"; }

.fa-gif {
  --fa: "\E190";
  --fa--fa: "\E190\E190"; }

.fa-chess {
  --fa: "\F439";
  --fa--fa: "\F439\F439"; }

.fa-trash-slash {
  --fa: "\E2B3";
  --fa--fa: "\E2B3\E2B3"; }

.fa-arrow-left-long {
  --fa: "\F177";
  --fa--fa: "\F177\F177"; }

.fa-long-arrow-left {
  --fa: "\F177";
  --fa--fa: "\F177\F177"; }

.fa-plug-circle-check {
  --fa: "\E55C";
  --fa--fa: "\E55C\E55C"; }

.fa-font-case {
  --fa: "\F866";
  --fa--fa: "\F866\F866"; }

.fa-street-view {
  --fa: "\F21D";
  --fa--fa: "\F21D\F21D"; }

.fa-arrow-down-left {
  --fa: "\E091";
  --fa--fa: "\E091\E091"; }

.fa-franc-sign {
  --fa: "\E18F";
  --fa--fa: "\E18F\E18F"; }

.fa-flask-round-poison {
  --fa: "\F6E0";
  --fa--fa: "\F6E0\F6E0"; }

.fa-flask-poison {
  --fa: "\F6E0";
  --fa--fa: "\F6E0\F6E0"; }

.fa-volume-off {
  --fa: "\F026";
  --fa--fa: "\F026\F026"; }

.fa-book-circle-arrow-right {
  --fa: "\E0BC";
  --fa--fa: "\E0BC\E0BC"; }

.fa-chart-user {
  --fa: "\F6A3";
  --fa--fa: "\F6A3\F6A3"; }

.fa-user-chart {
  --fa: "\F6A3";
  --fa--fa: "\F6A3\F6A3"; }

.fa-hands-asl-interpreting {
  --fa: "\F2A3";
  --fa--fa: "\F2A3\F2A3"; }

.fa-american-sign-language-interpreting {
  --fa: "\F2A3";
  --fa--fa: "\F2A3\F2A3"; }

.fa-asl-interpreting {
  --fa: "\F2A3";
  --fa--fa: "\F2A3\F2A3"; }

.fa-hands-american-sign-language-interpreting {
  --fa: "\F2A3";
  --fa--fa: "\F2A3\F2A3"; }

.fa-presentation-screen {
  --fa: "\F685";
  --fa--fa: "\F685\F685"; }

.fa-presentation {
  --fa: "\F685";
  --fa--fa: "\F685\F685"; }

.fa-circle-bolt {
  --fa: "\E0FE";
  --fa--fa: "\E0FE\E0FE"; }

.fa-face-smile-halo {
  --fa: "\E38F";
  --fa--fa: "\E38F\E38F"; }

.fa-cart-circle-arrow-down {
  --fa: "\E3EF";
  --fa--fa: "\E3EF\E3EF"; }

.fa-house-person-return {
  --fa: "\E011";
  --fa--fa: "\E011\E011"; }

.fa-house-person-arrive {
  --fa: "\E011";
  --fa--fa: "\E011\E011"; }

.fa-house-return {
  --fa: "\E011";
  --fa--fa: "\E011\E011"; }

.fa-message-xmark {
  --fa: "\F4AB";
  --fa--fa: "\F4AB\F4AB"; }

.fa-comment-alt-times {
  --fa: "\F4AB";
  --fa--fa: "\F4AB\F4AB"; }

.fa-message-times {
  --fa: "\F4AB";
  --fa--fa: "\F4AB\F4AB"; }

.fa-file-certificate {
  --fa: "\F5F3";
  --fa--fa: "\F5F3\F5F3"; }

.fa-file-award {
  --fa: "\F5F3";
  --fa--fa: "\F5F3\F5F3"; }

.fa-user-doctor-hair-long {
  --fa: "\E459";
  --fa--fa: "\E459\E459"; }

.fa-camera-security {
  --fa: "\F8FE";
  --fa--fa: "\F8FE\F8FE"; }

.fa-camera-home {
  --fa: "\F8FE";
  --fa--fa: "\F8FE\F8FE"; }

.fa-gear {
  --fa: "\F013";
  --fa--fa: "\F013\F013"; }

.fa-cog {
  --fa: "\F013";
  --fa--fa: "\F013\F013"; }

.fa-droplet-slash {
  --fa: "\F5C7";
  --fa--fa: "\F5C7\F5C7"; }

.fa-tint-slash {
  --fa: "\F5C7";
  --fa--fa: "\F5C7\F5C7"; }

.fa-book-heart {
  --fa: "\F499";
  --fa--fa: "\F499\F499"; }

.fa-mosque {
  --fa: "\F678";
  --fa--fa: "\F678\F678"; }

.fa-duck {
  --fa: "\F6D8";
  --fa--fa: "\F6D8\F6D8"; }

.fa-mosquito {
  --fa: "\E52B";
  --fa--fa: "\E52B\E52B"; }

.fa-star-of-david {
  --fa: "\F69A";
  --fa--fa: "\F69A\F69A"; }

.fa-flag-swallowtail {
  --fa: "\F74C";
  --fa--fa: "\F74C\F74C"; }

.fa-flag-alt {
  --fa: "\F74C";
  --fa--fa: "\F74C\F74C"; }

.fa-person-military-rifle {
  --fa: "\E54B";
  --fa--fa: "\E54B\E54B"; }

.fa-car-garage {
  --fa: "\F5E2";
  --fa--fa: "\F5E2\F5E2"; }

.fa-cart-shopping {
  --fa: "\F07A";
  --fa--fa: "\F07A\F07A"; }

.fa-shopping-cart {
  --fa: "\F07A";
  --fa--fa: "\F07A\F07A"; }

.fa-book-font {
  --fa: "\E0BF";
  --fa--fa: "\E0BF\E0BF"; }

.fa-shield-plus {
  --fa: "\E24A";
  --fa--fa: "\E24A\E24A"; }

.fa-vials {
  --fa: "\F493";
  --fa--fa: "\F493\F493"; }

.fa-eye-dropper-full {
  --fa: "\E172";
  --fa--fa: "\E172\E172"; }

.fa-distribute-spacing-horizontal {
  --fa: "\E365";
  --fa--fa: "\E365\E365"; }

.fa-tablet-rugged {
  --fa: "\F48F";
  --fa--fa: "\F48F\F48F"; }

.fa-temperature-snow {
  --fa: "\F768";
  --fa--fa: "\F768\F768"; }

.fa-temperature-frigid {
  --fa: "\F768";
  --fa--fa: "\F768\F768"; }

.fa-moped {
  --fa: "\E3B9";
  --fa--fa: "\E3B9\E3B9"; }

.fa-face-smile-plus {
  --fa: "\F5B9";
  --fa--fa: "\F5B9\F5B9"; }

.fa-smile-plus {
  --fa: "\F5B9";
  --fa--fa: "\F5B9\F5B9"; }

.fa-radio-tuner {
  --fa: "\F8D8";
  --fa--fa: "\F8D8\F8D8"; }

.fa-radio-alt {
  --fa: "\F8D8";
  --fa--fa: "\F8D8\F8D8"; }

.fa-face-swear {
  --fa: "\E399";
  --fa--fa: "\E399\E399"; }

.fa-water-arrow-down {
  --fa: "\F774";
  --fa--fa: "\F774\F774"; }

.fa-water-lower {
  --fa: "\F774";
  --fa--fa: "\F774\F774"; }

.fa-scanner-touchscreen {
  --fa: "\F48A";
  --fa--fa: "\F48A\F48A"; }

.fa-circle-7 {
  --fa: "\E0F4";
  --fa--fa: "\E0F4\E0F4"; }

.fa-plug-circle-plus {
  --fa: "\E55F";
  --fa--fa: "\E55F\E55F"; }

.fa-person-ski-jumping {
  --fa: "\F7C7";
  --fa--fa: "\F7C7\F7C7"; }

.fa-ski-jump {
  --fa: "\F7C7";
  --fa--fa: "\F7C7\F7C7"; }

.fa-place-of-worship {
  --fa: "\F67F";
  --fa--fa: "\F67F\F67F"; }

.fa-water-arrow-up {
  --fa: "\F775";
  --fa--fa: "\F775\F775"; }

.fa-water-rise {
  --fa: "\F775";
  --fa--fa: "\F775\F775"; }

.fa-waveform-lines {
  --fa: "\F8F2";
  --fa--fa: "\F8F2\F8F2"; }

.fa-waveform-path {
  --fa: "\F8F2";
  --fa--fa: "\F8F2\F8F2"; }

.fa-split {
  --fa: "\E254";
  --fa--fa: "\E254\E254"; }

.fa-film-canister {
  --fa: "\F8B7";
  --fa--fa: "\F8B7\F8B7"; }

.fa-film-cannister {
  --fa: "\F8B7";
  --fa--fa: "\F8B7\F8B7"; }

.fa-folder-xmark {
  --fa: "\F65F";
  --fa--fa: "\F65F\F65F"; }

.fa-folder-times {
  --fa: "\F65F";
  --fa--fa: "\F65F\F65F"; }

.fa-toilet-paper-blank {
  --fa: "\F71F";
  --fa--fa: "\F71F\F71F"; }

.fa-toilet-paper-alt {
  --fa: "\F71F";
  --fa--fa: "\F71F\F71F"; }

.fa-tablet-screen {
  --fa: "\F3FC";
  --fa--fa: "\F3FC\F3FC"; }

.fa-tablet-android-alt {
  --fa: "\F3FC";
  --fa--fa: "\F3FC\F3FC"; }

.fa-hexagon-vertical-nft-slanted {
  --fa: "\E506";
  --fa--fa: "\E506\E506"; }

.fa-folder-music {
  --fa: "\E18D";
  --fa--fa: "\E18D\E18D"; }

.fa-display-medical {
  --fa: "\E166";
  --fa--fa: "\E166\E166"; }

.fa-desktop-medical {
  --fa: "\E166";
  --fa--fa: "\E166\E166"; }

.fa-share-all {
  --fa: "\F367";
  --fa--fa: "\F367\F367"; }

.fa-peapod {
  --fa: "\E31C";
  --fa--fa: "\E31C\E31C"; }

.fa-chess-clock {
  --fa: "\F43D";
  --fa--fa: "\F43D\F43D"; }

.fa-axe {
  --fa: "\F6B2";
  --fa--fa: "\F6B2\F6B2"; }

.fa-square-d {
  --fa: "\E268";
  --fa--fa: "\E268\E268"; }

.fa-grip-vertical {
  --fa: "\F58E";
  --fa--fa: "\F58E\F58E"; }

.fa-mobile-signal-out {
  --fa: "\E1F0";
  --fa--fa: "\E1F0\E1F0"; }

.fa-hexagon-nodes {
  --fa: "\E699";
  --fa--fa: "\E699\E699"; }

.fa-arrow-turn-up {
  --fa: "\F148";
  --fa--fa: "\F148\F148"; }

.fa-level-up {
  --fa: "\F148";
  --fa--fa: "\F148\F148"; }

.fa-u {
  --fa: "U";
  --fa--fa: "UU"; }

.fa-arrow-up-from-dotted-line {
  --fa: "\E09B";
  --fa--fa: "\E09B\E09B"; }

.fa-square-root-variable {
  --fa: "\F698";
  --fa--fa: "\F698\F698"; }

.fa-square-root-alt {
  --fa: "\F698";
  --fa--fa: "\F698\F698"; }

.fa-light-switch-on {
  --fa: "\E019";
  --fa--fa: "\E019\E019"; }

.fa-arrow-down-arrow-up {
  --fa: "\F883";
  --fa--fa: "\F883\F883"; }

.fa-sort-alt {
  --fa: "\F883";
  --fa--fa: "\F883\F883"; }

.fa-raindrops {
  --fa: "\F75C";
  --fa--fa: "\F75C\F75C"; }

.fa-dash {
  --fa: "\E404";
  --fa--fa: "\E404\E404"; }

.fa-minus-large {
  --fa: "\E404";
  --fa--fa: "\E404\E404"; }

.fa-clock {
  --fa: "\F017";
  --fa--fa: "\F017\F017"; }

.fa-clock-four {
  --fa: "\F017";
  --fa--fa: "\F017\F017"; }

.fa-input-numeric {
  --fa: "\E1BD";
  --fa--fa: "\E1BD\E1BD"; }

.fa-truck-tow {
  --fa: "\E2B8";
  --fa--fa: "\E2B8\E2B8"; }

.fa-backward-step {
  --fa: "\F048";
  --fa--fa: "\F048\F048"; }

.fa-step-backward {
  --fa: "\F048";
  --fa--fa: "\F048\F048"; }

.fa-pallet {
  --fa: "\F482";
  --fa--fa: "\F482\F482"; }

.fa-car-bolt {
  --fa: "\E341";
  --fa--fa: "\E341\E341"; }

.fa-arrows-maximize {
  --fa: "\F31D";
  --fa--fa: "\F31D\F31D"; }

.fa-expand-arrows {
  --fa: "\F31D";
  --fa--fa: "\F31D\F31D"; }

.fa-faucet {
  --fa: "\E005";
  --fa--fa: "\E005\E005"; }

.fa-cloud-sleet {
  --fa: "\F741";
  --fa--fa: "\F741\F741"; }

.fa-lamp-street {
  --fa: "\E1C5";
  --fa--fa: "\E1C5\E1C5"; }

.fa-list-radio {
  --fa: "\E1D0";
  --fa--fa: "\E1D0\E1D0"; }

.fa-pen-nib-slash {
  --fa: "\E4A1";
  --fa--fa: "\E4A1\E4A1"; }

.fa-baseball-bat-ball {
  --fa: "\F432";
  --fa--fa: "\F432\F432"; }

.fa-square-up-left {
  --fa: "\E282";
  --fa--fa: "\E282\E282"; }

.fa-overline {
  --fa: "\F876";
  --fa--fa: "\F876\F876"; }

.fa-s {
  --fa: "S";
  --fa--fa: "SS"; }

.fa-timeline {
  --fa: "\E29C";
  --fa--fa: "\E29C\E29C"; }

.fa-keyboard {
  --fa: "\F11C";
  --fa--fa: "\F11C\F11C"; }

.fa-arrows-from-dotted-line {
  --fa: "\E0A3";
  --fa--fa: "\E0A3\E0A3"; }

.fa-usb-drive {
  --fa: "\F8E9";
  --fa--fa: "\F8E9\F8E9"; }

.fa-ballot {
  --fa: "\F732";
  --fa--fa: "\F732\F732"; }

.fa-caret-down {
  --fa: "\F0D7";
  --fa--fa: "\F0D7\F0D7"; }

.fa-location-dot-slash {
  --fa: "\F605";
  --fa--fa: "\F605\F605"; }

.fa-map-marker-alt-slash {
  --fa: "\F605";
  --fa--fa: "\F605\F605"; }

.fa-cards {
  --fa: "\E3ED";
  --fa--fa: "\E3ED\E3ED"; }

.fa-house-chimney-medical {
  --fa: "\F7F2";
  --fa--fa: "\F7F2\F7F2"; }

.fa-clinic-medical {
  --fa: "\F7F2";
  --fa--fa: "\F7F2\F7F2"; }

.fa-boxing-glove {
  --fa: "\F438";
  --fa--fa: "\F438\F438"; }

.fa-glove-boxing {
  --fa: "\F438";
  --fa--fa: "\F438\F438"; }

.fa-temperature-three-quarters {
  --fa: "\F2C8";
  --fa--fa: "\F2C8\F2C8"; }

.fa-temperature-3 {
  --fa: "\F2C8";
  --fa--fa: "\F2C8\F2C8"; }

.fa-thermometer-3 {
  --fa: "\F2C8";
  --fa--fa: "\F2C8\F2C8"; }

.fa-thermometer-three-quarters {
  --fa: "\F2C8";
  --fa--fa: "\F2C8\F2C8"; }

.fa-bell-school {
  --fa: "\F5D5";
  --fa--fa: "\F5D5\F5D5"; }

.fa-mobile-screen {
  --fa: "\F3CF";
  --fa--fa: "\F3CF\F3CF"; }

.fa-mobile-android-alt {
  --fa: "\F3CF";
  --fa--fa: "\F3CF\F3CF"; }

.fa-plane-up {
  --fa: "\E22D";
  --fa--fa: "\E22D\E22D"; }

.fa-folder-heart {
  --fa: "\E189";
  --fa--fa: "\E189\E189"; }

.fa-circle-location-arrow {
  --fa: "\F602";
  --fa--fa: "\F602\F602"; }

.fa-location-circle {
  --fa: "\F602";
  --fa--fa: "\F602\F602"; }

.fa-face-head-bandage {
  --fa: "\E37A";
  --fa--fa: "\E37A\E37A"; }

.fa-sushi-roll {
  --fa: "\E48B";
  --fa--fa: "\E48B\E48B"; }

.fa-maki-roll {
  --fa: "\E48B";
  --fa--fa: "\E48B\E48B"; }

.fa-makizushi {
  --fa: "\E48B";
  --fa--fa: "\E48B\E48B"; }

.fa-car-bump {
  --fa: "\F5E0";
  --fa--fa: "\F5E0\F5E0"; }

.fa-piggy-bank {
  --fa: "\F4D3";
  --fa--fa: "\F4D3\F4D3"; }

.fa-racquet {
  --fa: "\F45A";
  --fa--fa: "\F45A\F45A"; }

.fa-car-mirrors {
  --fa: "\E343";
  --fa--fa: "\E343\E343"; }

.fa-industry-windows {
  --fa: "\F3B3";
  --fa--fa: "\F3B3\F3B3"; }

.fa-industry-alt {
  --fa: "\F3B3";
  --fa--fa: "\F3B3\F3B3"; }

.fa-bolt-auto {
  --fa: "\E0B6";
  --fa--fa: "\E0B6\E0B6"; }

.fa-battery-half {
  --fa: "\F242";
  --fa--fa: "\F242\F242"; }

.fa-battery-3 {
  --fa: "\F242";
  --fa--fa: "\F242\F242"; }

.fa-flux-capacitor {
  --fa: "\F8BA";
  --fa--fa: "\F8BA\F8BA"; }

.fa-mountain-city {
  --fa: "\E52E";
  --fa--fa: "\E52E\E52E"; }

.fa-coins {
  --fa: "\F51E";
  --fa--fa: "\F51E\F51E"; }

.fa-honey-pot {
  --fa: "\E418";
  --fa--fa: "\E418\E418"; }

.fa-olive {
  --fa: "\E316";
  --fa--fa: "\E316\E316"; }

.fa-khanda {
  --fa: "\F66D";
  --fa--fa: "\F66D\F66D"; }

.fa-filter-list {
  --fa: "\E17C";
  --fa--fa: "\E17C\E17C"; }

.fa-outlet {
  --fa: "\E01C";
  --fa--fa: "\E01C\E01C"; }

.fa-sliders {
  --fa: "\F1DE";
  --fa--fa: "\F1DE\F1DE"; }

.fa-sliders-h {
  --fa: "\F1DE";
  --fa--fa: "\F1DE\F1DE"; }

.fa-cauldron {
  --fa: "\F6BF";
  --fa--fa: "\F6BF\F6BF"; }

.fa-people {
  --fa: "\E216";
  --fa--fa: "\E216\E216"; }

.fa-folder-tree {
  --fa: "\F802";
  --fa--fa: "\F802\F802"; }

.fa-network-wired {
  --fa: "\F6FF";
  --fa--fa: "\F6FF\F6FF"; }

.fa-croissant {
  --fa: "\F7F6";
  --fa--fa: "\F7F6\F7F6"; }

.fa-map-pin {
  --fa: "\F276";
  --fa--fa: "\F276\F276"; }

.fa-hamsa {
  --fa: "\F665";
  --fa--fa: "\F665\F665"; }

.fa-cent-sign {
  --fa: "\E3F5";
  --fa--fa: "\E3F5\E3F5"; }

.fa-swords-laser {
  --fa: "\E03D";
  --fa--fa: "\E03D\E03D"; }

.fa-flask {
  --fa: "\F0C3";
  --fa--fa: "\F0C3\F0C3"; }

.fa-person-pregnant {
  --fa: "\E31E";
  --fa--fa: "\E31E\E31E"; }

.fa-square-u {
  --fa: "\E281";
  --fa--fa: "\E281\E281"; }

.fa-wand-sparkles {
  --fa: "\F72B";
  --fa--fa: "\F72B\F72B"; }

.fa-router {
  --fa: "\F8DA";
  --fa--fa: "\F8DA\F8DA"; }

.fa-ellipsis-vertical {
  --fa: "\F142";
  --fa--fa: "\F142\F142"; }

.fa-ellipsis-v {
  --fa: "\F142";
  --fa--fa: "\F142\F142"; }

.fa-sword-laser-alt {
  --fa: "\E03C";
  --fa--fa: "\E03C\E03C"; }

.fa-ticket {
  --fa: "\F145";
  --fa--fa: "\F145\F145"; }

.fa-power-off {
  --fa: "\F011";
  --fa--fa: "\F011\F011"; }

.fa-coin {
  --fa: "\F85C";
  --fa--fa: "\F85C\F85C"; }

.fa-laptop-slash {
  --fa: "\E1C7";
  --fa--fa: "\E1C7\E1C7"; }

.fa-right-long {
  --fa: "\F30B";
  --fa--fa: "\F30B\F30B"; }

.fa-long-arrow-alt-right {
  --fa: "\F30B";
  --fa--fa: "\F30B\F30B"; }

.fa-circle-b {
  --fa: "\E0FD";
  --fa--fa: "\E0FD\E0FD"; }

.fa-person-dress-simple {
  --fa: "\E21C";
  --fa--fa: "\E21C\E21C"; }

.fa-pipe-collar {
  --fa: "\E437";
  --fa--fa: "\E437\E437"; }

.fa-lights-holiday {
  --fa: "\F7B2";
  --fa--fa: "\F7B2\F7B2"; }

.fa-citrus {
  --fa: "\E2F4";
  --fa--fa: "\E2F4\E2F4"; }

.fa-flag-usa {
  --fa: "\F74D";
  --fa--fa: "\F74D\F74D"; }

.fa-laptop-file {
  --fa: "\E51D";
  --fa--fa: "\E51D\E51D"; }

.fa-tty {
  --fa: "\F1E4";
  --fa--fa: "\F1E4\F1E4"; }

.fa-teletype {
  --fa: "\F1E4";
  --fa--fa: "\F1E4\F1E4"; }

.fa-chart-tree-map {
  --fa: "\E0EA";
  --fa--fa: "\E0EA\E0EA"; }

.fa-diagram-next {
  --fa: "\E476";
  --fa--fa: "\E476\E476"; }

.fa-person-rifle {
  --fa: "\E54E";
  --fa--fa: "\E54E\E54E"; }

.fa-clock-five-thirty {
  --fa: "\E34A";
  --fa--fa: "\E34A\E34A"; }

.fa-pipe-valve {
  --fa: "\E439";
  --fa--fa: "\E439\E439"; }

.fa-lightbulb-message {
  --fa: "\E687";
  --fa--fa: "\E687\E687"; }

.fa-arrow-up-from-arc {
  --fa: "\E4B4";
  --fa--fa: "\E4B4\E4B4"; }

.fa-face-spiral-eyes {
  --fa: "\E485";
  --fa--fa: "\E485\E485"; }

.fa-compress-wide {
  --fa: "\F326";
  --fa--fa: "\F326\F326"; }

.fa-circle-phone-hangup {
  --fa: "\E11D";
  --fa--fa: "\E11D\E11D"; }

.fa-phone-circle-down {
  --fa: "\E11D";
  --fa--fa: "\E11D\E11D"; }

.fa-gear-complex-code {
  --fa: "\E5EB";
  --fa--fa: "\E5EB\E5EB"; }

.fa-house-medical-circle-exclamation {
  --fa: "\E512";
  --fa--fa: "\E512\E512"; }

.fa-badminton {
  --fa: "\E33A";
  --fa--fa: "\E33A\E33A"; }

.fa-closed-captioning {
  --fa: "\F20A";
  --fa--fa: "\F20A\F20A"; }

.fa-person-hiking {
  --fa: "\F6EC";
  --fa--fa: "\F6EC\F6EC"; }

.fa-hiking {
  --fa: "\F6EC";
  --fa--fa: "\F6EC\F6EC"; }

.fa-right-from-line {
  --fa: "\F347";
  --fa--fa: "\F347\F347"; }

.fa-arrow-alt-from-left {
  --fa: "\F347";
  --fa--fa: "\F347\F347"; }

.fa-venus-double {
  --fa: "\F226";
  --fa--fa: "\F226\F226"; }

.fa-images {
  --fa: "\F302";
  --fa--fa: "\F302\F302"; }

.fa-calculator {
  --fa: "\F1EC";
  --fa--fa: "\F1EC\F1EC"; }

.fa-shuttlecock {
  --fa: "\F45B";
  --fa--fa: "\F45B\F45B"; }

.fa-user-hair {
  --fa: "\E45A";
  --fa--fa: "\E45A\E45A"; }

.fa-eye-evil {
  --fa: "\F6DB";
  --fa--fa: "\F6DB\F6DB"; }

.fa-people-pulling {
  --fa: "\E535";
  --fa--fa: "\E535\E535"; }

.fa-n {
  --fa: "N";
  --fa--fa: "NN"; }

.fa-swap {
  --fa: "\E609";
  --fa--fa: "\E609\E609"; }

.fa-garage {
  --fa: "\E009";
  --fa--fa: "\E009\E009"; }

.fa-cable-car {
  --fa: "\F7DA";
  --fa--fa: "\F7DA\F7DA"; }

.fa-tram {
  --fa: "\F7DA";
  --fa--fa: "\F7DA\F7DA"; }

.fa-shovel-snow {
  --fa: "\F7C3";
  --fa--fa: "\F7C3\F7C3"; }

.fa-cloud-rain {
  --fa: "\F73D";
  --fa--fa: "\F73D\F73D"; }

.fa-face-lying {
  --fa: "\E37E";
  --fa--fa: "\E37E\E37E"; }

.fa-sprinkler {
  --fa: "\E035";
  --fa--fa: "\E035\E035"; }

.fa-building-circle-xmark {
  --fa: "\E4D4";
  --fa--fa: "\E4D4\E4D4"; }

.fa-person-sledding {
  --fa: "\F7CB";
  --fa--fa: "\F7CB\F7CB"; }

.fa-sledding {
  --fa: "\F7CB";
  --fa--fa: "\F7CB\F7CB"; }

.fa-game-console-handheld {
  --fa: "\F8BB";
  --fa--fa: "\F8BB\F8BB"; }

.fa-ship {
  --fa: "\F21A";
  --fa--fa: "\F21A\F21A"; }

.fa-clock-six-thirty {
  --fa: "\E353";
  --fa--fa: "\E353\E353"; }

.fa-battery-slash {
  --fa: "\F377";
  --fa--fa: "\F377\F377"; }

.fa-tugrik-sign {
  --fa: "\E2BA";
  --fa--fa: "\E2BA\E2BA"; }

.fa-arrows-down-to-line {
  --fa: "\E4B8";
  --fa--fa: "\E4B8\E4B8"; }

.fa-download {
  --fa: "\F019";
  --fa--fa: "\F019\F019"; }

.fa-angles-up-down {
  --fa: "\E60D";
  --fa--fa: "\E60D\E60D"; }

.fa-shelves {
  --fa: "\F480";
  --fa--fa: "\F480\F480"; }

.fa-inventory {
  --fa: "\F480";
  --fa--fa: "\F480\F480"; }

.fa-cloud-snow {
  --fa: "\F742";
  --fa--fa: "\F742\F742"; }

.fa-face-grin {
  --fa: "\F580";
  --fa--fa: "\F580\F580"; }

.fa-grin {
  --fa: "\F580";
  --fa--fa: "\F580\F580"; }

.fa-delete-left {
  --fa: "\F55A";
  --fa--fa: "\F55A\F55A"; }

.fa-backspace {
  --fa: "\F55A";
  --fa--fa: "\F55A\F55A"; }

.fa-oven {
  --fa: "\E01D";
  --fa--fa: "\E01D\E01D"; }

.fa-cloud-binary {
  --fa: "\E601";
  --fa--fa: "\E601\E601"; }

.fa-eye-dropper {
  --fa: "\F1FB";
  --fa--fa: "\F1FB\F1FB"; }

.fa-eye-dropper-empty {
  --fa: "\F1FB";
  --fa--fa: "\F1FB\F1FB"; }

.fa-eyedropper {
  --fa: "\F1FB";
  --fa--fa: "\F1FB\F1FB"; }

.fa-comment-captions {
  --fa: "\E146";
  --fa--fa: "\E146\E146"; }

.fa-comments-question {
  --fa: "\E14E";
  --fa--fa: "\E14E\E14E"; }

.fa-scribble {
  --fa: "\E23F";
  --fa--fa: "\E23F\E23F"; }

.fa-rotate-exclamation {
  --fa: "\E23C";
  --fa--fa: "\E23C\E23C"; }

.fa-file-circle-check {
  --fa: "\E5A0";
  --fa--fa: "\E5A0\E5A0"; }

.fa-glass {
  --fa: "\F804";
  --fa--fa: "\F804\F804"; }

.fa-loader {
  --fa: "\E1D4";
  --fa--fa: "\E1D4\E1D4"; }

.fa-forward {
  --fa: "\F04E";
  --fa--fa: "\F04E\F04E"; }

.fa-user-pilot {
  --fa: "\E2C0";
  --fa--fa: "\E2C0\E2C0"; }

.fa-mobile {
  --fa: "\F3CE";
  --fa--fa: "\F3CE\F3CE"; }

.fa-mobile-android {
  --fa: "\F3CE";
  --fa--fa: "\F3CE\F3CE"; }

.fa-mobile-phone {
  --fa: "\F3CE";
  --fa--fa: "\F3CE\F3CE"; }

.fa-code-pull-request-closed {
  --fa: "\E3F9";
  --fa--fa: "\E3F9\E3F9"; }

.fa-face-meh {
  --fa: "\F11A";
  --fa--fa: "\F11A\F11A"; }

.fa-meh {
  --fa: "\F11A";
  --fa--fa: "\F11A\F11A"; }

.fa-align-center {
  --fa: "\F037";
  --fa--fa: "\F037\F037"; }

.fa-book-skull {
  --fa: "\F6B7";
  --fa--fa: "\F6B7\F6B7"; }

.fa-book-dead {
  --fa: "\F6B7";
  --fa--fa: "\F6B7\F6B7"; }

.fa-id-card {
  --fa: "\F2C2";
  --fa--fa: "\F2C2\F2C2"; }

.fa-drivers-license {
  --fa: "\F2C2";
  --fa--fa: "\F2C2\F2C2"; }

.fa-face-dotted {
  --fa: "\E47F";
  --fa--fa: "\E47F\E47F"; }

.fa-face-worried {
  --fa: "\E3A3";
  --fa--fa: "\E3A3\E3A3"; }

.fa-outdent {
  --fa: "\F03B";
  --fa--fa: "\F03B\F03B"; }

.fa-dedent {
  --fa: "\F03B";
  --fa--fa: "\F03B\F03B"; }

.fa-court-sport {
  --fa: "\E643";
  --fa--fa: "\E643\E643"; }

.fa-heart-circle-exclamation {
  --fa: "\E4FE";
  --fa--fa: "\E4FE\E4FE"; }

.fa-house {
  --fa: "\F015";
  --fa--fa: "\F015\F015"; }

.fa-home {
  --fa: "\F015";
  --fa--fa: "\F015\F015"; }

.fa-home-alt {
  --fa: "\F015";
  --fa--fa: "\F015\F015"; }

.fa-home-lg-alt {
  --fa: "\F015";
  --fa--fa: "\F015\F015"; }

.fa-vector-circle {
  --fa: "\E2C6";
  --fa--fa: "\E2C6\E2C6"; }

.fa-car-circle-bolt {
  --fa: "\E342";
  --fa--fa: "\E342\E342"; }

.fa-calendar-week {
  --fa: "\F784";
  --fa--fa: "\F784\F784"; }

.fa-flying-disc {
  --fa: "\E3A9";
  --fa--fa: "\E3A9\E3A9"; }

.fa-laptop-medical {
  --fa: "\F812";
  --fa--fa: "\F812\F812"; }

.fa-square-down-right {
  --fa: "\E26C";
  --fa--fa: "\E26C\E26C"; }

.fa-b {
  --fa: "B";
  --fa--fa: "BB"; }

.fa-seat-airline {
  --fa: "\E244";
  --fa--fa: "\E244\E244"; }

.fa-moon-over-sun {
  --fa: "\F74A";
  --fa--fa: "\F74A\F74A"; }

.fa-eclipse-alt {
  --fa: "\F74A";
  --fa--fa: "\F74A\F74A"; }

.fa-pipe {
  --fa: "|";
  --fa--fa: "||"; }

.fa-file-medical {
  --fa: "\F477";
  --fa--fa: "\F477\F477"; }

.fa-potato {
  --fa: "\E440";
  --fa--fa: "\E440\E440"; }

.fa-dice-one {
  --fa: "\F525";
  --fa--fa: "\F525\F525"; }

.fa-circle-a {
  --fa: "\E0F7";
  --fa--fa: "\E0F7\E0F7"; }

.fa-helmet-battle {
  --fa: "\F6EB";
  --fa--fa: "\F6EB\F6EB"; }

.fa-butter {
  --fa: "\E3E4";
  --fa--fa: "\E3E4\E3E4"; }

.fa-blanket-fire {
  --fa: "\E3DA";
  --fa--fa: "\E3DA\E3DA"; }

.fa-kiwi-bird {
  --fa: "\F535";
  --fa--fa: "\F535\F535"; }

.fa-castle {
  --fa: "\E0DE";
  --fa--fa: "\E0DE\E0DE"; }

.fa-golf-club {
  --fa: "\F451";
  --fa--fa: "\F451\F451"; }

.fa-arrow-right-arrow-left {
  --fa: "\F0EC";
  --fa--fa: "\F0EC\F0EC"; }

.fa-exchange {
  --fa: "\F0EC";
  --fa--fa: "\F0EC\F0EC"; }

.fa-rotate-right {
  --fa: "\F2F9";
  --fa--fa: "\F2F9\F2F9"; }

.fa-redo-alt {
  --fa: "\F2F9";
  --fa--fa: "\F2F9\F2F9"; }

.fa-rotate-forward {
  --fa: "\F2F9";
  --fa--fa: "\F2F9\F2F9"; }

.fa-utensils {
  --fa: "\F2E7";
  --fa--fa: "\F2E7\F2E7"; }

.fa-cutlery {
  --fa: "\F2E7";
  --fa--fa: "\F2E7\F2E7"; }

.fa-arrow-up-wide-short {
  --fa: "\F161";
  --fa--fa: "\F161\F161"; }

.fa-sort-amount-up {
  --fa: "\F161";
  --fa--fa: "\F161\F161"; }

.fa-chart-pie-simple-circle-dollar {
  --fa: "\E605";
  --fa--fa: "\E605\E605"; }

.fa-balloons {
  --fa: "\E2E4";
  --fa--fa: "\E2E4\E2E4"; }

.fa-mill-sign {
  --fa: "\E1ED";
  --fa--fa: "\E1ED\E1ED"; }

.fa-bowl-rice {
  --fa: "\E2EB";
  --fa--fa: "\E2EB\E2EB"; }

.fa-timeline-arrow {
  --fa: "\E29D";
  --fa--fa: "\E29D\E29D"; }

.fa-skull {
  --fa: "\F54C";
  --fa--fa: "\F54C\F54C"; }

.fa-game-board-simple {
  --fa: "\F868";
  --fa--fa: "\F868\F868"; }

.fa-game-board-alt {
  --fa: "\F868";
  --fa--fa: "\F868\F868"; }

.fa-circle-video {
  --fa: "\E12B";
  --fa--fa: "\E12B\E12B"; }

.fa-video-circle {
  --fa: "\E12B";
  --fa--fa: "\E12B\E12B"; }

.fa-chart-scatter-bubble {
  --fa: "\E0E9";
  --fa--fa: "\E0E9\E0E9"; }

.fa-house-turret {
  --fa: "\E1B4";
  --fa--fa: "\E1B4\E1B4"; }

.fa-banana {
  --fa: "\E2E5";
  --fa--fa: "\E2E5\E2E5"; }

.fa-hand-holding-skull {
  --fa: "\E1A4";
  --fa--fa: "\E1A4\E1A4"; }

.fa-people-dress {
  --fa: "\E217";
  --fa--fa: "\E217\E217"; }

.fa-loveseat {
  --fa: "\F4CC";
  --fa--fa: "\F4CC\F4CC"; }

.fa-couch-small {
  --fa: "\F4CC";
  --fa--fa: "\F4CC\F4CC"; }

.fa-tower-broadcast {
  --fa: "\F519";
  --fa--fa: "\F519\F519"; }

.fa-broadcast-tower {
  --fa: "\F519";
  --fa--fa: "\F519\F519"; }

.fa-truck-pickup {
  --fa: "\F63C";
  --fa--fa: "\F63C\F63C"; }

.fa-block-quote {
  --fa: "\E0B5";
  --fa--fa: "\E0B5\E0B5"; }

.fa-up-long {
  --fa: "\F30C";
  --fa--fa: "\F30C\F30C"; }

.fa-long-arrow-alt-up {
  --fa: "\F30C";
  --fa--fa: "\F30C\F30C"; }

.fa-stop {
  --fa: "\F04D";
  --fa--fa: "\F04D\F04D"; }

.fa-code-merge {
  --fa: "\F387";
  --fa--fa: "\F387\F387"; }

.fa-money-check-dollar-pen {
  --fa: "\F873";
  --fa--fa: "\F873\F873"; }

.fa-money-check-edit-alt {
  --fa: "\F873";
  --fa--fa: "\F873\F873"; }

.fa-up-from-line {
  --fa: "\F346";
  --fa--fa: "\F346\F346"; }

.fa-arrow-alt-from-bottom {
  --fa: "\F346";
  --fa--fa: "\F346\F346"; }

.fa-upload {
  --fa: "\F093";
  --fa--fa: "\F093\F093"; }

.fa-hurricane {
  --fa: "\F751";
  --fa--fa: "\F751\F751"; }

.fa-grid-round-2-plus {
  --fa: "\E5DC";
  --fa--fa: "\E5DC\E5DC"; }

.fa-people-pants {
  --fa: "\E219";
  --fa--fa: "\E219\E219"; }

.fa-mound {
  --fa: "\E52D";
  --fa--fa: "\E52D\E52D"; }

.fa-windsock {
  --fa: "\F777";
  --fa--fa: "\F777\F777"; }

.fa-circle-half {
  --fa: "\E110";
  --fa--fa: "\E110\E110"; }

.fa-brake-warning {
  --fa: "\E0C7";
  --fa--fa: "\E0C7\E0C7"; }

.fa-toilet-portable {
  --fa: "\E583";
  --fa--fa: "\E583\E583"; }

.fa-compact-disc {
  --fa: "\F51F";
  --fa--fa: "\F51F\F51F"; }

.fa-file-arrow-down {
  --fa: "\F56D";
  --fa--fa: "\F56D\F56D"; }

.fa-file-download {
  --fa: "\F56D";
  --fa--fa: "\F56D\F56D"; }

.fa-saxophone-fire {
  --fa: "\F8DB";
  --fa--fa: "\F8DB\F8DB"; }

.fa-sax-hot {
  --fa: "\F8DB";
  --fa--fa: "\F8DB\F8DB"; }

.fa-camera-web-slash {
  --fa: "\F833";
  --fa--fa: "\F833\F833"; }

.fa-webcam-slash {
  --fa: "\F833";
  --fa--fa: "\F833\F833"; }

.fa-folder-medical {
  --fa: "\E18C";
  --fa--fa: "\E18C\E18C"; }

.fa-folder-gear {
  --fa: "\E187";
  --fa--fa: "\E187\E187"; }

.fa-folder-cog {
  --fa: "\E187";
  --fa--fa: "\E187\E187"; }

.fa-hand-wave {
  --fa: "\E1A7";
  --fa--fa: "\E1A7\E1A7"; }

.fa-arrow-up-arrow-down {
  --fa: "\E099";
  --fa--fa: "\E099\E099"; }

.fa-sort-up-down {
  --fa: "\E099";
  --fa--fa: "\E099\E099"; }

.fa-caravan {
  --fa: "\F8FF";
  --fa--fa: "\F8FF\F8FF"; }

.fa-shield-cat {
  --fa: "\E572";
  --fa--fa: "\E572\E572"; }

.fa-message-slash {
  --fa: "\F4A9";
  --fa--fa: "\F4A9\F4A9"; }

.fa-comment-alt-slash {
  --fa: "\F4A9";
  --fa--fa: "\F4A9\F4A9"; }

.fa-bolt {
  --fa: "\F0E7";
  --fa--fa: "\F0E7\F0E7"; }

.fa-zap {
  --fa: "\F0E7";
  --fa--fa: "\F0E7\F0E7"; }

.fa-trash-can-check {
  --fa: "\E2A9";
  --fa--fa: "\E2A9\E2A9"; }

.fa-glass-water {
  --fa: "\E4F4";
  --fa--fa: "\E4F4\E4F4"; }

.fa-oil-well {
  --fa: "\E532";
  --fa--fa: "\E532\E532"; }

.fa-table-cells-column-unlock {
  --fa: "\E690";
  --fa--fa: "\E690\E690"; }

.fa-person-simple {
  --fa: "\E220";
  --fa--fa: "\E220\E220"; }

.fa-arrow-turn-left-up {
  --fa: "\E634";
  --fa--fa: "\E634\E634"; }

.fa-vault {
  --fa: "\E2C5";
  --fa--fa: "\E2C5\E2C5"; }

.fa-mars {
  --fa: "\F222";
  --fa--fa: "\F222\F222"; }

.fa-toilet {
  --fa: "\F7D8";
  --fa--fa: "\F7D8\F7D8"; }

.fa-plane-circle-xmark {
  --fa: "\E557";
  --fa--fa: "\E557\E557"; }

.fa-yen-sign {
  --fa: "\F157";
  --fa--fa: "\F157\F157"; }

.fa-cny {
  --fa: "\F157";
  --fa--fa: "\F157\F157"; }

.fa-jpy {
  --fa: "\F157";
  --fa--fa: "\F157\F157"; }

.fa-rmb {
  --fa: "\F157";
  --fa--fa: "\F157\F157"; }

.fa-yen {
  --fa: "\F157";
  --fa--fa: "\F157\F157"; }

.fa-gear-code {
  --fa: "\E5E8";
  --fa--fa: "\E5E8\E5E8"; }

.fa-notes {
  --fa: "\E202";
  --fa--fa: "\E202\E202"; }

.fa-ruble-sign {
  --fa: "\F158";
  --fa--fa: "\F158\F158"; }

.fa-rouble {
  --fa: "\F158";
  --fa--fa: "\F158\F158"; }

.fa-rub {
  --fa: "\F158";
  --fa--fa: "\F158\F158"; }

.fa-ruble {
  --fa: "\F158";
  --fa--fa: "\F158\F158"; }

.fa-trash-undo {
  --fa: "\F895";
  --fa--fa: "\F895\F895"; }

.fa-trash-arrow-turn-left {
  --fa: "\F895";
  --fa--fa: "\F895\F895"; }

.fa-champagne-glass {
  --fa: "\F79E";
  --fa--fa: "\F79E\F79E"; }

.fa-glass-champagne {
  --fa: "\F79E";
  --fa--fa: "\F79E\F79E"; }

.fa-objects-align-center-horizontal {
  --fa: "\E3BC";
  --fa--fa: "\E3BC\E3BC"; }

.fa-sun {
  --fa: "\F185";
  --fa--fa: "\F185\F185"; }

.fa-trash-can-slash {
  --fa: "\E2AD";
  --fa--fa: "\E2AD\E2AD"; }

.fa-trash-alt-slash {
  --fa: "\E2AD";
  --fa--fa: "\E2AD\E2AD"; }

.fa-screen-users {
  --fa: "\F63D";
  --fa--fa: "\F63D\F63D"; }

.fa-users-class {
  --fa: "\F63D";
  --fa--fa: "\F63D\F63D"; }

.fa-guitar {
  --fa: "\F7A6";
  --fa--fa: "\F7A6\F7A6"; }

.fa-square-arrow-left {
  --fa: "\F33A";
  --fa--fa: "\F33A\F33A"; }

.fa-arrow-square-left {
  --fa: "\F33A";
  --fa--fa: "\F33A\F33A"; }

.fa-square-8 {
  --fa: "\E25D";
  --fa--fa: "\E25D\E25D"; }

.fa-face-smile-hearts {
  --fa: "\E390";
  --fa--fa: "\E390\E390"; }

.fa-brackets-square {
  --fa: "\F7E9";
  --fa--fa: "\F7E9\F7E9"; }

.fa-brackets {
  --fa: "\F7E9";
  --fa--fa: "\F7E9\F7E9"; }

.fa-laptop-arrow-down {
  --fa: "\E1C6";
  --fa--fa: "\E1C6\E1C6"; }

.fa-hockey-stick-puck {
  --fa: "\E3AE";
  --fa--fa: "\E3AE\E3AE"; }

.fa-house-tree {
  --fa: "\E1B3";
  --fa--fa: "\E1B3\E1B3"; }

.fa-signal-fair {
  --fa: "\F68D";
  --fa--fa: "\F68D\F68D"; }

.fa-signal-2 {
  --fa: "\F68D";
  --fa--fa: "\F68D\F68D"; }

.fa-face-laugh-wink {
  --fa: "\F59C";
  --fa--fa: "\F59C\F59C"; }

.fa-laugh-wink {
  --fa: "\F59C";
  --fa--fa: "\F59C\F59C"; }

.fa-circle-dollar {
  --fa: "\F2E8";
  --fa--fa: "\F2E8\F2E8"; }

.fa-dollar-circle {
  --fa: "\F2E8";
  --fa--fa: "\F2E8\F2E8"; }

.fa-usd-circle {
  --fa: "\F2E8";
  --fa--fa: "\F2E8\F2E8"; }

.fa-horse-head {
  --fa: "\F7AB";
  --fa--fa: "\F7AB\F7AB"; }

.fa-arrows-repeat {
  --fa: "\F364";
  --fa--fa: "\F364\F364"; }

.fa-repeat-alt {
  --fa: "\F364";
  --fa--fa: "\F364\F364"; }

.fa-bore-hole {
  --fa: "\E4C3";
  --fa--fa: "\E4C3\E4C3"; }

.fa-industry {
  --fa: "\F275";
  --fa--fa: "\F275\F275"; }

.fa-image-polaroid {
  --fa: "\F8C4";
  --fa--fa: "\F8C4\F8C4"; }

.fa-wave-triangle {
  --fa: "\F89A";
  --fa--fa: "\F89A\F89A"; }

.fa-turn-left-down {
  --fa: "\E637";
  --fa--fa: "\E637\E637"; }

.fa-person-running-fast {
  --fa: "\E5FF";
  --fa--fa: "\E5FF\E5FF"; }

.fa-circle-down {
  --fa: "\F358";
  --fa--fa: "\F358\F358"; }

.fa-arrow-alt-circle-down {
  --fa: "\F358";
  --fa--fa: "\F358\F358"; }

.fa-grill {
  --fa: "\E5A3";
  --fa--fa: "\E5A3\E5A3"; }

.fa-arrows-turn-to-dots {
  --fa: "\E4C1";
  --fa--fa: "\E4C1\E4C1"; }

.fa-chart-mixed {
  --fa: "\F643";
  --fa--fa: "\F643\F643"; }

.fa-analytics {
  --fa: "\F643";
  --fa--fa: "\F643\F643"; }

.fa-florin-sign {
  --fa: "\E184";
  --fa--fa: "\E184\E184"; }

.fa-arrow-down-short-wide {
  --fa: "\F884";
  --fa--fa: "\F884\F884"; }

.fa-sort-amount-desc {
  --fa: "\F884";
  --fa--fa: "\F884\F884"; }

.fa-sort-amount-down-alt {
  --fa: "\F884";
  --fa--fa: "\F884\F884"; }

.fa-less-than {
  --fa: "<";
  --fa--fa: "<<"; }

.fa-display-code {
  --fa: "\E165";
  --fa--fa: "\E165\E165"; }

.fa-desktop-code {
  --fa: "\E165";
  --fa--fa: "\E165\E165"; }

.fa-face-drooling {
  --fa: "\E372";
  --fa--fa: "\E372\E372"; }

.fa-oil-temperature {
  --fa: "\F614";
  --fa--fa: "\F614\F614"; }

.fa-oil-temp {
  --fa: "\F614";
  --fa--fa: "\F614\F614"; }

.fa-square-question {
  --fa: "\F2FD";
  --fa--fa: "\F2FD\F2FD"; }

.fa-question-square {
  --fa: "\F2FD";
  --fa--fa: "\F2FD\F2FD"; }

.fa-air-conditioner {
  --fa: "\F8F4";
  --fa--fa: "\F8F4\F8F4"; }

.fa-angle-down {
  --fa: "\F107";
  --fa--fa: "\F107\F107"; }

.fa-mountains {
  --fa: "\F6FD";
  --fa--fa: "\F6FD\F6FD"; }

.fa-omega {
  --fa: "\F67A";
  --fa--fa: "\F67A\F67A"; }

.fa-car-tunnel {
  --fa: "\E4DE";
  --fa--fa: "\E4DE\E4DE"; }

.fa-person-dolly-empty {
  --fa: "\F4D1";
  --fa--fa: "\F4D1\F4D1"; }

.fa-pan-food {
  --fa: "\E42B";
  --fa--fa: "\E42B\E42B"; }

.fa-head-side-cough {
  --fa: "\E061";
  --fa--fa: "\E061\E061"; }

.fa-grip-lines {
  --fa: "\F7A4";
  --fa--fa: "\F7A4\F7A4"; }

.fa-thumbs-down {
  --fa: "\F165";
  --fa--fa: "\F165\F165"; }

.fa-user-lock {
  --fa: "\F502";
  --fa--fa: "\F502\F502"; }

.fa-arrow-right-long {
  --fa: "\F178";
  --fa--fa: "\F178\F178"; }

.fa-long-arrow-right {
  --fa: "\F178";
  --fa--fa: "\F178\F178"; }

.fa-tickets-airline {
  --fa: "\E29B";
  --fa--fa: "\E29B\E29B"; }

.fa-tickets-perforated-plane {
  --fa: "\E29B";
  --fa--fa: "\E29B\E29B"; }

.fa-tickets-plane {
  --fa: "\E29B";
  --fa--fa: "\E29B\E29B"; }

.fa-tent-double-peak {
  --fa: "\E627";
  --fa--fa: "\E627\E627"; }

.fa-anchor-circle-xmark {
  --fa: "\E4AC";
  --fa--fa: "\E4AC\E4AC"; }

.fa-ellipsis {
  --fa: "\F141";
  --fa--fa: "\F141\F141"; }

.fa-ellipsis-h {
  --fa: "\F141";
  --fa--fa: "\F141\F141"; }

.fa-nfc-slash {
  --fa: "\E1FC";
  --fa--fa: "\E1FC\E1FC"; }

.fa-chess-pawn {
  --fa: "\F443";
  --fa--fa: "\F443\F443"; }

.fa-kit-medical {
  --fa: "\F479";
  --fa--fa: "\F479\F479"; }

.fa-first-aid {
  --fa: "\F479";
  --fa--fa: "\F479\F479"; }

.fa-grid-2-plus {
  --fa: "\E197";
  --fa--fa: "\E197\E197"; }

.fa-bells {
  --fa: "\F77F";
  --fa--fa: "\F77F\F77F"; }

.fa-person-through-window {
  --fa: "\E5A9";
  --fa--fa: "\E5A9\E5A9"; }

.fa-toolbox {
  --fa: "\F552";
  --fa--fa: "\F552\F552"; }

.fa-globe-wifi {
  --fa: "\E685";
  --fa--fa: "\E685\E685"; }

.fa-envelope-dot {
  --fa: "\E16F";
  --fa--fa: "\E16F\E16F"; }

.fa-envelope-badge {
  --fa: "\E16F";
  --fa--fa: "\E16F\E16F"; }

.fa-magnifying-glass-waveform {
  --fa: "\E661";
  --fa--fa: "\E661\E661"; }

.fa-hands-holding-circle {
  --fa: "\E4FB";
  --fa--fa: "\E4FB\E4FB"; }

.fa-bug {
  --fa: "\F188";
  --fa--fa: "\F188\F188"; }

.fa-bowl-chopsticks {
  --fa: "\E2E9";
  --fa--fa: "\E2E9\E2E9"; }

.fa-credit-card {
  --fa: "\F09D";
  --fa--fa: "\F09D\F09D"; }

.fa-credit-card-alt {
  --fa: "\F09D";
  --fa--fa: "\F09D\F09D"; }

.fa-circle-s {
  --fa: "\E121";
  --fa--fa: "\E121\E121"; }

.fa-box-ballot {
  --fa: "\F735";
  --fa--fa: "\F735\F735"; }

.fa-car {
  --fa: "\F1B9";
  --fa--fa: "\F1B9\F1B9"; }

.fa-automobile {
  --fa: "\F1B9";
  --fa--fa: "\F1B9\F1B9"; }

.fa-hand-holding-hand {
  --fa: "\E4F7";
  --fa--fa: "\E4F7\E4F7"; }

.fa-user-tie-hair {
  --fa: "\E45F";
  --fa--fa: "\E45F\E45F"; }

.fa-podium-star {
  --fa: "\F758";
  --fa--fa: "\F758\F758"; }

.fa-user-hair-mullet {
  --fa: "\E45C";
  --fa--fa: "\E45C\E45C"; }

.fa-business-front {
  --fa: "\E45C";
  --fa--fa: "\E45C\E45C"; }

.fa-party-back {
  --fa: "\E45C";
  --fa--fa: "\E45C\E45C"; }

.fa-trian-balbot {
  --fa: "\E45C";
  --fa--fa: "\E45C\E45C"; }

.fa-microphone-stand {
  --fa: "\F8CB";
  --fa--fa: "\F8CB\F8CB"; }

.fa-book-open-reader {
  --fa: "\F5DA";
  --fa--fa: "\F5DA\F5DA"; }

.fa-book-reader {
  --fa: "\F5DA";
  --fa--fa: "\F5DA\F5DA"; }

.fa-family-dress {
  --fa: "\E301";
  --fa--fa: "\E301\E301"; }

.fa-circle-x {
  --fa: "\E12E";
  --fa--fa: "\E12E\E12E"; }

.fa-cabin {
  --fa: "\E46D";
  --fa--fa: "\E46D\E46D"; }

.fa-mountain-sun {
  --fa: "\E52F";
  --fa--fa: "\E52F\E52F"; }

.fa-chart-simple-horizontal {
  --fa: "\E474";
  --fa--fa: "\E474\E474"; }

.fa-arrows-left-right-to-line {
  --fa: "\E4BA";
  --fa--fa: "\E4BA\E4BA"; }

.fa-hand-back-point-left {
  --fa: "\E19F";
  --fa--fa: "\E19F\E19F"; }

.fa-message-dots {
  --fa: "\F4A3";
  --fa--fa: "\F4A3\F4A3"; }

.fa-comment-alt-dots {
  --fa: "\F4A3";
  --fa--fa: "\F4A3\F4A3"; }

.fa-messaging {
  --fa: "\F4A3";
  --fa--fa: "\F4A3\F4A3"; }

.fa-file-heart {
  --fa: "\E176";
  --fa--fa: "\E176\E176"; }

.fa-beer-mug {
  --fa: "\E0B3";
  --fa--fa: "\E0B3\E0B3"; }

.fa-beer-foam {
  --fa: "\E0B3";
  --fa--fa: "\E0B3\E0B3"; }

.fa-dice-d20 {
  --fa: "\F6CF";
  --fa--fa: "\F6CF\F6CF"; }

.fa-drone {
  --fa: "\F85F";
  --fa--fa: "\F85F\F85F"; }

.fa-truck-droplet {
  --fa: "\E58C";
  --fa--fa: "\E58C\E58C"; }

.fa-file-circle-xmark {
  --fa: "\E5A1";
  --fa--fa: "\E5A1\E5A1"; }

.fa-temperature-arrow-up {
  --fa: "\E040";
  --fa--fa: "\E040\E040"; }

.fa-temperature-up {
  --fa: "\E040";
  --fa--fa: "\E040\E040"; }

.fa-medal {
  --fa: "\F5A2";
  --fa--fa: "\F5A2\F5A2"; }

.fa-person-fairy {
  --fa: "\E608";
  --fa--fa: "\E608\E608"; }

.fa-bed {
  --fa: "\F236";
  --fa--fa: "\F236\F236"; }

.fa-book-copy {
  --fa: "\E0BE";
  --fa--fa: "\E0BE\E0BE"; }

.fa-square-h {
  --fa: "\F0FD";
  --fa--fa: "\F0FD\F0FD"; }

.fa-h-square {
  --fa: "\F0FD";
  --fa--fa: "\F0FD\F0FD"; }

.fa-square-c {
  --fa: "\E266";
  --fa--fa: "\E266\E266"; }

.fa-clock-two {
  --fa: "\E35A";
  --fa--fa: "\E35A\E35A"; }

.fa-square-ellipsis-vertical {
  --fa: "\E26F";
  --fa--fa: "\E26F\E26F"; }

.fa-calendar-users {
  --fa: "\E5E2";
  --fa--fa: "\E5E2\E5E2"; }

.fa-podcast {
  --fa: "\F2CE";
  --fa--fa: "\F2CE\F2CE"; }

.fa-bee {
  --fa: "\E0B2";
  --fa--fa: "\E0B2\E0B2"; }

.fa-temperature-full {
  --fa: "\F2C7";
  --fa--fa: "\F2C7\F2C7"; }

.fa-temperature-4 {
  --fa: "\F2C7";
  --fa--fa: "\F2C7\F2C7"; }

.fa-thermometer-4 {
  --fa: "\F2C7";
  --fa--fa: "\F2C7\F2C7"; }

.fa-thermometer-full {
  --fa: "\F2C7";
  --fa--fa: "\F2C7\F2C7"; }

.fa-bell {
  --fa: "\F0F3";
  --fa--fa: "\F0F3\F0F3"; }

.fa-candy-bar {
  --fa: "\E3E8";
  --fa--fa: "\E3E8\E3E8"; }

.fa-chocolate-bar {
  --fa: "\E3E8";
  --fa--fa: "\E3E8\E3E8"; }

.fa-xmark-large {
  --fa: "\E59B";
  --fa--fa: "\E59B\E59B"; }

.fa-pinata {
  --fa: "\E3C3";
  --fa--fa: "\E3C3\E3C3"; }

.fa-file-ppt {
  --fa: "\E64A";
  --fa--fa: "\E64A\E64A"; }

.fa-arrows-from-line {
  --fa: "\E0A4";
  --fa--fa: "\E0A4\E0A4"; }

.fa-superscript {
  --fa: "\F12B";
  --fa--fa: "\F12B\F12B"; }

.fa-bowl-spoon {
  --fa: "\E3E0";
  --fa--fa: "\E3E0\E3E0"; }

.fa-hexagon-check {
  --fa: "\E416";
  --fa--fa: "\E416\E416"; }

.fa-plug-circle-xmark {
  --fa: "\E560";
  --fa--fa: "\E560\E560"; }

.fa-star-of-life {
  --fa: "\F621";
  --fa--fa: "\F621\F621"; }

.fa-phone-slash {
  --fa: "\F3DD";
  --fa--fa: "\F3DD\F3DD"; }

.fa-traffic-light-stop {
  --fa: "\F63A";
  --fa--fa: "\F63A\F63A"; }

.fa-paint-roller {
  --fa: "\F5AA";
  --fa--fa: "\F5AA\F5AA"; }

.fa-accent-grave {
  --fa: "`";
  --fa--fa: "``"; }

.fa-handshake-angle {
  --fa: "\F4C4";
  --fa--fa: "\F4C4\F4C4"; }

.fa-hands-helping {
  --fa: "\F4C4";
  --fa--fa: "\F4C4\F4C4"; }

.fa-circle-0 {
  --fa: "\E0ED";
  --fa--fa: "\E0ED\E0ED"; }

.fa-dial-med-low {
  --fa: "\E160";
  --fa--fa: "\E160\E160"; }

.fa-location-dot {
  --fa: "\F3C5";
  --fa--fa: "\F3C5\F3C5"; }

.fa-map-marker-alt {
  --fa: "\F3C5";
  --fa--fa: "\F3C5\F3C5"; }

.fa-crab {
  --fa: "\E3FF";
  --fa--fa: "\E3FF\E3FF"; }

.fa-box-open-full {
  --fa: "\F49C";
  --fa--fa: "\F49C\F49C"; }

.fa-box-full {
  --fa: "\F49C";
  --fa--fa: "\F49C\F49C"; }

.fa-file {
  --fa: "\F15B";
  --fa--fa: "\F15B\F15B"; }

.fa-greater-than {
  --fa: ">";
  --fa--fa: ">>"; }

.fa-quotes {
  --fa: "\E234";
  --fa--fa: "\E234\E234"; }

.fa-pretzel {
  --fa: "\E441";
  --fa--fa: "\E441\E441"; }

.fa-t-rex {
  --fa: "\E629";
  --fa--fa: "\E629\E629"; }

.fa-person-swimming {
  --fa: "\F5C4";
  --fa--fa: "\F5C4\F5C4"; }

.fa-swimmer {
  --fa: "\F5C4";
  --fa--fa: "\F5C4\F5C4"; }

.fa-arrow-down {
  --fa: "\F063";
  --fa--fa: "\F063\F063"; }

.fa-user-robot-xmarks {
  --fa: "\E4A7";
  --fa--fa: "\E4A7\E4A7"; }

.fa-message-quote {
  --fa: "\E1E4";
  --fa--fa: "\E1E4\E1E4"; }

.fa-comment-alt-quote {
  --fa: "\E1E4";
  --fa--fa: "\E1E4\E1E4"; }

.fa-candy-corn {
  --fa: "\F6BD";
  --fa--fa: "\F6BD\F6BD"; }

.fa-folder-magnifying-glass {
  --fa: "\E18B";
  --fa--fa: "\E18B\E18B"; }

.fa-folder-search {
  --fa: "\E18B";
  --fa--fa: "\E18B\E18B"; }

.fa-notebook {
  --fa: "\E201";
  --fa--fa: "\E201\E201"; }

.fa-circle-wifi {
  --fa: "\E67D";
  --fa--fa: "\E67D\E67D"; }

.fa-droplet {
  --fa: "\F043";
  --fa--fa: "\F043\F043"; }

.fa-tint {
  --fa: "\F043";
  --fa--fa: "\F043\F043"; }

.fa-bullseye-pointer {
  --fa: "\F649";
  --fa--fa: "\F649\F649"; }

.fa-eraser {
  --fa: "\F12D";
  --fa--fa: "\F12D\F12D"; }

.fa-hexagon-image {
  --fa: "\E504";
  --fa--fa: "\E504\E504"; }

.fa-earth-americas {
  --fa: "\F57D";
  --fa--fa: "\F57D\F57D"; }

.fa-earth {
  --fa: "\F57D";
  --fa--fa: "\F57D\F57D"; }

.fa-earth-america {
  --fa: "\F57D";
  --fa--fa: "\F57D\F57D"; }

.fa-globe-americas {
  --fa: "\F57D";
  --fa--fa: "\F57D\F57D"; }

.fa-file-svg {
  --fa: "\E64B";
  --fa--fa: "\E64B\E64B"; }

.fa-crate-apple {
  --fa: "\F6B1";
  --fa--fa: "\F6B1\F6B1"; }

.fa-apple-crate {
  --fa: "\F6B1";
  --fa--fa: "\F6B1\F6B1"; }

.fa-person-burst {
  --fa: "\E53B";
  --fa--fa: "\E53B\E53B"; }

.fa-game-board {
  --fa: "\F867";
  --fa--fa: "\F867\F867"; }

.fa-hat-chef {
  --fa: "\F86B";
  --fa--fa: "\F86B\F86B"; }

.fa-hand-back-point-right {
  --fa: "\E1A1";
  --fa--fa: "\E1A1\E1A1"; }

.fa-dove {
  --fa: "\F4BA";
  --fa--fa: "\F4BA\F4BA"; }

.fa-snowflake-droplets {
  --fa: "\E5C1";
  --fa--fa: "\E5C1\E5C1"; }

.fa-battery-empty {
  --fa: "\F244";
  --fa--fa: "\F244\F244"; }

.fa-battery-0 {
  --fa: "\F244";
  --fa--fa: "\F244\F244"; }

.fa-grid-4 {
  --fa: "\E198";
  --fa--fa: "\E198\E198"; }

.fa-socks {
  --fa: "\F696";
  --fa--fa: "\F696\F696"; }

.fa-face-sunglasses {
  --fa: "\E398";
  --fa--fa: "\E398\E398"; }

.fa-inbox {
  --fa: "\F01C";
  --fa--fa: "\F01C\F01C"; }

.fa-square-0 {
  --fa: "\E255";
  --fa--fa: "\E255\E255"; }

.fa-section {
  --fa: "\E447";
  --fa--fa: "\E447\E447"; }

.fa-square-this-way-up {
  --fa: "\F49F";
  --fa--fa: "\F49F\F49F"; }

.fa-box-up {
  --fa: "\F49F";
  --fa--fa: "\F49F\F49F"; }

.fa-gauge-high {
  --fa: "\F625";
  --fa--fa: "\F625\F625"; }

.fa-tachometer-alt {
  --fa: "\F625";
  --fa--fa: "\F625\F625"; }

.fa-tachometer-alt-fast {
  --fa: "\F625";
  --fa--fa: "\F625\F625"; }

.fa-square-ampersand {
  --fa: "\E260";
  --fa--fa: "\E260\E260"; }

.fa-envelope-open-text {
  --fa: "\F658";
  --fa--fa: "\F658\F658"; }

.fa-lamp-desk {
  --fa: "\E014";
  --fa--fa: "\E014\E014"; }

.fa-hospital {
  --fa: "\F0F8";
  --fa--fa: "\F0F8\F0F8"; }

.fa-hospital-alt {
  --fa: "\F0F8";
  --fa--fa: "\F0F8\F0F8"; }

.fa-hospital-wide {
  --fa: "\F0F8";
  --fa--fa: "\F0F8\F0F8"; }

.fa-poll-people {
  --fa: "\F759";
  --fa--fa: "\F759\F759"; }

.fa-whiskey-glass-ice {
  --fa: "\F7A1";
  --fa--fa: "\F7A1\F7A1"; }

.fa-glass-whiskey-rocks {
  --fa: "\F7A1";
  --fa--fa: "\F7A1\F7A1"; }

.fa-wine-bottle {
  --fa: "\F72F";
  --fa--fa: "\F72F\F72F"; }

.fa-chess-rook {
  --fa: "\F447";
  --fa--fa: "\F447\F447"; }

.fa-user-bounty-hunter {
  --fa: "\E2BF";
  --fa--fa: "\E2BF\E2BF"; }

.fa-bars-staggered {
  --fa: "\F550";
  --fa--fa: "\F550\F550"; }

.fa-reorder {
  --fa: "\F550";
  --fa--fa: "\F550\F550"; }

.fa-stream {
  --fa: "\F550";
  --fa--fa: "\F550\F550"; }

.fa-diagram-sankey {
  --fa: "\E158";
  --fa--fa: "\E158\E158"; }

.fa-cloud-hail-mixed {
  --fa: "\F73A";
  --fa--fa: "\F73A\F73A"; }

.fa-circle-up-left {
  --fa: "\E128";
  --fa--fa: "\E128\E128"; }

.fa-dharmachakra {
  --fa: "\F655";
  --fa--fa: "\F655\F655"; }

.fa-objects-align-left {
  --fa: "\E3BE";
  --fa--fa: "\E3BE\E3BE"; }

.fa-oil-can-drip {
  --fa: "\E205";
  --fa--fa: "\E205\E205"; }

.fa-face-smiling-hands {
  --fa: "\E396";
  --fa--fa: "\E396\E396"; }

.fa-broccoli {
  --fa: "\E3E2";
  --fa--fa: "\E3E2\E3E2"; }

.fa-route-interstate {
  --fa: "\F61B";
  --fa--fa: "\F61B\F61B"; }

.fa-ear-muffs {
  --fa: "\F795";
  --fa--fa: "\F795\F795"; }

.fa-hotdog {
  --fa: "\F80F";
  --fa--fa: "\F80F\F80F"; }

.fa-transporter-empty {
  --fa: "\E046";
  --fa--fa: "\E046\E046"; }

.fa-person-walking-with-cane {
  --fa: "\F29D";
  --fa--fa: "\F29D\F29D"; }

.fa-blind {
  --fa: "\F29D";
  --fa--fa: "\F29D\F29D"; }

.fa-angle-90 {
  --fa: "\E08D";
  --fa--fa: "\E08D\E08D"; }

.fa-rectangle-terminal {
  --fa: "\E236";
  --fa--fa: "\E236\E236"; }

.fa-kite {
  --fa: "\F6F4";
  --fa--fa: "\F6F4\F6F4"; }

.fa-drum {
  --fa: "\F569";
  --fa--fa: "\F569\F569"; }

.fa-scrubber {
  --fa: "\F2F8";
  --fa--fa: "\F2F8\F2F8"; }

.fa-ice-cream {
  --fa: "\F810";
  --fa--fa: "\F810\F810"; }

.fa-heart-circle-bolt {
  --fa: "\E4FC";
  --fa--fa: "\E4FC\E4FC"; }

.fa-fish-bones {
  --fa: "\E304";
  --fa--fa: "\E304\E304"; }

.fa-deer-rudolph {
  --fa: "\F78F";
  --fa--fa: "\F78F\F78F"; }

.fa-fax {
  --fa: "\F1AC";
  --fa--fa: "\F1AC\F1AC"; }

.fa-paragraph {
  --fa: "\F1DD";
  --fa--fa: "\F1DD\F1DD"; }

.fa-head-side-heart {
  --fa: "\E1AA";
  --fa--fa: "\E1AA\E1AA"; }

.fa-square-e {
  --fa: "\E26D";
  --fa--fa: "\E26D\E26D"; }

.fa-meter-fire {
  --fa: "\E1EB";
  --fa--fa: "\E1EB\E1EB"; }

.fa-cloud-hail {
  --fa: "\F739";
  --fa--fa: "\F739\F739"; }

.fa-check-to-slot {
  --fa: "\F772";
  --fa--fa: "\F772\F772"; }

.fa-vote-yea {
  --fa: "\F772";
  --fa--fa: "\F772\F772"; }

.fa-money-from-bracket {
  --fa: "\E312";
  --fa--fa: "\E312\E312"; }

.fa-star-half {
  --fa: "\F089";
  --fa--fa: "\F089\F089"; }

.fa-car-bus {
  --fa: "\F85A";
  --fa--fa: "\F85A\F85A"; }

.fa-speaker {
  --fa: "\F8DF";
  --fa--fa: "\F8DF\F8DF"; }

.fa-timer {
  --fa: "\E29E";
  --fa--fa: "\E29E\E29E"; }

.fa-boxes-stacked {
  --fa: "\F468";
  --fa--fa: "\F468\F468"; }

.fa-boxes {
  --fa: "\F468";
  --fa--fa: "\F468\F468"; }

.fa-boxes-alt {
  --fa: "\F468";
  --fa--fa: "\F468\F468"; }

.fa-landmark-magnifying-glass {
  --fa: "\E622";
  --fa--fa: "\E622\E622"; }

.fa-grill-hot {
  --fa: "\E5A5";
  --fa--fa: "\E5A5\E5A5"; }

.fa-ballot-check {
  --fa: "\F733";
  --fa--fa: "\F733\F733"; }

.fa-link {
  --fa: "\F0C1";
  --fa--fa: "\F0C1\F0C1"; }

.fa-chain {
  --fa: "\F0C1";
  --fa--fa: "\F0C1\F0C1"; }

.fa-ear-listen {
  --fa: "\F2A2";
  --fa--fa: "\F2A2\F2A2"; }

.fa-assistive-listening-systems {
  --fa: "\F2A2";
  --fa--fa: "\F2A2\F2A2"; }

.fa-file-minus {
  --fa: "\F318";
  --fa--fa: "\F318\F318"; }

.fa-tree-city {
  --fa: "\E587";
  --fa--fa: "\E587\E587"; }

.fa-play {
  --fa: "\F04B";
  --fa--fa: "\F04B\F04B"; }

.fa-font {
  --fa: "\F031";
  --fa--fa: "\F031\F031"; }

.fa-cup-togo {
  --fa: "\F6C5";
  --fa--fa: "\F6C5\F6C5"; }

.fa-coffee-togo {
  --fa: "\F6C5";
  --fa--fa: "\F6C5\F6C5"; }

.fa-square-down-left {
  --fa: "\E26B";
  --fa--fa: "\E26B\E26B"; }

.fa-burger-lettuce {
  --fa: "\E3E3";
  --fa--fa: "\E3E3\E3E3"; }

.fa-table-cells-row-lock {
  --fa: "\E67A";
  --fa--fa: "\E67A\E67A"; }

.fa-rupiah-sign {
  --fa: "\E23D";
  --fa--fa: "\E23D\E23D"; }

.fa-magnifying-glass {
  --fa: "\F002";
  --fa--fa: "\F002\F002"; }

.fa-search {
  --fa: "\F002";
  --fa--fa: "\F002\F002"; }

.fa-table-tennis-paddle-ball {
  --fa: "\F45D";
  --fa--fa: "\F45D\F45D"; }

.fa-ping-pong-paddle-ball {
  --fa: "\F45D";
  --fa--fa: "\F45D\F45D"; }

.fa-table-tennis {
  --fa: "\F45D";
  --fa--fa: "\F45D\F45D"; }

.fa-person-dots-from-line {
  --fa: "\F470";
  --fa--fa: "\F470\F470"; }

.fa-diagnoses {
  --fa: "\F470";
  --fa--fa: "\F470\F470"; }

.fa-chevrons-down {
  --fa: "\F322";
  --fa--fa: "\F322\F322"; }

.fa-chevron-double-down {
  --fa: "\F322";
  --fa--fa: "\F322\F322"; }

.fa-trash-can-arrow-up {
  --fa: "\F82A";
  --fa--fa: "\F82A\F82A"; }

.fa-trash-restore-alt {
  --fa: "\F82A";
  --fa--fa: "\F82A\F82A"; }

.fa-signal-good {
  --fa: "\F68E";
  --fa--fa: "\F68E\F68E"; }

.fa-signal-3 {
  --fa: "\F68E";
  --fa--fa: "\F68E\F68E"; }

.fa-location-question {
  --fa: "\F60B";
  --fa--fa: "\F60B\F60B"; }

.fa-map-marker-question {
  --fa: "\F60B";
  --fa--fa: "\F60B\F60B"; }

.fa-floppy-disk-circle-xmark {
  --fa: "\E181";
  --fa--fa: "\E181\E181"; }

.fa-floppy-disk-times {
  --fa: "\E181";
  --fa--fa: "\E181\E181"; }

.fa-save-circle-xmark {
  --fa: "\E181";
  --fa--fa: "\E181\E181"; }

.fa-save-times {
  --fa: "\E181";
  --fa--fa: "\E181\E181"; }

.fa-naira-sign {
  --fa: "\E1F6";
  --fa--fa: "\E1F6\E1F6"; }

.fa-peach {
  --fa: "\E20B";
  --fa--fa: "\E20B\E20B"; }

.fa-circles-overlap-3 {
  --fa: "\E6A1";
  --fa--fa: "\E6A1\E6A1"; }

.fa-pronoun {
  --fa: "\E6A1";
  --fa--fa: "\E6A1\E6A1"; }

.fa-taxi-bus {
  --fa: "\E298";
  --fa--fa: "\E298\E298"; }

.fa-bracket-curly {
  --fa: "{";
  --fa--fa: "{{"; }

.fa-bracket-curly-left {
  --fa: "{";
  --fa--fa: "{{"; }

.fa-lobster {
  --fa: "\E421";
  --fa--fa: "\E421\E421"; }

.fa-cart-flatbed-empty {
  --fa: "\F476";
  --fa--fa: "\F476\F476"; }

.fa-dolly-flatbed-empty {
  --fa: "\F476";
  --fa--fa: "\F476\F476"; }

.fa-colon {
  --fa: ":";
  --fa--fa: "::"; }

.fa-cart-arrow-down {
  --fa: "\F218";
  --fa--fa: "\F218\F218"; }

.fa-wand {
  --fa: "\F72A";
  --fa--fa: "\F72A\F72A"; }

.fa-walkie-talkie {
  --fa: "\F8EF";
  --fa--fa: "\F8EF\F8EF"; }

.fa-file-pen {
  --fa: "\F31C";
  --fa--fa: "\F31C\F31C"; }

.fa-file-edit {
  --fa: "\F31C";
  --fa--fa: "\F31C\F31C"; }

.fa-receipt {
  --fa: "\F543";
  --fa--fa: "\F543\F543"; }

.fa-table-picnic {
  --fa: "\E32D";
  --fa--fa: "\E32D\E32D"; }

.fa-square-pen {
  --fa: "\F14B";
  --fa--fa: "\F14B\F14B"; }

.fa-pen-square {
  --fa: "\F14B";
  --fa--fa: "\F14B\F14B"; }

.fa-pencil-square {
  --fa: "\F14B";
  --fa--fa: "\F14B\F14B"; }

.fa-circle-microphone-lines {
  --fa: "\E117";
  --fa--fa: "\E117\E117"; }

.fa-microphone-circle-alt {
  --fa: "\E117";
  --fa--fa: "\E117\E117"; }

.fa-display-slash {
  --fa: "\E2FA";
  --fa--fa: "\E2FA\E2FA"; }

.fa-desktop-slash {
  --fa: "\E2FA";
  --fa--fa: "\E2FA\E2FA"; }

.fa-suitcase-rolling {
  --fa: "\F5C1";
  --fa--fa: "\F5C1\F5C1"; }

.fa-person-circle-exclamation {
  --fa: "\E53F";
  --fa--fa: "\E53F\E53F"; }

.fa-transporter-2 {
  --fa: "\E044";
  --fa--fa: "\E044\E044"; }

.fa-user-hoodie {
  --fa: "\E68A";
  --fa--fa: "\E68A\E68A"; }

.fa-hands-holding-diamond {
  --fa: "\F47C";
  --fa--fa: "\F47C\F47C"; }

.fa-hand-receiving {
  --fa: "\F47C";
  --fa--fa: "\F47C\F47C"; }

.fa-money-bill-simple-wave {
  --fa: "\E1F2";
  --fa--fa: "\E1F2\E1F2"; }

.fa-chevron-down {
  --fa: "\F078";
  --fa--fa: "\F078\F078"; }

.fa-battery-full {
  --fa: "\F240";
  --fa--fa: "\F240\F240"; }

.fa-battery {
  --fa: "\F240";
  --fa--fa: "\F240\F240"; }

.fa-battery-5 {
  --fa: "\F240";
  --fa--fa: "\F240\F240"; }

.fa-bell-plus {
  --fa: "\F849";
  --fa--fa: "\F849\F849"; }

.fa-book-arrow-right {
  --fa: "\E0B9";
  --fa--fa: "\E0B9\E0B9"; }

.fa-hospitals {
  --fa: "\F80E";
  --fa--fa: "\F80E\F80E"; }

.fa-club {
  --fa: "\F327";
  --fa--fa: "\F327\F327"; }

.fa-skull-crossbones {
  --fa: "\F714";
  --fa--fa: "\F714\F714"; }

.fa-droplet-degree {
  --fa: "\F748";
  --fa--fa: "\F748\F748"; }

.fa-dewpoint {
  --fa: "\F748";
  --fa--fa: "\F748\F748"; }

.fa-code-compare {
  --fa: "\E13A";
  --fa--fa: "\E13A\E13A"; }

.fa-list-ul {
  --fa: "\F0CA";
  --fa--fa: "\F0CA\F0CA"; }

.fa-list-dots {
  --fa: "\F0CA";
  --fa--fa: "\F0CA\F0CA"; }

.fa-hand-holding-magic {
  --fa: "\F6E5";
  --fa--fa: "\F6E5\F6E5"; }

.fa-watermelon-slice {
  --fa: "\E337";
  --fa--fa: "\E337\E337"; }

.fa-circle-ellipsis {
  --fa: "\E10A";
  --fa--fa: "\E10A\E10A"; }

.fa-school-lock {
  --fa: "\E56F";
  --fa--fa: "\E56F\E56F"; }

.fa-tower-cell {
  --fa: "\E585";
  --fa--fa: "\E585\E585"; }

.fa-sd-cards {
  --fa: "\E240";
  --fa--fa: "\E240\E240"; }

.fa-jug-bottle {
  --fa: "\E5FB";
  --fa--fa: "\E5FB\E5FB"; }

.fa-down-long {
  --fa: "\F309";
  --fa--fa: "\F309\F309"; }

.fa-long-arrow-alt-down {
  --fa: "\F309";
  --fa--fa: "\F309\F309"; }

.fa-envelopes {
  --fa: "\E170";
  --fa--fa: "\E170\E170"; }

.fa-phone-office {
  --fa: "\F67D";
  --fa--fa: "\F67D\F67D"; }

.fa-ranking-star {
  --fa: "\E561";
  --fa--fa: "\E561\E561"; }

.fa-chess-king {
  --fa: "\F43F";
  --fa--fa: "\F43F\F43F"; }

.fa-nfc-pen {
  --fa: "\E1FA";
  --fa--fa: "\E1FA\E1FA"; }

.fa-person-harassing {
  --fa: "\E549";
  --fa--fa: "\E549\E549"; }

.fa-magnifying-glass-play {
  --fa: "\E660";
  --fa--fa: "\E660\E660"; }

.fa-hat-winter {
  --fa: "\F7A8";
  --fa--fa: "\F7A8\F7A8"; }

.fa-brazilian-real-sign {
  --fa: "\E46C";
  --fa--fa: "\E46C\E46C"; }

.fa-landmark-dome {
  --fa: "\F752";
  --fa--fa: "\F752\F752"; }

.fa-landmark-alt {
  --fa: "\F752";
  --fa--fa: "\F752\F752"; }

.fa-bone-break {
  --fa: "\F5D8";
  --fa--fa: "\F5D8\F5D8"; }

.fa-arrow-up {
  --fa: "\F062";
  --fa--fa: "\F062\F062"; }

.fa-down-from-dotted-line {
  --fa: "\E407";
  --fa--fa: "\E407\E407"; }

.fa-tv {
  --fa: "\F26C";
  --fa--fa: "\F26C\F26C"; }

.fa-television {
  --fa: "\F26C";
  --fa--fa: "\F26C\F26C"; }

.fa-tv-alt {
  --fa: "\F26C";
  --fa--fa: "\F26C\F26C"; }

.fa-border-left {
  --fa: "\F84F";
  --fa--fa: "\F84F\F84F"; }

.fa-circle-divide {
  --fa: "\E106";
  --fa--fa: "\E106\E106"; }

.fa-shrimp {
  --fa: "\E448";
  --fa--fa: "\E448\E448"; }

.fa-list-check {
  --fa: "\F0AE";
  --fa--fa: "\F0AE\F0AE"; }

.fa-tasks {
  --fa: "\F0AE";
  --fa--fa: "\F0AE\F0AE"; }

.fa-diagram-subtask {
  --fa: "\E479";
  --fa--fa: "\E479\E479"; }

.fa-jug-detergent {
  --fa: "\E519";
  --fa--fa: "\E519\E519"; }

.fa-circle-user {
  --fa: "\F2BD";
  --fa--fa: "\F2BD\F2BD"; }

.fa-user-circle {
  --fa: "\F2BD";
  --fa--fa: "\F2BD\F2BD"; }

.fa-square-y {
  --fa: "\E287";
  --fa--fa: "\E287\E287"; }

.fa-user-doctor-hair {
  --fa: "\E458";
  --fa--fa: "\E458\E458"; }

.fa-planet-ringed {
  --fa: "\E020";
  --fa--fa: "\E020\E020"; }

.fa-mushroom {
  --fa: "\E425";
  --fa--fa: "\E425\E425"; }

.fa-user-shield {
  --fa: "\F505";
  --fa--fa: "\F505\F505"; }

.fa-megaphone {
  --fa: "\F675";
  --fa--fa: "\F675\F675"; }

.fa-wreath-laurel {
  --fa: "\E5D2";
  --fa--fa: "\E5D2\E5D2"; }

.fa-circle-exclamation-check {
  --fa: "\E10D";
  --fa--fa: "\E10D\E10D"; }

.fa-wind {
  --fa: "\F72E";
  --fa--fa: "\F72E\F72E"; }

.fa-box-dollar {
  --fa: "\F4A0";
  --fa--fa: "\F4A0\F4A0"; }

.fa-box-usd {
  --fa: "\F4A0";
  --fa--fa: "\F4A0\F4A0"; }

.fa-car-burst {
  --fa: "\F5E1";
  --fa--fa: "\F5E1\F5E1"; }

.fa-car-crash {
  --fa: "\F5E1";
  --fa--fa: "\F5E1\F5E1"; }

.fa-y {
  --fa: "Y";
  --fa--fa: "YY"; }

.fa-user-headset {
  --fa: "\F82D";
  --fa--fa: "\F82D\F82D"; }

.fa-arrows-retweet {
  --fa: "\F361";
  --fa--fa: "\F361\F361"; }

.fa-retweet-alt {
  --fa: "\F361";
  --fa--fa: "\F361\F361"; }

.fa-person-snowboarding {
  --fa: "\F7CE";
  --fa--fa: "\F7CE\F7CE"; }

.fa-snowboarding {
  --fa: "\F7CE";
  --fa--fa: "\F7CE\F7CE"; }

.fa-square-chevron-right {
  --fa: "\F32B";
  --fa--fa: "\F32B\F32B"; }

.fa-chevron-square-right {
  --fa: "\F32B";
  --fa--fa: "\F32B\F32B"; }

.fa-lacrosse-stick-ball {
  --fa: "\E3B6";
  --fa--fa: "\E3B6\E3B6"; }

.fa-truck-fast {
  --fa: "\F48B";
  --fa--fa: "\F48B\F48B"; }

.fa-shipping-fast {
  --fa: "\F48B";
  --fa--fa: "\F48B\F48B"; }

.fa-user-magnifying-glass {
  --fa: "\E5C5";
  --fa--fa: "\E5C5\E5C5"; }

.fa-star-sharp {
  --fa: "\E28B";
  --fa--fa: "\E28B\E28B"; }

.fa-comment-heart {
  --fa: "\E5C8";
  --fa--fa: "\E5C8\E5C8"; }

.fa-circle-1 {
  --fa: "\E0EE";
  --fa--fa: "\E0EE\E0EE"; }

.fa-circle-star {
  --fa: "\E123";
  --fa--fa: "\E123\E123"; }

.fa-star-circle {
  --fa: "\E123";
  --fa--fa: "\E123\E123"; }

.fa-fish {
  --fa: "\F578";
  --fa--fa: "\F578\F578"; }

.fa-cloud-fog {
  --fa: "\F74E";
  --fa--fa: "\F74E\F74E"; }

.fa-fog {
  --fa: "\F74E";
  --fa--fa: "\F74E\F74E"; }

.fa-waffle {
  --fa: "\E466";
  --fa--fa: "\E466\E466"; }

.fa-music-note {
  --fa: "\F8CF";
  --fa--fa: "\F8CF\F8CF"; }

.fa-music-alt {
  --fa: "\F8CF";
  --fa--fa: "\F8CF\F8CF"; }

.fa-hexagon-exclamation {
  --fa: "\E417";
  --fa--fa: "\E417\E417"; }

.fa-cart-shopping-fast {
  --fa: "\E0DC";
  --fa--fa: "\E0DC\E0DC"; }

.fa-object-union {
  --fa: "\E49F";
  --fa--fa: "\E49F\E49F"; }

.fa-user-graduate {
  --fa: "\F501";
  --fa--fa: "\F501\F501"; }

.fa-starfighter {
  --fa: "\E037";
  --fa--fa: "\E037\E037"; }

.fa-circle-half-stroke {
  --fa: "\F042";
  --fa--fa: "\F042\F042"; }

.fa-adjust {
  --fa: "\F042";
  --fa--fa: "\F042\F042"; }

.fa-arrow-right-long-to-line {
  --fa: "\E3D5";
  --fa--fa: "\E3D5\E3D5"; }

.fa-square-arrow-down {
  --fa: "\F339";
  --fa--fa: "\F339\F339"; }

.fa-arrow-square-down {
  --fa: "\F339";
  --fa--fa: "\F339\F339"; }

.fa-diamond-half-stroke {
  --fa: "\E5B8";
  --fa--fa: "\E5B8\E5B8"; }

.fa-clapperboard {
  --fa: "\E131";
  --fa--fa: "\E131\E131"; }

.fa-square-chevron-left {
  --fa: "\F32A";
  --fa--fa: "\F32A\F32A"; }

.fa-chevron-square-left {
  --fa: "\F32A";
  --fa--fa: "\F32A\F32A"; }

.fa-phone-intercom {
  --fa: "\E434";
  --fa--fa: "\E434\E434"; }

.fa-link-horizontal {
  --fa: "\E1CB";
  --fa--fa: "\E1CB\E1CB"; }

.fa-chain-horizontal {
  --fa: "\E1CB";
  --fa--fa: "\E1CB\E1CB"; }

.fa-mango {
  --fa: "\E30F";
  --fa--fa: "\E30F\E30F"; }

.fa-music-note-slash {
  --fa: "\F8D0";
  --fa--fa: "\F8D0\F8D0"; }

.fa-music-alt-slash {
  --fa: "\F8D0";
  --fa--fa: "\F8D0\F8D0"; }

.fa-circle-radiation {
  --fa: "\F7BA";
  --fa--fa: "\F7BA\F7BA"; }

.fa-radiation-alt {
  --fa: "\F7BA";
  --fa--fa: "\F7BA\F7BA"; }

.fa-face-tongue-sweat {
  --fa: "\E39E";
  --fa--fa: "\E39E\E39E"; }

.fa-globe-stand {
  --fa: "\F5F6";
  --fa--fa: "\F5F6\F5F6"; }

.fa-baseball {
  --fa: "\F433";
  --fa--fa: "\F433\F433"; }

.fa-baseball-ball {
  --fa: "\F433";
  --fa--fa: "\F433\F433"; }

.fa-circle-p {
  --fa: "\E11A";
  --fa--fa: "\E11A\E11A"; }

.fa-award-simple {
  --fa: "\E0AB";
  --fa--fa: "\E0AB\E0AB"; }

.fa-jet-fighter-up {
  --fa: "\E518";
  --fa--fa: "\E518\E518"; }

.fa-diagram-project {
  --fa: "\F542";
  --fa--fa: "\F542\F542"; }

.fa-project-diagram {
  --fa: "\F542";
  --fa--fa: "\F542\F542"; }

.fa-pedestal {
  --fa: "\E20D";
  --fa--fa: "\E20D\E20D"; }

.fa-chart-pyramid {
  --fa: "\E0E6";
  --fa--fa: "\E0E6\E0E6"; }

.fa-sidebar {
  --fa: "\E24E";
  --fa--fa: "\E24E\E24E"; }

.fa-snowman-head {
  --fa: "\F79B";
  --fa--fa: "\F79B\F79B"; }

.fa-frosty-head {
  --fa: "\F79B";
  --fa--fa: "\F79B\F79B"; }

.fa-copy {
  --fa: "\F0C5";
  --fa--fa: "\F0C5\F0C5"; }

.fa-burger-glass {
  --fa: "\E0CE";
  --fa--fa: "\E0CE\E0CE"; }

.fa-volume-xmark {
  --fa: "\F6A9";
  --fa--fa: "\F6A9\F6A9"; }

.fa-volume-mute {
  --fa: "\F6A9";
  --fa--fa: "\F6A9\F6A9"; }

.fa-volume-times {
  --fa: "\F6A9";
  --fa--fa: "\F6A9\F6A9"; }

.fa-hand-sparkles {
  --fa: "\E05D";
  --fa--fa: "\E05D\E05D"; }

.fa-bars-filter {
  --fa: "\E0AD";
  --fa--fa: "\E0AD\E0AD"; }

.fa-paintbrush-pencil {
  --fa: "\E206";
  --fa--fa: "\E206\E206"; }

.fa-party-bell {
  --fa: "\E31A";
  --fa--fa: "\E31A\E31A"; }

.fa-user-vneck-hair {
  --fa: "\E462";
  --fa--fa: "\E462\E462"; }

.fa-jack-o-lantern {
  --fa: "\F30E";
  --fa--fa: "\F30E\F30E"; }

.fa-grip {
  --fa: "\F58D";
  --fa--fa: "\F58D\F58D"; }

.fa-grip-horizontal {
  --fa: "\F58D";
  --fa--fa: "\F58D\F58D"; }

.fa-share-from-square {
  --fa: "\F14D";
  --fa--fa: "\F14D\F14D"; }

.fa-share-square {
  --fa: "\F14D";
  --fa--fa: "\F14D\F14D"; }

.fa-keynote {
  --fa: "\F66C";
  --fa--fa: "\F66C\F66C"; }

.fa-child-combatant {
  --fa: "\E4E0";
  --fa--fa: "\E4E0\E4E0"; }

.fa-child-rifle {
  --fa: "\E4E0";
  --fa--fa: "\E4E0\E4E0"; }

.fa-gun {
  --fa: "\E19B";
  --fa--fa: "\E19B\E19B"; }

.fa-square-phone {
  --fa: "\F098";
  --fa--fa: "\F098\F098"; }

.fa-phone-square {
  --fa: "\F098";
  --fa--fa: "\F098\F098"; }

.fa-hat-beach {
  --fa: "\E606";
  --fa--fa: "\E606\E606"; }

.fa-plus {
  --fa: "+";
  --fa--fa: "++"; }

.fa-add {
  --fa: "+";
  --fa--fa: "++"; }

.fa-expand {
  --fa: "\F065";
  --fa--fa: "\F065\F065"; }

.fa-computer {
  --fa: "\E4E5";
  --fa--fa: "\E4E5\E4E5"; }

.fa-fort {
  --fa: "\E486";
  --fa--fa: "\E486\E486"; }

.fa-cloud-check {
  --fa: "\E35C";
  --fa--fa: "\E35C\E35C"; }

.fa-xmark {
  --fa: "\F00D";
  --fa--fa: "\F00D\F00D"; }

.fa-close {
  --fa: "\F00D";
  --fa--fa: "\F00D\F00D"; }

.fa-multiply {
  --fa: "\F00D";
  --fa--fa: "\F00D\F00D"; }

.fa-remove {
  --fa: "\F00D";
  --fa--fa: "\F00D\F00D"; }

.fa-times {
  --fa: "\F00D";
  --fa--fa: "\F00D\F00D"; }

.fa-face-smirking {
  --fa: "\E397";
  --fa--fa: "\E397\E397"; }

.fa-arrows-up-down-left-right {
  --fa: "\F047";
  --fa--fa: "\F047\F047"; }

.fa-arrows {
  --fa: "\F047";
  --fa--fa: "\F047\F047"; }

.fa-chalkboard-user {
  --fa: "\F51C";
  --fa--fa: "\F51C\F51C"; }

.fa-chalkboard-teacher {
  --fa: "\F51C";
  --fa--fa: "\F51C\F51C"; }

.fa-rhombus {
  --fa: "\E23B";
  --fa--fa: "\E23B\E23B"; }

.fa-claw-marks {
  --fa: "\F6C2";
  --fa--fa: "\F6C2\F6C2"; }

.fa-peso-sign {
  --fa: "\E222";
  --fa--fa: "\E222\E222"; }

.fa-face-smile-tongue {
  --fa: "\E394";
  --fa--fa: "\E394\E394"; }

.fa-cart-circle-xmark {
  --fa: "\E3F4";
  --fa--fa: "\E3F4\E3F4"; }

.fa-building-shield {
  --fa: "\E4D8";
  --fa--fa: "\E4D8\E4D8"; }

.fa-circle-phone-flip {
  --fa: "\E11C";
  --fa--fa: "\E11C\E11C"; }

.fa-phone-circle-alt {
  --fa: "\E11C";
  --fa--fa: "\E11C\E11C"; }

.fa-baby {
  --fa: "\F77C";
  --fa--fa: "\F77C\F77C"; }

.fa-users-line {
  --fa: "\E592";
  --fa--fa: "\E592\E592"; }

.fa-quote-left {
  --fa: "\F10D";
  --fa--fa: "\F10D\F10D"; }

.fa-quote-left-alt {
  --fa: "\F10D";
  --fa--fa: "\F10D\F10D"; }

.fa-tractor {
  --fa: "\F722";
  --fa--fa: "\F722\F722"; }

.fa-down-from-bracket {
  --fa: "\E66B";
  --fa--fa: "\E66B\E66B"; }

.fa-key-skeleton {
  --fa: "\F6F3";
  --fa--fa: "\F6F3\F6F3"; }

.fa-trash-arrow-up {
  --fa: "\F829";
  --fa--fa: "\F829\F829"; }

.fa-trash-restore {
  --fa: "\F829";
  --fa--fa: "\F829\F829"; }

.fa-arrow-down-up-lock {
  --fa: "\E4B0";
  --fa--fa: "\E4B0\E4B0"; }

.fa-arrow-down-to-bracket {
  --fa: "\E094";
  --fa--fa: "\E094\E094"; }

.fa-lines-leaning {
  --fa: "\E51E";
  --fa--fa: "\E51E\E51E"; }

.fa-square-q {
  --fa: "\E27B";
  --fa--fa: "\E27B\E27B"; }

.fa-ruler-combined {
  --fa: "\F546";
  --fa--fa: "\F546\F546"; }

.fa-symbols {
  --fa: "\F86E";
  --fa--fa: "\F86E\F86E"; }

.fa-icons-alt {
  --fa: "\F86E";
  --fa--fa: "\F86E\F86E"; }

.fa-copyright {
  --fa: "\F1F9";
  --fa--fa: "\F1F9\F1F9"; }

.fa-flask-gear {
  --fa: "\E5F1";
  --fa--fa: "\E5F1\E5F1"; }

.fa-highlighter-line {
  --fa: "\E1AF";
  --fa--fa: "\E1AF\E1AF"; }

.fa-bracket-square {
  --fa: "[";
  --fa--fa: "[["; }

.fa-bracket {
  --fa: "[";
  --fa--fa: "[["; }

.fa-bracket-left {
  --fa: "[";
  --fa--fa: "[["; }

.fa-island-tropical {
  --fa: "\F811";
  --fa--fa: "\F811\F811"; }

.fa-island-tree-palm {
  --fa: "\F811";
  --fa--fa: "\F811\F811"; }

.fa-arrow-right-from-line {
  --fa: "\F343";
  --fa--fa: "\F343\F343"; }

.fa-arrow-from-left {
  --fa: "\F343";
  --fa--fa: "\F343\F343"; }

.fa-h2 {
  --fa: "\F314";
  --fa--fa: "\F314\F314"; }

.fa-equals {
  --fa: "=";
  --fa--fa: "=="; }

.fa-cake-slice {
  --fa: "\E3E5";
  --fa--fa: "\E3E5\E3E5"; }

.fa-shortcake {
  --fa: "\E3E5";
  --fa--fa: "\E3E5\E3E5"; }

.fa-building-magnifying-glass {
  --fa: "\E61C";
  --fa--fa: "\E61C\E61C"; }

.fa-peanut {
  --fa: "\E430";
  --fa--fa: "\E430\E430"; }

.fa-wrench-simple {
  --fa: "\E2D1";
  --fa--fa: "\E2D1\E2D1"; }

.fa-blender {
  --fa: "\F517";
  --fa--fa: "\F517\F517"; }

.fa-teeth {
  --fa: "\F62E";
  --fa--fa: "\F62E\F62E"; }

.fa-tally-2 {
  --fa: "\E295";
  --fa--fa: "\E295\E295"; }

.fa-shekel-sign {
  --fa: "\F20B";
  --fa--fa: "\F20B\F20B"; }

.fa-ils {
  --fa: "\F20B";
  --fa--fa: "\F20B\F20B"; }

.fa-shekel {
  --fa: "\F20B";
  --fa--fa: "\F20B\F20B"; }

.fa-sheqel {
  --fa: "\F20B";
  --fa--fa: "\F20B\F20B"; }

.fa-sheqel-sign {
  --fa: "\F20B";
  --fa--fa: "\F20B\F20B"; }

.fa-cars {
  --fa: "\F85B";
  --fa--fa: "\F85B\F85B"; }

.fa-axe-battle {
  --fa: "\F6B3";
  --fa--fa: "\F6B3\F6B3"; }

.fa-user-hair-long {
  --fa: "\E45B";
  --fa--fa: "\E45B\E45B"; }

.fa-map {
  --fa: "\F279";
  --fa--fa: "\F279\F279"; }

.fa-arrow-left-from-arc {
  --fa: "\E615";
  --fa--fa: "\E615\E615"; }

.fa-file-circle-info {
  --fa: "\E493";
  --fa--fa: "\E493\E493"; }

.fa-face-disappointed {
  --fa: "\E36F";
  --fa--fa: "\E36F\E36F"; }

.fa-lasso-sparkles {
  --fa: "\E1C9";
  --fa--fa: "\E1C9\E1C9"; }

.fa-clock-eleven {
  --fa: "\E347";
  --fa--fa: "\E347\E347"; }

.fa-rocket {
  --fa: "\F135";
  --fa--fa: "\F135\F135"; }

.fa-siren-on {
  --fa: "\E02E";
  --fa--fa: "\E02E\E02E"; }

.fa-clock-ten {
  --fa: "\E354";
  --fa--fa: "\E354\E354"; }

.fa-candle-holder {
  --fa: "\F6BC";
  --fa--fa: "\F6BC\F6BC"; }

.fa-video-arrow-down-left {
  --fa: "\E2C8";
  --fa--fa: "\E2C8\E2C8"; }

.fa-photo-film {
  --fa: "\F87C";
  --fa--fa: "\F87C\F87C"; }

.fa-photo-video {
  --fa: "\F87C";
  --fa--fa: "\F87C\F87C"; }

.fa-floppy-disk-circle-arrow-right {
  --fa: "\E180";
  --fa--fa: "\E180\E180"; }

.fa-save-circle-arrow-right {
  --fa: "\E180";
  --fa--fa: "\E180\E180"; }

.fa-folder-minus {
  --fa: "\F65D";
  --fa--fa: "\F65D\F65D"; }

.fa-hexagon-nodes-bolt {
  --fa: "\E69A";
  --fa--fa: "\E69A\E69A"; }

.fa-planet-moon {
  --fa: "\E01F";
  --fa--fa: "\E01F\E01F"; }

.fa-face-eyes-xmarks {
  --fa: "\E374";
  --fa--fa: "\E374\E374"; }

.fa-chart-scatter {
  --fa: "\F7EE";
  --fa--fa: "\F7EE\F7EE"; }

.fa-circle-gf {
  --fa: "\E67F";
  --fa--fa: "\E67F\E67F"; }

.fa-display-arrow-down {
  --fa: "\E164";
  --fa--fa: "\E164\E164"; }

.fa-store {
  --fa: "\F54E";
  --fa--fa: "\F54E\F54E"; }

.fa-arrow-trend-up {
  --fa: "\E098";
  --fa--fa: "\E098\E098"; }

.fa-plug-circle-minus {
  --fa: "\E55E";
  --fa--fa: "\E55E\E55E"; }

.fa-olive-branch {
  --fa: "\E317";
  --fa--fa: "\E317\E317"; }

.fa-angle {
  --fa: "\E08C";
  --fa--fa: "\E08C\E08C"; }

.fa-vacuum-robot {
  --fa: "\E04E";
  --fa--fa: "\E04E\E04E"; }

.fa-sign-hanging {
  --fa: "\F4D9";
  --fa--fa: "\F4D9\F4D9"; }

.fa-sign {
  --fa: "\F4D9";
  --fa--fa: "\F4D9\F4D9"; }

.fa-square-divide {
  --fa: "\E26A";
  --fa--fa: "\E26A\E26A"; }

.fa-folder-check {
  --fa: "\E64E";
  --fa--fa: "\E64E\E64E"; }

.fa-signal-stream-slash {
  --fa: "\E250";
  --fa--fa: "\E250\E250"; }

.fa-bezier-curve {
  --fa: "\F55B";
  --fa--fa: "\F55B\F55B"; }

.fa-eye-dropper-half {
  --fa: "\E173";
  --fa--fa: "\E173\E173"; }

.fa-store-lock {
  --fa: "\E4A6";
  --fa--fa: "\E4A6\E4A6"; }

.fa-bell-slash {
  --fa: "\F1F6";
  --fa--fa: "\F1F6\F1F6"; }

.fa-cloud-bolt-sun {
  --fa: "\F76E";
  --fa--fa: "\F76E\F76E"; }

.fa-thunderstorm-sun {
  --fa: "\F76E";
  --fa--fa: "\F76E\F76E"; }

.fa-camera-slash {
  --fa: "\E0D9";
  --fa--fa: "\E0D9\E0D9"; }

.fa-comment-quote {
  --fa: "\E14C";
  --fa--fa: "\E14C\E14C"; }

.fa-tablet {
  --fa: "\F3FB";
  --fa--fa: "\F3FB\F3FB"; }

.fa-tablet-android {
  --fa: "\F3FB";
  --fa--fa: "\F3FB\F3FB"; }

.fa-school-flag {
  --fa: "\E56E";
  --fa--fa: "\E56E\E56E"; }

.fa-message-code {
  --fa: "\E1DF";
  --fa--fa: "\E1DF\E1DF"; }

.fa-glass-half {
  --fa: "\E192";
  --fa--fa: "\E192\E192"; }

.fa-glass-half-empty {
  --fa: "\E192";
  --fa--fa: "\E192\E192"; }

.fa-glass-half-full {
  --fa: "\E192";
  --fa--fa: "\E192\E192"; }

.fa-fill {
  --fa: "\F575";
  --fa--fa: "\F575\F575"; }

.fa-message-minus {
  --fa: "\F4A7";
  --fa--fa: "\F4A7\F4A7"; }

.fa-comment-alt-minus {
  --fa: "\F4A7";
  --fa--fa: "\F4A7\F4A7"; }

.fa-angle-up {
  --fa: "\F106";
  --fa--fa: "\F106\F106"; }

.fa-dinosaur {
  --fa: "\E5FE";
  --fa--fa: "\E5FE\E5FE"; }

.fa-drumstick-bite {
  --fa: "\F6D7";
  --fa--fa: "\F6D7\F6D7"; }

.fa-link-horizontal-slash {
  --fa: "\E1CC";
  --fa--fa: "\E1CC\E1CC"; }

.fa-chain-horizontal-slash {
  --fa: "\E1CC";
  --fa--fa: "\E1CC\E1CC"; }

.fa-holly-berry {
  --fa: "\F7AA";
  --fa--fa: "\F7AA\F7AA"; }

.fa-nose {
  --fa: "\E5BD";
  --fa--fa: "\E5BD\E5BD"; }

.fa-arrow-left-to-arc {
  --fa: "\E616";
  --fa--fa: "\E616\E616"; }

.fa-chevron-left {
  --fa: "\F053";
  --fa--fa: "\F053\F053"; }

.fa-bacteria {
  --fa: "\E059";
  --fa--fa: "\E059\E059"; }

.fa-clouds {
  --fa: "\F744";
  --fa--fa: "\F744\F744"; }

.fa-money-bill-simple {
  --fa: "\E1F1";
  --fa--fa: "\E1F1\E1F1"; }

.fa-hand-lizard {
  --fa: "\F258";
  --fa--fa: "\F258\F258"; }

.fa-table-pivot {
  --fa: "\E291";
  --fa--fa: "\E291\E291"; }

.fa-filter-slash {
  --fa: "\E17D";
  --fa--fa: "\E17D\E17D"; }

.fa-trash-can-undo {
  --fa: "\F896";
  --fa--fa: "\F896\F896"; }

.fa-trash-can-arrow-turn-left {
  --fa: "\F896";
  --fa--fa: "\F896\F896"; }

.fa-trash-undo-alt {
  --fa: "\F896";
  --fa--fa: "\F896\F896"; }

.fa-notdef {
  --fa: "\E1FE";
  --fa--fa: "\E1FE\E1FE"; }

.fa-disease {
  --fa: "\F7FA";
  --fa--fa: "\F7FA\F7FA"; }

.fa-person-to-door {
  --fa: "\E433";
  --fa--fa: "\E433\E433"; }

.fa-turntable {
  --fa: "\F8E4";
  --fa--fa: "\F8E4\F8E4"; }

.fa-briefcase-medical {
  --fa: "\F469";
  --fa--fa: "\F469\F469"; }

.fa-genderless {
  --fa: "\F22D";
  --fa--fa: "\F22D\F22D"; }

.fa-chevron-right {
  --fa: "\F054";
  --fa--fa: "\F054\F054"; }

.fa-signal-weak {
  --fa: "\F68C";
  --fa--fa: "\F68C\F68C"; }

.fa-signal-1 {
  --fa: "\F68C";
  --fa--fa: "\F68C\F68C"; }

.fa-clock-five {
  --fa: "\E349";
  --fa--fa: "\E349\E349"; }

.fa-retweet {
  --fa: "\F079";
  --fa--fa: "\F079\F079"; }

.fa-car-rear {
  --fa: "\F5DE";
  --fa--fa: "\F5DE\F5DE"; }

.fa-car-alt {
  --fa: "\F5DE";
  --fa--fa: "\F5DE\F5DE"; }

.fa-pump-soap {
  --fa: "\E06B";
  --fa--fa: "\E06B\E06B"; }

.fa-computer-classic {
  --fa: "\F8B1";
  --fa--fa: "\F8B1\F8B1"; }

.fa-frame {
  --fa: "\E495";
  --fa--fa: "\E495\E495"; }

.fa-video-slash {
  --fa: "\F4E2";
  --fa--fa: "\F4E2\F4E2"; }

.fa-battery-quarter {
  --fa: "\F243";
  --fa--fa: "\F243\F243"; }

.fa-battery-2 {
  --fa: "\F243";
  --fa--fa: "\F243\F243"; }

.fa-ellipsis-stroke {
  --fa: "\F39B";
  --fa--fa: "\F39B\F39B"; }

.fa-ellipsis-h-alt {
  --fa: "\F39B";
  --fa--fa: "\F39B\F39B"; }

.fa-radio {
  --fa: "\F8D7";
  --fa--fa: "\F8D7\F8D7"; }

.fa-baby-carriage {
  --fa: "\F77D";
  --fa--fa: "\F77D\F77D"; }

.fa-carriage-baby {
  --fa: "\F77D";
  --fa--fa: "\F77D\F77D"; }

.fa-face-expressionless {
  --fa: "\E373";
  --fa--fa: "\E373\E373"; }

.fa-down-to-dotted-line {
  --fa: "\E408";
  --fa--fa: "\E408\E408"; }

.fa-cloud-music {
  --fa: "\F8AE";
  --fa--fa: "\F8AE\F8AE"; }

.fa-traffic-light {
  --fa: "\F637";
  --fa--fa: "\F637\F637"; }

.fa-cloud-minus {
  --fa: "\E35D";
  --fa--fa: "\E35D\E35D"; }

.fa-thermometer {
  --fa: "\F491";
  --fa--fa: "\F491\F491"; }

.fa-shield-minus {
  --fa: "\E249";
  --fa--fa: "\E249\E249"; }

.fa-vr-cardboard {
  --fa: "\F729";
  --fa--fa: "\F729\F729"; }

.fa-car-tilt {
  --fa: "\F5E5";
  --fa--fa: "\F5E5\F5E5"; }

.fa-gauge-circle-minus {
  --fa: "\E497";
  --fa--fa: "\E497\E497"; }

.fa-brightness-low {
  --fa: "\E0CA";
  --fa--fa: "\E0CA\E0CA"; }

.fa-hand-middle-finger {
  --fa: "\F806";
  --fa--fa: "\F806\F806"; }

.fa-percent {
  --fa: "%";
  --fa--fa: "%%"; }

.fa-percentage {
  --fa: "%";
  --fa--fa: "%%"; }

.fa-truck-moving {
  --fa: "\F4DF";
  --fa--fa: "\F4DF\F4DF"; }

.fa-glass-water-droplet {
  --fa: "\E4F5";
  --fa--fa: "\E4F5\E4F5"; }

.fa-conveyor-belt {
  --fa: "\F46E";
  --fa--fa: "\F46E\F46E"; }

.fa-location-check {
  --fa: "\F606";
  --fa--fa: "\F606\F606"; }

.fa-map-marker-check {
  --fa: "\F606";
  --fa--fa: "\F606\F606"; }

.fa-coin-vertical {
  --fa: "\E3FD";
  --fa--fa: "\E3FD\E3FD"; }

.fa-display {
  --fa: "\E163";
  --fa--fa: "\E163\E163"; }

.fa-person-sign {
  --fa: "\F757";
  --fa--fa: "\F757\F757"; }

.fa-face-smile {
  --fa: "\F118";
  --fa--fa: "\F118\F118"; }

.fa-smile {
  --fa: "\F118";
  --fa--fa: "\F118\F118"; }

.fa-phone-hangup {
  --fa: "\E225";
  --fa--fa: "\E225\E225"; }

.fa-signature-slash {
  --fa: "\E3CB";
  --fa--fa: "\E3CB\E3CB"; }

.fa-thumbtack {
  --fa: "\F08D";
  --fa--fa: "\F08D\F08D"; }

.fa-thumb-tack {
  --fa: "\F08D";
  --fa--fa: "\F08D\F08D"; }

.fa-wheat-slash {
  --fa: "\E339";
  --fa--fa: "\E339\E339"; }

.fa-trophy {
  --fa: "\F091";
  --fa--fa: "\F091\F091"; }

.fa-clouds-sun {
  --fa: "\F746";
  --fa--fa: "\F746\F746"; }

.fa-person-praying {
  --fa: "\F683";
  --fa--fa: "\F683\F683"; }

.fa-pray {
  --fa: "\F683";
  --fa--fa: "\F683\F683"; }

.fa-hammer {
  --fa: "\F6E3";
  --fa--fa: "\F6E3\F6E3"; }

.fa-face-vomit {
  --fa: "\E3A0";
  --fa--fa: "\E3A0\E3A0"; }

.fa-speakers {
  --fa: "\F8E0";
  --fa--fa: "\F8E0\F8E0"; }

.fa-tty-answer {
  --fa: "\E2B9";
  --fa--fa: "\E2B9\E2B9"; }

.fa-teletype-answer {
  --fa: "\E2B9";
  --fa--fa: "\E2B9\E2B9"; }

.fa-mug-tea-saucer {
  --fa: "\E1F5";
  --fa--fa: "\E1F5\E1F5"; }

.fa-diagram-lean-canvas {
  --fa: "\E156";
  --fa--fa: "\E156\E156"; }

.fa-alt {
  --fa: "\E08A";
  --fa--fa: "\E08A\E08A"; }

.fa-dial {
  --fa: "\E15B";
  --fa--fa: "\E15B\E15B"; }

.fa-dial-med-high {
  --fa: "\E15B";
  --fa--fa: "\E15B\E15B"; }

.fa-hand-peace {
  --fa: "\F25B";
  --fa--fa: "\F25B\F25B"; }

.fa-circle-trash {
  --fa: "\E126";
  --fa--fa: "\E126\E126"; }

.fa-trash-circle {
  --fa: "\E126";
  --fa--fa: "\E126\E126"; }

.fa-rotate {
  --fa: "\F2F1";
  --fa--fa: "\F2F1\F2F1"; }

.fa-sync-alt {
  --fa: "\F2F1";
  --fa--fa: "\F2F1\F2F1"; }

.fa-circle-quarters {
  --fa: "\E3F8";
  --fa--fa: "\E3F8\E3F8"; }

.fa-spinner {
  --fa: "\F110";
  --fa--fa: "\F110\F110"; }

.fa-tower-control {
  --fa: "\E2A2";
  --fa--fa: "\E2A2\E2A2"; }

.fa-arrow-up-triangle-square {
  --fa: "\F88A";
  --fa--fa: "\F88A\F88A"; }

.fa-sort-shapes-up {
  --fa: "\F88A";
  --fa--fa: "\F88A\F88A"; }

.fa-whale {
  --fa: "\F72C";
  --fa--fa: "\F72C\F72C"; }

.fa-robot {
  --fa: "\F544";
  --fa--fa: "\F544\F544"; }

.fa-peace {
  --fa: "\F67C";
  --fa--fa: "\F67C\F67C"; }

.fa-party-horn {
  --fa: "\E31B";
  --fa--fa: "\E31B\E31B"; }

.fa-gears {
  --fa: "\F085";
  --fa--fa: "\F085\F085"; }

.fa-cogs {
  --fa: "\F085";
  --fa--fa: "\F085\F085"; }

.fa-sun-bright {
  --fa: "\E28F";
  --fa--fa: "\E28F\E28F"; }

.fa-sun-alt {
  --fa: "\E28F";
  --fa--fa: "\E28F\E28F"; }

.fa-warehouse {
  --fa: "\F494";
  --fa--fa: "\F494\F494"; }

.fa-conveyor-belt-arm {
  --fa: "\E5F8";
  --fa--fa: "\E5F8\E5F8"; }

.fa-lock-keyhole-open {
  --fa: "\F3C2";
  --fa--fa: "\F3C2\F3C2"; }

.fa-lock-open-alt {
  --fa: "\F3C2";
  --fa--fa: "\F3C2\F3C2"; }

.fa-square-fragile {
  --fa: "\F49B";
  --fa--fa: "\F49B\F49B"; }

.fa-box-fragile {
  --fa: "\F49B";
  --fa--fa: "\F49B\F49B"; }

.fa-square-wine-glass-crack {
  --fa: "\F49B";
  --fa--fa: "\F49B\F49B"; }

.fa-arrow-up-right-dots {
  --fa: "\E4B7";
  --fa--fa: "\E4B7\E4B7"; }

.fa-square-n {
  --fa: "\E277";
  --fa--fa: "\E277\E277"; }

.fa-splotch {
  --fa: "\F5BC";
  --fa--fa: "\F5BC\F5BC"; }

.fa-face-grin-hearts {
  --fa: "\F584";
  --fa--fa: "\F584\F584"; }

.fa-grin-hearts {
  --fa: "\F584";
  --fa--fa: "\F584\F584"; }

.fa-meter {
  --fa: "\E1E8";
  --fa--fa: "\E1E8\E1E8"; }

.fa-mandolin {
  --fa: "\F6F9";
  --fa--fa: "\F6F9\F6F9"; }

.fa-dice-four {
  --fa: "\F524";
  --fa--fa: "\F524\F524"; }

.fa-sim-card {
  --fa: "\F7C4";
  --fa--fa: "\F7C4\F7C4"; }

.fa-transgender {
  --fa: "\F225";
  --fa--fa: "\F225\F225"; }

.fa-transgender-alt {
  --fa: "\F225";
  --fa--fa: "\F225\F225"; }

.fa-mercury {
  --fa: "\F223";
  --fa--fa: "\F223\F223"; }

.fa-up-from-bracket {
  --fa: "\E590";
  --fa--fa: "\E590\E590"; }

.fa-knife-kitchen {
  --fa: "\F6F5";
  --fa--fa: "\F6F5\F6F5"; }

.fa-border-right {
  --fa: "\F852";
  --fa--fa: "\F852\F852"; }

.fa-arrow-turn-down {
  --fa: "\F149";
  --fa--fa: "\F149\F149"; }

.fa-level-down {
  --fa: "\F149";
  --fa--fa: "\F149\F149"; }

.fa-spade {
  --fa: "\F2F4";
  --fa--fa: "\F2F4\F2F4"; }

.fa-card-spade {
  --fa: "\E3EC";
  --fa--fa: "\E3EC\E3EC"; }

.fa-line-columns {
  --fa: "\F870";
  --fa--fa: "\F870\F870"; }

.fa-ant {
  --fa: "\E680";
  --fa--fa: "\E680\E680"; }

.fa-arrow-right-to-line {
  --fa: "\F340";
  --fa--fa: "\F340\F340"; }

.fa-arrow-to-right {
  --fa: "\F340";
  --fa--fa: "\F340\F340"; }

.fa-person-falling-burst {
  --fa: "\E547";
  --fa--fa: "\E547\E547"; }

.fa-flag-pennant {
  --fa: "\F456";
  --fa--fa: "\F456\F456"; }

.fa-pennant {
  --fa: "\F456";
  --fa--fa: "\F456\F456"; }

.fa-conveyor-belt-empty {
  --fa: "\E150";
  --fa--fa: "\E150\E150"; }

.fa-user-group-simple {
  --fa: "\E603";
  --fa--fa: "\E603\E603"; }

.fa-award {
  --fa: "\F559";
  --fa--fa: "\F559\F559"; }

.fa-ticket-simple {
  --fa: "\F3FF";
  --fa--fa: "\F3FF\F3FF"; }

.fa-ticket-alt {
  --fa: "\F3FF";
  --fa--fa: "\F3FF\F3FF"; }

.fa-building {
  --fa: "\F1AD";
  --fa--fa: "\F1AD\F1AD"; }

.fa-angles-left {
  --fa: "\F100";
  --fa--fa: "\F100\F100"; }

.fa-angle-double-left {
  --fa: "\F100";
  --fa--fa: "\F100\F100"; }

.fa-camcorder {
  --fa: "\F8A8";
  --fa--fa: "\F8A8\F8A8"; }

.fa-video-handheld {
  --fa: "\F8A8";
  --fa--fa: "\F8A8\F8A8"; }

.fa-pancakes {
  --fa: "\E42D";
  --fa--fa: "\E42D\E42D"; }

.fa-album-circle-user {
  --fa: "\E48D";
  --fa--fa: "\E48D\E48D"; }

.fa-subtitles-slash {
  --fa: "\E610";
  --fa--fa: "\E610\E610"; }

.fa-qrcode {
  --fa: "\F029";
  --fa--fa: "\F029\F029"; }

.fa-dice-d10 {
  --fa: "\F6CD";
  --fa--fa: "\F6CD\F6CD"; }

.fa-fireplace {
  --fa: "\F79A";
  --fa--fa: "\F79A\F79A"; }

.fa-browser {
  --fa: "\F37E";
  --fa--fa: "\F37E\F37E"; }

.fa-pen-paintbrush {
  --fa: "\F618";
  --fa--fa: "\F618\F618"; }

.fa-pencil-paintbrush {
  --fa: "\F618";
  --fa--fa: "\F618\F618"; }

.fa-fish-cooked {
  --fa: "\F7FE";
  --fa--fa: "\F7FE\F7FE"; }

.fa-chair-office {
  --fa: "\F6C1";
  --fa--fa: "\F6C1\F6C1"; }

.fa-magnifying-glass-music {
  --fa: "\E65F";
  --fa--fa: "\E65F\E65F"; }

.fa-nesting-dolls {
  --fa: "\E3BA";
  --fa--fa: "\E3BA\E3BA"; }

.fa-clock-rotate-left {
  --fa: "\F1DA";
  --fa--fa: "\F1DA\F1DA"; }

.fa-history {
  --fa: "\F1DA";
  --fa--fa: "\F1DA\F1DA"; }

.fa-trumpet {
  --fa: "\F8E3";
  --fa--fa: "\F8E3\F8E3"; }

.fa-face-grin-beam-sweat {
  --fa: "\F583";
  --fa--fa: "\F583\F583"; }

.fa-grin-beam-sweat {
  --fa: "\F583";
  --fa--fa: "\F583\F583"; }

.fa-fire-smoke {
  --fa: "\F74B";
  --fa--fa: "\F74B\F74B"; }

.fa-phone-missed {
  --fa: "\E226";
  --fa--fa: "\E226\E226"; }

.fa-file-export {
  --fa: "\F56E";
  --fa--fa: "\F56E\F56E"; }

.fa-arrow-right-from-file {
  --fa: "\F56E";
  --fa--fa: "\F56E\F56E"; }

.fa-shield {
  --fa: "\F132";
  --fa--fa: "\F132\F132"; }

.fa-shield-blank {
  --fa: "\F132";
  --fa--fa: "\F132\F132"; }

.fa-arrow-up-short-wide {
  --fa: "\F885";
  --fa--fa: "\F885\F885"; }

.fa-sort-amount-up-alt {
  --fa: "\F885";
  --fa--fa: "\F885\F885"; }

.fa-arrows-repeat-1 {
  --fa: "\F366";
  --fa--fa: "\F366\F366"; }

.fa-repeat-1-alt {
  --fa: "\F366";
  --fa--fa: "\F366\F366"; }

.fa-gun-slash {
  --fa: "\E19C";
  --fa--fa: "\E19C\E19C"; }

.fa-avocado {
  --fa: "\E0AA";
  --fa--fa: "\E0AA\E0AA"; }

.fa-binary {
  --fa: "\E33B";
  --fa--fa: "\E33B\E33B"; }

.fa-comment-nodes {
  --fa: "\E696";
  --fa--fa: "\E696\E696"; }

.fa-glasses-round {
  --fa: "\F5F5";
  --fa--fa: "\F5F5\F5F5"; }

.fa-glasses-alt {
  --fa: "\F5F5";
  --fa--fa: "\F5F5\F5F5"; }

.fa-phone-plus {
  --fa: "\F4D2";
  --fa--fa: "\F4D2\F4D2"; }

.fa-ditto {
  --fa: "\"";
  --fa--fa: "\"\""; }

.fa-person-seat {
  --fa: "\E21E";
  --fa--fa: "\E21E\E21E"; }

.fa-house-medical {
  --fa: "\E3B2";
  --fa--fa: "\E3B2\E3B2"; }

.fa-golf-ball-tee {
  --fa: "\F450";
  --fa--fa: "\F450\F450"; }

.fa-golf-ball {
  --fa: "\F450";
  --fa--fa: "\F450\F450"; }

.fa-circle-chevron-left {
  --fa: "\F137";
  --fa--fa: "\F137\F137"; }

.fa-chevron-circle-left {
  --fa: "\F137";
  --fa--fa: "\F137\F137"; }

.fa-house-chimney-window {
  --fa: "\E00D";
  --fa--fa: "\E00D\E00D"; }

.fa-scythe {
  --fa: "\F710";
  --fa--fa: "\F710\F710"; }

.fa-pen-nib {
  --fa: "\F5AD";
  --fa--fa: "\F5AD\F5AD"; }

.fa-ban-parking {
  --fa: "\F616";
  --fa--fa: "\F616\F616"; }

.fa-parking-circle-slash {
  --fa: "\F616";
  --fa--fa: "\F616\F616"; }

.fa-tent-arrow-turn-left {
  --fa: "\E580";
  --fa--fa: "\E580\E580"; }

.fa-face-diagonal-mouth {
  --fa: "\E47E";
  --fa--fa: "\E47E\E47E"; }

.fa-diagram-cells {
  --fa: "\E475";
  --fa--fa: "\E475\E475"; }

.fa-cricket-bat-ball {
  --fa: "\F449";
  --fa--fa: "\F449\F449"; }

.fa-cricket {
  --fa: "\F449";
  --fa--fa: "\F449\F449"; }

.fa-tents {
  --fa: "\E582";
  --fa--fa: "\E582\E582"; }

.fa-wand-magic {
  --fa: "\F0D0";
  --fa--fa: "\F0D0\F0D0"; }

.fa-magic {
  --fa: "\F0D0";
  --fa--fa: "\F0D0\F0D0"; }

.fa-dog {
  --fa: "\F6D3";
  --fa--fa: "\F6D3\F6D3"; }

.fa-pen-line {
  --fa: "\E212";
  --fa--fa: "\E212\E212"; }

.fa-atom-simple {
  --fa: "\F5D3";
  --fa--fa: "\F5D3\F5D3"; }

.fa-atom-alt {
  --fa: "\F5D3";
  --fa--fa: "\F5D3\F5D3"; }

.fa-ampersand {
  --fa: "&";
  --fa--fa: "&&"; }

.fa-carrot {
  --fa: "\F787";
  --fa--fa: "\F787\F787"; }

.fa-arrow-up-from-line {
  --fa: "\F342";
  --fa--fa: "\F342\F342"; }

.fa-arrow-from-bottom {
  --fa: "\F342";
  --fa--fa: "\F342\F342"; }

.fa-moon {
  --fa: "\F186";
  --fa--fa: "\F186\F186"; }

.fa-pen-slash {
  --fa: "\E213";
  --fa--fa: "\E213\E213"; }

.fa-wine-glass-empty {
  --fa: "\F5CE";
  --fa--fa: "\F5CE\F5CE"; }

.fa-wine-glass-alt {
  --fa: "\F5CE";
  --fa--fa: "\F5CE\F5CE"; }

.fa-square-star {
  --fa: "\E27F";
  --fa--fa: "\E27F\E27F"; }

.fa-cheese {
  --fa: "\F7EF";
  --fa--fa: "\F7EF\F7EF"; }

.fa-send-backward {
  --fa: "\F87F";
  --fa--fa: "\F87F\F87F"; }

.fa-yin-yang {
  --fa: "\F6AD";
  --fa--fa: "\F6AD\F6AD"; }

.fa-music {
  --fa: "\F001";
  --fa--fa: "\F001\F001"; }

.fa-compass-slash {
  --fa: "\F5E9";
  --fa--fa: "\F5E9\F5E9"; }

.fa-clock-one {
  --fa: "\E34E";
  --fa--fa: "\E34E\E34E"; }

.fa-file-music {
  --fa: "\F8B6";
  --fa--fa: "\F8B6\F8B6"; }

.fa-code-commit {
  --fa: "\F386";
  --fa--fa: "\F386\F386"; }

.fa-temperature-low {
  --fa: "\F76B";
  --fa--fa: "\F76B\F76B"; }

.fa-person-biking {
  --fa: "\F84A";
  --fa--fa: "\F84A\F84A"; }

.fa-biking {
  --fa: "\F84A";
  --fa--fa: "\F84A\F84A"; }

.fa-display-chart-up-circle-currency {
  --fa: "\E5E5";
  --fa--fa: "\E5E5\E5E5"; }

.fa-skeleton {
  --fa: "\F620";
  --fa--fa: "\F620\F620"; }

.fa-circle-g {
  --fa: "\E10F";
  --fa--fa: "\E10F\E10F"; }

.fa-circle-arrow-up-left {
  --fa: "\E0FB";
  --fa--fa: "\E0FB\E0FB"; }

.fa-coin-blank {
  --fa: "\E3FB";
  --fa--fa: "\E3FB\E3FB"; }

.fa-broom {
  --fa: "\F51A";
  --fa--fa: "\F51A\F51A"; }

.fa-vacuum {
  --fa: "\E04D";
  --fa--fa: "\E04D\E04D"; }

.fa-shield-heart {
  --fa: "\E574";
  --fa--fa: "\E574\E574"; }

.fa-card-heart {
  --fa: "\E3EB";
  --fa--fa: "\E3EB\E3EB"; }

.fa-lightbulb-cfl-on {
  --fa: "\E5A7";
  --fa--fa: "\E5A7\E5A7"; }

.fa-melon {
  --fa: "\E310";
  --fa--fa: "\E310\E310"; }

.fa-gopuram {
  --fa: "\F664";
  --fa--fa: "\F664\F664"; }

.fa-earth-oceania {
  --fa: "\E47B";
  --fa--fa: "\E47B\E47B"; }

.fa-globe-oceania {
  --fa: "\E47B";
  --fa--fa: "\E47B\E47B"; }

.fa-container-storage {
  --fa: "\F4B7";
  --fa--fa: "\F4B7\F4B7"; }

.fa-face-pouting {
  --fa: "\E387";
  --fa--fa: "\E387\E387"; }

.fa-square-xmark {
  --fa: "\F2D3";
  --fa--fa: "\F2D3\F2D3"; }

.fa-times-square {
  --fa: "\F2D3";
  --fa--fa: "\F2D3\F2D3"; }

.fa-xmark-square {
  --fa: "\F2D3";
  --fa--fa: "\F2D3\F2D3"; }

.fa-face-explode {
  --fa: "\E2FE";
  --fa--fa: "\E2FE\E2FE"; }

.fa-exploding-head {
  --fa: "\E2FE";
  --fa--fa: "\E2FE\E2FE"; }

.fa-hashtag {
  --fa: "#";
  --fa--fa: "##"; }

.fa-up-right-and-down-left-from-center {
  --fa: "\F424";
  --fa--fa: "\F424\F424"; }

.fa-expand-alt {
  --fa: "\F424";
  --fa--fa: "\F424\F424"; }

.fa-oil-can {
  --fa: "\F613";
  --fa--fa: "\F613\F613"; }

.fa-t {
  --fa: "T";
  --fa--fa: "TT"; }

.fa-transformer-bolt {
  --fa: "\E2A4";
  --fa--fa: "\E2A4\E2A4"; }

.fa-hippo {
  --fa: "\F6ED";
  --fa--fa: "\F6ED\F6ED"; }

.fa-chart-column {
  --fa: "\E0E3";
  --fa--fa: "\E0E3\E0E3"; }

.fa-cassette-vhs {
  --fa: "\F8EC";
  --fa--fa: "\F8EC\F8EC"; }

.fa-vhs {
  --fa: "\F8EC";
  --fa--fa: "\F8EC\F8EC"; }

.fa-infinity {
  --fa: "\F534";
  --fa--fa: "\F534\F534"; }

.fa-vial-circle-check {
  --fa: "\E596";
  --fa--fa: "\E596\E596"; }

.fa-chimney {
  --fa: "\F78B";
  --fa--fa: "\F78B\F78B"; }

.fa-object-intersect {
  --fa: "\E49D";
  --fa--fa: "\E49D\E49D"; }

.fa-person-arrow-down-to-line {
  --fa: "\E538";
  --fa--fa: "\E538\E538"; }

.fa-voicemail {
  --fa: "\F897";
  --fa--fa: "\F897\F897"; }

.fa-block-brick {
  --fa: "\E3DB";
  --fa--fa: "\E3DB\E3DB"; }

.fa-wall-brick {
  --fa: "\E3DB";
  --fa--fa: "\E3DB\E3DB"; }

.fa-fan {
  --fa: "\F863";
  --fa--fa: "\F863\F863"; }

.fa-bags-shopping {
  --fa: "\F847";
  --fa--fa: "\F847\F847"; }

.fa-paragraph-left {
  --fa: "\F878";
  --fa--fa: "\F878\F878"; }

.fa-paragraph-rtl {
  --fa: "\F878";
  --fa--fa: "\F878\F878"; }

.fa-person-walking-luggage {
  --fa: "\E554";
  --fa--fa: "\E554\E554"; }

.fa-caravan-simple {
  --fa: "\E000";
  --fa--fa: "\E000\E000"; }

.fa-caravan-alt {
  --fa: "\E000";
  --fa--fa: "\E000\E000"; }

.fa-turtle {
  --fa: "\F726";
  --fa--fa: "\F726\F726"; }

.fa-pencil-mechanical {
  --fa: "\E5CA";
  --fa--fa: "\E5CA\E5CA"; }

.fa-up-down {
  --fa: "\F338";
  --fa--fa: "\F338\F338"; }

.fa-arrows-alt-v {
  --fa: "\F338";
  --fa--fa: "\F338\F338"; }

.fa-cloud-moon-rain {
  --fa: "\F73C";
  --fa--fa: "\F73C\F73C"; }

.fa-booth-curtain {
  --fa: "\F734";
  --fa--fa: "\F734\F734"; }

.fa-calendar {
  --fa: "\F133";
  --fa--fa: "\F133\F133"; }

.fa-box-heart {
  --fa: "\F49D";
  --fa--fa: "\F49D\F49D"; }

.fa-trailer {
  --fa: "\E041";
  --fa--fa: "\E041\E041"; }

.fa-user-doctor-message {
  --fa: "\F82E";
  --fa--fa: "\F82E\F82E"; }

.fa-user-md-chat {
  --fa: "\F82E";
  --fa--fa: "\F82E\F82E"; }

.fa-bahai {
  --fa: "\F666";
  --fa--fa: "\F666\F666"; }

.fa-haykal {
  --fa: "\F666";
  --fa--fa: "\F666\F666"; }

.fa-lighthouse {
  --fa: "\E612";
  --fa--fa: "\E612\E612"; }

.fa-amp-guitar {
  --fa: "\F8A1";
  --fa--fa: "\F8A1\F8A1"; }

.fa-sd-card {
  --fa: "\F7C2";
  --fa--fa: "\F7C2\F7C2"; }

.fa-volume-slash {
  --fa: "\F2E2";
  --fa--fa: "\F2E2\F2E2"; }

.fa-border-bottom {
  --fa: "\F84D";
  --fa--fa: "\F84D\F84D"; }

.fa-wifi-weak {
  --fa: "\F6AA";
  --fa--fa: "\F6AA\F6AA"; }

.fa-wifi-1 {
  --fa: "\F6AA";
  --fa--fa: "\F6AA\F6AA"; }

.fa-dragon {
  --fa: "\F6D5";
  --fa--fa: "\F6D5\F6D5"; }

.fa-shoe-prints {
  --fa: "\F54B";
  --fa--fa: "\F54B\F54B"; }

.fa-circle-plus {
  --fa: "\F055";
  --fa--fa: "\F055\F055"; }

.fa-plus-circle {
  --fa: "\F055";
  --fa--fa: "\F055\F055"; }

.fa-face-grin-tongue-wink {
  --fa: "\F58B";
  --fa--fa: "\F58B\F58B"; }

.fa-grin-tongue-wink {
  --fa: "\F58B";
  --fa--fa: "\F58B\F58B"; }

.fa-hand-holding {
  --fa: "\F4BD";
  --fa--fa: "\F4BD\F4BD"; }

.fa-plug-circle-exclamation {
  --fa: "\E55D";
  --fa--fa: "\E55D\E55D"; }

.fa-link-slash {
  --fa: "\F127";
  --fa--fa: "\F127\F127"; }

.fa-chain-broken {
  --fa: "\F127";
  --fa--fa: "\F127\F127"; }

.fa-chain-slash {
  --fa: "\F127";
  --fa--fa: "\F127\F127"; }

.fa-unlink {
  --fa: "\F127";
  --fa--fa: "\F127\F127"; }

.fa-clone {
  --fa: "\F24D";
  --fa--fa: "\F24D\F24D"; }

.fa-person-walking-arrow-loop-left {
  --fa: "\E551";
  --fa--fa: "\E551\E551"; }

.fa-arrow-up-z-a {
  --fa: "\F882";
  --fa--fa: "\F882\F882"; }

.fa-sort-alpha-up-alt {
  --fa: "\F882";
  --fa--fa: "\F882\F882"; }

.fa-fire-flame-curved {
  --fa: "\F7E4";
  --fa--fa: "\F7E4\F7E4"; }

.fa-fire-alt {
  --fa: "\F7E4";
  --fa--fa: "\F7E4\F7E4"; }

.fa-tornado {
  --fa: "\F76F";
  --fa--fa: "\F76F\F76F"; }

.fa-file-circle-plus {
  --fa: "\E494";
  --fa--fa: "\E494\E494"; }

.fa-delete-right {
  --fa: "\E154";
  --fa--fa: "\E154\E154"; }

.fa-book-quran {
  --fa: "\F687";
  --fa--fa: "\F687\F687"; }

.fa-quran {
  --fa: "\F687";
  --fa--fa: "\F687\F687"; }

.fa-circle-quarter {
  --fa: "\E11F";
  --fa--fa: "\E11F\E11F"; }

.fa-anchor {
  --fa: "\F13D";
  --fa--fa: "\F13D\F13D"; }

.fa-border-all {
  --fa: "\F84C";
  --fa--fa: "\F84C\F84C"; }

.fa-function {
  --fa: "\F661";
  --fa--fa: "\F661\F661"; }

.fa-face-angry {
  --fa: "\F556";
  --fa--fa: "\F556\F556"; }

.fa-angry {
  --fa: "\F556";
  --fa--fa: "\F556\F556"; }

.fa-people-simple {
  --fa: "\E21B";
  --fa--fa: "\E21B\E21B"; }

.fa-cookie-bite {
  --fa: "\F564";
  --fa--fa: "\F564\F564"; }

.fa-arrow-trend-down {
  --fa: "\E097";
  --fa--fa: "\E097\E097"; }

.fa-rss {
  --fa: "\F09E";
  --fa--fa: "\F09E\F09E"; }

.fa-feed {
  --fa: "\F09E";
  --fa--fa: "\F09E\F09E"; }

.fa-face-monocle {
  --fa: "\E380";
  --fa--fa: "\E380\E380"; }

.fa-draw-polygon {
  --fa: "\F5EE";
  --fa--fa: "\F5EE\F5EE"; }

.fa-scale-balanced {
  --fa: "\F24E";
  --fa--fa: "\F24E\F24E"; }

.fa-balance-scale {
  --fa: "\F24E";
  --fa--fa: "\F24E\F24E"; }

.fa-calendar-lines {
  --fa: "\E0D5";
  --fa--fa: "\E0D5\E0D5"; }

.fa-calendar-note {
  --fa: "\E0D5";
  --fa--fa: "\E0D5\E0D5"; }

.fa-arrow-down-big-small {
  --fa: "\F88C";
  --fa--fa: "\F88C\F88C"; }

.fa-sort-size-down {
  --fa: "\F88C";
  --fa--fa: "\F88C\F88C"; }

.fa-gauge-simple-high {
  --fa: "\F62A";
  --fa--fa: "\F62A\F62A"; }

.fa-tachometer {
  --fa: "\F62A";
  --fa--fa: "\F62A\F62A"; }

.fa-tachometer-fast {
  --fa: "\F62A";
  --fa--fa: "\F62A\F62A"; }

.fa-do-not-enter {
  --fa: "\F5EC";
  --fa--fa: "\F5EC\F5EC"; }

.fa-shower {
  --fa: "\F2CC";
  --fa--fa: "\F2CC\F2CC"; }

.fa-dice-d8 {
  --fa: "\F6D2";
  --fa--fa: "\F6D2\F6D2"; }

.fa-desktop {
  --fa: "\F390";
  --fa--fa: "\F390\F390"; }

.fa-desktop-alt {
  --fa: "\F390";
  --fa--fa: "\F390\F390"; }

.fa-m {
  --fa: "M";
  --fa--fa: "MM"; }

.fa-spinner-scale {
  --fa: "\E62A";
  --fa--fa: "\E62A\E62A"; }

.fa-grip-dots-vertical {
  --fa: "\E411";
  --fa--fa: "\E411\E411"; }

.fa-face-viewfinder {
  --fa: "\E2FF";
  --fa--fa: "\E2FF\E2FF"; }

.fa-soft-serve {
  --fa: "\E400";
  --fa--fa: "\E400\E400"; }

.fa-creemee {
  --fa: "\E400";
  --fa--fa: "\E400\E400"; }

.fa-h5 {
  --fa: "\E412";
  --fa--fa: "\E412\E412"; }

.fa-hand-back-point-down {
  --fa: "\E19E";
  --fa--fa: "\E19E\E19E"; }

.fa-table-list {
  --fa: "\F00B";
  --fa--fa: "\F00B\F00B"; }

.fa-th-list {
  --fa: "\F00B";
  --fa--fa: "\F00B\F00B"; }

.fa-basket-shopping-minus {
  --fa: "\E652";
  --fa--fa: "\E652\E652"; }

.fa-comment-sms {
  --fa: "\F7CD";
  --fa--fa: "\F7CD\F7CD"; }

.fa-sms {
  --fa: "\F7CD";
  --fa--fa: "\F7CD\F7CD"; }

.fa-rectangle {
  --fa: "\F2FA";
  --fa--fa: "\F2FA\F2FA"; }

.fa-rectangle-landscape {
  --fa: "\F2FA";
  --fa--fa: "\F2FA\F2FA"; }

.fa-clipboard-list-check {
  --fa: "\F737";
  --fa--fa: "\F737\F737"; }

.fa-turkey {
  --fa: "\F725";
  --fa--fa: "\F725\F725"; }

.fa-book {
  --fa: "\F02D";
  --fa--fa: "\F02D\F02D"; }

.fa-user-plus {
  --fa: "\F234";
  --fa--fa: "\F234\F234"; }

.fa-ice-skate {
  --fa: "\F7AC";
  --fa--fa: "\F7AC\F7AC"; }

.fa-check {
  --fa: "\F00C";
  --fa--fa: "\F00C\F00C"; }

.fa-battery-three-quarters {
  --fa: "\F241";
  --fa--fa: "\F241\F241"; }

.fa-battery-4 {
  --fa: "\F241";
  --fa--fa: "\F241\F241"; }

.fa-tomato {
  --fa: "\E330";
  --fa--fa: "\E330\E330"; }

.fa-sword-laser {
  --fa: "\E03B";
  --fa--fa: "\E03B\E03B"; }

.fa-house-circle-check {
  --fa: "\E509";
  --fa--fa: "\E509\E509"; }

.fa-buildings {
  --fa: "\E0CC";
  --fa--fa: "\E0CC\E0CC"; }

.fa-angle-left {
  --fa: "\F104";
  --fa--fa: "\F104\F104"; }

.fa-cart-flatbed-boxes {
  --fa: "\F475";
  --fa--fa: "\F475\F475"; }

.fa-dolly-flatbed-alt {
  --fa: "\F475";
  --fa--fa: "\F475\F475"; }

.fa-diagram-successor {
  --fa: "\E47A";
  --fa--fa: "\E47A\E47A"; }

.fa-truck-arrow-right {
  --fa: "\E58B";
  --fa--fa: "\E58B\E58B"; }

.fa-square-w {
  --fa: "\E285";
  --fa--fa: "\E285\E285"; }

.fa-arrows-split-up-and-left {
  --fa: "\E4BC";
  --fa--fa: "\E4BC\E4BC"; }

.fa-lamp {
  --fa: "\F4CA";
  --fa--fa: "\F4CA\F4CA"; }

.fa-airplay {
  --fa: "\E089";
  --fa--fa: "\E089\E089"; }

.fa-hand-fist {
  --fa: "\F6DE";
  --fa--fa: "\F6DE\F6DE"; }

.fa-fist-raised {
  --fa: "\F6DE";
  --fa--fa: "\F6DE\F6DE"; }

.fa-shield-quartered {
  --fa: "\E575";
  --fa--fa: "\E575\E575"; }

.fa-slash-forward {
  --fa: "/";
  --fa--fa: "//"; }

.fa-location-pen {
  --fa: "\F607";
  --fa--fa: "\F607\F607"; }

.fa-map-marker-edit {
  --fa: "\F607";
  --fa--fa: "\F607\F607"; }

.fa-cloud-moon {
  --fa: "\F6C3";
  --fa--fa: "\F6C3\F6C3"; }

.fa-pot-food {
  --fa: "\E43F";
  --fa--fa: "\E43F\E43F"; }

.fa-briefcase {
  --fa: "\F0B1";
  --fa--fa: "\F0B1\F0B1"; }

.fa-person-falling {
  --fa: "\E546";
  --fa--fa: "\E546\E546"; }

.fa-image-portrait {
  --fa: "\F3E0";
  --fa--fa: "\F3E0\F3E0"; }

.fa-portrait {
  --fa: "\F3E0";
  --fa--fa: "\F3E0\F3E0"; }

.fa-user-tag {
  --fa: "\F507";
  --fa--fa: "\F507\F507"; }

.fa-rug {
  --fa: "\E569";
  --fa--fa: "\E569\E569"; }

.fa-print-slash {
  --fa: "\F686";
  --fa--fa: "\F686\F686"; }

.fa-earth-europe {
  --fa: "\F7A2";
  --fa--fa: "\F7A2\F7A2"; }

.fa-globe-europe {
  --fa: "\F7A2";
  --fa--fa: "\F7A2\F7A2"; }

.fa-cart-flatbed-suitcase {
  --fa: "\F59D";
  --fa--fa: "\F59D\F59D"; }

.fa-luggage-cart {
  --fa: "\F59D";
  --fa--fa: "\F59D\F59D"; }

.fa-hand-back-point-ribbon {
  --fa: "\E1A0";
  --fa--fa: "\E1A0\E1A0"; }

.fa-rectangle-xmark {
  --fa: "\F410";
  --fa--fa: "\F410\F410"; }

.fa-rectangle-times {
  --fa: "\F410";
  --fa--fa: "\F410\F410"; }

.fa-times-rectangle {
  --fa: "\F410";
  --fa--fa: "\F410\F410"; }

.fa-window-close {
  --fa: "\F410";
  --fa--fa: "\F410\F410"; }

.fa-tire-rugged {
  --fa: "\F634";
  --fa--fa: "\F634\F634"; }

.fa-lightbulb-dollar {
  --fa: "\F670";
  --fa--fa: "\F670\F670"; }

.fa-cowbell {
  --fa: "\F8B3";
  --fa--fa: "\F8B3\F8B3"; }

.fa-baht-sign {
  --fa: "\E0AC";
  --fa--fa: "\E0AC\E0AC"; }

.fa-corner {
  --fa: "\E3FE";
  --fa--fa: "\E3FE\E3FE"; }

.fa-chevrons-right {
  --fa: "\F324";
  --fa--fa: "\F324\F324"; }

.fa-chevron-double-right {
  --fa: "\F324";
  --fa--fa: "\F324\F324"; }

.fa-book-open {
  --fa: "\F518";
  --fa--fa: "\F518\F518"; }

.fa-book-journal-whills {
  --fa: "\F66A";
  --fa--fa: "\F66A\F66A"; }

.fa-journal-whills {
  --fa: "\F66A";
  --fa--fa: "\F66A\F66A"; }

.fa-inhaler {
  --fa: "\F5F9";
  --fa--fa: "\F5F9\F5F9"; }

.fa-handcuffs {
  --fa: "\E4F8";
  --fa--fa: "\E4F8\E4F8"; }

.fa-snake {
  --fa: "\F716";
  --fa--fa: "\F716\F716"; }

.fa-triangle-exclamation {
  --fa: "\F071";
  --fa--fa: "\F071\F071"; }

.fa-exclamation-triangle {
  --fa: "\F071";
  --fa--fa: "\F071\F071"; }

.fa-warning {
  --fa: "\F071";
  --fa--fa: "\F071\F071"; }

.fa-note-medical {
  --fa: "\E200";
  --fa--fa: "\E200\E200"; }

.fa-database {
  --fa: "\F1C0";
  --fa--fa: "\F1C0\F1C0"; }

.fa-down-left {
  --fa: "\E16A";
  --fa--fa: "\E16A\E16A"; }

.fa-share {
  --fa: "\F064";
  --fa--fa: "\F064\F064"; }

.fa-mail-forward {
  --fa: "\F064";
  --fa--fa: "\F064\F064"; }

.fa-face-thinking {
  --fa: "\E39B";
  --fa--fa: "\E39B\E39B"; }

.fa-turn-down-right {
  --fa: "\E455";
  --fa--fa: "\E455\E455"; }

.fa-bottle-droplet {
  --fa: "\E4C4";
  --fa--fa: "\E4C4\E4C4"; }

.fa-mask-face {
  --fa: "\E1D7";
  --fa--fa: "\E1D7\E1D7"; }

.fa-hill-rockslide {
  --fa: "\E508";
  --fa--fa: "\E508\E508"; }

.fa-scanner-keyboard {
  --fa: "\F489";
  --fa--fa: "\F489\F489"; }

.fa-circle-o {
  --fa: "\E119";
  --fa--fa: "\E119\E119"; }

.fa-grid-horizontal {
  --fa: "\E307";
  --fa--fa: "\E307\E307"; }

.fa-message-dollar {
  --fa: "\F650";
  --fa--fa: "\F650\F650"; }

.fa-comment-alt-dollar {
  --fa: "\F650";
  --fa--fa: "\F650\F650"; }

.fa-right-left {
  --fa: "\F362";
  --fa--fa: "\F362\F362"; }

.fa-exchange-alt {
  --fa: "\F362";
  --fa--fa: "\F362\F362"; }

.fa-columns-3 {
  --fa: "\E361";
  --fa--fa: "\E361\E361"; }

.fa-paper-plane {
  --fa: "\F1D8";
  --fa--fa: "\F1D8\F1D8"; }

.fa-road-circle-exclamation {
  --fa: "\E565";
  --fa--fa: "\E565\E565"; }

.fa-dungeon {
  --fa: "\F6D9";
  --fa--fa: "\F6D9\F6D9"; }

.fa-hand-holding-box {
  --fa: "\F47B";
  --fa--fa: "\F47B\F47B"; }

.fa-input-text {
  --fa: "\E1BF";
  --fa--fa: "\E1BF\E1BF"; }

.fa-window-flip {
  --fa: "\F40F";
  --fa--fa: "\F40F\F40F"; }

.fa-window-alt {
  --fa: "\F40F";
  --fa--fa: "\F40F\F40F"; }

.fa-align-right {
  --fa: "\F038";
  --fa--fa: "\F038\F038"; }

.fa-scanner-gun {
  --fa: "\F488";
  --fa--fa: "\F488\F488"; }

.fa-scanner {
  --fa: "\F488";
  --fa--fa: "\F488\F488"; }

.fa-tire {
  --fa: "\F631";
  --fa--fa: "\F631\F631"; }

.fa-engine {
  --fa: "\E16E";
  --fa--fa: "\E16E\E16E"; }

.fa-money-bill-1-wave {
  --fa: "\F53B";
  --fa--fa: "\F53B\F53B"; }

.fa-money-bill-wave-alt {
  --fa: "\F53B";
  --fa--fa: "\F53B\F53B"; }

.fa-life-ring {
  --fa: "\F1CD";
  --fa--fa: "\F1CD\F1CD"; }

.fa-hands {
  --fa: "\F2A7";
  --fa--fa: "\F2A7\F2A7"; }

.fa-sign-language {
  --fa: "\F2A7";
  --fa--fa: "\F2A7\F2A7"; }

.fa-signing {
  --fa: "\F2A7";
  --fa--fa: "\F2A7\F2A7"; }

.fa-circle-caret-right {
  --fa: "\F330";
  --fa--fa: "\F330\F330"; }

.fa-caret-circle-right {
  --fa: "\F330";
  --fa--fa: "\F330\F330"; }

.fa-turn-left {
  --fa: "\E636";
  --fa--fa: "\E636\E636"; }

.fa-wheat {
  --fa: "\F72D";
  --fa--fa: "\F72D\F72D"; }

.fa-file-spreadsheet {
  --fa: "\F65B";
  --fa--fa: "\F65B\F65B"; }

.fa-audio-description-slash {
  --fa: "\E0A8";
  --fa--fa: "\E0A8\E0A8"; }

.fa-bell-ring {
  --fa: "\E62C";
  --fa--fa: "\E62C\E62C"; }

.fa-calendar-day {
  --fa: "\F783";
  --fa--fa: "\F783\F783"; }

.fa-water-ladder {
  --fa: "\F5C5";
  --fa--fa: "\F5C5\F5C5"; }

.fa-ladder-water {
  --fa: "\F5C5";
  --fa--fa: "\F5C5\F5C5"; }

.fa-swimming-pool {
  --fa: "\F5C5";
  --fa--fa: "\F5C5\F5C5"; }

.fa-arrows-up-down {
  --fa: "\F07D";
  --fa--fa: "\F07D\F07D"; }

.fa-arrows-v {
  --fa: "\F07D";
  --fa--fa: "\F07D\F07D"; }

.fa-chess-pawn-piece {
  --fa: "\F444";
  --fa--fa: "\F444\F444"; }

.fa-chess-pawn-alt {
  --fa: "\F444";
  --fa--fa: "\F444\F444"; }

.fa-face-grimace {
  --fa: "\F57F";
  --fa--fa: "\F57F\F57F"; }

.fa-grimace {
  --fa: "\F57F";
  --fa--fa: "\F57F\F57F"; }

.fa-wheelchair-move {
  --fa: "\E2CE";
  --fa--fa: "\E2CE\E2CE"; }

.fa-wheelchair-alt {
  --fa: "\E2CE";
  --fa--fa: "\E2CE\E2CE"; }

.fa-turn-down {
  --fa: "\F3BE";
  --fa--fa: "\F3BE\F3BE"; }

.fa-level-down-alt {
  --fa: "\F3BE";
  --fa--fa: "\F3BE\F3BE"; }

.fa-square-s {
  --fa: "\E27D";
  --fa--fa: "\E27D\E27D"; }

.fa-rectangle-barcode {
  --fa: "\F463";
  --fa--fa: "\F463\F463"; }

.fa-barcode-alt {
  --fa: "\F463";
  --fa--fa: "\F463\F463"; }

.fa-person-walking-arrow-right {
  --fa: "\E552";
  --fa--fa: "\E552\E552"; }

.fa-square-envelope {
  --fa: "\F199";
  --fa--fa: "\F199\F199"; }

.fa-envelope-square {
  --fa: "\F199";
  --fa--fa: "\F199\F199"; }

.fa-dice {
  --fa: "\F522";
  --fa--fa: "\F522\F522"; }

.fa-unicorn {
  --fa: "\F727";
  --fa--fa: "\F727\F727"; }

.fa-bowling-ball {
  --fa: "\F436";
  --fa--fa: "\F436\F436"; }

.fa-pompebled {
  --fa: "\E43D";
  --fa--fa: "\E43D\E43D"; }

.fa-brain {
  --fa: "\F5DC";
  --fa--fa: "\F5DC\F5DC"; }

.fa-watch-smart {
  --fa: "\E2CC";
  --fa--fa: "\E2CC\E2CC"; }

.fa-book-user {
  --fa: "\F7E7";
  --fa--fa: "\F7E7\F7E7"; }

.fa-sensor-cloud {
  --fa: "\E02C";
  --fa--fa: "\E02C\E02C"; }

.fa-sensor-smoke {
  --fa: "\E02C";
  --fa--fa: "\E02C\E02C"; }

.fa-clapperboard-play {
  --fa: "\E132";
  --fa--fa: "\E132\E132"; }

.fa-bandage {
  --fa: "\F462";
  --fa--fa: "\F462\F462"; }

.fa-band-aid {
  --fa: "\F462";
  --fa--fa: "\F462\F462"; }

.fa-calendar-minus {
  --fa: "\F272";
  --fa--fa: "\F272\F272"; }

.fa-circle-xmark {
  --fa: "\F057";
  --fa--fa: "\F057\F057"; }

.fa-times-circle {
  --fa: "\F057";
  --fa--fa: "\F057\F057"; }

.fa-xmark-circle {
  --fa: "\F057";
  --fa--fa: "\F057\F057"; }

.fa-circle-4 {
  --fa: "\E0F1";
  --fa--fa: "\E0F1\E0F1"; }

.fa-gifts {
  --fa: "\F79C";
  --fa--fa: "\F79C\F79C"; }

.fa-album-collection {
  --fa: "\F8A0";
  --fa--fa: "\F8A0\F8A0"; }

.fa-hotel {
  --fa: "\F594";
  --fa--fa: "\F594\F594"; }

.fa-earth-asia {
  --fa: "\F57E";
  --fa--fa: "\F57E\F57E"; }

.fa-globe-asia {
  --fa: "\F57E";
  --fa--fa: "\F57E\F57E"; }

.fa-id-card-clip {
  --fa: "\F47F";
  --fa--fa: "\F47F\F47F"; }

.fa-id-card-alt {
  --fa: "\F47F";
  --fa--fa: "\F47F\F47F"; }

.fa-magnifying-glass-plus {
  --fa: "\F00E";
  --fa--fa: "\F00E\F00E"; }

.fa-search-plus {
  --fa: "\F00E";
  --fa--fa: "\F00E\F00E"; }

.fa-thumbs-up {
  --fa: "\F164";
  --fa--fa: "\F164\F164"; }

.fa-cloud-showers {
  --fa: "\F73F";
  --fa--fa: "\F73F\F73F"; }

.fa-user-clock {
  --fa: "\F4FD";
  --fa--fa: "\F4FD\F4FD"; }

.fa-onion {
  --fa: "\E427";
  --fa--fa: "\E427\E427"; }

.fa-clock-twelve-thirty {
  --fa: "\E359";
  --fa--fa: "\E359\E359"; }

.fa-arrow-down-to-dotted-line {
  --fa: "\E095";
  --fa--fa: "\E095\E095"; }

.fa-hand-dots {
  --fa: "\F461";
  --fa--fa: "\F461\F461"; }

.fa-allergies {
  --fa: "\F461";
  --fa--fa: "\F461\F461"; }

.fa-file-invoice {
  --fa: "\F570";
  --fa--fa: "\F570\F570"; }

.fa-window-minimize {
  --fa: "\F2D1";
  --fa--fa: "\F2D1\F2D1"; }

.fa-rectangle-wide {
  --fa: "\F2FC";
  --fa--fa: "\F2FC\F2FC"; }

.fa-comment-arrow-up {
  --fa: "\E144";
  --fa--fa: "\E144\E144"; }

.fa-garlic {
  --fa: "\E40E";
  --fa--fa: "\E40E\E40E"; }

.fa-mug-saucer {
  --fa: "\F0F4";
  --fa--fa: "\F0F4\F0F4"; }

.fa-coffee {
  --fa: "\F0F4";
  --fa--fa: "\F0F4\F0F4"; }

.fa-brush {
  --fa: "\F55D";
  --fa--fa: "\F55D\F55D"; }

.fa-file-half-dashed {
  --fa: "\E698";
  --fa--fa: "\E698\E698"; }

.fa-tree-decorated {
  --fa: "\F7DC";
  --fa--fa: "\F7DC\F7DC"; }

.fa-mask {
  --fa: "\F6FA";
  --fa--fa: "\F6FA\F6FA"; }

.fa-calendar-heart {
  --fa: "\E0D3";
  --fa--fa: "\E0D3\E0D3"; }

.fa-magnifying-glass-minus {
  --fa: "\F010";
  --fa--fa: "\F010\F010"; }

.fa-search-minus {
  --fa: "\F010";
  --fa--fa: "\F010\F010"; }

.fa-flower {
  --fa: "\F7FF";
  --fa--fa: "\F7FF\F7FF"; }

.fa-arrow-down-from-arc {
  --fa: "\E614";
  --fa--fa: "\E614\E614"; }

.fa-right-left-large {
  --fa: "\E5E1";
  --fa--fa: "\E5E1\E5E1"; }

.fa-ruler-vertical {
  --fa: "\F548";
  --fa--fa: "\F548\F548"; }

.fa-circles-overlap {
  --fa: "\E600";
  --fa--fa: "\E600\E600"; }

.fa-user-large {
  --fa: "\F406";
  --fa--fa: "\F406\F406"; }

.fa-user-alt {
  --fa: "\F406";
  --fa--fa: "\F406\F406"; }

.fa-starship-freighter {
  --fa: "\E03A";
  --fa--fa: "\E03A\E03A"; }

.fa-train-tram {
  --fa: "\E5B4";
  --fa--fa: "\E5B4\E5B4"; }

.fa-bridge-suspension {
  --fa: "\E4CD";
  --fa--fa: "\E4CD\E4CD"; }

.fa-trash-check {
  --fa: "\E2AF";
  --fa--fa: "\E2AF\E2AF"; }

.fa-user-nurse {
  --fa: "\F82F";
  --fa--fa: "\F82F\F82F"; }

.fa-boombox {
  --fa: "\F8A5";
  --fa--fa: "\F8A5\F8A5"; }

.fa-syringe {
  --fa: "\F48E";
  --fa--fa: "\F48E\F48E"; }

.fa-cloud-sun {
  --fa: "\F6C4";
  --fa--fa: "\F6C4\F6C4"; }

.fa-shield-exclamation {
  --fa: "\E247";
  --fa--fa: "\E247\E247"; }

.fa-stopwatch-20 {
  --fa: "\E06F";
  --fa--fa: "\E06F\E06F"; }

.fa-square-full {
  --fa: "\F45C";
  --fa--fa: "\F45C\F45C"; }

.fa-grip-dots {
  --fa: "\E410";
  --fa--fa: "\E410\E410"; }

.fa-comment-exclamation {
  --fa: "\F4AF";
  --fa--fa: "\F4AF\F4AF"; }

.fa-pen-swirl {
  --fa: "\E214";
  --fa--fa: "\E214\E214"; }

.fa-falafel {
  --fa: "\E40A";
  --fa--fa: "\E40A\E40A"; }

.fa-circle-2 {
  --fa: "\E0EF";
  --fa--fa: "\E0EF\E0EF"; }

.fa-magnet {
  --fa: "\F076";
  --fa--fa: "\F076\F076"; }

.fa-jar {
  --fa: "\E516";
  --fa--fa: "\E516\E516"; }

.fa-gramophone {
  --fa: "\F8BD";
  --fa--fa: "\F8BD\F8BD"; }

.fa-dice-d12 {
  --fa: "\F6CE";
  --fa--fa: "\F6CE\F6CE"; }

.fa-note-sticky {
  --fa: "\F249";
  --fa--fa: "\F249\F249"; }

.fa-sticky-note {
  --fa: "\F249";
  --fa--fa: "\F249\F249"; }

.fa-down {
  --fa: "\F354";
  --fa--fa: "\F354\F354"; }

.fa-arrow-alt-down {
  --fa: "\F354";
  --fa--fa: "\F354\F354"; }

.fa-hundred-points {
  --fa: "\E41C";
  --fa--fa: "\E41C\E41C"; }

.fa-100 {
  --fa: "\E41C";
  --fa--fa: "\E41C\E41C"; }

.fa-paperclip-vertical {
  --fa: "\E3C2";
  --fa--fa: "\E3C2\E3C2"; }

.fa-wind-warning {
  --fa: "\F776";
  --fa--fa: "\F776\F776"; }

.fa-wind-circle-exclamation {
  --fa: "\F776";
  --fa--fa: "\F776\F776"; }

.fa-location-pin-slash {
  --fa: "\F60C";
  --fa--fa: "\F60C\F60C"; }

.fa-map-marker-slash {
  --fa: "\F60C";
  --fa--fa: "\F60C\F60C"; }

.fa-face-sad-sweat {
  --fa: "\E38A";
  --fa--fa: "\E38A\E38A"; }

.fa-bug-slash {
  --fa: "\E490";
  --fa--fa: "\E490\E490"; }

.fa-cupcake {
  --fa: "\E402";
  --fa--fa: "\E402\E402"; }

.fa-light-switch-off {
  --fa: "\E018";
  --fa--fa: "\E018\E018"; }

.fa-toggle-large-off {
  --fa: "\E5B0";
  --fa--fa: "\E5B0\E5B0"; }

.fa-pen-fancy-slash {
  --fa: "\E210";
  --fa--fa: "\E210\E210"; }

.fa-truck-container {
  --fa: "\F4DC";
  --fa--fa: "\F4DC\F4DC"; }

.fa-boot {
  --fa: "\F782";
  --fa--fa: "\F782\F782"; }

.fa-arrow-up-from-water-pump {
  --fa: "\E4B6";
  --fa--fa: "\E4B6\E4B6"; }

.fa-file-check {
  --fa: "\F316";
  --fa--fa: "\F316\F316"; }

.fa-bone {
  --fa: "\F5D7";
  --fa--fa: "\F5D7\F5D7"; }

.fa-cards-blank {
  --fa: "\E4DF";
  --fa--fa: "\E4DF\E4DF"; }

.fa-circle-3 {
  --fa: "\E0F0";
  --fa--fa: "\E0F0\E0F0"; }

.fa-bench-tree {
  --fa: "\E2E7";
  --fa--fa: "\E2E7\E2E7"; }

.fa-keyboard-brightness-low {
  --fa: "\E1C1";
  --fa--fa: "\E1C1\E1C1"; }

.fa-ski-boot-ski {
  --fa: "\E3CD";
  --fa--fa: "\E3CD\E3CD"; }

.fa-brain-circuit {
  --fa: "\E0C6";
  --fa--fa: "\E0C6\E0C6"; }

.fa-table-cells-row-unlock {
  --fa: "\E691";
  --fa--fa: "\E691\E691"; }

.fa-user-injured {
  --fa: "\F728";
  --fa--fa: "\F728\F728"; }

.fa-block-brick-fire {
  --fa: "\E3DC";
  --fa--fa: "\E3DC\E3DC"; }

.fa-firewall {
  --fa: "\E3DC";
  --fa--fa: "\E3DC\E3DC"; }

.fa-face-sad-tear {
  --fa: "\F5B4";
  --fa--fa: "\F5B4\F5B4"; }

.fa-sad-tear {
  --fa: "\F5B4";
  --fa--fa: "\F5B4\F5B4"; }

.fa-plane {
  --fa: "\F072";
  --fa--fa: "\F072\F072"; }

.fa-tent-arrows-down {
  --fa: "\E581";
  --fa--fa: "\E581\E581"; }

.fa-exclamation {
  --fa: "!";
  --fa--fa: "!!"; }

.fa-arrows-spin {
  --fa: "\E4BB";
  --fa--fa: "\E4BB\E4BB"; }

.fa-face-smile-relaxed {
  --fa: "\E392";
  --fa--fa: "\E392\E392"; }

.fa-comment-xmark {
  --fa: "\F4B5";
  --fa--fa: "\F4B5\F4B5"; }

.fa-comment-times {
  --fa: "\F4B5";
  --fa--fa: "\F4B5\F4B5"; }

.fa-print {
  --fa: "\F02F";
  --fa--fa: "\F02F\F02F"; }

.fa-turkish-lira-sign {
  --fa: "\E2BB";
  --fa--fa: "\E2BB\E2BB"; }

.fa-try {
  --fa: "\E2BB";
  --fa--fa: "\E2BB\E2BB"; }

.fa-turkish-lira {
  --fa: "\E2BB";
  --fa--fa: "\E2BB\E2BB"; }

.fa-face-nose-steam {
  --fa: "\E382";
  --fa--fa: "\E382\E382"; }

.fa-circle-waveform-lines {
  --fa: "\E12D";
  --fa--fa: "\E12D\E12D"; }

.fa-waveform-circle {
  --fa: "\E12D";
  --fa--fa: "\E12D\E12D"; }

.fa-dollar-sign {
  --fa: "$";
  --fa--fa: "$$"; }

.fa-dollar {
  --fa: "$";
  --fa--fa: "$$"; }

.fa-usd {
  --fa: "$";
  --fa--fa: "$$"; }

.fa-ferris-wheel {
  --fa: "\E174";
  --fa--fa: "\E174\E174"; }

.fa-computer-speaker {
  --fa: "\F8B2";
  --fa--fa: "\F8B2\F8B2"; }

.fa-skull-cow {
  --fa: "\F8DE";
  --fa--fa: "\F8DE\F8DE"; }

.fa-x {
  --fa: "X";
  --fa--fa: "XX"; }

.fa-magnifying-glass-dollar {
  --fa: "\F688";
  --fa--fa: "\F688\F688"; }

.fa-search-dollar {
  --fa: "\F688";
  --fa--fa: "\F688\F688"; }

.fa-users-gear {
  --fa: "\F509";
  --fa--fa: "\F509\F509"; }

.fa-users-cog {
  --fa: "\F509";
  --fa--fa: "\F509\F509"; }

.fa-person-military-pointing {
  --fa: "\E54A";
  --fa--fa: "\E54A\E54A"; }

.fa-building-columns {
  --fa: "\F19C";
  --fa--fa: "\F19C\F19C"; }

.fa-bank {
  --fa: "\F19C";
  --fa--fa: "\F19C\F19C"; }

.fa-institution {
  --fa: "\F19C";
  --fa--fa: "\F19C\F19C"; }

.fa-museum {
  --fa: "\F19C";
  --fa--fa: "\F19C\F19C"; }

.fa-university {
  --fa: "\F19C";
  --fa--fa: "\F19C\F19C"; }

.fa-circle-t {
  --fa: "\E124";
  --fa--fa: "\E124\E124"; }

.fa-sack {
  --fa: "\F81C";
  --fa--fa: "\F81C\F81C"; }

.fa-grid-2 {
  --fa: "\E196";
  --fa--fa: "\E196\E196"; }

.fa-camera-cctv {
  --fa: "\F8AC";
  --fa--fa: "\F8AC\F8AC"; }

.fa-cctv {
  --fa: "\F8AC";
  --fa--fa: "\F8AC\F8AC"; }

.fa-umbrella {
  --fa: "\F0E9";
  --fa--fa: "\F0E9\F0E9"; }

.fa-trowel {
  --fa: "\E589";
  --fa--fa: "\E589\E589"; }

.fa-horizontal-rule {
  --fa: "\F86C";
  --fa--fa: "\F86C\F86C"; }

.fa-bed-front {
  --fa: "\F8F7";
  --fa--fa: "\F8F7\F8F7"; }

.fa-bed-alt {
  --fa: "\F8F7";
  --fa--fa: "\F8F7\F8F7"; }

.fa-d {
  --fa: "D";
  --fa--fa: "DD"; }

.fa-stapler {
  --fa: "\E5AF";
  --fa--fa: "\E5AF\E5AF"; }

.fa-masks-theater {
  --fa: "\F630";
  --fa--fa: "\F630\F630"; }

.fa-theater-masks {
  --fa: "\F630";
  --fa--fa: "\F630\F630"; }

.fa-file-gif {
  --fa: "\E645";
  --fa--fa: "\E645\E645"; }

.fa-kip-sign {
  --fa: "\E1C4";
  --fa--fa: "\E1C4\E1C4"; }

.fa-face-woozy {
  --fa: "\E3A2";
  --fa--fa: "\E3A2\E3A2"; }

.fa-cloud-question {
  --fa: "\E492";
  --fa--fa: "\E492\E492"; }

.fa-pineapple {
  --fa: "\E31F";
  --fa--fa: "\E31F\E31F"; }

.fa-hand-point-left {
  --fa: "\F0A5";
  --fa--fa: "\F0A5\F0A5"; }

.fa-gallery-thumbnails {
  --fa: "\E3AA";
  --fa--fa: "\E3AA\E3AA"; }

.fa-circle-j {
  --fa: "\E112";
  --fa--fa: "\E112\E112"; }

.fa-eyes {
  --fa: "\E367";
  --fa--fa: "\E367\E367"; }

.fa-handshake-simple {
  --fa: "\F4C6";
  --fa--fa: "\F4C6\F4C6"; }

.fa-handshake-alt {
  --fa: "\F4C6";
  --fa--fa: "\F4C6\F4C6"; }

.fa-page-caret-up {
  --fa: "\E42A";
  --fa--fa: "\E42A\E42A"; }

.fa-file-caret-up {
  --fa: "\E42A";
  --fa--fa: "\E42A\E42A"; }

.fa-jet-fighter {
  --fa: "\F0FB";
  --fa--fa: "\F0FB\F0FB"; }

.fa-fighter-jet {
  --fa: "\F0FB";
  --fa--fa: "\F0FB\F0FB"; }

.fa-comet {
  --fa: "\E003";
  --fa--fa: "\E003\E003"; }

.fa-square-share-nodes {
  --fa: "\F1E1";
  --fa--fa: "\F1E1\F1E1"; }

.fa-share-alt-square {
  --fa: "\F1E1";
  --fa--fa: "\F1E1\F1E1"; }

.fa-reflect-vertical {
  --fa: "\E665";
  --fa--fa: "\E665\E665"; }

.fa-shield-keyhole {
  --fa: "\E248";
  --fa--fa: "\E248\E248"; }

.fa-file-mp4 {
  --fa: "\E649";
  --fa--fa: "\E649\E649"; }

.fa-barcode {
  --fa: "\F02A";
  --fa--fa: "\F02A\F02A"; }

.fa-bulldozer {
  --fa: "\E655";
  --fa--fa: "\E655\E655"; }

.fa-plus-minus {
  --fa: "\E43C";
  --fa--fa: "\E43C\E43C"; }

.fa-square-sliders-vertical {
  --fa: "\F3F2";
  --fa--fa: "\F3F2\F3F2"; }

.fa-sliders-v-square {
  --fa: "\F3F2";
  --fa--fa: "\F3F2\F3F2"; }

.fa-video {
  --fa: "\F03D";
  --fa--fa: "\F03D\F03D"; }

.fa-video-camera {
  --fa: "\F03D";
  --fa--fa: "\F03D\F03D"; }

.fa-message-middle {
  --fa: "\E1E1";
  --fa--fa: "\E1E1\E1E1"; }

.fa-comment-middle-alt {
  --fa: "\E1E1";
  --fa--fa: "\E1E1\E1E1"; }

.fa-graduation-cap {
  --fa: "\F19D";
  --fa--fa: "\F19D\F19D"; }

.fa-mortar-board {
  --fa: "\F19D";
  --fa--fa: "\F19D\F19D"; }

.fa-hand-holding-medical {
  --fa: "\E05C";
  --fa--fa: "\E05C\E05C"; }

.fa-person-circle-check {
  --fa: "\E53E";
  --fa--fa: "\E53E\E53E"; }

.fa-square-z {
  --fa: "\E288";
  --fa--fa: "\E288\E288"; }

.fa-message-text {
  --fa: "\E1E6";
  --fa--fa: "\E1E6\E1E6"; }

.fa-comment-alt-text {
  --fa: "\E1E6";
  --fa--fa: "\E1E6\E1E6"; }

.fa-turn-up {
  --fa: "\F3BF";
  --fa--fa: "\F3BF\F3BF"; }

.fa-level-up-alt {
  --fa: "\F3BF";
  --fa--fa: "\F3BF\F3BF"; }

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }
:root, :host {
  --fa-style-family-brands: 'Font Awesome 6 Brands';
  --fa-font-brands: normal 400 1em/1 'Font Awesome 6 Brands'; }

@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(/static/media/fa-brands-400.b202664d.woff2) format("woff2"), url(/static/media/fa-brands-400.e9a507ba.ttf) format("truetype"); }

.fab,
.fa-brands {
  font-weight: 400; }

.fa-monero {
  --fa: "\F3D0"; }

.fa-hooli {
  --fa: "\F427"; }

.fa-yelp {
  --fa: "\F1E9"; }

.fa-cc-visa {
  --fa: "\F1F0"; }

.fa-lastfm {
  --fa: "\F202"; }

.fa-shopware {
  --fa: "\F5B5"; }

.fa-creative-commons-nc {
  --fa: "\F4E8"; }

.fa-aws {
  --fa: "\F375"; }

.fa-redhat {
  --fa: "\F7BC"; }

.fa-yoast {
  --fa: "\F2B1"; }

.fa-cloudflare {
  --fa: "\E07D"; }

.fa-ups {
  --fa: "\F7E0"; }

.fa-pixiv {
  --fa: "\E640"; }

.fa-wpexplorer {
  --fa: "\F2DE"; }

.fa-dyalog {
  --fa: "\F399"; }

.fa-bity {
  --fa: "\F37A"; }

.fa-stackpath {
  --fa: "\F842"; }

.fa-buysellads {
  --fa: "\F20D"; }

.fa-first-order {
  --fa: "\F2B0"; }

.fa-modx {
  --fa: "\F285"; }

.fa-guilded {
  --fa: "\E07E"; }

.fa-vnv {
  --fa: "\F40B"; }

.fa-square-js {
  --fa: "\F3B9"; }

.fa-js-square {
  --fa: "\F3B9"; }

.fa-microsoft {
  --fa: "\F3CA"; }

.fa-qq {
  --fa: "\F1D6"; }

.fa-orcid {
  --fa: "\F8D2"; }

.fa-java {
  --fa: "\F4E4"; }

.fa-invision {
  --fa: "\F7B0"; }

.fa-creative-commons-pd-alt {
  --fa: "\F4ED"; }

.fa-centercode {
  --fa: "\F380"; }

.fa-glide-g {
  --fa: "\F2A6"; }

.fa-drupal {
  --fa: "\F1A9"; }

.fa-jxl {
  --fa: "\E67B"; }

.fa-dart-lang {
  --fa: "\E693"; }

.fa-hire-a-helper {
  --fa: "\F3B0"; }

.fa-creative-commons-by {
  --fa: "\F4E7"; }

.fa-unity {
  --fa: "\E049"; }

.fa-whmcs {
  --fa: "\F40D"; }

.fa-rocketchat {
  --fa: "\F3E8"; }

.fa-vk {
  --fa: "\F189"; }

.fa-untappd {
  --fa: "\F405"; }

.fa-mailchimp {
  --fa: "\F59E"; }

.fa-css3-alt {
  --fa: "\F38B"; }

.fa-square-reddit {
  --fa: "\F1A2"; }

.fa-reddit-square {
  --fa: "\F1A2"; }

.fa-vimeo-v {
  --fa: "\F27D"; }

.fa-contao {
  --fa: "\F26D"; }

.fa-square-font-awesome {
  --fa: "\E5AD"; }

.fa-deskpro {
  --fa: "\F38F"; }

.fa-brave {
  --fa: "\E63C"; }

.fa-sistrix {
  --fa: "\F3EE"; }

.fa-square-instagram {
  --fa: "\E055"; }

.fa-instagram-square {
  --fa: "\E055"; }

.fa-battle-net {
  --fa: "\F835"; }

.fa-the-red-yeti {
  --fa: "\F69D"; }

.fa-square-hacker-news {
  --fa: "\F3AF"; }

.fa-hacker-news-square {
  --fa: "\F3AF"; }

.fa-edge {
  --fa: "\F282"; }

.fa-threads {
  --fa: "\E618"; }

.fa-napster {
  --fa: "\F3D2"; }

.fa-square-snapchat {
  --fa: "\F2AD"; }

.fa-snapchat-square {
  --fa: "\F2AD"; }

.fa-google-plus-g {
  --fa: "\F0D5"; }

.fa-artstation {
  --fa: "\F77A"; }

.fa-markdown {
  --fa: "\F60F"; }

.fa-sourcetree {
  --fa: "\F7D3"; }

.fa-google-plus {
  --fa: "\F2B3"; }

.fa-diaspora {
  --fa: "\F791"; }

.fa-foursquare {
  --fa: "\F180"; }

.fa-stack-overflow {
  --fa: "\F16C"; }

.fa-github-alt {
  --fa: "\F113"; }

.fa-phoenix-squadron {
  --fa: "\F511"; }

.fa-pagelines {
  --fa: "\F18C"; }

.fa-algolia {
  --fa: "\F36C"; }

.fa-red-river {
  --fa: "\F3E3"; }

.fa-creative-commons-sa {
  --fa: "\F4EF"; }

.fa-safari {
  --fa: "\F267"; }

.fa-google {
  --fa: "\F1A0"; }

.fa-square-font-awesome-stroke {
  --fa: "\F35C"; }

.fa-font-awesome-alt {
  --fa: "\F35C"; }

.fa-atlassian {
  --fa: "\F77B"; }

.fa-linkedin-in {
  --fa: "\F0E1"; }

.fa-digital-ocean {
  --fa: "\F391"; }

.fa-nimblr {
  --fa: "\F5A8"; }

.fa-chromecast {
  --fa: "\F838"; }

.fa-evernote {
  --fa: "\F839"; }

.fa-hacker-news {
  --fa: "\F1D4"; }

.fa-creative-commons-sampling {
  --fa: "\F4F0"; }

.fa-adversal {
  --fa: "\F36A"; }

.fa-creative-commons {
  --fa: "\F25E"; }

.fa-watchman-monitoring {
  --fa: "\E087"; }

.fa-fonticons {
  --fa: "\F280"; }

.fa-weixin {
  --fa: "\F1D7"; }

.fa-shirtsinbulk {
  --fa: "\F214"; }

.fa-codepen {
  --fa: "\F1CB"; }

.fa-git-alt {
  --fa: "\F841"; }

.fa-lyft {
  --fa: "\F3C3"; }

.fa-rev {
  --fa: "\F5B2"; }

.fa-windows {
  --fa: "\F17A"; }

.fa-wizards-of-the-coast {
  --fa: "\F730"; }

.fa-square-viadeo {
  --fa: "\F2AA"; }

.fa-viadeo-square {
  --fa: "\F2AA"; }

.fa-meetup {
  --fa: "\F2E0"; }

.fa-centos {
  --fa: "\F789"; }

.fa-adn {
  --fa: "\F170"; }

.fa-cloudsmith {
  --fa: "\F384"; }

.fa-opensuse {
  --fa: "\E62B"; }

.fa-pied-piper-alt {
  --fa: "\F1A8"; }

.fa-square-dribbble {
  --fa: "\F397"; }

.fa-dribbble-square {
  --fa: "\F397"; }

.fa-codiepie {
  --fa: "\F284"; }

.fa-node {
  --fa: "\F419"; }

.fa-mix {
  --fa: "\F3CB"; }

.fa-steam {
  --fa: "\F1B6"; }

.fa-cc-apple-pay {
  --fa: "\F416"; }

.fa-scribd {
  --fa: "\F28A"; }

.fa-debian {
  --fa: "\E60B"; }

.fa-openid {
  --fa: "\F19B"; }

.fa-instalod {
  --fa: "\E081"; }

.fa-files-pinwheel {
  --fa: "\E69F"; }

.fa-expeditedssl {
  --fa: "\F23E"; }

.fa-sellcast {
  --fa: "\F2DA"; }

.fa-square-twitter {
  --fa: "\F081"; }

.fa-twitter-square {
  --fa: "\F081"; }

.fa-r-project {
  --fa: "\F4F7"; }

.fa-delicious {
  --fa: "\F1A5"; }

.fa-freebsd {
  --fa: "\F3A4"; }

.fa-vuejs {
  --fa: "\F41F"; }

.fa-accusoft {
  --fa: "\F369"; }

.fa-ioxhost {
  --fa: "\F208"; }

.fa-fonticons-fi {
  --fa: "\F3A2"; }

.fa-app-store {
  --fa: "\F36F"; }

.fa-cc-mastercard {
  --fa: "\F1F1"; }

.fa-itunes-note {
  --fa: "\F3B5"; }

.fa-golang {
  --fa: "\E40F"; }

.fa-kickstarter {
  --fa: "\F3BB"; }

.fa-square-kickstarter {
  --fa: "\F3BB"; }

.fa-grav {
  --fa: "\F2D6"; }

.fa-weibo {
  --fa: "\F18A"; }

.fa-uncharted {
  --fa: "\E084"; }

.fa-firstdraft {
  --fa: "\F3A1"; }

.fa-square-youtube {
  --fa: "\F431"; }

.fa-youtube-square {
  --fa: "\F431"; }

.fa-wikipedia-w {
  --fa: "\F266"; }

.fa-wpressr {
  --fa: "\F3E4"; }

.fa-rendact {
  --fa: "\F3E4"; }

.fa-angellist {
  --fa: "\F209"; }

.fa-galactic-republic {
  --fa: "\F50C"; }

.fa-nfc-directional {
  --fa: "\E530"; }

.fa-skype {
  --fa: "\F17E"; }

.fa-joget {
  --fa: "\F3B7"; }

.fa-fedora {
  --fa: "\F798"; }

.fa-stripe-s {
  --fa: "\F42A"; }

.fa-meta {
  --fa: "\E49B"; }

.fa-laravel {
  --fa: "\F3BD"; }

.fa-hotjar {
  --fa: "\F3B1"; }

.fa-bluetooth-b {
  --fa: "\F294"; }

.fa-square-letterboxd {
  --fa: "\E62E"; }

.fa-sticker-mule {
  --fa: "\F3F7"; }

.fa-creative-commons-zero {
  --fa: "\F4F3"; }

.fa-hips {
  --fa: "\F452"; }

.fa-css {
  --fa: "\E6A2"; }

.fa-behance {
  --fa: "\F1B4"; }

.fa-reddit {
  --fa: "\F1A1"; }

.fa-discord {
  --fa: "\F392"; }

.fa-chrome {
  --fa: "\F268"; }

.fa-app-store-ios {
  --fa: "\F370"; }

.fa-cc-discover {
  --fa: "\F1F2"; }

.fa-wpbeginner {
  --fa: "\F297"; }

.fa-confluence {
  --fa: "\F78D"; }

.fa-shoelace {
  --fa: "\E60C"; }

.fa-mdb {
  --fa: "\F8CA"; }

.fa-dochub {
  --fa: "\F394"; }

.fa-accessible-icon {
  --fa: "\F368"; }

.fa-ebay {
  --fa: "\F4F4"; }

.fa-amazon {
  --fa: "\F270"; }

.fa-unsplash {
  --fa: "\E07C"; }

.fa-yarn {
  --fa: "\F7E3"; }

.fa-square-steam {
  --fa: "\F1B7"; }

.fa-steam-square {
  --fa: "\F1B7"; }

.fa-500px {
  --fa: "\F26E"; }

.fa-square-vimeo {
  --fa: "\F194"; }

.fa-vimeo-square {
  --fa: "\F194"; }

.fa-asymmetrik {
  --fa: "\F372"; }

.fa-font-awesome {
  --fa: "\F2B4"; }

.fa-font-awesome-flag {
  --fa: "\F2B4"; }

.fa-font-awesome-logo-full {
  --fa: "\F2B4"; }

.fa-gratipay {
  --fa: "\F184"; }

.fa-apple {
  --fa: "\F179"; }

.fa-hive {
  --fa: "\E07F"; }

.fa-gitkraken {
  --fa: "\F3A6"; }

.fa-keybase {
  --fa: "\F4F5"; }

.fa-apple-pay {
  --fa: "\F415"; }

.fa-padlet {
  --fa: "\E4A0"; }

.fa-amazon-pay {
  --fa: "\F42C"; }

.fa-square-github {
  --fa: "\F092"; }

.fa-github-square {
  --fa: "\F092"; }

.fa-stumbleupon {
  --fa: "\F1A4"; }

.fa-fedex {
  --fa: "\F797"; }

.fa-phoenix-framework {
  --fa: "\F3DC"; }

.fa-shopify {
  --fa: "\E057"; }

.fa-neos {
  --fa: "\F612"; }

.fa-square-threads {
  --fa: "\E619"; }

.fa-hackerrank {
  --fa: "\F5F7"; }

.fa-researchgate {
  --fa: "\F4F8"; }

.fa-swift {
  --fa: "\F8E1"; }

.fa-angular {
  --fa: "\F420"; }

.fa-speakap {
  --fa: "\F3F3"; }

.fa-angrycreative {
  --fa: "\F36E"; }

.fa-y-combinator {
  --fa: "\F23B"; }

.fa-empire {
  --fa: "\F1D1"; }

.fa-envira {
  --fa: "\F299"; }

.fa-google-scholar {
  --fa: "\E63B"; }

.fa-square-gitlab {
  --fa: "\E5AE"; }

.fa-gitlab-square {
  --fa: "\E5AE"; }

.fa-studiovinari {
  --fa: "\F3F8"; }

.fa-pied-piper {
  --fa: "\F2AE"; }

.fa-wordpress {
  --fa: "\F19A"; }

.fa-product-hunt {
  --fa: "\F288"; }

.fa-firefox {
  --fa: "\F269"; }

.fa-linode {
  --fa: "\F2B8"; }

.fa-goodreads {
  --fa: "\F3A8"; }

.fa-square-odnoklassniki {
  --fa: "\F264"; }

.fa-odnoklassniki-square {
  --fa: "\F264"; }

.fa-jsfiddle {
  --fa: "\F1CC"; }

.fa-sith {
  --fa: "\F512"; }

.fa-themeisle {
  --fa: "\F2B2"; }

.fa-page4 {
  --fa: "\F3D7"; }

.fa-hashnode {
  --fa: "\E499"; }

.fa-react {
  --fa: "\F41B"; }

.fa-cc-paypal {
  --fa: "\F1F4"; }

.fa-squarespace {
  --fa: "\F5BE"; }

.fa-cc-stripe {
  --fa: "\F1F5"; }

.fa-creative-commons-share {
  --fa: "\F4F2"; }

.fa-bitcoin {
  --fa: "\F379"; }

.fa-keycdn {
  --fa: "\F3BA"; }

.fa-opera {
  --fa: "\F26A"; }

.fa-itch-io {
  --fa: "\F83A"; }

.fa-umbraco {
  --fa: "\F8E8"; }

.fa-galactic-senate {
  --fa: "\F50D"; }

.fa-ubuntu {
  --fa: "\F7DF"; }

.fa-draft2digital {
  --fa: "\F396"; }

.fa-stripe {
  --fa: "\F429"; }

.fa-houzz {
  --fa: "\F27C"; }

.fa-gg {
  --fa: "\F260"; }

.fa-dhl {
  --fa: "\F790"; }

.fa-square-pinterest {
  --fa: "\F0D3"; }

.fa-pinterest-square {
  --fa: "\F0D3"; }

.fa-xing {
  --fa: "\F168"; }

.fa-blackberry {
  --fa: "\F37B"; }

.fa-creative-commons-pd {
  --fa: "\F4EC"; }

.fa-playstation {
  --fa: "\F3DF"; }

.fa-quinscape {
  --fa: "\F459"; }

.fa-less {
  --fa: "\F41D"; }

.fa-blogger-b {
  --fa: "\F37D"; }

.fa-opencart {
  --fa: "\F23D"; }

.fa-vine {
  --fa: "\F1CA"; }

.fa-signal-messenger {
  --fa: "\E663"; }

.fa-paypal {
  --fa: "\F1ED"; }

.fa-gitlab {
  --fa: "\F296"; }

.fa-typo3 {
  --fa: "\F42B"; }

.fa-reddit-alien {
  --fa: "\F281"; }

.fa-yahoo {
  --fa: "\F19E"; }

.fa-dailymotion {
  --fa: "\E052"; }

.fa-affiliatetheme {
  --fa: "\F36B"; }

.fa-pied-piper-pp {
  --fa: "\F1A7"; }

.fa-bootstrap {
  --fa: "\F836"; }

.fa-odnoklassniki {
  --fa: "\F263"; }

.fa-nfc-symbol {
  --fa: "\E531"; }

.fa-mintbit {
  --fa: "\E62F"; }

.fa-ethereum {
  --fa: "\F42E"; }

.fa-speaker-deck {
  --fa: "\F83C"; }

.fa-creative-commons-nc-eu {
  --fa: "\F4E9"; }

.fa-patreon {
  --fa: "\F3D9"; }

.fa-avianex {
  --fa: "\F374"; }

.fa-ello {
  --fa: "\F5F1"; }

.fa-gofore {
  --fa: "\F3A7"; }

.fa-bimobject {
  --fa: "\F378"; }

.fa-brave-reverse {
  --fa: "\E63D"; }

.fa-facebook-f {
  --fa: "\F39E"; }

.fa-square-google-plus {
  --fa: "\F0D4"; }

.fa-google-plus-square {
  --fa: "\F0D4"; }

.fa-web-awesome {
  --fa: "\E682"; }

.fa-mandalorian {
  --fa: "\F50F"; }

.fa-first-order-alt {
  --fa: "\F50A"; }

.fa-osi {
  --fa: "\F41A"; }

.fa-google-wallet {
  --fa: "\F1EE"; }

.fa-d-and-d-beyond {
  --fa: "\F6CA"; }

.fa-periscope {
  --fa: "\F3DA"; }

.fa-fulcrum {
  --fa: "\F50B"; }

.fa-cloudscale {
  --fa: "\F383"; }

.fa-forumbee {
  --fa: "\F211"; }

.fa-mizuni {
  --fa: "\F3CC"; }

.fa-schlix {
  --fa: "\F3EA"; }

.fa-square-xing {
  --fa: "\F169"; }

.fa-xing-square {
  --fa: "\F169"; }

.fa-bandcamp {
  --fa: "\F2D5"; }

.fa-wpforms {
  --fa: "\F298"; }

.fa-cloudversify {
  --fa: "\F385"; }

.fa-usps {
  --fa: "\F7E1"; }

.fa-megaport {
  --fa: "\F5A3"; }

.fa-magento {
  --fa: "\F3C4"; }

.fa-spotify {
  --fa: "\F1BC"; }

.fa-optin-monster {
  --fa: "\F23C"; }

.fa-fly {
  --fa: "\F417"; }

.fa-square-bluesky {
  --fa: "\E6A3"; }

.fa-aviato {
  --fa: "\F421"; }

.fa-itunes {
  --fa: "\F3B4"; }

.fa-cuttlefish {
  --fa: "\F38C"; }

.fa-blogger {
  --fa: "\F37C"; }

.fa-flickr {
  --fa: "\F16E"; }

.fa-viber {
  --fa: "\F409"; }

.fa-soundcloud {
  --fa: "\F1BE"; }

.fa-digg {
  --fa: "\F1A6"; }

.fa-tencent-weibo {
  --fa: "\F1D5"; }

.fa-letterboxd {
  --fa: "\E62D"; }

.fa-symfony {
  --fa: "\F83D"; }

.fa-maxcdn {
  --fa: "\F136"; }

.fa-etsy {
  --fa: "\F2D7"; }

.fa-facebook-messenger {
  --fa: "\F39F"; }

.fa-audible {
  --fa: "\F373"; }

.fa-think-peaks {
  --fa: "\F731"; }

.fa-bilibili {
  --fa: "\E3D9"; }

.fa-erlang {
  --fa: "\F39D"; }

.fa-x-twitter {
  --fa: "\E61B"; }

.fa-cotton-bureau {
  --fa: "\F89E"; }

.fa-dashcube {
  --fa: "\F210"; }

.fa-42-group {
  --fa: "\E080"; }

.fa-innosoft {
  --fa: "\E080"; }

.fa-stack-exchange {
  --fa: "\F18D"; }

.fa-elementor {
  --fa: "\F430"; }

.fa-square-pied-piper {
  --fa: "\E01E"; }

.fa-pied-piper-square {
  --fa: "\E01E"; }

.fa-creative-commons-nd {
  --fa: "\F4EB"; }

.fa-palfed {
  --fa: "\F3D8"; }

.fa-superpowers {
  --fa: "\F2DD"; }

.fa-resolving {
  --fa: "\F3E7"; }

.fa-xbox {
  --fa: "\F412"; }

.fa-square-web-awesome-stroke {
  --fa: "\E684"; }

.fa-searchengin {
  --fa: "\F3EB"; }

.fa-tiktok {
  --fa: "\E07B"; }

.fa-square-facebook {
  --fa: "\F082"; }

.fa-facebook-square {
  --fa: "\F082"; }

.fa-renren {
  --fa: "\F18B"; }

.fa-linux {
  --fa: "\F17C"; }

.fa-glide {
  --fa: "\F2A5"; }

.fa-linkedin {
  --fa: "\F08C"; }

.fa-hubspot {
  --fa: "\F3B2"; }

.fa-deploydog {
  --fa: "\F38E"; }

.fa-twitch {
  --fa: "\F1E8"; }

.fa-flutter {
  --fa: "\E694"; }

.fa-ravelry {
  --fa: "\F2D9"; }

.fa-mixer {
  --fa: "\E056"; }

.fa-square-lastfm {
  --fa: "\F203"; }

.fa-lastfm-square {
  --fa: "\F203"; }

.fa-vimeo {
  --fa: "\F40A"; }

.fa-mendeley {
  --fa: "\F7B3"; }

.fa-uniregistry {
  --fa: "\F404"; }

.fa-figma {
  --fa: "\F799"; }

.fa-creative-commons-remix {
  --fa: "\F4EE"; }

.fa-cc-amazon-pay {
  --fa: "\F42D"; }

.fa-dropbox {
  --fa: "\F16B"; }

.fa-instagram {
  --fa: "\F16D"; }

.fa-cmplid {
  --fa: "\E360"; }

.fa-upwork {
  --fa: "\E641"; }

.fa-facebook {
  --fa: "\F09A"; }

.fa-gripfire {
  --fa: "\F3AC"; }

.fa-jedi-order {
  --fa: "\F50E"; }

.fa-uikit {
  --fa: "\F403"; }

.fa-fort-awesome-alt {
  --fa: "\F3A3"; }

.fa-phabricator {
  --fa: "\F3DB"; }

.fa-ussunnah {
  --fa: "\F407"; }

.fa-earlybirds {
  --fa: "\F39A"; }

.fa-trade-federation {
  --fa: "\F513"; }

.fa-autoprefixer {
  --fa: "\F41C"; }

.fa-whatsapp {
  --fa: "\F232"; }

.fa-square-upwork {
  --fa: "\E67C"; }

.fa-slideshare {
  --fa: "\F1E7"; }

.fa-google-play {
  --fa: "\F3AB"; }

.fa-viadeo {
  --fa: "\F2A9"; }

.fa-line {
  --fa: "\F3C0"; }

.fa-google-drive {
  --fa: "\F3AA"; }

.fa-servicestack {
  --fa: "\F3EC"; }

.fa-simplybuilt {
  --fa: "\F215"; }

.fa-bitbucket {
  --fa: "\F171"; }

.fa-imdb {
  --fa: "\F2D8"; }

.fa-deezer {
  --fa: "\E077"; }

.fa-raspberry-pi {
  --fa: "\F7BB"; }

.fa-jira {
  --fa: "\F7B1"; }

.fa-docker {
  --fa: "\F395"; }

.fa-screenpal {
  --fa: "\E570"; }

.fa-bluetooth {
  --fa: "\F293"; }

.fa-gitter {
  --fa: "\F426"; }

.fa-d-and-d {
  --fa: "\F38D"; }

.fa-microblog {
  --fa: "\E01A"; }

.fa-cc-diners-club {
  --fa: "\F24C"; }

.fa-gg-circle {
  --fa: "\F261"; }

.fa-pied-piper-hat {
  --fa: "\F4E5"; }

.fa-kickstarter-k {
  --fa: "\F3BC"; }

.fa-yandex {
  --fa: "\F413"; }

.fa-readme {
  --fa: "\F4D5"; }

.fa-html5 {
  --fa: "\F13B"; }

.fa-sellsy {
  --fa: "\F213"; }

.fa-square-web-awesome {
  --fa: "\E683"; }

.fa-sass {
  --fa: "\F41E"; }

.fa-wirsindhandwerk {
  --fa: "\E2D0"; }

.fa-wsh {
  --fa: "\E2D0"; }

.fa-buromobelexperte {
  --fa: "\F37F"; }

.fa-salesforce {
  --fa: "\F83B"; }

.fa-octopus-deploy {
  --fa: "\E082"; }

.fa-medapps {
  --fa: "\F3C6"; }

.fa-ns8 {
  --fa: "\F3D5"; }

.fa-pinterest-p {
  --fa: "\F231"; }

.fa-apper {
  --fa: "\F371"; }

.fa-fort-awesome {
  --fa: "\F286"; }

.fa-waze {
  --fa: "\F83F"; }

.fa-bluesky {
  --fa: "\E671"; }

.fa-cc-jcb {
  --fa: "\F24B"; }

.fa-snapchat {
  --fa: "\F2AB"; }

.fa-snapchat-ghost {
  --fa: "\F2AB"; }

.fa-fantasy-flight-games {
  --fa: "\F6DC"; }

.fa-rust {
  --fa: "\E07A"; }

.fa-wix {
  --fa: "\F5CF"; }

.fa-square-behance {
  --fa: "\F1B5"; }

.fa-behance-square {
  --fa: "\F1B5"; }

.fa-supple {
  --fa: "\F3F9"; }

.fa-webflow {
  --fa: "\E65C"; }

.fa-rebel {
  --fa: "\F1D0"; }

.fa-css3 {
  --fa: "\F13C"; }

.fa-staylinked {
  --fa: "\F3F5"; }

.fa-kaggle {
  --fa: "\F5FA"; }

.fa-space-awesome {
  --fa: "\E5AC"; }

.fa-deviantart {
  --fa: "\F1BD"; }

.fa-cpanel {
  --fa: "\F388"; }

.fa-goodreads-g {
  --fa: "\F3A9"; }

.fa-square-git {
  --fa: "\F1D2"; }

.fa-git-square {
  --fa: "\F1D2"; }

.fa-square-tumblr {
  --fa: "\F174"; }

.fa-tumblr-square {
  --fa: "\F174"; }

.fa-trello {
  --fa: "\F181"; }

.fa-creative-commons-nc-jp {
  --fa: "\F4EA"; }

.fa-get-pocket {
  --fa: "\F265"; }

.fa-perbyte {
  --fa: "\E083"; }

.fa-grunt {
  --fa: "\F3AD"; }

.fa-weebly {
  --fa: "\F5CC"; }

.fa-connectdevelop {
  --fa: "\F20E"; }

.fa-leanpub {
  --fa: "\F212"; }

.fa-black-tie {
  --fa: "\F27E"; }

.fa-themeco {
  --fa: "\F5C6"; }

.fa-python {
  --fa: "\F3E2"; }

.fa-android {
  --fa: "\F17B"; }

.fa-bots {
  --fa: "\E340"; }

.fa-free-code-camp {
  --fa: "\F2C5"; }

.fa-hornbill {
  --fa: "\F592"; }

.fa-js {
  --fa: "\F3B8"; }

.fa-ideal {
  --fa: "\E013"; }

.fa-git {
  --fa: "\F1D3"; }

.fa-dev {
  --fa: "\F6CC"; }

.fa-sketch {
  --fa: "\F7C6"; }

.fa-yandex-international {
  --fa: "\F414"; }

.fa-cc-amex {
  --fa: "\F1F3"; }

.fa-uber {
  --fa: "\F402"; }

.fa-github {
  --fa: "\F09B"; }

.fa-php {
  --fa: "\F457"; }

.fa-alipay {
  --fa: "\F642"; }

.fa-youtube {
  --fa: "\F167"; }

.fa-skyatlas {
  --fa: "\F216"; }

.fa-firefox-browser {
  --fa: "\E007"; }

.fa-replyd {
  --fa: "\F3E6"; }

.fa-suse {
  --fa: "\F7D6"; }

.fa-jenkins {
  --fa: "\F3B6"; }

.fa-twitter {
  --fa: "\F099"; }

.fa-rockrms {
  --fa: "\F3E9"; }

.fa-pinterest {
  --fa: "\F0D2"; }

.fa-buffer {
  --fa: "\F837"; }

.fa-npm {
  --fa: "\F3D4"; }

.fa-yammer {
  --fa: "\F840"; }

.fa-btc {
  --fa: "\F15A"; }

.fa-dribbble {
  --fa: "\F17D"; }

.fa-stumbleupon-circle {
  --fa: "\F1A3"; }

.fa-internet-explorer {
  --fa: "\F26B"; }

.fa-stubber {
  --fa: "\E5C7"; }

.fa-telegram {
  --fa: "\F2C6"; }

.fa-telegram-plane {
  --fa: "\F2C6"; }

.fa-old-republic {
  --fa: "\F510"; }

.fa-odysee {
  --fa: "\E5C6"; }

.fa-square-whatsapp {
  --fa: "\F40C"; }

.fa-whatsapp-square {
  --fa: "\F40C"; }

.fa-node-js {
  --fa: "\F3D3"; }

.fa-edge-legacy {
  --fa: "\E078"; }

.fa-slack {
  --fa: "\F198"; }

.fa-slack-hash {
  --fa: "\F198"; }

.fa-medrt {
  --fa: "\F3C8"; }

.fa-usb {
  --fa: "\F287"; }

.fa-tumblr {
  --fa: "\F173"; }

.fa-vaadin {
  --fa: "\F408"; }

.fa-quora {
  --fa: "\F2C4"; }

.fa-square-x-twitter {
  --fa: "\E61A"; }

.fa-reacteurope {
  --fa: "\F75D"; }

.fa-medium {
  --fa: "\F23A"; }

.fa-medium-m {
  --fa: "\F23A"; }

.fa-amilia {
  --fa: "\F36D"; }

.fa-mixcloud {
  --fa: "\F289"; }

.fa-flipboard {
  --fa: "\F44D"; }

.fa-viacoin {
  --fa: "\F237"; }

.fa-critical-role {
  --fa: "\F6C9"; }

.fa-sitrox {
  --fa: "\E44A"; }

.fa-discourse {
  --fa: "\F393"; }

.fa-joomla {
  --fa: "\F1AA"; }

.fa-mastodon {
  --fa: "\F4F6"; }

.fa-airbnb {
  --fa: "\F834"; }

.fa-wolf-pack-battalion {
  --fa: "\F514"; }

.fa-buy-n-large {
  --fa: "\F8A6"; }

.fa-gulp {
  --fa: "\F3AE"; }

.fa-creative-commons-sampling-plus {
  --fa: "\F4F1"; }

.fa-strava {
  --fa: "\F428"; }

.fa-ember {
  --fa: "\F423"; }

.fa-canadian-maple-leaf {
  --fa: "\F785"; }

.fa-teamspeak {
  --fa: "\F4F9"; }

.fa-pushed {
  --fa: "\F3E1"; }

.fa-wordpress-simple {
  --fa: "\F411"; }

.fa-nutritionix {
  --fa: "\F3D6"; }

.fa-wodu {
  --fa: "\E088"; }

.fa-google-pay {
  --fa: "\E079"; }

.fa-intercom {
  --fa: "\F7AF"; }

.fa-zhihu {
  --fa: "\F63F"; }

.fa-korvue {
  --fa: "\F42F"; }

.fa-pix {
  --fa: "\E43A"; }

.fa-steam-symbol {
  --fa: "\F3F6"; }
:root, :host {
  --fa-style-family-duotone: 'Font Awesome 6 Duotone';
  --fa-font-duotone: normal 900 1em/1 'Font Awesome 6 Duotone'; }

@font-face {
  font-family: 'Font Awesome 6 Duotone';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(/static/media/fa-duotone-900.058cf97e.woff2) format("woff2"), url(/static/media/fa-duotone-900.5f1cd537.ttf) format("truetype"); }

.fad,
.fa-duotone {
  position: relative;
  font-weight: 900;
  letter-spacing: normal; }

.fad::before,
.fa-duotone::before {
  position: absolute;
  color: inherit;
  color: var(--fa-primary-color, inherit);
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1); }

.fad::after,
.fa-duotone::after {
  color: inherit;
  color: var(--fa-secondary-color, inherit);
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4); }

.fa-swap-opacity .fad::before,
.fa-swap-opacity .fa-duotone::before,
.fad.fa-swap-opacity::before,
.fa-duotone.fa-swap-opacity::before {
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4); }

.fa-swap-opacity .fad::after,
.fa-swap-opacity .fa-duotone::after,
.fad.fa-swap-opacity::after,
.fa-duotone.fa-swap-opacity::after {
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1); }

.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: #fff;
  color: var(--fa-inverse, #fff); }

.fad.fa-stack-1x, .fad.fa-stack-2x,
.fa-duotone.fa-stack-1x, .fa-duotone.fa-stack-2x {
  position: absolute; }
:root, :host {
  --fa-style-family-classic: 'Font Awesome 6 Pro';
  --fa-font-light: normal 300 1em/1 'Font Awesome 6 Pro'; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(/static/media/fa-light-300.48093c8d.woff2) format("woff2"), url(/static/media/fa-light-300.13cb2d21.ttf) format("truetype"); }

.fal,
.fa-light {
  font-weight: 300; }
:root, :host {
  --fa-style-family-classic: 'Font Awesome 6 Pro';
  --fa-font-regular: normal 400 1em/1 'Font Awesome 6 Pro'; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(/static/media/fa-regular-400.f1f6b302.woff2) format("woff2"), url(/static/media/fa-regular-400.1e6d83db.ttf) format("truetype"); }

.far,
.fa-regular {
  font-weight: 400; }
:root, :host {
  --fa-style-family-classic: 'Font Awesome 6 Pro';
  --fa-font-solid: normal 900 1em/1 'Font Awesome 6 Pro'; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(/static/media/fa-solid-900.0bf5b479.woff2) format("woff2"), url(/static/media/fa-solid-900.5803286f.ttf) format("truetype"); }

.fas,
.fa-solid {
  font-weight: 900; }
:root, :host {
  --fa-style-family-classic: 'Font Awesome 6 Pro';
  --fa-font-thin: normal 100 1em/1 'Font Awesome 6 Pro'; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url(/static/media/fa-thin-100.537f2e1e.woff2) format("woff2"), url(/static/media/fa-thin-100.685c2e1e.ttf) format("truetype"); }

.fat,
.fa-thin {
  font-weight: 100; }
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-display: block;
  font-weight: 400;
  src: url(/static/media/fa-brands-400.b202664d.woff2) format("woff2"), url(/static/media/fa-brands-400.e9a507ba.ttf) format("truetype"); }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-display: block;
  font-weight: 900;
  src: url(/static/media/fa-solid-900.0bf5b479.woff2) format("woff2"), url(/static/media/fa-solid-900.5803286f.ttf) format("truetype"); }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-display: block;
  font-weight: 400;
  src: url(/static/media/fa-regular-400.f1f6b302.woff2) format("woff2"), url(/static/media/fa-regular-400.1e6d83db.ttf) format("truetype"); }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-display: block;
  font-weight: 300;
  src: url(/static/media/fa-light-300.48093c8d.woff2) format("woff2"), url(/static/media/fa-light-300.13cb2d21.ttf) format("truetype"); }

@font-face {
  font-family: 'Font Awesome 5 Duotone';
  font-display: block;
  font-weight: 900;
  src: url(/static/media/fa-duotone-900.058cf97e.woff2) format("woff2"), url(/static/media/fa-duotone-900.5f1cd537.ttf) format("truetype"); }
@font-face {
  font-family: 'FontAwesome';
  font-display: block;
  src: url(/static/media/fa-solid-900.0bf5b479.woff2) format("woff2"), url(/static/media/fa-solid-900.5803286f.ttf) format("truetype"); }

@font-face {
  font-family: 'FontAwesome';
  font-display: block;
  src: url(/static/media/fa-brands-400.b202664d.woff2) format("woff2"), url(/static/media/fa-brands-400.e9a507ba.ttf) format("truetype"); }

@font-face {
  font-family: 'FontAwesome';
  font-display: block;
  src: url(/static/media/fa-regular-400.f1f6b302.woff2) format("woff2"), url(/static/media/fa-regular-400.1e6d83db.ttf) format("truetype");
  unicode-range: U+F003,U+F006,U+F014,U+F016-F017,U+F01A-F01B,U+F01D,U+F022,U+F03E,U+F044,U+F046,U+F05C-F05D,U+F06E,U+F070,U+F087-F088,U+F08A,U+F094,U+F096-F097,U+F09D,U+F0A0,U+F0A2,U+F0A4-F0A7,U+F0C5,U+F0C7,U+F0E5-F0E6,U+F0EB,U+F0F6-F0F8,U+F10C,U+F114-F115,U+F118-F11A,U+F11C-F11D,U+F133,U+F147,U+F14E,U+F150-F152,U+F185-F186,U+F18E,U+F190-F192,U+F196,U+F1C1-F1C9,U+F1D9,U+F1DB,U+F1E3,U+F1EA,U+F1F7,U+F1F9,U+F20A,U+F247-F248,U+F24A,U+F24D,U+F255-F25B,U+F25D,U+F271-F274,U+F278,U+F27B,U+F28C,U+F28E,U+F29C,U+F2B5,U+F2B7,U+F2BA,U+F2BC,U+F2BE,U+F2C0-F2C1,U+F2C3,U+F2D0,U+F2D2,U+F2D4,U+F2DC; }

@font-face {
  font-family: 'FontAwesome';
  font-display: block;
  src: url(/static/media/fa-v4compatibility.2c97f630.woff2) format("woff2"), url(/static/media/fa-v4compatibility.b6cdc930.ttf) format("truetype");
  unicode-range: U+F041,U+F047,U+F065-F066,U+F07D-F07E,U+F080,U+F08B,U+F08E,U+F090,U+F09A,U+F0AC,U+F0AE,U+F0B2,U+F0D0,U+F0D6,U+F0E4,U+F0EC,U+F10A-F10B,U+F123,U+F13E,U+F148-F149,U+F14C,U+F156,U+F15E,U+F160-F161,U+F163,U+F175-F178,U+F195,U+F1F8,U+F219,U+F27A; }

